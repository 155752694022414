<template>
  <v-app>
    <div class="dashboard-layout">
      <!-- Conditionally hide the dashboard layout when LLBuilder is active -->
      <div v-if="selectedMenu !== 'llbuilder'" class="dashboard-container">
        <!-- Left Column (Menu) -->
        <div class="dashboard-menu">
          <!-- Display Avatar above the menu -->
          <div class="avatar-border">
            <img :src="selectedAvatarPath" alt="User Avatar" class="avatar-image" />
          </div>

          <div class="menu-list">
            <div 
              :class="{ active: selectedMenu === 'homepage' }" 
              @click="selectMenu('homepage')"
              class="menu-item"
            >
              <font-awesome-icon :icon="['fas', 'home']" />
              <span class="menu-text">HOME PAGE</span>
            </div>
            <div v-if="isSuperUser || isTeacher"
              :class="{ active: selectedMenu === 'yagausers' }"
              @click="selectMenu('yagausers')"
              class="menu-item"
            >
              <font-awesome-icon :icon="['fas', 'user-shield']" />
              <span class="menu-text">YAGA USERS</span>
            </div>
            <div v-else 
              :class="{ active: selectedMenu === 'firststeps' }" 
              @click="selectMenu('firststeps')"
              class="menu-item"
            >
              <font-awesome-icon :icon="['fas', 'rocket']" />
              <span class="menu-text">FIRST STEPS</span>
            </div>
            <div 
              :class="{ active: selectedMenu === 'llbuilder' }" 
              @click="selectMenu('llbuilder')"
              class="menu-item"
            >
              <font-awesome-icon :icon="['fas', 'code-branch']" :rotation="90" />
              <span class="menu-text">LADDER LOGIC BUILDER</span>
            </div>
            <div 
              :class="{ active: selectedMenu === 'digitaltwin' }" 
              @click="selectMenu('digitaltwin')"
              class="menu-item"
            >
              <font-awesome-icon :icon="['fas', 'cubes']" />
              <span class="menu-text">TRAINING ENVIRONMENTS</span>
            </div>
            <div 
              :class="{ active: selectedMenu === 'iiotdata' }" 
              @click="selectMenu('iiotdata')"
              class="menu-item"
            >
              <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" />
              <span class="menu-text">VR/AV SIMULATION</span>
            </div>
            <div 
              :class="{ active: selectedMenu === 'profile' }" 
              @click="selectMenu('profile')"
              class="menu-item"
            >
              <font-awesome-icon :icon="['fas', 'user-circle']" />
              <span class="menu-text">PROFILE SETTINGS</span>
            </div>
          </div>
        </div>

        <!-- Right Column (Content Area) -->
        <div class="dashboard-content">
          <keep-alive>
            <component :is="activeComponent" :userId="editingUserId" @goBack="goBackToUserList"/>
          </keep-alive>
        </div>
      </div>

      <!-- Load LLBuilder in full screen when selected -->
        <LLBuilder v-if="selectedMenu === 'llbuilder'" @goHome="selectMenu('homepage')" />

      <!-- Footer Banner (only if dashboard layout is shown) -->
      <footer v-if="selectedMenu !== 'llbuilder'" class="dashboard-footer">
        <div class="footer-content">
          <img :src="logo" alt="Company Logo" class="company-logo" />
          © 2024 Medusa Digital. All Rights Reserved ©
        </div>
      </footer>
    </div>

    <div class="portrait-warning">
      <div class="svg-icons">
        <svg width="64" height="64" stroke-width="0.5"  viewBox="0 0 16 16">
          <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
          <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
        </svg>
        <svg width="64" height="64" stroke-width="0.5"  viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
        </svg>
        <svg width="64" height="64" stroke-width="0.5" viewBox="0 0 16 16">
          <path d="M1 4.5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm-1 6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2z"/>
          <path d="M14 7.5a1 1 0 1 0-2 0 1 1 0 0 0 2 0"/>
        </svg>
      </div>
      <div class="rotate-message">
        Please rotate your device 
      </div>
      <div class="rotate-message"> 
        to landscape mode
      </div>
      <div class="portrait-warning-logo">
        <img :src="logo" alt="Company Logo"/>
      </div>
    </div>
  </v-app>
</template>

<script>
import { ref, provide, computed, onMounted } from 'vue';
import axios from 'axios'; // Ensure axios is imported
import { useStore } from 'vuex'; // Add this import to use Vuex
import AVSimulation from '@/views/AVSimulation.vue';



// Import all avatars statically
import Avatar1 from '@/assets/MD-Avatar-1.webp';
import Avatar2 from '@/assets/MD-Avatar-2.webp';
import Avatar3 from '@/assets/MD-Avatar-3.webp';
import Avatar4 from '@/assets/MD-Avatar-4.webp';
import Avatar5 from '@/assets/MD-Avatar-5.webp';
import Avatar6 from '@/assets/MD-Avatar-6.webp';
import Avatar7 from '@/assets/MD-Avatar-7.webp';
import Avatar8 from '@/assets/MD-Avatar-8.webp';
import Avatar9 from '@/assets/MD-Avatar-9.webp';
import Avatar10 from '@/assets/MD-Avatar-10.webp';

import YagaHomePage from '@/views/YagaHomePage.vue';
import FirstSteps from '@/views/FirstSteps.vue';
import LLBuilder from '@/views/LLBuilder.vue';
import DigitalTwin from '@/views/DigitalTwin.vue';
import IIoTDataSources from '@/views/IIoTDataSources.vue';
import ProfileSettings from '@/views/ProfileSettings.vue';
import AddYagaUser from '@/views/AddYagaUser.vue';
import EditYagaUser from '@/views/EditYagaUser.vue';
import logo from '@/assets/Logo_Medusa_Digital_Dark.webp'; 

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

export default {
  name: 'YagaDashboard',
  components: {
    LLBuilder,
    FontAwesomeIcon,
    AVSimulation,
  },
  setup() {
    const store = useStore(); // Add this line to access the Vuex store
    const selectedMenu = computed(() => store.getters.getSelectedMenu);

    const avatarNumber = ref(localStorage.getItem('selectedAvatar') || 1);
    provide('avatarNumber', avatarNumber);

    const avatarPaths = [
      Avatar1, Avatar2, Avatar3, Avatar4, Avatar5,
      Avatar6, Avatar7, Avatar8, Avatar9, Avatar10
    ];

    const selectedAvatarPath = computed(() => avatarPaths[avatarNumber.value - 1]);

    // Check if the user is a superuser
    const isSuperUser = ref(false);
    const isTeacher = ref(false); // New state to track if the user is a teacher

    onMounted(async () => {
      try {
        const token = localStorage.getItem('jwt');  // Retrieve the JWT token from local storage
        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        const response = await axios.get('/yaga-auth/profile', {
          headers: {
            Authorization: `Bearer ${token}`  // Include token in the request headers
          }
        });

        isSuperUser.value = response.data.role === 'SuperAdmin';
        isTeacher.value = response.data.role === 'Teacher'; // Check if the user is a teacher
      } catch (error) {
        console.error('Error checking user role:', error.response ? error.response.data : error.message);
      }
    });

    return {
      store,
      selectedMenu,
      selectedAvatarPath,
      isSuperUser,
      isTeacher, // Return the isTeacher state
    };
  },
  data() {
    return {
      logo,
      editingUserId: null,
    };
  },
  computed: {
  activeComponent() {
    const selectedMenu = this.$store.getters.getSelectedMenu;  // Access selectedMenu from Vuex directly
    if (selectedMenu === 'yagausers' && this.editingUserId) {
      return EditYagaUser;
    }
    switch (selectedMenu) {
      case 'yagausers':
        return AddYagaUser;
      case 'firststeps':
        return FirstSteps;
      case 'digitaltwin':
        return DigitalTwin;
      case 'iiotdata':
        return IIoTDataSources;
      case 'profile':
        return ProfileSettings;
      case 'avsimulation': // Add this case
        return AVSimulation;
      default:
        return YagaHomePage;
    }
  }
},
  methods: {
    selectMenu(menu) {

          // Store any changes to IIoTDataSources before switching away
          if (this.selectedMenu === 'iiotdata') {
      // Save the current state of Ladder Logic Diagram and VR Scenario before switching tabs
      this.store.dispatch('setIIoTDataSources', {
        selectedDiagramId: this.store.getters.getIIoTDataSources.selectedDiagramId,
        selectedVRScenarioId: this.store.getters.getIIoTDataSources.selectedVRScenarioId,
        vrTagsTable: this.store.getters.getIIoTDataSources.vrTagsTable,
        variablesTable: this.store.getters.getIIoTDataSources.variablesTable,
      });
    }
    this.store.dispatch('setSelectedMenu', menu); // Set the selectedMenu via Vuex
    this.editingUserId = null; // Reset editing user ID when changing menu

  
  },
    editUser(userId) {
      if (this.isSuperUser) {
        this.editingUserId = userId;
      } else {
        console.error('Only SuperAdmins can edit users.');
      }
    },
    goBackToUserList() {
      this.editingUserId = null;
    }
  }
};
</script>


<style scoped>
.dashboard-layout {
  background: linear-gradient(to bottom, #000814, #0d1b2a); /* Background gradient */
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.dashboard-container {
  background: linear-gradient(to bottom, #000814, #0d1b2a); /* Background gradient */
  display: flex;
  flex-grow: 1; /* Ensures the content area grows to fill available space */
}

.dashboard-menu {
  width: 20%;
  background: linear-gradient(to bottom, #000814, #000000); /* Gradient background for left menu */
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 15px; /* Rounded upper right corner */
  border-bottom-right-radius: 15px; /* Rounded lower right corner */
  height: 93%;
  z-index: 2; /* Higher z-index to make sure it is on top */

}

.avatar-border {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #ffc300, #ffd60a); /* Yellow border background */
  border-radius: 50%;
  padding: 10px; /* Adjust to control the size of the border */
  width: 170px;
  height: 170px;
  margin: 10% auto 10% auto;
}

.avatar-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.menu-list {
  display: flex;
  flex-direction: column;
}

.menu-item {
  padding: 15px 10px;
  cursor: pointer;
  margin-bottom: 10px;
  text-align: left;
  border: none;
  border-top-right-radius: 15px; /* Rounded upper right corner */
  border-bottom-right-radius: 15px; /* Rounded lower right corner */
  background: linear-gradient(to bottom, #ffc300, #ffd60a); /* Default yellow background */
  color: #000814; /* Default text color */
  width: 100%; /* Ensure all buttons have the same width */
  display: flex;
  justify-content: flex-start;
  align-items: center; /* Align icons and text vertically */
  transition: background-color 0.3s ease; /* Smooth transition for button click */
  font-weight: bold; /* Bold font for menu items */
  font-size: 16px; /* Font size for menu items */
}

.menu-text {
  margin-left: 15px; /* Adds padding between the icon and the text */
}

.menu-item.active {
  background: linear-gradient(to bottom, #9ad7ff, #03abff); /* Selected blue background */
  color: #000814; /* Selected text color */
}

.menu-item:hover:not(.active) { /* Apply hover only if not active */
  background: linear-gradient(to bottom, #ffd966, #ffdd7b); /* Hover yellow background */
  color: #000814; /* Hover text color */
}

.dashboard-content {
  width: 80%;
  padding: 20px;
  height: 90%;
  z-index: 1; /* Lower z-index compared to the menu */

}

.dashboard-footer {
  background: linear-gradient(to bottom, #000814, #0d1b2a);
  color: white;
  text-align: center;
  padding: 5px 10px; /* Compressed padding for a smaller footer */
  font-size: 16px; /* Set your desired font size here */
  font-weight: bold;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000; /* Ensure footer stays on top */
  height: 7%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-logo {
  width: 120px; /* Adjust the size as needed */
}

.dashboard-footer span {
  margin-left: auto;
}


/* Styles for landscape mode */
@media only screen and (orientation: landscape) {
  /* Your landscape styles here */
  .portrait-warning {
    display: none;
  }
}

/* Styles for portrait mode */
@media only screen and (orientation: portrait) {
  /* Hide main content and show a warning in portrait mode */
  .dashboard-layout {
    display: none;
  }

  .portrait-warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to bottom, #000814, #0d1b2a);
  color: #ffd60a;
  stroke: #ffd60a;
  text-align: center;
  padding: 20px;
}

.portrait-warning-logo {
  position: bottom;
  padding: 150px;
}

.svg-icons {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.rotate-message {
  font-size: 24px;
}

}


/* Media query for devices with width below 950px */
@media (max-width: 950px) {
  .dashboard-menu {
  width: 25%;
}
.dashboard-content {
  width: 75%;

}
}

/* Media query for devices with width below 850px */
@media (max-width: 850px) {
  .dashboard-menu {
  width: 30%;
}
.dashboard-content {
  width: 70%;
}
}

/* Media query for devices with width below 850px */
@media (max-width: 750px) {
  .dashboard-menu {
  width: 35%;
}
.dashboard-content {
  width: 65%;
}
}

</style>
