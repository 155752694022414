<template>
  <v-app>
    <div id="app">
      <router-view v-slot="{ Component, route }">
        <div>
          <template v-if="!route.meta.hideHeaderFooter">
            <SiteHeader />
          </template>
          <component :is="Component" />
          <template v-if="!route.meta.hideHeaderFooter">
            <SiteFooter />
          </template>
        </div>
      </router-view>
    </div>
  </v-app>
</template>

<script>
import SiteHeader from '@/views/SiteHeader.vue';
import SiteFooter from '@/views/SiteFooter.vue';

export default {
  name: 'App',
  components: {
    SiteHeader,
    SiteFooter
  }
};
</script>

