<template>
    <div class="edit-user-container">
      <!-- Button to Go Back -->
      <button class="back-button" @click="goBack">
        <font-awesome-icon icon="arrow-left" /><span class="button-text">Go Back</span>
      </button>
  
      <!-- Edit User Form Section -->
      <div class="form-container">
        <h4>Edit User</h4>
        <!-- Row 1: Name and Last Name -->
        <div class="row">
          <div class="input-group">
            <label for="name" class="label-text">NAME</label>
            <input type="text" id="name" class="input-field" v-model="user.name" placeholder="First Name" />
          </div>
          <div class="input-group">
            <label for="lastName" class="label-text">LAST NAME</label>
            <input type="text" id="lastName" class="input-field" v-model="user.last_name" placeholder="Last Name" />
          </div>
        </div>
  
        <!-- Row 2: Email and Role -->
        <div class="row">
          <div class="input-group">
            <label for="email" class="label-text">EMAIL</label>
            <input type="email" id="email" class="input-field" v-model="user.email" placeholder="Email" />
          </div>
          <div class="input-group">
            <label for="role" class="label-text">ROLE</label>
            <select id="role" v-model="user.role" class="input-field">
              <option value="SuperAdmin">SuperAdmin</option>
              <option value="Teacher">Teacher</option>
              <option value="Student">Student</option>
            </select>
          </div>
        </div>

             <!-- Student Capacity (Only visible if editing a Teacher) -->
      <div v-if="user.role === 'Teacher' && isSuperAdmin" class="row">
        <div class="input-group">
          <label for="studentCapacity" class="label-text">STUDENT CAPACITY</label>
          <input type="number" id="studentCapacity" class="input-field" v-model.number="user.studentCapacity" placeholder="Student Capacity" />
        </div>
      </div>

           <!-- Modes and Projects (Only visible for SuperAdmin) -->
           <div v-if="isSuperAdmin && user.role === 'Teacher'" class="row">
        <div class="input-group">
          <label for="modes" class="label-text">MODES</label>
          <select id="modes" v-model="selectedMode" class="input-field">
            <option v-for="mode in modesOptions" :key="mode" :value="mode">{{ mode }}</option>
          </select>
        </div>
      </div>

      <div v-if="isSuperAdmin && user.role === 'Teacher'" class="row">
        <div class="input-group">
          <label class="label-text">PROJECTS</label>
          <!-- VR Mode Projects -->
          <div v-if="selectedMode === 'VR' || selectedMode === 'Both'">
            <label class="label-text-2">VR Mode Projects:</label>
            <div v-for="project in projectsVR" :key="project.id">
              <input type="checkbox" :value="project.id" v-model="selectedProjects" />
              <label class="label-text-3">{{ project.name }}</label>
            </div>
          </div>
          <!-- AV Mode Projects -->
          <div v-if="selectedMode === 'AV' || selectedMode === 'Both'">
            <label class="label-text-2">AV Mode Projects:</label>
            <div v-for="project in projectsAV" :key="project.id">
              <input type="checkbox" :value="project.id" v-model="selectedProjects" />
              <label class="label-text-3">{{ project.name }}</label>
            </div>
          </div>
        </div>
      </div>
  
        <!-- Row 3: Password Fields -->
        <div class="row">
          <div class="input-group">
            <label for="newPassword" class="label-text">New Password</label>
            <input 
              :type="passwordVisible ? 'text' : 'password'" 
              id="newPassword" 
              class="input-field" 
              v-model="passwords.new" 
              placeholder="New Password" 
            />
            <span class="eye-icon" @click="togglePasswordVisibility">
              <i :class="passwordVisible ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"></i>
            </span>
          </div>
          <div class="input-group">
            <label for="confirmPassword" class="label-text">Confirm Password</label>
            <input 
              :type="passwordVisible ? 'text' : 'password'" 
              id="confirmPassword" 
              class="input-field" 
              v-model="passwords.confirm" 
              placeholder="Confirm Password" 
            />
            <span class="eye-icon" @click="togglePasswordVisibility">
              <i :class="passwordVisible ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"></i>
            </span>
          </div>
        </div>
  
        <!-- Save Button -->
        <div class="row">
          <button class="save-button blue-button" @click="updateUser">
            UPDATE USER
          </button>
        </div>
        <!-- Feedback messages -->
        <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, watch } from 'vue';
  import axios from 'axios';
  
  export default {
    name: 'EditYagaUser',
    props: ['userId'], // Receive the userId as a prop
    setup(props, { emit }) {
      const user = ref({
        name: '',
        last_name: '',
        email: '',
        role: 'Student',
        studentCapacity: 0, // Add this for teacher's student capacity

      });
  
      const passwords = ref({
        new: '',
        confirm: '',
      });
  
      const passwordVisible = ref(false);
      const successMessage = ref('');
      const errorMessage = ref('');
  
    // Check if the user is a superuser
    const isSuperAdmin = ref(false);

    const selectedMode = ref('');
    const modesOptions = ref(['VR', 'AV', 'Both']);
    const projectsVR = ref([]);
    const projectsAV = ref([]);
    const selectedProjects = ref([]);

    // Check user role and load projects if SuperAdmin
    onMounted(async () => {
      const token = localStorage.getItem('jwt');
      if (!token) return;
      
      const profileResponse = await axios.get('/yaga-auth/profile', { headers: { Authorization: `Bearer ${token}` } });
      isSuperAdmin.value = profileResponse.data.role === 'SuperAdmin';
      
      if (isSuperAdmin.value) await loadProjects();
      await loadUser();
    });

    // Method to toggle password visibility
    const togglePasswordVisibility = () => {
      passwordVisible.value = !passwordVisible.value;
    };
  
    const loadProjects = async () => {
      try {
        const token = localStorage.getItem('jwt');
        if (!token) return;

        const response = await axios.get('/yaga-auth/projects', { headers: { Authorization: `Bearer ${token}` } });
        projectsVR.value = response.data.filter(project => project.mode === 'VR');
        projectsAV.value = response.data.filter(project => project.mode === 'AV');
      } catch (error) {
        errorMessage.value = 'Error loading projects.';
      }
    };

      // Method to load user data
      const loadUser = async () => {
        try {
          const token = localStorage.getItem('jwt'); // Retrieve the JWT token from local storage
          if (!token) {
            console.error("No token found in local storage");
            return;
          }
  
          const response = await axios.get(`/yaga-auth/user/${props.userId}`, {
            headers: {
              Authorization: `Bearer ${token}` // Include the token in the request headers
            }
          });
  
          user.value = response.data; // Set the user data
          console.log('Loaded user:', user.value); // Log the loaded user data
  
        } catch (error) {
          console.error('Error loading user:', error.response ? error.response.data : error.message);
          errorMessage.value = 'Error loading user.';
        }
      };
  
      // Method to update user
      const updateUser = async () => {
        try {
          if (passwords.value.new !== passwords.value.confirm) {
            errorMessage.value = "Passwords do not match.";
            return;
          }
  
          const payload = {
            ...user.value,
            password: passwords.value.new || null, // Only send the new password if it's provided
            studentCapacity: user.value.studentCapacity, // Include studentCapacity in the payload
            modes: selectedMode.value,
            projectIds: selectedProjects.value,

          };
  
          const token = localStorage.getItem('jwt'); // Retrieve the JWT token from local storage
          if (!token) {
            console.error("No token found in local storage");
            return;
          }
  
          const response = await axios.put(`/yaga-auth/user/${props.userId}`, payload, {
            headers: {
              Authorization: `Bearer ${token}` // Include the token in the request headers
            }
          });
  
          console.log('Response after updating user:', response.data);
          successMessage.value = 'User updated successfully!';
          errorMessage.value = '';
  
        } catch (error) {
          console.error('Error updating user:', error.response ? error.response.data : error.message);
          errorMessage.value = 'Error updating user. Please try again.';
          successMessage.value = '';
        }
      };

          // Method to go back to the user list
    const goBack = () => {
      emit('cancelEdit'); // Emit the cancelEdit event to the parent
    };
  
      // Load user data when the component is mounted or userId changes
      watch(() => props.userId, loadUser, { immediate: true });
  
      return {
        user,
        passwords,
        passwordVisible,
        togglePasswordVisibility,
        updateUser,
        successMessage,
        errorMessage,
        goBack, // No actual routing, just reloading the list
        isSuperAdmin, // Add this
        selectedMode,
        modesOptions,
        projectsVR,
        projectsAV,
        selectedProjects,
        loadProjects,
      };
    }
  };
  </script>
  
   
  
  <style scoped>
  .edit-user-container {
    padding: 20px;
  }
  
  /* Button to Go Back */
  .back-button {
    background: linear-gradient(to bottom, #ffc300, #ffd60a);
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .back-button:hover {
    filter: brightness(0.9);
  }
  
  .form-container {
    width: 100%;
  }
  
  h4 {
    color: #03abff;
  }
  
  .label-text {
    color: #ffc300;
    font-weight: bold;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    width: 48%; /* 48% to leave space for the two-column layout */
  }
  
  .input-field {
    background-color: #1b263b;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    color: lightgray;
    font-weight: normal;
  }
  
  .eye-icon {
    position: relative;
    align-self: flex-end;
    right: 10px;
    top: -35%;
    cursor: pointer;
    color: lightgray; /* Adjust color as needed */
  }
  
  .save-button {
    background: linear-gradient(to bottom, #9ad7ff, #03abff); /* Default gradient */
    color: #000814;
    border-radius: 25px;
    padding: 10px 30px;
    margin: 10px auto;
    display: block;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s, filter 0.3s; /* Smooth transition for the background and filter */
    border: none;
    outline: none;
  }
  
  .save-button:hover {
    filter: brightness(1.3); /* Darken the button on hover */
  }
  
  .save-button:active {
    box-shadow: inset 0 6px 8px rgba(0, 0, 0, 0.3); /* Simulate the button being pressed */
  }
  
  .success-message {
    color: #4CAF50;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .error-message {
    color: #FF5252;
    font-weight: bold;
    margin-top: 10px;
  }
  
  /* Apply styles to the select dropdown */
  select.input-field {
    background-color: #1b263b; /* Black background for the dropdown */
    color: white; /* White text color */
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
  }
  
  /* Styling the dropdown options */
  select.input-field option {
    background-color: #1b263b; /* Black background for options */
    color: white; /* White text color */
  }
  
  /* Highlight color for options */
  select.input-field option:hover {
    background-color: #ffc300; /* Yellow background on hover */
  }
  
  .button-text {
    margin-left: 10px;
  }
  .label-text-2 {
  color: #03abff;
  font-weight: bold;
}

.label-text-3 {
  color: #fffcf3;
  font-weight: bold;
}
  </style>
  