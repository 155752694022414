<template>
  <div class="digital-twin-container">
    <div class="header">
      <h3>Please Select a Training Environment</h3>
      <v-btn class="load-vr-button" @click="toggleScenarioList">
        Load Digital Twin
      </v-btn>
    </div>

    <!-- Show the scenario list when user clicks the Load Digital Twin button -->
    <div v-if="showScenarioList" class="scenario-list">
      <v-btn class="nav-button" :disabled="currentPage === 1" @click="previousPage">
        Previous
      </v-btn>

      <div class="scenarios">
        <!-- Display up to 3 scenarios per page -->
        <div
          v-for="scenario in paginatedScenarios"
          :key="scenario.id"
          class="scenario-item"
          @click="loadSelectedScene(scenario)"
          :class="{ selected: selectedScenario && selectedScenario.id === scenario.id }"
        >
          <img :src="scenario.thumbnail" alt="Scenario Thumbnail" />
          <h3>{{ scenario.name }}</h3>
        </div>
      </div>

      <v-btn class="nav-button" :disabled="currentPage === totalPages" @click="nextPage">
        Next
      </v-btn>
    </div>

    <!-- Currently Loaded Scenario -->
    <div v-if="loadedScenario" class="loaded-scenario-info">
      Currently Loaded: {{ loadedScenario.name }}
    </div>

    <!-- Scene Box -->
    <div v-if="loadedScenario" class="scene-box">
      <component :is="currentSceneComponent" />
    </div>

    <!-- Instructions Box -->
    <div v-if="loadedScenario && loadedScenario.instructions" class="instructions-box">
      <h2>{{ loadedScenario.instructions.title }}</h2>

      <h5>{{ loadedScenario.instructions.objective.subtitle }}</h5>
      <p v-html="formatTextWithBoldMarkers(loadedScenario.instructions.objective.description)" style="text-align: justify;"></p>

      <h5>{{ loadedScenario.instructions.equipmentOverview.subtitle }}</h5>
      <p v-html="formatTextWithBoldMarkers(loadedScenario.instructions.equipmentOverview.description)" style="text-align: justify;"></p>

      <h5>{{ loadedScenario.instructions.taskInstructions.subtitle }}</h5>
      <p v-html="formatTextWithBoldMarkers(loadedScenario.instructions.taskInstructions.description)" style="text-align: justify;"></p>

      <h5>{{ loadedScenario.instructions.stepsToFollow.subtitle }}</h5>
      <ul>
        <li
          v-for="(step, index) in loadedScenario.instructions.stepsToFollow.steps"
          :key="index"
          v-html="formatTextWithBoldMarkers(step)"
          style="text-align: justify;"
        ></li>
      </ul>

      <h5>{{ loadedScenario.instructions.keyConcept.subtitle }}</h5>
      <p v-html="formatTextWithBoldMarkers(loadedScenario.instructions.keyConcept.description)" style="text-align: justify;"></p>

      <h5>{{ loadedScenario.instructions.exampleOutline.subtitle }}</h5>
      <ul>
        <li
          v-for="(step, index) in loadedScenario.instructions.exampleOutline.steps"
          :key="index"
          v-html="formatTextWithBoldMarkers(step)"
          style="text-align: justify;"
        ></li>
      </ul>
    </div>

  </div>
</template>

<script>
import { vrScenarios } from "./vrScenarios.js"; // Adjust the path as necessary
import { avScenarios } from "./avScenarios.js";
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
import axios from "axios";

export default {
  name: "DigitalTwin",
  setup() {
    const store = useStore();

    // Reactive variables
    const selectedScenario = ref(null);
    const loadedScenario = ref(null);
    const currentSceneComponent = ref(null); // Unified component for both VR and AV
    const currentPage = ref(1);
    const itemsPerPage = 3;
    const showScenarioList = ref(false);

    // Combined scenarios data
    const scenariosList = ref([]); // Combined list of accessible scenarios
    const accessibleProjects = ref([]);

    // Computed properties for pagination
    const totalPages = computed(() =>
      Math.ceil(scenariosList.value.length / itemsPerPage)
    );
    const paginatedScenarios = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      return scenariosList.value.slice(start, start + itemsPerPage);
    });

    // Toggle scenario list visibility
    const toggleScenarioList = () => {
      showScenarioList.value = !showScenarioList.value;
    };

    // Load selected scene component
    const loadSelectedScene = (scenario) => {
      if (scenario) {
        selectedScenario.value = scenario;
        loadedScenario.value = scenario;
        currentSceneComponent.value = scenario.component; // Use unified variable
        showScenarioList.value = false; // Hide the scenario list after loading the scene
        store.dispatch("setDigitalTwinToRun", { scenarioId: scenario.id });
      }
    };

    // Pagination methods
    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    // Fetch accessible projects on mount
    const loadAccessibleProjects = async () => {
      try {
        const response = await axios.get("/yaga-auth/accessible-projects", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        accessibleProjects.value = response.data;

        // Filter VR scenarios based on accessible projects
        const filteredVrScenarios = vrScenarios.filter((scenario) => {
          return accessibleProjects.value.some(
            (project) =>
              project.name === scenario.project &&
              (project.mode === "VR" || project.mode === "Both")
          );
        });

        // Filter AV scenarios based on accessible projects
        const filteredAvScenarios = avScenarios.filter((scenario) => {
          return accessibleProjects.value.some(
            (project) =>
              project.name === scenario.project &&
              (project.mode === "AV" || project.mode === "Both")
          );
        });

        // Combine the filtered VR and AV scenarios
        scenariosList.value = [...filteredVrScenarios, ...filteredAvScenarios];
      } catch (error) {
        console.error(
          "Failed to load accessible projects:",
          error.response ? error.response.data : error.message
        );
      }
    };
    function formatTextWithBoldMarkers(text) {
  return text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
}


    // Load the previously selected scenario if available
    onMounted(async () => {
      await loadAccessibleProjects();
      const digitalTwinData = store.getters.getDigitalTwin;
      if (digitalTwinData && digitalTwinData.scenarioId) {
        const selectedScenario = scenariosList.value.find(
          (s) => s.id === digitalTwinData.scenarioId
        );
        if (selectedScenario) {
          loadSelectedScene(selectedScenario);
        }
      }
    });

    return {
      scenariosList,
      selectedScenario,
      loadedScenario,
      currentSceneComponent, // Unified scene component reference
      currentPage,
      itemsPerPage,
      showScenarioList,
      totalPages,
      paginatedScenarios,
      toggleScenarioList,
      loadSelectedScene,
      previousPage,
      nextPage,
      formatTextWithBoldMarkers,
    };
  },
};
</script>

<style scoped>
.digital-twin-container {
  padding: 20px;
}

h3 {
  color: #03abff;
  font-size: 1.5rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.load-vr-button {
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.load-vr-button:hover {
  filter: brightness(0.9);
}

.scenario-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.nav-button {
  background: linear-gradient(to bottom, #03abff, #005792);
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.scenarios {
  display: flex;
  gap: 20px;
}

.scenario-item {
  border: 2px solid #03abff;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  width: 150px;
}

.scenario-item.selected {
  border-color: #ffc300;
}

.scenario-item img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.loaded-scenario-info {
  margin-top: 20px;
  padding: 10px;
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: #000814;
  font-weight: bold;
  border-radius: 5px;
}

.scene-box {
  margin-top: 20px;
  border: 2px solid #03abff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 100%;
}

.instructions-box {
  margin-top: 20px;
  padding: 20px;
  border: 2px solid #03abff;
  border-radius: 10px;
}

.instructions-box h2,
.instructions-box h5 {
  color: #ffc300;
}

.instructions-box p,
.instructions-box ul,
.instructions-box li {
  color: white;
  text-align: justify;
}

.instructions-box b {
  font-weight: bold;
}
</style>
