<template>
  <div>
    <v-container     :class="[
      'main-container',
      isLeftColumnVisible ? 'full-screen' : 'fill-parent',
    ]" fluid>
      <!-- Here it begins the template section for the left-column tools menu  -->
      <v-app-bar class="custom-app-bar" v-if="showHeader">
        <div class="header-left">
          <v-btn
            icon
            class="custom-button yellow-button"
            @mouseover="showTooltip('New File', $event)"
            @mouseleave="hideTooltip"
            @click="newProjectDialog = true"
          >
            <font-awesome-icon :icon="['fas', 'file']" />
          </v-btn>
          <v-btn
            class="pill-button light-blue-button"
            @mouseover="showTooltip('Save As', $event)"
            @mouseleave="hideTooltip"
            @click="showSaveDiagramDialogMethod"
          >
            Save As
          </v-btn>

          <v-btn
            class="pill-button light-blue-button"
            @mouseover="showTooltip('Save', $event)"
            @mouseleave="hideTooltip"
            @click="saveCurrentDiagram"
          >
            Save
          </v-btn>


          <v-btn
            class="pill-button light-blue-button"
            @mouseover="showTooltip('Open File', $event)"
            @mouseleave="hideTooltip"
            @click="fetchUserDiagrams"
          >
            Open
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <img :src="logo" alt="Company Logo" class="company-logo" />
        <v-spacer></v-spacer>
        <div class="header-right">

          <!-- Run/Stop Simulation Button -->
          <v-btn
            icon
            :class="isSimulating ? 'custom-red-button' : 'custom-green-button'"
            @mouseover="showTooltip(isSimulating ? 'Stop Simulation' : 'Run Simulation', $event)"
            @mouseleave="hideTooltip"
            @click="toggleSimulation"
          >
            <font-awesome-icon :icon="[isSimulating ? 'fas' : 'fas', isSimulating ? 'stop' : 'play']" />
          </v-btn>

          <v-btn
            icon
            class="custom-button blue-button"
            @mouseover="showTooltip('Undo', $event)"
            @mouseleave="hideTooltip"
            @click="undo"
          >
            <font-awesome-icon :icon="['fas', 'undo']" />
          </v-btn>
          <v-btn
            icon
            class="custom-button blue-button"
            @mouseover="showTooltip('Redo', $event)"
            @mouseleave="hideTooltip"
            @click="redo"
          >
            <font-awesome-icon :icon="['fas', 'redo']" />
          </v-btn>
          <v-btn
            icon
            class="custom-button blue-button"
            @mouseover="showTooltip('Share File', $event)"
            @mouseleave="hideTooltip"
            @click="showShareDiagramDialogMethod"
          >
            <font-awesome-icon :icon="['fas', 'share']" />
          </v-btn>
          <v-btn
            icon
            class="custom-button yellow-button"
            @mouseover="showTooltip('Go Back Home', $event)"
            @mouseleave="hideTooltip"
            @click="goHome"
          >
            <font-awesome-icon :icon="['fas', 'home']" />
          </v-btn>
        </div>
      </v-app-bar>
      <!-- Here it ends the template section for the header menu -->
      
      <!-- Here it begins the template section for the header menu -->
      <v-container :class="['inner-container', isLeftColumnVisible ? 'with-left-column' : 'no-left-column']" fluid>
        <v-row no-gutters>
          <v-col cols="3" class="left-column" v-if="showLeftColumn">
            <!-- Tabs Container -->
            <div class="tabs-container">
              <v-tabs
                vertical
                v-model="tab"
                class="full-width-tabs"
                background-color="transparent"
                show-arrows="false"
              >
                <v-tab key="ld_editor">Tools</v-tab>
                <v-tab key="variables">Variables</v-tab>
              </v-tabs>
            </div>
            <!-- Tools Container -->
            <div class="tools-container">
              <v-tabs-items v-model="tab">
                <v-tab-item key="ld_editor">
                  <div v-if="tab === 0">
                    
                    <div class="section-title">CONTACTS</div>
                    <div class="section-line"></div>
                    <v-row>
                  <v-col cols="3">
                    <v-btn
                      class="custom-blue-button"
                      @mouseover="showTooltip2('Normally OPEN Contact', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('contact_no')"
                      @dragstart="startDrag('contact_no')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <svg width="64" height="64" viewBox="0 0 72 72">
                        <line x1="0" y1="36" x2="28" y2="36" stroke="#000814" stroke-width="4" />
                        <line x1="44" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4" />
                        <line x1="28" y1="18" x2="28" y2="54" stroke="#000814" stroke-width="4" />
                        <line x1="44" y1="18" x2="44" y2="54" stroke="#000814" stroke-width="4" />
                      </svg>
                      </v-btn>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      class="custom-blue-button"
                      @mouseover="showTooltip2('Normally CLOSED Contact', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('contact_nc')"
                      @dragstart="startDrag('contact_nc')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <svg width="64" height="64" viewBox="0 0 72 72">
                        <line x1="0" y1="36" x2="28" y2="36" stroke="#000814" stroke-width="4"/>
                        <line x1="44" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                        <line x1="28" y1="18" x2="28" y2="54" stroke="#000814" stroke-width="4"/>
                        <line x1="44" y1="18" x2="44" y2="54" stroke="#000814" stroke-width="4"/>
                        <line x1="28" y1="50" x2="44" y2="22" stroke="#000814" stroke-width="4"/>
                      </svg>
                    </v-btn>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      class="custom-blue-button"
                      @mouseover="showTooltip2('One Shot POSITIVE Contact', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('contact_pos')"
                      @dragstart="startDrag('contact_pos')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <svg width="64" height="64" viewBox="0 0 72 72">
                        <line x1="0" y1="36" x2="26" y2="36" stroke="#000814" stroke-width="4"/>
                        <line x1="46" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                        <line x1="26" y1="18" x2="26" y2="54" stroke="#000814" stroke-width="4"/>
                        <line x1="46" y1="18" x2="46" y2="54" stroke="#000814" stroke-width="4"/>
                        <text x="36" y="37" fill="#000814" stroke="#000814" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">P</text>
                      </svg>
                    </v-btn>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      class="custom-blue-button"
                      @mouseover="showTooltip2('One Shot NEGATIVE Contact', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('contact_neg')"
                      @dragstart="startDrag('contact_neg')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <svg width="64" height="64" viewBox="0 0 72 72">
                        <line x1="0" y1="36" x2="26" y2="36" stroke="#000814" stroke-width="4"/>
                        <line x1="46" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                        <line x1="26" y1="18" x2="26" y2="54" stroke="#000814" stroke-width="4"/>
                        <line x1="46" y1="18" x2="46" y2="54" stroke="#000814" stroke-width="4"/>
                        <text x="36" y="37" fill="#000814" stroke="#000814" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">N</text>
                      </svg>
                    </v-btn>
                  </v-col>
                </v-row>
                <div class="section-title">COILS</div>
                  <div class="section-line"></div>
                  <v-row>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                      @mouseover="showTooltip2('Coil', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('coil')"
                      @dragstart="startDrag('coil')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <line x1="0" y1="36" x2="19" y2="36" stroke="#000814" stroke-width="4" />
                          <line x1="53" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4" />
                          <circle cx="36" cy="36" r="18" fill="none" stroke="#000814" stroke-width="4" />
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                      @mouseover="showTooltip2('SET (Latch) Coil', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('coil_set')"
                      @dragstart="startDrag('coil_set')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <line x1="0" y1="36" x2="19" y2="36" stroke="#000814" stroke-width="4" />
                          <line x1="53" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4" />
                          <circle cx="36" cy="36" r="18" fill="none" stroke="#000814" stroke-width="4" />
                          <text x="36" y="37" fill="#000814" stroke="#000814" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">S</text>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                      @mouseover="showTooltip2('RESET (Unlatch) Coil', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('coil_reset')"
                      @dragstart="startDrag('coil_reset')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <line x1="0" y1="36" x2="19" y2="36" stroke="#000814" stroke-width="4" />
                          <line x1="53" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4" />
                          <circle cx="36" cy="36" r="18" fill="none" stroke="#000814" stroke-width="4" />
                          <text x="36" y="37" fill="#000814" stroke="#000814" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">R</text>
                        </svg>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <div class="section-title">TIMERS</div>
                  <div class="section-line"></div>
                  <v-row>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                      @mouseover="showTooltip2('Timer ON Delay', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('timer_ton')"
                      @dragstart="startDrag('timer_ton')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <line x1="0" y1="36" x2="8" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="64" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <circle cx="36" cy="36" r="26" fill="none" stroke="#000814" stroke-width="4"/>
                          <text x="36" y="37" fill="#000814" stroke="#000814" stroke-width="1" font-size="19" text-anchor="middle" alignment-baseline="middle">TON</text>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                      @mouseover="showTooltip2('OFF Delay Timer', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('timer_tof')"
                      @dragstart="startDrag('timer_tof')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <line x1="0" y1="36" x2="8" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="64" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <circle cx="36" cy="36" r="26" fill="none" stroke="#000814" stroke-width="4"/>
                          <text x="36" y="37" fill="#000814" stroke="#000814" stroke-width="1" font-size="19" text-anchor="middle" alignment-baseline="middle">TOF</text>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                      @mouseover="showTooltip2('Timer ON Retentive', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('timer_tonr')"
                      @dragstart="startDrag('timer_tonr')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <line x1="0" y1="36" x2="8" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="64" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <circle cx="36" cy="36" r="26" fill="none" stroke="#000814" stroke-width="4"/>
                          <text x="36" y="37" fill="#000814" stroke="#000814" stroke-width="1" font-size="19" text-anchor="middle" alignment-baseline="middle">TONR</text>
                        </svg>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <div class="section-title">COUNTERS</div>
                  <div class="section-line"></div>
                  <v-row>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                      @mouseover="showTooltip2('Count UP', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('counter_ctu')"
                      @dragstart="startDrag('counter_ctu')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">C T U</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                      @mouseover="showTooltip2('Count DOWN', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('counter_ctd')"
                      @dragstart="startDrag('counter_ctd')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">C T D</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                   <!-- Add ID text  <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                      @mouseover="showTooltip2('Count UP/DOWN', $event)"
                      @mouseleave="hideTooltip"
                      @click="addToolToRung('counter_ctud')"
                      @dragstart="startDrag('counter_ctud')"
                      draggable="true"
                      v-bind="attrs"
                      v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">C TU D</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="25" y1="45" x2="25" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="37" y1="45" x2="37" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="47" y1="45" x2="47" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col> -->
                  </v-row>

                  <div class="section-title">COMPARATORS</div>
                  <div class="section-line"></div>
                  <v-row>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('Equal', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('comparator_equ')"
                        @dragstart="startDrag('comparator_equ')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">E Q U</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('NOT Equal', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('comparator_neq')"
                        @dragstart="startDrag('comparator_neq')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">N E Q</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('GREATER Than', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('comparator_grt')"
                        @dragstart="startDrag('comparator_grt')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">G R T</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('GREATER Than OR Equal To', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('comparator_geq')"
                        @dragstart="startDrag('comparator_geq')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">G E Q</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('LESS Than', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('comparator_les')"
                        @dragstart="startDrag('comparator_les')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">L E S</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('LESS Than OR Equal To', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('comparator_leq')"
                        @dragstart="startDrag('comparator_leq')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">L E Q</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <div class="section-title">Math Functions</div>
                  <div class="section-line"></div>
                  <v-row>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                        @mouseover="showTooltip2('Addition', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('add')"
                        @dragstart="startDrag('add')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">A D D</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                        @mouseover="showTooltip2('Substraction', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('sub')"
                        @dragstart="startDrag('sub')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">S U B</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                        @mouseover="showTooltip2('Multiplication', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('mul')"
                        @dragstart="startDrag('mul')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">M U L</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="custom-yellow-button"
                        @mouseover="showTooltip2('Division', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('div')"
                        @dragstart="startDrag('div')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">D I V</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <div class="section-title">Logical Operations</div>
                  <div class="section-line"></div>
                  <v-row>
                    <v-col cols="4">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('AND Logic', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('logical_and')"
                        @dragstart="startDrag('logical_and')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">A N D</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('OR Logic', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('logical_or')"
                        @dragstart="startDrag('logical_or')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">O R</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="37" y1="45" x2="37" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        class="custom-blue-button"
                        @mouseover="showTooltip2('NOT Logic', $event)"
                        @mouseleave="hideTooltip"
                        @click="addToolToRung('logical_not')"
                        @dragstart="startDrag('logical_not')"
                        draggable="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <svg width="64" height="64" viewBox="0 0 72 72">
                          <rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/>
                          <text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">N O T</text>
                          <line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/>
                          <line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/>
                          <line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/>
                        </svg>
                      </v-btn>
                    </v-col>
                  </v-row>
                  </div>
                </v-tab-item>
                <v-tab-item key="variables">
                <div v-if="tab === 1" class="variables-table-wrapper">
                  <v-simple-table class="variables-table">
                    <thead>
                      <tr>
                        <th class="text-left">ID</th>
                        <th class="text-left">Name</th>
                        <th class="text-left">Class</th>
                        <th class="text-left">Type</th>
                        <th class="text-left">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(variable, index) in variables"
                        :key="variable.id"
                        :class="{
                          'highlight-id-class-type-cps': isSelectedElement(variable.id),
                          'highlight-name-value': isSelectedElement(variable.id)
                        }"
                      >
                        <td>{{ variable.id }}</td>
                        <td>
                          <input
                            type="text"
                            v-model="variable.name"
                            @blur="updateVariable(index, variable)"
                            @keyup.enter="updateVariable(index, variable)"
                            maxlength="14"
                            pattern="[A-Za-z0-9_-]+"
                          />
                        </td>
                        <td>{{ variable.class }}</td>
                        <td>{{ variable.type }}</td>
                        <td>{{ variable.value ? 'True' : 'False' }}</td> <!-- Reflecting state as '1' or '0' -->

                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </v-tab-item>
              </v-tabs-items>
            </div>
          </v-col>
      <!-- Here it ends the template section for the left-column tools menu -->

      <!-- Here it begins the template section for the rightt-column ladder logic area -->
      <v-col
           :cols="isLeftColumnVisible ? 9 : 12"
           :class="['ladder-logic-area', isLeftColumnVisible ? 'with-left-column' : 'no-left-column']">
           <div
              class="ladder-logic-wrapper"
             :class="{ 'no-left-column': !isLeftColumnVisible }"
              ref="ladderLogicArea"
              @dragover.prevent
             @drop="handleDrop">      
              <!-- Run/Stop Simulation Button when left column is hidden -->
              <div v-if="!isLeftColumnVisible" class="simulation-button-container">
                <v-btn
                  :class="isSimulating ? 'custom-red-sim-button' : 'custom-green-sim-button'"
                  @mouseover="showTooltip(isSimulating ? 'Stop Simulation' : 'Run Simulation', $event)"
                  @mouseleave="hideTooltip"
                  @click="toggleSimulation"
                >
                  <font-awesome-icon :icon="[isSimulating ? 'fas' : 'fas', isSimulating ? 'stop' : 'play']" />
                  <span style="margin-left: 8px;">{{ isSimulating ? 'Stop Simulation' : 'Start Simulation' }}</span>
                </v-btn>
              </div>
      
            <div
              v-for="(computedRung, rungIndex) in computedSegments"
              :key="computedRung.id"
              class="rung-wrapper"
              :style="{ height: computedRungHeights[rungIndex] + 'px' }"
            >

              <svg
                class="rung-svg"
                :width="computedRungWidths[rungIndex]"
                :height="computedRungHeights[rungIndex]"
              >
         <!-- Vertical left line -->
         <line
          x1="0"
          y1="40"
          x2="0"
          y2="120"
          :stroke="isSimulating ? '#ffc300' : 'rgb(3, 171, 255)'"  
          stroke-width="12"
        />
        
        <!-- Mini horizontal rung segment -->
        <line
          x1="0"
          y1="80" 
          x2="15" 
          y2="80" 
          :stroke="isSimulating ? '#ffc300' : 'rgb(3, 171, 255)'"  
          stroke-width="4"
        />

        <!-- Horizontal segments -->
        <line
          v-for="segment in computedRung.segments"
          :key="segment.id"
          :x1="segment.x1 + 15" 
          :y1="segment.y1" 
          :x2="segment.x2 + 15" 
          :y2="segment.y2" 
          :stroke="selectedSegment === segment.id && selectedRung === rungIndex ? '#ffc300' : getSegmentColor(rungIndex, segment.id)"
          stroke-width="4"
        />

        <!-- Branch elements -->
        <line
          v-for="element in computedVerticalBranches[rungIndex]"
          :key="element.id"
          :x1="element.position + 15" 
          :y1="element.y1"
          :x2="element.position + 15" 
          :y2="element.y2"
          :stroke="getSegmentColor(rungIndex, element.id)"
          stroke-width="4"
        />

        <!-- Transparent rectangles for regular segments only -->
        <rect
          v-for="segment in nonBranchSegments[rungIndex]"
          :key="'rect-' + segment.id"
          :x="segment.x1 + 15" 
          :y="segment.y1 - 36" 
          width="72"
          height="72"
          fill="transparent"
          @click="selectSegment(segment.id, rungIndex)"
          @dragover.prevent
          @drop="dropOnSegment(segment, rungIndex)"
        />

        <!-- Vertical right line -->
        <line
          :x1="computedRungWidths[rungIndex]"
          y1="40"
          :x2="computedRungWidths[rungIndex]"
          y2="120"
          :stroke="getSegmentColor(rungIndex, computedRung.segments[computedRung.segments.length - 1].id)"
          stroke-width="12"
        />


                <g v-for="element in nonBranchElements[rungIndex]" :key="element.id" :transform="'translate(' + (element.position + 15) + ', ' + (element.y1-36) + ')'">>
                  <g @click="isSimulating ? toggleContact(element, null, true) : selectElement(element, rungIndex)">
                    <path :d="element.content" :fill="getElementColor(element)" />
                    <rect x="0" y="0" width="72" height="60" fill="transparent" stroke="transparent" stroke-width="1" />
                    <template v-if="element.type === 'contact_no'">
                    <line x1="0" y1="36" x2="28" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="44" y1="36" x2="72" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="28" y1="18" x2="28" y2="54" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="44" y1="18" x2="44" y2="54" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'contact_nc'">
                    <line x1="0" y1="36" x2="28" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="44" y1="36" x2="72" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="28" y1="18" x2="28" y2="54" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="44" y1="18" x2="44" y2="54" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="28" y1="50" x2="44" y2="22" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>                  
                  </template>


                  <template v-else-if="element.type === 'contact_pos'">
                  <line x1="0" y1="36" x2="26" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <line x1="46" y1="36" x2="72" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <line x1="26" y1="18" x2="26" y2="54" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <line x1="46" y1="18" x2="46" y2="54" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <text x="36" y="37" :fill="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">P</text>
                  <!-- Add ID text -->
                  <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                </template>

                <template v-else-if="element.type === 'contact_neg'">
                  <line x1="0" y1="36" x2="26" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <line x1="46" y1="36" x2="72" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <line x1="26" y1="18" x2="26" y2="54" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <line x1="46" y1="18" x2="46" y2="54" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                  <text x="36" y="37" :fill="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">N</text>
                  <!-- Add ID text -->
                  <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                </template>

                <template v-else-if="element.type === 'coil'"> 
                  <g @click="configureCoil(element)">
                    <!-- Invisible larger clickable area -->
                    <rect x="0" y="0" width="90" height="90" fill="transparent" />
                    <line x1="0" y1="36" x2="19" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="53" y1="36" x2="72" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <circle cx="36" cy="36" r="18" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    
                    <!-- Display Coil ID and Linked Contacts -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="element-id">
                      {{ element.id }}<tspan v-if="element.linkedContacts && element.linkedContacts.length > 0" stroke="#ffc300" fill="#ffc300">:</tspan>
                      <tspan 
                        v-for="(contact, index) in element.linkedContacts" 
                        :key="contact" 
                        stroke="#ff00ff" 
                        fill="#ff00ff">
                        {{ contact }}<tspan v-if="index < element.linkedContacts.length - 1">, </tspan>
                      </tspan>
                    </text>
                  </g>
                </template>




                <template v-else-if="element.type === 'r_coil'">
                  <g>
                    <line x1="0" y1="36" x2="19" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="53" y1="36" x2="72" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <circle cx="36" cy="36" r="18" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="element-id">
                      {{ element.id }}
                      <tspan v-if="element.linkedId" stroke="#ffc300" fill="#ffc300">:</tspan>
                      <tspan v-if="element.linkedId" stroke="#ff00ff" fill="#ff00ff">{{ element.linkedId }}</tspan>
                    </text>
                  </g>
                </template>

                <template v-else-if="element.type === 'coil_set'">
                  <g @click="configureCoil(element)">
                    <!-- Invisible larger clickable area -->
                    <rect x="0" y="0" width="90" height="90" fill="transparent" />
                    <line x1="0" y1="36" x2="19" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="53" y1="36" x2="72" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <circle cx="36" cy="36" r="18" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <!-- Add ID text and Linked Contacts in fuchsia -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="element-id">
                      {{ element.id }}<tspan v-if="element.linkedContacts && element.linkedContacts.length > 0" stroke="#ffc300" fill="#ffc300">:</tspan>
                      <tspan 
                        v-for="(contact, index) in element.linkedContacts" 
                        :key="contact" 
                        stroke="#ff00ff" 
                        fill="#ff00ff">
                        {{ contact }}<tspan v-if="index < element.linkedContacts.length - 1">, </tspan>
                      </tspan>
                    </text>

                    <text v-if="element.linkedId" x="36" y="70" text-anchor="middle" stroke-width="0.5" stroke="#ff6103" fill="#ff6103" class="reset-id">Reset: {{ element.linkedId }}</text>

                  </g>
                </template>

                <template v-else-if="element.type === 'coil_reset'">
                  <g>
                    <line x1="0" y1="36" x2="19" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <line x1="53" y1="36" x2="72" y2="36" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <circle cx="36" cy="36" r="18" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="element-id">
                      {{ element.id }}
                      <tspan v-if="element.linkedId" stroke="#ffc300" fill="#ffc300">:</tspan>
                      <tspan v-if="element.linkedId" stroke="#ff00ff" fill="#ff00ff">{{ element.linkedId }}</tspan>
                    </text>
                  </g>
                </template>


                <template v-else-if="element.type === 'timer_ton'">
                    <g @click="configureTimer(element)">
                      <!-- Invisible larger clickable area -->
                      <rect x="0" y="0" width="90" height="90" fill="transparent" />
                      <rect x="11" y="7" width="50" height="60" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                      <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4" />
                      <text x="36" y="22" fill="rgb(3, 171, 255)" font-size="15" font-weight="bold" stroke-width="0.5" text-anchor="middle">TON</text>
                      <text x="22" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">IN</text>
                      <text x="50" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">Q</text>
                      <text x="22" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">PT</text>
                      <text x="50" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">ET</text>
                      <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4" />
                      <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4" />
                      <line x1="0" y1="54" x2="9" y2="54" stroke="gray" stroke-width="2" />
                      <line x1="61" y1="54" x2="72" y2="54" stroke="gray" stroke-width="2" />
                      
                      <!-- Add ID text and Linked Contacts in fuchsia -->
                      <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="element-id">
                        {{ element.id }}<tspan v-if="element.linkedContacts && element.linkedContacts.length > 0" stroke="#ffc300" fill="#ffc300">:</tspan>
                        <tspan 
                          v-for="(contact, index) in element.linkedContacts" 
                          :key="contact" 
                          stroke="#ff00ff" 
                          fill="#ff00ff">
                          {{ contact }}<tspan v-if="index < element.linkedContacts.length - 1">, </tspan>
                        </tspan>
                      </text>
                      
                      <!-- Preset Time (PT) and Elapsed Time (ET) -->
                      <text x="-15" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ element.presetTime }}s</text>
                      <text x="85" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ displayElapsedTime(element) }}s</text> 
                    </g>
                  </template>



                  <template v-else-if="element.type === 'timer_tof'">
                      <g @click="configureTimer(element)">
                        <!-- Invisible larger clickable area -->
                        <rect x="0" y="0" width="90" height="90" fill="transparent" />
                        <rect x="11" y="7" width="50" height="60" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                        <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4" />
                        <text x="36" y="22" fill="rgb(3, 171, 255)" font-size="15" font-weight="bold" stroke-width="0.5" text-anchor="middle">TOF</text>
                        <text x="22" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">IN</text>
                        <text x="50" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">Q</text>
                        <text x="22" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">PT</text>
                        <text x="50" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">ET</text>
                        <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4" />
                        <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4" />
                        <line x1="0" y1="54" x2="9" y2="54" stroke="gray" stroke-width="2" />
                        <line x1="61" y1="54" x2="72" y2="54" stroke="gray" stroke-width="2" />
                        
                        <!-- Add ID text and Linked Contacts in fuchsia -->
                        <text x="38" y="0" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="element-id">
                          {{ element.id }}<tspan v-if="element.linkedContacts && element.linkedContacts.length > 0" stroke="#ffc300" fill="#ffc300">:</tspan>
                          <tspan 
                            v-for="(contact, index) in element.linkedContacts" 
                            :key="contact" 
                            stroke="#ff00ff" 
                            fill="#ff00ff">
                            {{ contact }}<tspan v-if="index < element.linkedContacts.length - 1">, </tspan>
                          </tspan>
                        </text>
                        
                        <!-- Preset Time (PT) and Elapsed Time (ET) -->
                        <text x="-15" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ element.presetTime }}s</text>
                        <text x="85" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ displayElapsedTime(element) }}s</text> 
                      </g>
                    </template>



                <template v-else-if="element.type === 'timer_tonr'">
                  <g @click="configureTimer(element)">
                                      <!-- Invisible larger clickable area -->
                  <rect x="0" y="0" width="90" height="90" fill="transparent" />
                    <rect x="11" y="7" width="50" height="60" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <text x="36" y="22" fill="rgb(3, 171, 255)" font-size="15" font-weight="bold" stroke-width="0.5" text-anchor="middle">TONR</text>
                    <text x="22" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">IN</text>
                    <text x="50" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">Q</text>
                    <text x="22" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">PT</text>
                    <text x="50" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">ET</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="54" x2="9" y2="54" stroke="gray" stroke-width="2"/>
                    <line x1="61" y1="54" x2="72" y2="54" stroke="gray" stroke-width="2"/>
                    <!-- Add ID text -->
                    <!-- Add ID text and Linked Contacts in fuchsia -->
                    <text x="38" y="0" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="element-id">
                      {{ element.id }}<tspan v-if="element.linkedContacts && element.linkedContacts.length > 0" stroke="#ffc300" fill="#ffc300">:</tspan>
                      <tspan 
                        v-for="(contact, index) in element.linkedContacts" 
                        :key="contact" 
                        stroke="#ff00ff" 
                        fill="#ff00ff">
                        {{ contact }}<tspan v-if="index < element.linkedContacts.length - 1">, </tspan>
                      </tspan>
                    </text>
                    
                    <text v-if="element.resetCoilId" x="36" y="86" text-anchor="middle" stroke-width="0.5" stroke="#ff6103" fill="#ff6103" class="reset-id">Reset: {{ element.resetCoilId }}</text>
                    <!-- Preset Time (PT) and Elapsed Time (ET) -->
                    <text x="-15" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ element.presetTime }}s</text>
                    <text x="85" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ displayElapsedTime(element) }}s</text> 
                  </g>
                </template>



                <template v-else-if="element.type === 'counter_ctu'">
                  <g @click="configureCounter(element)">
                                      <!-- Invisible larger clickable area -->
                  <rect x="0" y="0" width="90" height="90" fill="transparent" />
                    <rect x="11" y="7" width="50" height="64" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <text x="36" y="22" fill="rgb(3, 171, 255)" font-size="15" font-weight="bold" stroke-width="0.5" text-anchor="middle">CTU</text>
                    <text x="22" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">CU</text>
                    <text x="50" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">Q</text>
                    <text x="22" y="68" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">PV</text>
                    <text x="50" y="68" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">CV</text>
                    <text x="18" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">R</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="62" x2="9" y2="62" stroke="gray" stroke-width="2"/>
                    <line x1="0" y1="49" x2="9" y2="49" stroke="gray" stroke-width="2"/>
                    <line x1="61" y1="62" x2="72" y2="62" stroke="gray" stroke-width="2"/>
                    <!-- Add ID text and Linked Contacts in fuchsia -->
                    <text x="38" y="0" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="element-id">
                      {{ element.id }}<tspan v-if="element.linkedContacts && element.linkedContacts.length > 0" stroke="#ffc300" fill="#ffc300">:</tspan>
                      <tspan 
                        v-for="(contact, index) in element.linkedContacts" 
                        :key="contact" 
                        stroke="#ff00ff" 
                        fill="#ff00ff">
                        {{ contact }}<tspan v-if="index < element.linkedContacts.length - 1">, </tspan>
                      </tspan>
                    </text>
                    
                    <text v-if="element.resetCoilId" x="36" y="86" text-anchor="middle" stroke-width="0.5" stroke="#ff6103" fill="#ff6103" class="reset-id">Reset: {{ element.resetCoilId }}</text>
                    <!-- Preset and Current Value -->
                    <text x="-15" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ element.presetValue }}</text>
                    <text x="85" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ element.currentValue }}</text>
                  </g>
                </template>

                <template v-else-if="element.type === 'counter_ctd'">
                  <g @click="configureCounter(element)">
                                      <!-- Invisible larger clickable area -->
                  <rect x="0" y="0" width="90" height="90" fill="transparent" />
                    <rect x="11" y="7" width="50" height="64" fill="none" :stroke="isSimulating ? (element.state === 2 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <text x="36" y="22" fill="rgb(3, 171, 255)" font-size="15" font-weight="bold" stroke-width="0.5" text-anchor="middle">CTD</text>
                    <text x="22" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">CD</text>
                    <text x="50" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">Q</text>
                    <text x="22" y="68" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">PV</text>
                    <text x="50" y="68" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">CV</text>
                    <text x="18" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">R</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="62" x2="9" y2="62" stroke="gray" stroke-width="2"/>
                    <line x1="0" y1="49" x2="9" y2="49" stroke="gray" stroke-width="2"/>
                    <line x1="61" y1="62" x2="72" y2="62" stroke="gray" stroke-width="2"/>
                    <!-- Add ID text and Linked Contacts in fuchsia -->
                    <text x="38" y="0" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="element-id">
                      {{ element.id }}<tspan v-if="element.linkedContacts && element.linkedContacts.length > 0" stroke="#ffc300" fill="#ffc300">:</tspan>
                      <tspan 
                        v-for="(contact, index) in element.linkedContacts" 
                        :key="contact" 
                        stroke="#ff00ff" 
                        fill="#ff00ff">
                        {{ contact }}<tspan v-if="index < element.linkedContacts.length - 1">, </tspan>
                      </tspan>
                    </text>
                    
                    <text v-if="element.resetCoilId" x="36" y="86" text-anchor="middle" stroke-width="0.5" stroke="#ff6103" fill="#ff6103" class="reset-id">Reset: {{ element.resetCoilId }}</text>
                    <!-- Preset Value (PV) and Current Value (CV) -->
                    <text x="-15" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ element.presetValue }}</text>
                    <text x="85" y="59" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">{{ element.currentValue }}</text> 
                  </g>
                </template>


                <template v-else-if="element.type === 'counter_ctud'">
                  <g>
                    <rect x="11" y="7" width="50" height="64" fill="none" :stroke="isSimulating ? (element.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)') : (selectedElement === element.id ? '#ffc300' : 'rgb(3, 171, 255)')" stroke-width="4" />
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <text x="36" y="22" fill="rgb(3, 171, 255)" font-size="15" font-weight="bold" stroke-width="0.5" text-anchor="middle">CTUD</text>
                    <text x="22" y="40" fill="rgb(3, 171, 255)" font-size="11" stroke-width="0.5" text-anchor="middle">CU</text>
                    <text x="50" y="40" fill="rgb(3, 171, 255)" font-size="11" stroke-width="0.5" text-anchor="middle">QU</text>
                    <text x="22" y="50" fill="rgb(3, 171, 255)" font-size="11" stroke-width="0.5" text-anchor="middle">CD</text>
                    <text x="50" y="50" fill="rgb(3, 171, 255)" font-size="11" stroke-width="0.5" text-anchor="middle">QD</text>
                    <text x="22" y="60" fill="rgb(3, 171, 255)" font-size="11" stroke-width="0.5" text-anchor="middle">R</text>
                    <text x="50" y="60" fill="rgb(3, 171, 255)" font-size="11" stroke-width="0.5" text-anchor="middle">CV</text>
                    <text x="22" y="70" fill="rgb(3, 171, 255)" font-size="11" stroke-width="0.5" text-anchor="middle">LD</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="45" x2="9" y2="45" stroke="gray" stroke-width="2"/>
                    <line x1="0" y1="56" x2="9" y2="56" stroke="gray" stroke-width="2"/>
                    <line x1="0" y1="65" x2="9" y2="65" stroke="gray" stroke-width="2"/>
                    <line x1="61" y1="45" x2="72" y2="45" stroke="gray" stroke-width="2"/>
                    <line x1="61" y1="56" x2="72" y2="56" stroke="gray" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                    <!-- Linked ID text -->
                    <text v-if="element.linkedId" x="36" y="82" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="linked-id">Linked: {{ element.linkedId }}</text>
                    <text v-if="element.resetId" x="36" y="98" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" fill="#ffc300" class="reset-id">Reset: {{ element.resetId }}</text>
                  </g>
                </template>


                  <template v-else-if="element.type === 'comparator_equ'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">EQU</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <line x1="20" y1="60" x2="20" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="60" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="30" y1="48" x2="40" y2="48" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="30" y1="51" x2="40" y2="51" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'comparator_neq'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">NEQ</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <line x1="20" y1="60" x2="20" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="60" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="30" y1="48" x2="40" y2="48" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="30" y1="51" x2="40" y2="51" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="33" y1="53" x2="38" y2="46" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'comparator_grt'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">GRT</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <line x1="20" y1="60" x2="20" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="60" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="31" y1="53" x2="40" y2="49" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="31" y1="46" x2="40" y2="49" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'comparator_geq'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">GEQ</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <line x1="20" y1="60" x2="20" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="60" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="31" y1="53" x2="40" y2="49" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="31" y1="46" x2="40" y2="49" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="31" y1="55.5" x2="40" y2="51.5" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'comparator_les'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">LES</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <line x1="20" y1="60" x2="20" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="60" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="31" y1="49" x2="40" y2="53" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="31" y1="49" x2="40" y2="46" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'comparator_leq'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">LEQ</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <line x1="20" y1="60" x2="20" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="2"/>
                    <line x1="60" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="31" y1="49" x2="40" y2="53" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="31" y1="49" x2="40" y2="46" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <line x1="40" y1="55.5" x2="31" y2="51.5" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="1.5"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'add'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">ADD</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <text x="35" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" font-weight="bold" text-anchor="middle">+</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'sub'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">SUB</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <text x="35" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" font-weight="bold" text-anchor="middle">-</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'mul'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">MUL</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <text x="35" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" font-weight="bold" text-anchor="middle">x</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'div'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">DIV</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <text x="35" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" font-weight="bold" text-anchor="middle">/</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'logical_and'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">AND</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <text x="35" y="54" fill="rgb(3, 171, 255)" font-size="9" stroke-width="0.5" font-weight="bold" text-anchor="middle">AND</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'logical_or'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">OR</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <text x="35" y="54" fill="rgb(3, 171, 255)" font-size="9" stroke-width="0.5" font-weight="bold" text-anchor="middle">OR</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>

                  <template v-else-if="element.type === 'logical_not'">
                    <rect x="11" y="7" width="50" height="51" fill="none" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <rect x="11" y="7" width="50" height="20" fill="none"/>
                    <text x="36" y="22" fill="white" font-size="16" stroke-width="1" text-anchor="middle">NOT</text>
                    <text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">OUT</text>
                    <text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">A</text>
                    <text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" stroke-width="0.5" text-anchor="middle">B</text>
                    <text x="35" y="54" fill="rgb(3, 171, 255)" font-size="9" stroke-width="0.5" font-weight="bold" text-anchor="middle">NOT</text>
                    <line x1="0" y1="36" x2="9" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="63" y1="36" x2="72" y2="36" :stroke="selectedElement === element.id && selectedRung === rungIndex ? '#ffc300' : 'rgb(3, 171, 255)'" stroke-width="4"/>
                    <line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="2"/>
                    <!-- Add ID text -->
                    <text x="38" y="5" text-anchor="middle" stroke-width="0.5" stroke="#ffc300" class="element-id">{{ element.id }}</text>
                  </template>
                   </g>
                   <foreignObject
                      v-if="(selectedElement === element.id && selectedRung === rungIndex && !isSimulating)"
                      x="60"
                      y="-12"
                      width="16"
                      height="16"
                      class="delete-button-fo"
                    >
                      <div class="delete-button" @click.stop="deleteElement(element.id, rungIndex)">
                        <svg width="16" height="16" viewBox="0 0 24 24">
                          <circle cx="12" cy="12" r="12" fill="red" stroke="#000814" stroke-width="1"/>
                          <line x1="6" y1="6" x2="18" y2="18" stroke="white" stroke-width="2"/>
                          <line x1="6" y1="18" x2="18" y2="6" stroke="white" stroke-width="2"/>
                        </svg>
                      </div>
                    </foreignObject>
                  </g>
                </svg>
                <foreignObject
                v-if="deleteButtonCondition && selectedRung === rungIndex"
                :style="{ position: 'absolute', top: deleteButtonPosition.top + 'px', left: deleteButtonPosition.left + 'px' }"
                  width="16"
                  height="16"
                  class="delete-button-fo"
                >
                  <div class="delete-button" @click.stop="deleteSelectedBranch(currentElement.position, rungIndex)">
                    <svg width="16" height="16" viewBox="0 0 24 24">
                      <circle cx="12" cy="12" r="12" fill="red" stroke="#000814" stroke-width="1"/>
                      <line x1="6" y1="6" x2="18" y2="18" stroke="white" stroke-width="2"/>
                      <line x1="6" y1="18" x2="18" y2="6" stroke="white" stroke-width="2"/>
                    </svg>
                  </div>
                </foreignObject>

                <!-- Place the "+" button dynamically for selected segment/element -->
                <div v-if="showPlusButton && selectedRung === rungIndex" :style="{ position: 'absolute', top: plusButtonPosition.top + 'px', left: plusButtonPosition.left + 'px' }">
                  <v-btn icon class="add-branch-button" @click="addBranch">
                    <v-icon :style="{ color: '#ffc300' }">mdi-plus-circle</v-icon>
                  </v-btn>
                </div>
                <!-- Place the "+" button dynamically for extending a branch -->
                <div v-if="showExtendBranchButton && selectedRung === rungIndex" :style="{ position: 'absolute', top: extendBranchButtonPosition.top + 'px', left: extendBranchButtonPosition.left + 'px' }">
                  <v-btn icon class="extend-branch-button" @click="extendBranch">
                    <v-icon :style="{ color: '#ffc300' }">mdi-plus-circle</v-icon>
                  </v-btn>
                </div>
                <!-- Warning Button -->
                 <div v-if="showWarningButton" :style="{ position: 'absolute', top: `${warningButtonPosition.top}px`, left: `${warningButtonPosition.left}px` }">
                  <v-btn icon class="warning-branch-button" @click="warningDialog.visible = true">
                    <v-icon :style="{ color: 'red' }">mdi-plus-circle</v-icon>
                  </v-btn>
                </div>
                

              </div>
              <v-btn icon class="add-rung-button" @click="addRung" v-if="!isSimulating">
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>

              <v-btn icon class="delete-rung-button" @click="deleteRung" v-if="!isSimulating">
                <v-icon>mdi-minus-circle</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <transition name="fade">
        <div
          v-if="tooltipVisible"
          class="tooltip-box"
          :style="{ top: tooltipY + 'px', left: tooltipX + 'px' }"
        >
          {{ tooltipText }}
        </div>
      </transition>
    </v-container>
    <!-- Here it ends the template section for the right-column ladder logic area -->

    <!-- Here it begins the template section for the dialog boxes in the LLBuilder -->
    <v-dialog v-model="newProjectDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Start New Project</v-card-title>
        <v-card-text>Do you really want to create a new project?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#ffc300" text @click="confirmNewProject">Yes, I confirm</v-btn>
          <v-btn color="#03abff" text @click="newProjectDialog = false">No, Cancel Operation</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="portrait-warning">
      <div class="svg-icons">
        <svg width="64" height="64" stroke-width="0.5"  viewBox="0 0 16 16">
          <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
          <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
        </svg>
        <svg width="64" height="64" stroke-width="0.5"  viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
        </svg>
        <svg width="64" height="64" stroke-width="0.5" viewBox="0 0 16 16">
          <path d="M1 4.5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm-1 6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2z"/>
          <path d="M14 7.5a1 1 0 1 0-2 0 1 1 0 0 0 2 0"/>
        </svg>
      </div>
      <div class="rotate-message">
        Please rotate your device 
      </div>
      <div class="rotate-message"> 
        to landscape mode
      </div>
      <div class="portrait-warning-logo">
        <img :src="logo" alt="Company Logo"/>
      </div>
    </div>

    <!-- Add Tool Alert Dialog -->
<v-dialog v-model="addToolDialog.visible" persistent max-width="300px">
  <v-card>
    <v-card-title class="headline">Simulation Active</v-card-title>
    <v-card-text>You can't add a tool while in Run Mode</v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#ffc300" text @click="addToolDialog.visible = false">Close</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

<!-- Add Rung Alert Dialog -->
<v-dialog v-model="addRungDialog.visible" persistent max-width="300px">
  <v-card>
    <v-card-title class="headline">Simulation Active</v-card-title>
    <v-card-text>You can't add a rung while in Run Mode</v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#ffc300" text @click="addRungDialog.visible = false">Close</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

<!-- Timer Configuration Dialog -->
<v-dialog v-model="showTimerDialog" persistent max-width="400px">
  <v-card>
    <v-card-title class="headline">Configure Timer</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="dialogPresetTime"
              label="Preset Time (s)"
              type="number"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <!-- Updated v-select to support multiple contacts -->
            <v-select
              v-model="dialogLinkedContact"
              :items="availableContacts.map(contact => contact.id)"
              label="Linked Contacts"
              required
              multiple
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#ffc300" text @click="saveTimerSettings">Save</v-btn>
      <v-btn color="#ffc300" text @click="closeTimerDialog">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

<!-- Counter Configuration Dialog -->
<v-dialog v-model="showCounterDialog" persistent max-width="400px">
  <v-card>
    <v-card-title class="headline">Configure Counter</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="dialogPresetValue"
              label="Preset Value"
              type="number"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="dialogLinkedContact"
              :items="availableContacts.map(contact => contact.id)"
              label="Linked Contacts"
              required
              multiple  
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#ffc300" text @click="saveCounterSettings">Save</v-btn>
      <v-btn color="#ffc300" text @click="closeCounterDialog">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!-- Coil Configuration Dialog -->
<v-dialog v-model="showCoilDialog" persistent max-width="400px">
  <v-card>
    <v-card-title class="headline">Configure Coil</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="dialogLinkedContact"
              :items="availableContacts.map(contact => contact.id)"
              label="Linked Contacts"
              required
              multiple  
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#ffc300" text @click="saveCoilSettings">Save</v-btn>
      <v-btn color="#ffc300" text @click="closeCoilDialog">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>


   <!-- Here it ends the template section for the dialog boxes in the LLBuilder -->
  <!-- Add Simulation Alert Dialog -->
  <v-dialog v-model="simulationAlertDialog" persistent max-width="300px">
    <v-card>
      <v-card-title class="headline">Simulation Active</v-card-title>
      <v-card-text>You can't perform this action while in Run Mode</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#ffc300" text @click="simulationAlertDialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

    <!-- Warning Dialog -->
    <v-dialog v-model="warningDialog.visible" persistent max-width="300px">
    <v-card>
      <v-card-title class="headline">Warning</v-card-title>
      <v-card-text>
        Adding more levels of asymmetrical branches makes ladder logic difficult to read and is not a recommended practice.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#ffc300" text @click="warningDialog.visible = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

<!-- Open Diagram Dialog -->
<v-dialog v-model="showOpenDiagramDialog" persistent max-width="600px">
  <v-card>
    <v-card-title class="headline">Open Diagram</v-card-title>
    <v-card-text>
      <v-container>
        <!-- Section for Shared Diagrams -->
        <v-row>
          <v-col cols="12">
            <v-card-text class="textline">Shared Diagrams</v-card-text>
            <v-list>
              <v-list-item-group v-model="selectedDiagramId">
                <v-list-item 
                  v-for="diagram in sharedDiagrams" 
                  :key="diagram.id"
                >
                  <v-list-item-content>
                    <v-checkbox
                      :value="diagram.id"
                      v-model="selectedDiagramId"
                      :label="diagram.name"
                      hide-details
                    ></v-checkbox>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>

                <!-- Section for User Diagrams -->
                <v-row>
          <v-col cols="12">
            <v-card-text class="textline">User Diagrams</v-card-text>
            <v-list>
              <v-list-item-group v-model="selectedDiagramId">
                <v-list-item 
                  v-for="diagram in ownedDiagrams" 
                  :key="diagram.id"
                >
                  <v-list-item-content>
                    <v-checkbox
                      :value="diagram.id"
                      v-model="selectedDiagramId"
                      :label="diagram.name"
                      hide-details
                    ></v-checkbox>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#ffc300" text @click="openSelectedDiagram">Open</v-btn>
      <v-btn color="#ffc300" text @click="showOpenDiagramDialog = false">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>


<!-- Save As Dialog -->
<v-dialog v-model="showSaveDiagramDialog" persistent max-width="400px">
  <v-card>
    <v-card-title class="headline">Save As</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="diagramName"
              label="Diagram Name"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#ffc300" text @click="saveAsLadderLogicToBackend">Save</v-btn>
      <v-btn color="#ffc300" text @click="closeSaveDiagramDialog">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

<!-- Capacity Limit Dialog -->
<v-dialog v-model="showCapacityLimitDialog" max-width="500px">
  <v-card>
    <v-card-title class="headline">Capacity Limit Reached</v-card-title>
    <v-card-text>
      You have reached your diagram capacity limit. Please delete an existing diagram to save a new one.
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#ffc300" text @click="showCapacityLimitDialog = false">OK</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

<!-- Share Diagram Dialog -->
<v-dialog v-model="showShareDiagramDialog" max-width="600px">
    <v-card>
        <v-card-title class="headline">Share Diagram</v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-checkbox
                            v-if="isSuperAdmin"
                            v-model="selectAllTeachers"
                            label="Select All Teachers"
                            @change="toggleSelectAll('Teacher')"
                        ></v-checkbox>
                        <v-checkbox
                            v-if="isSuperAdmin"
                            v-model="selectAllStudents"
                            label="Select All Students"
                            @change="toggleSelectAll('Student')"
                        ></v-checkbox>
                        <v-list>
                            <v-list-item-group v-model="selectedRecipients" multiple>
                                <v-list-item
                                    v-for="user in usersList"
                                    :key="user.id"
                                >
                                    <v-list-item-content>
                                        <v-checkbox
                                            :value="user.id"
                                            v-model="selectedRecipients"
                                            :label="`${user.name} (${user.role})`"
                                            hide-details
                                        ></v-checkbox>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#ffc300" text @click="shareDiagram">Share</v-btn>
            <v-btn color="#ffc300" text @click="showShareDiagramDialog = false">Cancel</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>


  </div>
</template>

<script>
import { ref, reactive, watch, onMounted, computed, onUnmounted } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import logo from '@/assets/Logo_Medusa_Digital_Dark.webp';
import axios from 'axios';
import { useStore } from "vuex";




export default {
  name: 'LLBuilder',
  components: {
    FontAwesomeIcon,
  },
  props: {
    showHeader: {
      type: Boolean,
      default: true,
    },
    showLeftColumn: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
   //here we have all the setup for the header menu
      //----------------------------------------------------------------
   const newProjectDialog = ref(false);
   const coilStates = ref({});
  
   // Emit event to parent component (YagaDashboard) to navigate back home
    const goHome = () => {
      emit('goHome');
    };
function confirmNewProject() {
  // Reset the ladder logic to the initial state
  rungs.value = [{ id: 1, elements: [], segments: [] }];
  variables.value = [];
  resetElementCounters(); // Reset the element counters
  updateSegments();
  newProjectDialog.value = false; // Close the dialog
  pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) }); // Record the state
}

// Data for controlling the dialog
const store = useStore(); // Access Vuex store

const selectedDiagramId = ref(store.getters.getLadderLogic?.diagramId ?? null); // For holding the ID of the selected diagram
const userDiagrams = ref([]); // Store the user's diagrams
const showOpenDiagramDialog = ref(false); // Control visibility of the dialog
const originalCreatorName = ref('');  // Holds the original creator's name
const originalCreatorEmail = ref(''); // Holds the original creator's email


const getAvailableRungSegments = () => {
  return segmentCount.value; // Use the computed segmentCount which is based on screen width
};



const openSelectedDiagram = async () => {
  if (!selectedDiagramId.value) {
    alert('Please select a diagram to open.');
    return;
  }

  try {
    const token = localStorage.getItem('jwt'); // Retrieve the JWT token

    const response = await axios.get(`/yaga-auth/ladder-logic/${selectedDiagramId.value}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the request headers
      },
    });

    const ladderLogicData = response.data;
    if (ladderLogicData.data) {
      // Populate the current diagram and variables
      rungs.value = JSON.parse(ladderLogicData.data);
      currentDiagramName.value = ladderLogicData.name; // Set the current diagram name
      currentDiagramId.value = ladderLogicData.id;     // Set the current diagram ID


      // Initialize each element's `prevInputState` based on its type
      rungs.value.forEach(rung => {
        rung.elements.forEach(element => {
          if (element.type === 'contact_no' || element.type === 'contact_pos') {
            element.prevInputState = false; // Normally Open or One-Shot Positive starts open (false)
          } else if (element.type === 'contact_nc' || element.type === 'contact_neg') {
            element.prevInputState = true; // Normally Closed or One-Shot Negative starts closed (true)
          }
        });
      });

      // Get the available number of segments on this device
      const availableSegments = getAvailableRungSegments();

      // First, attempt to compress the elements
      let compressionSuccess = true;

      // Attempt compression and check for overlap
      const overlapDetected = compressAndRepositionElements(rungs.value, availableSegments);

      if (overlapDetected) {
        console.warn('Compression failed due to overlapping elements. Falling back to expanding rungs.');
        compressionSuccess = false;
      }

      if (!compressionSuccess) {
        // Fallback: Add extra rung segments to make sure everything fits
        rungs.value.forEach((rung) => {
          const elements = rung.elements || [];
          const maxElementPosition = Math.max(...elements.map(el => el.position || 0));

          const availableWidth = availableSegments * 72;
          const extraSegmentsNeeded = Math.ceil((maxElementPosition - availableWidth) / 72);

          if (extraSegmentsNeeded > 0) {
            // Add extra segments to the rung so the elements fit and can be scrolled horizontally
            const extraWidth = extraSegmentsNeeded * 72;
            rung.segments.push({
              id: `extra-${rung.id}-${Date.now()}`, // Generate unique ID for extra segments
              x1: availableWidth,
              x2: availableWidth + extraWidth,
              y1: 80,
              y2: 80,
            });
          }
        });

        // Enable horizontal scrolling by updating the computed widths
        updateElementCounters(); // This updates the width based on the added segments
        updateSegments(); // Refresh the segments for rendering
      }

     // **Load variables from ladderLogicData.variables**
     if (ladderLogicData.variables) {
        variables.value = JSON.parse(ladderLogicData.variables);
      } else {
        // Handle the case where variables are not provided
        variables.value = [];
        // Optionally reconstruct variables from rungs if necessary
      }

      // **Map variables to elements to set element.name**
      if (variables.value.length > 0) {
        const variableMap = {};
        variables.value.forEach(variable => {
          variableMap[variable.id] = variable;
        });

        rungs.value.forEach(rung => {
          rung.elements.forEach(element => {
            const variable = variableMap[element.id];
            if (variable) {
              element.name = variable.name;
            }
          });
        });
      }
      

      // Update the ladder diagram UI
      updateElementCounters();
      updateSegments();
      //console.log('LoGGG- Variables:', variables.value);

      showOpenDiagramDialog.value = false; // Close the dialog after opening
    } else {
      alert('Invalid ladder logic diagram format.');
    }
  } catch (error) {
    console.error('Error opening diagram:', error);
    alert('Failed to open the diagram.');
  }
};


const compressAndRepositionElements = (rungs, availableSegments) => {
  let overlapDetected = false;

  rungs.forEach((rung, rungIndex) => {
    // console.log(`Processing rung ${rungIndex}`);
    // console.log('Rung Object:', rung);

    // Check if the rung has elements and log them
    if (!rung.elements || !Array.isArray(rung.elements)) {
      console.warn(`No elements found in rung ${rungIndex}`);
      return; // Skip if there are no elements
    }

    // Filter elements that have the "position" property
    const elements = rung.elements.filter(el => el.position !== undefined);
    // console.log(`Elements Length: ${elements.length}`, elements);

    if (elements.length === 0) {
      console.warn(`No valid elements with a position found in rung ${rungIndex}`);
      return; // Skip rungs without valid elements
    }

    // Find the minimum and maximum positions of the elements
    const firstElementPosition = Math.min(...elements.map(el => el.position));
    const lastElementPosition = Math.max(...elements.map(el => el.position));
    // console.log(`First Element Position: ${firstElementPosition}, Last Element Position: ${lastElementPosition}`);

    // Calculate the total segments occupied by the elements in the original diagram
    const totalSegmentsInDiagram = Math.ceil((lastElementPosition - firstElementPosition) / 72);
    // console.log(`Total Segments in Diagram: ${totalSegmentsInDiagram}`);

    // Calculate how many segments we need to remove
    const segmentsToRemove = totalSegmentsInDiagram - availableSegments;
    // console.log(`Segments to Remove: ${segmentsToRemove}`);

    if (segmentsToRemove > 0) {
      // If we need to reposition elements, calculate how much we should shift them
      const shiftValue = (segmentsToRemove + 3) * 72;

      elements.forEach((element, index) => {
        // Check if the element is outside the available segments
        if (element.position > availableSegments * 72) {
          // Only shift elements that are beyond the visible segments
          // console.log(`Shifting element ${element.id} by ${shiftValue}px`);
          element.position -= shiftValue;
        }

        // Check for overlap with the previous element
        if (index > 0) {
          const previousElement = elements[index - 1];
          if (
            element.position === previousElement.position && // Same x position
            element.y1 === previousElement.y1 &&             // Same y1
            element.y2 === previousElement.y2                // Same y2
          ) {
            console.warn(`Overlap detected between element ${element.id} and ${previousElement.id}`);
            overlapDetected = true;
          }
        }
      });
    }
  });

  return overlapDetected;
};














const updateElementCounters = () => {
  // Reset the counters
  Object.keys(elementCounters).forEach(key => {
    elementCounters[key] = 0;
  });

  // Iterate through the rungs and elements to update counters
  rungs.value.forEach(rung => {
    rung.elements.forEach(element => {
      // Extract the prefix and number from the element ID
      const prefix = element.id.replace(/[0-9]/g, '');

      // Find the type corresponding to this prefix in generateId
      const typeEntry = Object.entries(generateIdPrefixes).find(([, value]) => value === prefix);
      
      if (typeEntry) {
        const type = typeEntry[0];
        // Update the counter for this type
        if (elementCounters[type] !== undefined) {
          // Extract the current element number
          const elementNumber = parseInt(element.id.replace(prefix, ''), 10);
          // Update the counter to the max of current counter and element number
          elementCounters[type] = Math.max(elementCounters[type], elementNumber);
        }
      }
    });
  });

  // Log the updated counters for debugging
  // console.log('Updated elementCounters after opening:', JSON.parse(JSON.stringify(elementCounters)));
};

// Prefixes mapping used in generateId
const generateIdPrefixes = {
  coil: 'C',
  r_coil: 'R_C',
  contact_no: 'NO',
  contact_nc: 'NC',
  contact_pos: 'P',
  contact_neg: 'N',
  coil_set: 'SC',
  coil_reset: 'RC',
  timer_ton: 'TON',
  timer_tof: 'TOF',
  timer_tonr: 'TONR',
  counter_ctu: 'CTU',
  counter_ctd: 'CTD',
  counter_ctud: 'CTUD',
  comparator_equ: 'EQU',
  comparator_neq: 'NEQ',
  comparator_grt: 'GRT',
  comparator_geq: 'GEQ',
  comparator_les: 'LES',
  comparator_leq: 'LEQ',
  add: 'ADD',
  sub: 'SUB',
  mul: 'MUL',
  div: 'DIV',
  logical_and: 'AND',
  logical_or: 'OR',
  logical_not: 'NOT',
};



const ownedDiagrams = ref([]);
const sharedDiagrams = ref([]);

// Method to fetch and display user's diagrams
const fetchUserDiagrams = async () => {
    try {
        const token = localStorage.getItem('jwt'); // Retrieve the JWT token

        const response = await axios.get('/yaga-auth/ladder-logic', {
            headers: {
                Authorization: `Bearer ${token}`, // Include the token in the request headers
            },
        });

        // Split diagrams into user-owned and shared diagrams
        ownedDiagrams.value = response.data.filter(diagram => !diagram.shared); // User's own diagrams
        sharedDiagrams.value = response.data.filter(diagram => diagram.shared); // Shared diagrams

        selectedDiagramId.value = null; // Clear any previous selection
        showOpenDiagramDialog.value = true; // Show the dialog
    } catch (error) {
        console.error('Error fetching diagrams:', error);
        alert('Failed to fetch diagrams.');
    }
};



// Method to close the open diagram dialog
const closeOpenDiagramDialog = () => {
    showOpenDiagramDialog.value = false;
};


// Data for controlling the save dialog
const showSaveDiagramDialog = ref(false);
const diagramName = ref('');
const currentDiagramId = ref(null); // To track the currently opened diagram
// Add this near the top of your setup function
const currentDiagramName = ref('');

// Method to show the save as dialog
const showSaveDiagramDialogMethod = () => {
    diagramName.value = currentDiagramId.value ? currentDiagramName.value : ''; // Use current diagram name if editing
    showSaveDiagramDialog.value = true;
};

const showCapacityLimitDialog = ref(false);


// Method to save the ladder logic to the backend as a new diagram ("Save As")
const saveAsLadderLogicToBackend = async () => {
    try {
        const ladderLogicData = {
            name: diagramName.value, // Get this from the input
            data: JSON.stringify(rungs.value, null, 2), // Convert the rungs to JSON
            variables: JSON.stringify(variables.value, null, 2), // Convert the variables to JSON
        };

        const token = localStorage.getItem('jwt'); // Retrieve the JWT token

        // Check if a diagram with the same name already exists
        const existingDiagram = userDiagrams.value.find(diagram => diagram.name === diagramName.value);
        if (existingDiagram) {
            // Show a confirmation dialog to overwrite
            if (!confirm('A diagram with this name already exists. Do you want to overwrite it?')) {
                return; // User canceled the overwrite
            } else {
                // If the user chooses to overwrite, update the existing diagram
                currentDiagramId.value = existingDiagram.id;
                await saveCurrentDiagram(); // Save the changes to the existing diagram
                return;
            }
        }

        // Save a new diagram
        const response = await axios.post('/yaga-auth/ladder-logic', ladderLogicData, {
            headers: {
                Authorization: `Bearer ${token}`, // Include the token in the request headers
            },
        });

        if (response.status === 201) {
            alert('Diagram saved successfully!');
            showSaveDiagramDialog.value = false; // Close the dialog after saving
            
            // Set the current diagram name and ID to the newly saved diagram
            currentDiagramName.value = diagramName.value;
            currentDiagramId.value = response.data.id; // Assuming the response returns the new diagram ID

            // Log the current diagram name and ID for debugging
            // console.log('Saved diagram name:', currentDiagramName.value);
            // console.log('Saved diagram ID:', currentDiagramId.value);
        }
    } catch (error) {
        // In the error handling part of saveAsLadderLogicToBackend
        if (error.response && error.response.status === 403) {
            // Show the capacity limit dialog
            showCapacityLimitDialog.value = true;
        } else {
            console.error('Error saving diagram:', error);
            alert('Failed to save the diagram.');
        }
    }
};




// Method to save the currently opened diagram ("Save")
const saveCurrentDiagram = async () => {
    if (!currentDiagramId.value) {
        alert('No diagram is currently open.');
        return;
    }
    // console.log('Saving diagram with ID:', currentDiagramId.value);

    try {
        const ladderLogicData = {
            data: JSON.stringify(rungs.value, null, 2), // Convert the rungs to JSON
            variables: JSON.stringify(variables.value, null, 2) // Convert the variables to JSON

        };
        console.log('FFFF- Payload for saving:', ladderLogicData); // Debug log
        const token = localStorage.getItem('jwt'); // Retrieve the JWT token

        const response = await axios.put(`/yaga-auth/ladder-logic/${currentDiagramId.value}`, ladderLogicData, {
            headers: {
                Authorization: `Bearer ${token}`, // Include the token in the request headers
            },
        });

        if (response.status === 200) {
            alert('Diagram saved successfully!');
            showSaveDiagramDialog.value = false;

        }
    } catch (error) {
        console.error('Error saving current diagram:', error);
        alert('Failed to save the diagram.');
    }
};

// Method to close the save diagram dialog
const closeSaveDiagramDialog = () => {
    showSaveDiagramDialog.value = false;
};


// Data for controlling the share dialog
const showShareDiagramDialog = ref(false);
const selectedRecipients = ref([]);
const selectAllTeachers = ref(false);
const selectAllStudents = ref(false);

const currentUser = ref(null); // For the current user's information
const usersList = ref([]); // For the list of users to share diagrams with

// Fetch the current user's information (including their role)
const fetchCurrentUser = async () => {
    try {
        const token = localStorage.getItem('jwt');
        const response = await axios.get('/yaga-auth/profile', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        currentUser.value = response.data;
    } catch (error) {
        console.error('Error fetching current user:', error);
    }
};

// Fetch users list based on current user role
const fetchUsersList = async () => {
    try {
        const token = localStorage.getItem('jwt');
        const response = await axios.get('/yaga-auth/users', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        usersList.value = response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        alert('Failed to fetch users.');
    }
};

// Compute if the current user is a SuperAdmin
const isSuperAdmin = computed(() => currentUser.value?.role === 'SuperAdmin');

// Call both methods when the component is mounted
onMounted(() => {
    fetchCurrentUser();
    fetchUsersList();
});


// Toggle select all for teachers or students
const toggleSelectAll = (role) => {
    selectedRecipients.value = [];
    if (role === 'Teacher') {
        selectedRecipients.value = usersList.value
            .filter(user => user.role === 'Teacher')
            .map(user => user.id);
    } else if (role === 'Student') {
        selectedRecipients.value = usersList.value
            .filter(user => user.role === 'Student')
            .map(user => user.id);
    }
};

// Method to show the share dialog
const showShareDiagramDialogMethod = async () => {
    await fetchUsersList();
    showShareDiagramDialog.value = true;
};

// Method to share the diagram
const shareDiagram = async () => {
    try {
        if (!currentDiagramId.value) {
            alert('No diagram is currently open.');
            return;
        }

        const token = localStorage.getItem('jwt');

        const isSample = isSuperAdmin.value; // Use the computed property here

        const response = await axios.post('/yaga-auth/share-diagram', {
            diagram_id: currentDiagramId.value,
            recipient_ids: selectedRecipients.value,
            is_sample: isSample
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            alert('Diagram shared successfully!');
            showShareDiagramDialog.value = false; // Close the dialog after sharing
        }
    } catch (error) {
        console.error('Error sharing diagram:', error);
        alert('Failed to share the diagram.');
    }
};




function runSimulation() {
  isSimulating.value = true;
  initializeTimers();
  initializeCounters();

    // Deselect any selected elements or segments
   selectedElement.value = null;
   selectedSegment.value = null;
   showPlusButton.value = false;
   showExtendBranchButton.value = false;

  rungs.value.forEach(rung => {
    rung.elements.forEach(element => {
      if (element.type === 'contact_no') {
        element.state = 0;
      } else if (element.type === 'contact_nc') {
        element.state = 1;
      } else if (element.type === 'contact_pos') {
        element.state = 0;
      } else if (element.type === 'contact_neg') {
        element.state = 1;
      } else if (element.type === 'coil') {
        element.state = 0;
      }  else if (element.type === 'r_coil') {
        element.state = 0;
      } else if (element.type === 'coil_set') {
        element.state = 0;
      } else if (element.type === 'coil_reset') {
        element.state = 0;
      } else if (element.type === 'timer_ton') {
        element.state = 0;
      } else if (element.type === 'timer_tof') {
        element.state = 0;
      } else if (element.type === 'timer_tonr') {
        element.state = 0;
      } else if (element.type === 'counter_ctu') {
        element.state = 0;
        element.currentValue = 0; // Reset counter current value

        const counter = counters.counter_ctu.find(c => c.id === element.id);
        if (counter) {
          counter.currentValue = 0; // Ensure counter is also reset
          counter.state = 0;
          counter.previousState = 0;
        }
      } else if (element.type === 'counter_ctd') {
        element.state = 0;
        element.currentValue = element.presetValue; // Initialize CTD counter current value to preset

        const counter = counters.counter_ctd.find(c => c.id === element.id);
        if (counter) {
          counter.currentValue = element.presetValue; // Ensure counter is also initialized
          counter.state = 0;
          counter.previousState = 0;
        }
      }
      element.elapsedTime = 0;
    });
  });

  lastUpdateTime = performance.now();
  startTimerIntervals();
  updateCounters();
  calculateEnergizedStates();
}

function stopSimulation() {
  isSimulating.value = false;
  clearInterval(timerInterval);

  rungs.value.forEach(rung => {
    rung.elements.forEach(element => {
      if (element.type === 'contact_no') {
        element.state = 0;
      } else if (element.type === 'contact_nc') {
        element.state = 1;
      } else if (element.type === 'contact_pos') {
        element.state = 0;
      } else if (element.type === 'contact_neg') {
        element.state = 1;
      } else if (element.type === 'coil') {
        element.state = 0;
      }  else if (element.type === 'r_coil') {
        element.state = 0;
      } else if (element.type === 'coil_set') {
        element.state = 0;
      } else if (element.type === 'coil_reset') {
        element.state = 0;
      } else if (element.type === 'timer_ton') {
        element.state = 0;
      } else if (element.type === 'timer_tof') {
        element.state = 0;
      } else if (element.type === 'timer_tonr') {
        element.state = 0;
      } else if (element.type === 'counter_ctu') {
        element.state = 0;
        element.currentValue = 0; // Reset counter current value
      } else if (element.type === 'counter_ctd') {
        element.state = 0;
        element.currentValue = element.presetValue; // Initialize CTD counter current value to preset
      }
      element.elapsedTime = 0;
    });
  });

  updateSegments();
}



   //here we have all the setup for the left-column tool menu
      //----------------------------------------------------------------
    const tooltipText = ref('');
    const tooltipVisible = ref(false);
    const tooltipX = ref(0);
    const tooltipY = ref(0);
    const tooltipTimeout = ref(null);
    //const tabIndices = ['ld_editor', 'variables'];
    const tab = ref(0);
    const variables = ref([]);

    function showTooltip(text, event) {
      tooltipTimeout.value = setTimeout(() => {
        tooltipText.value = text;
        tooltipX.value = event.clientX + 5;
        tooltipY.value = event.clientY + 28;
        tooltipVisible.value = true;
      }, 500);
    }

    function showTooltip2(text, event) {
      tooltipTimeout.value = setTimeout(() => {
        tooltipText.value = text;
        tooltipX.value = event.clientX + 0;
        tooltipY.value = event.clientY + 15;
        tooltipVisible.value = true;
      }, 500);
    }

    function hideTooltip() {
      clearTimeout(tooltipTimeout.value);
      tooltipVisible.value = false;
    }


    function getVariableClass(prefix) {
      switch (prefix) {
        case 'C': return 'Coil';
        case 'R_C': return 'R_Coil';
        case 'NO': return 'Contact';
        case 'NC': return 'Contact';
        case 'P': return 'Contact';
        case 'N': return 'Contact';
        case 'SC': return 'Coil';
        case 'RC': return 'Coil';
        case 'TON': return 'Timer';
        case 'TOF': return 'Timer';
        case 'TONR': return 'Timer';
        case 'CTU': return 'Counter';
        case 'CTD': return 'Counter';
        case 'CTUD': return 'Counter';
        case 'EQU': return 'Comparator';
        case 'NEQ': return 'Comparator';
        case 'GRT': return 'Comparator';
        case 'GEQ': return 'Comparator';
        case 'LES': return 'Comparator';
        case 'LEQ': return 'Comparator';
        case 'ADD': return 'Operation';
        case 'SUB': return 'Operation';
        case 'MUL': return 'Operation';
        case 'DIV': return 'Operation';
        case 'AND': return 'Logical Operation';
        case 'OR': return 'Logical Operation';
        case 'NOT': return 'Logical Operation';
        default: return '';
      }
    }

    function deleteVariable(id) {
      const index = variables.value.findIndex((v) => v.id === id);
      if (index !== -1) {
        variables.value.splice(index, 1);
      }
          updateSegments();

    // Push the new state to history
    pushState({
      rungs: JSON.parse(JSON.stringify(rungs.value)),
      variables: JSON.parse(JSON.stringify(variables.value)),
    });
    }


   //here we have all the setup for the right-column ladder logic area
   //----------------------------------------------------------------

   //+++++++++++++here is script related to rung selection and basic rung addition and rung simulation++++++++++++++
    const selectedSegment = ref(null); // Current selected segment id
    const selectedElement = ref(null); // Track selected element id
    const selectedRung = ref(null); // Track selected rung index
        // Simulation state
    const isSimulating = ref(false);
    const energizedRungs = ref([]);
    const energizedSegments = ref([]);

    const rungs = ref([{ id: 1, elements: [], segments: [] }]);
    const ladderLogicArea = ref(null);
    const ladderLogicWidth = ref(0);

    const rungHeights = ref([]); // This will store the dynamic heights of the rungs


    function pushState(state) {
      // When making a new action, clear all forward states if any exist
      if (history.currentIndex + 1 < history.states.length) {
        history.states.splice(history.currentIndex + 1);
      }
      history.states.push(state);
      history.currentIndex++;
    }

    const simulationAlertDialog = ref(false); // New dialog visibility state

    function undo() {
      if (isSimulating.value) {
        simulationAlertDialog.value = true; // Show dialog
        return;
      }
      if (history.currentIndex > 0) {
        history.currentIndex--;
        applyState(history.states[history.currentIndex]);
      }
    }

    function redo() {
      if (isSimulating.value) {
        simulationAlertDialog.value = true; // Show dialog
        return;
      }
      if (history.currentIndex < history.states.length - 1) {
        history.currentIndex++;
        applyState(history.states[history.currentIndex]);
      }
    }

    function applyState(state) {
      // Here you apply the state to your application
      rungs.value = JSON.parse(JSON.stringify(state.rungs));
      variables.value = JSON.parse(JSON.stringify(state.variables));
      updateSegments();
    }

    function energizeVerticalBranchSegments(rungIndex, lastPosition, segmentX1, segmentX2, energizeVerticalStart, energizeVerticalEnd) {
  const rung = rungs.value[rungIndex];

  // Find relevant vertical branches using lastPosition and segmentX1, segmentX2
  const verticalBranches = rung.elements.filter(element => 
    element.type === 'branch_vertical' &&
    (element.position == segmentX1 || element.position == segmentX2)
  );

  verticalBranches.forEach(branch => {
    // Determine if the branch is start or end based on its ID or position
    const isStartSegment = branch.y1 < branch.y2; // assuming y1 < y2 for start
    // Energize start segment if the condition is met
    if (isStartSegment) {
      branch.state = energizeVerticalStart ? 1 : 0;
    } else {
      branch.state = energizeVerticalEnd ? 1 : 0;
    }
    // console.log(`${branch.state === 1 ? 'Energizing' : 'De-energizing'} vertical branch: ${branch.id}`);
  });
}




    const segmentCount = computed(() => {
      if (!ladderLogicWidth.value) return 0; // Ensures there is a valid width
      const width = parseFloat(ladderLogicWidth.value); // Convert width to a number
      // console.log('Computed Width:', width);
      const count = Math.floor(width / 72);
      // console.log('count:', count);
      return count > 0 ? count : 0; // Prevents negative or zero counts which may cause errors
    });

    onMounted(() => {
  // Initial width update when component mounts
  updateWidth(); 
  selectedRung.value = 0; // Initialize to the first rung if exists

  // Listen for window resize to adjust width
  window.addEventListener('resize', updateWidth);

  // Observe changes in ladderLogicArea to update segments
  const observer = new ResizeObserver(() => {
    updateSegments();
  });
  if (ladderLogicArea.value) {
    observer.observe(ladderLogicArea.value);
  }

  // Push the initial state to history
  pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) });

  // Recalculate rung heights on component mount (initial load)
  calculateRungHeight();

  // Clean up on unmount
  onUnmounted(() => {
    window.removeEventListener('resize', updateWidth);
    observer.disconnect(); // Clean up observer on unmount
  });
});

    function updateWidth() {
      const area = document.querySelector('.ladder-logic-wrapper');
      if (area) {
        ladderLogicWidth.value = area.offsetWidth; // Set width based on the container's actual size
      }
      updateSegments(); // Ensure segments are updated after resizing
    }

// Watch for changes in window width to update the width and segments
watch(
  () => window.innerWidth,
  () => {
    if (ladderLogicArea.value) {
      ladderLogicWidth.value = ladderLogicArea.value.offsetWidth;
    }
    updateSegments(); // Ensure segments are updated after window resize
  }
);

// Watch for changes in the rungs to update the rung heights dynamically
watch(
  () => rungs.value,
  () => {
    calculateRungHeight(); // Recalculate heights when rungs change
  },
  { deep: true } // Ensure deep watch to capture nested changes
);

watch(
  () => window.innerWidth,
  () => {
    calculateRungHeight(); // Recalculate heights on window resize
  }
);

    
    const computedSegments = computed(() => {
      if (!rungs.value) return [];
      // console.log('Computing segments for rungs:', JSON.parse(JSON.stringify(rungs.value)));
      return rungs.value.map((rung) => { // return rungs.value.map((rung, index) => {
        // console.log(`Computing segments for rung ${index}:`, JSON.parse(JSON.stringify(rung)));
        const segments = getLineSegments(rung.elements || []);
        // console.log(`CS-Segments for rung ${index}:`, JSON.parse(JSON.stringify(segments)));
        return {
          ...rung,
          elements: rung.elements || [],
          segments,
        };
      });
    });

    const filteredSegments = (segments) => {
      return segments.filter((segment) => !isSegmentOverlappingTool(segment));
    };

    const computedWidth = computed(() => {
      // Calculate the necessary width based on the segments
      const widths = computedSegments.value.map((rung) =>
        rung.segments.reduce((max, segment) => Math.max(max, segment.x2), 0)
      );
      // Ensure at least 1000px wide or the maximum segment end
      return Math.max(...widths, 1000);
    });


   

    function addRung() {
  if (isSimulating.value) {
    addRungDialog.value.visible = true; // Show dialog if in simulation mode
    return;
  }

  // Find the highest rung ID and add 1 for the new rung ID
  const maxRungId = rungs.value.reduce((maxId, rung) => Math.max(maxId, rung.id), 0);
  const newRungId = maxRungId + 1;

  let insertIndex = rungs.value.length; // Default index to add rung at the end

  // Check if a rung or element is selected
  if (selectedRung.value !== null && selectedRung.value !== undefined) {
    // Place the new rung below the selected rung
    insertIndex = selectedRung.value + 1;
  }

  // Insert a new rung at the specified index with a unique rungId
  rungs.value.splice(insertIndex, 0, { id: newRungId, elements: [], segments: [] });
  updateSegments(); // Update segments for all rungs
  // console.log('Added new rung:', JSON.parse(JSON.stringify(rungs.value)));

  // Push the new state to history
  pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) });
 
}

function deleteRung() {
  if (isSimulating.value) {
    // console.log('Cannot delete rung while in Run Mode.');
    return;
  }

  // Default index to delete the last rung if nothing is selected
  let deleteIndex = rungs.value.length - 1;

  // Check if a rung or element is selected
  if (selectedRung.value !== null && selectedRung.value !== undefined) {
    deleteIndex = selectedRung.value; // Set the index to delete the selected rung
  }

  // Ensure we don't try to delete an invalid rung
  if (deleteIndex >= 0 && deleteIndex < rungs.value.length) {
    // console.log(`Deleting rung with index: ${deleteIndex} and id: ${rungs.value[deleteIndex].id}`);
    rungs.value.splice(deleteIndex, 1); // Remove the selected rung
    updateSegments(); // Update segments for all rungs

    // Clear selectedRung and selectedElement after deletion
    selectedRung.value = null;
    selectedElement.value = null;
    selectedSegment.value = null;

    // console.log('Updated rungs:', JSON.parse(JSON.stringify(rungs.value)));

    // Push the updated state to history
    pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) });
  } else {
    // console.log('No valid rung to delete.');
  }
}





 

    // Method to update variable when user changes input
    const updateVariable = (index, updatedVariable) => {
      variables.value[index] = { ...variables.value[index], ...updatedVariable };

      // Push the new state to history
      pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) });
    };

        // Function to calculate height based on content (e.g., number of elements in the rung)
        const calculateRungHeight = () => {
  rungHeights.value = rungs.value.map((rung) => {
    const baseHeight = getRungBaseHeight(rung); // Calculate base height based on elements
    const additionalHeight = 50; // Add extra 50 pixels to ensure no elements are missed
    return baseHeight + additionalHeight;
  });
};


    // Computed property to recalculate heights when the rungs data changes
    const computedRungHeights = computed(() => {
      return rungs.value.map((rung, index) => {
        // Ensure this is reactive to changes in rungs
        return rungHeights.value[index] || 100; // Default to 'auto' if not yet calculated
      });
    });



    const getRungBaseHeight = (rung) => {
  // Base height for an empty rung
  let baseHeight = 65;

  // Height added for each new branch level
  const branchLevelHeight = 60;

  // Initialize max branch level
  let maxBranchLevel = 0;

  // Filter branch elements
  const branchElements = rung.elements.filter(element => 
    element.type === 'branch_vertical' || element.type === 'branch_horizontal'
  );

  // Determine the highest branch level within this rung
  branchElements.forEach(element => {
    if (element.level > maxBranchLevel) {
      maxBranchLevel = element.level;
    }
  });

  // Increase height based on the maximum branch level
  baseHeight += maxBranchLevel * branchLevelHeight;

  // Return the calculated height
  return baseHeight;
};















    function isSegmentOverlappingTool(segment) {
      return rungs.value[selectedRung.value]?.elements.some(
        (element) =>
          element.position <= segment.x1 &&
          element.position + 72 >= segment.x2
      );
    }
    
    function getLineSegments(elements) {
  // console.log('Getting line segments for elements:', JSON.parse(JSON.stringify(elements)));
  let segments = [];
  const segmentWidth = 72; // Fixed segment width
  const rungWidth = parseInt(getRungWidth(elements, true)); // Total width of the rung
  const processedPositions = new Set();

  // Sort elements by position to ensure proper segment calculation
  elements.sort((a, b) => a.position - b.position);

  let lastPosition = 0;

  // Handle branch elements separately
  const branchElements = elements.filter(element => element.type === 'branch_vertical' || element.type === 'branch_horizontal');
  const nonBranchElements = elements.filter(element => element.type !== 'branch_vertical' && element.type !== 'branch_horizontal');

  // console.log('Non-branch elements:', JSON.parse(JSON.stringify(nonBranchElements)));
  // console.log('Branch elements:', JSON.parse(JSON.stringify(branchElements)));

  // Add segments before the first element
  if (nonBranchElements.length > 0) {
    const firstElementPosition = nonBranchElements[0].position;
    // console.log('First non-branch element position:', firstElementPosition);

    for (let x = 0; x < firstElementPosition; x += segmentWidth) {
      if (!processedPositions.has(x)) {
        segments.push({
          id: `segment-${x}-${80}`,
          x1: x,
          x2: Math.min(x + segmentWidth, firstElementPosition),
          y1: 80,
          y2: 80,
          state: 1, // Default state
          type: 'segment',
          level: 0,
          isLast: false // Not the last segment
        });
        processedPositions.add(x);
      }
    }
    lastPosition = firstElementPosition;
    // console.log('Segments before the first element:', JSON.parse(JSON.stringify(segments)));
  }

  // Add segments for non-branch elements
  nonBranchElements.forEach(element => {
    const start = element.position;
    // console.log(`Processing element at position ${start}`);

    // Add segments before the current element if there's space
    if (lastPosition < start) {
      for (let x = lastPosition; x < start; x += segmentWidth) {
        if (!processedPositions.has(x)) {
          segments.push({
            id: `segment-${x}-${80}`,
            x1: x,
            x2: Math.min(x + segmentWidth, start),
            y1: 80,
            y2: 80,
            state: 1, // Default state
            type: 'segment',
            level: 0,
            isLast: false // Not the last segment
          });
          processedPositions.add(x);
        }
      }
    }

    // Update lastPosition to the end of the current element
    lastPosition = start + segmentWidth;
    // console.log(`Updated lastPosition to ${lastPosition}`);
  });

  // Handle branches and ensure segment is added if needed
  branchElements.forEach(branch => {
    // console.log(`Processing branch at position ${branch.position}`);
    const yOffset = branch.level * 70; // Calculate y offset based on the branch level
    if (branch.type === 'branch_vertical') {
      // Handle vertical branches if needed
    } else if (branch.type === 'branch_horizontal') {
      // Add segments before the branch if there's space
      if (lastPosition < branch.position) {
        for (let x = lastPosition; x < branch.position; x += segmentWidth) {
          if (!processedPositions.has(x)) {
            segments.push({
              id: `segment-${x}-${80}`,
              x1: x,
              x2: Math.min(x + segmentWidth, branch.position),
              y1: 80,
              y2: 80,
              state: 1, // Default state
              type: 'segment',
              level: 0,
              isLast: false // Not the last segment
            });
            processedPositions.add(x);
          }
        }
      }
      // Add rung segments in the place where a branch is added
      for (let x = branch.position; x < branch.position + segmentWidth; x += segmentWidth) {
        if (!processedPositions.has(x)) {
          segments.push({
            id: `segment-${x}-${80}`,
            x1: x,
            x2: Math.min(x + segmentWidth, branch.position + segmentWidth),
            y1: 80,
            y2: 80,
            state: 1, // Default state
            type: 'segment',
            level: 0,
            isLast: false // Not the last segment
          });
          processedPositions.add(x);
          lastPosition = branch.position + segmentWidth;
        }
      }
      // Add horizontal branch segments
      const branchEndPosition = branch.position + segmentWidth;
      segments.push({
        id: `branch-horizontal-${branch.position}-${branch.level}`, // Ensure consistent ID with x and y positions
        x1: branch.position,
        x2: branchEndPosition,
        y1: 78 + yOffset, // Adjust y1 based on level
        y2: 78 + yOffset, // Same y for both points in a horizontal line
        state: 1, // Default state
        type: 'branch_horizontal',
        level: branch.level,
        isLast: false // Not the last segment
      });
      processedPositions.add(branch.position);

      lastPosition = branchEndPosition; // Update last position
      // console.log(`Updated lastPosition after branch to ${lastPosition}`);
      // console.log(`Processing branch at  branch level ${branch.level}`);

    }
  });

  // Ensure rung segment is added above the horizontal branch line if it has been replaced by an element
  elements.forEach(element => {
    const branchPosition = element.position;
    const branchEndPosition = branchPosition + segmentWidth;
    if (element.type !== 'branch_vertical' && element.type !== 'branch_horizontal' && element.y1 !== 80) {
      if (!segments.some(seg => seg.x1 === branchPosition && seg.y1 === 80) && 
          !elements.some(el => el.position === branchPosition && el.y1 === 80 && el.type !== 'branch_vertical')) {
        segments.push({
          id: `segment-${branchPosition}-${80}`,
          x1: branchPosition,
          x2: branchEndPosition,
          y1: 80,
          y2: 80,
          state: 1, // Default state
          type: 'segment',
          level: 0,
          isLast: false // Not the last segment
        });
        processedPositions.add(branchPosition);
        lastPosition = branchEndPosition; // Update last position
        // console.log(`segment positioned at ${branchPosition}`);
      }
    } else if (element.type !== 'branch_vertical' && element.type !== 'branch_horizontal' && element.y1 === 80) {
      // If there's an element at y1 = 80, remove the segment to avoid overlap
      segments = segments.filter(seg => {
        if (seg.x1 === element.position && seg.y1 === 80) {
          // console.log(`Removing segment with x1: ${seg.x1}, y1: ${seg.y1}`);
          return false; // This will remove the segment
        }
        return true;
      });
      processedPositions.add(element.position); // Update processed positions
      lastPosition = Math.max(lastPosition, element.position + segmentWidth); // Update last position
      // console.log(`Removed overlapping segment at ${element.position}`);
    }
  });

  // console.log('Segments before filtering:', JSON.parse(JSON.stringify(segments)));

  // Add remaining segments after the last element or branch
  for (let x = lastPosition; x < rungWidth; x += segmentWidth) {
    if (!processedPositions.has(x)) {
      segments.push({
        id: `segment-${x}-${80}`,
        x1: x,
        x2: Math.min(x + segmentWidth, rungWidth),
        y1: 80,
        y2: 80,
        state: 1, // Default state
        type: 'segment',
        level: 0,
        isLast: x + segmentWidth >= rungWidth // Mark the last segment
      });
      processedPositions.add(x);
    }
  }

  // console.log('Segments before final filtering:', JSON.parse(JSON.stringify(segments)));

  // Filter out segments that overlap with elements, but keep the segment where the branch was added
  const nonOverlappingSegments = segments.filter(segment => {
    return !nonBranchElements.some(element =>
      (segment.x1 < element.position + segmentWidth) && (segment.x2 > element.position)
    ) || segment.id.startsWith('branch-horizontal') || segment.id.startsWith('segment');
  });

  // console.log('Final segments after filtering:', JSON.parse(JSON.stringify(nonOverlappingSegments)));
  return nonOverlappingSegments;
}




const computedRungWidths = computed(() => {
  return computedSegments.value.map(rung => {
    const maxElementPosition = Math.max(
      ...rung.elements.map(el => el.position || 0)
    );

    // Ensure the width is at least the available width, but extends to fit all elements
    return Math.max(getRungWidth(rung.elements || [], true), maxElementPosition + 86); // Add some padding (72px)
  });
});





function getRungWidth(elements, isEndLine = false) {
  // Ensure elements is an array before filtering
  const elementsArray = Array.isArray(elements) ? elements : [];

  // Filter elements that are positioned on the main rung line (y1 === 80)
  const filteredElements = elementsArray.filter(el => el.y1 === 80);

  // Calculate the base width based on the filtered elements
  const baseWidth = filteredElements.length * 72; // Each element contributes 72 to the width
  const totalWidth = baseWidth + (isEndLine ? 30 : 0);

  return Math.max(totalWidth, ladderLogicWidth.value); // Use the greater of calculated width or the container width
}




    // Methods to add rung branches

    const showPlusButton = ref(false);
    const plusButtonPosition = ref({ x: 0, y: 0 });
    const selectedBranchElement = ref(null); // To track the selected element for branching
    const showExtendBranchButton = ref(false);
    const extendBranchButtonPosition = ref({ x: 0, y: 0 });
    const showWarningButton = ref(false);
    const warningButtonPosition = ref({ top: 0, left: 0 });
    const warningDialog = ref({ visible: false });


    const computedVerticalBranches = computed(() => {
      return rungs.value.map((rung) => {
        return rung.elements.filter((element) => element.type === 'branch_vertical');
      });
    });

    const computedHorizontalBranches = computed(() => {
      return rungs.value.map((rung) => {
        return rung.elements.filter((element) => element.type === 'branch_horizontal');
      });
    });


    function addBranch() {
  const rungIndex = selectedRung.value;
  const rung = rungs.value[rungIndex];
  let position = null;

  // console.log('CCC- Current rung index:', rungIndex);
  // console.log('CCC- Selected segment:', selectedSegment.value);
  // console.log('CCC- Selected element:', selectedElement.value);

  // Reset selectedSegment to null if an element is selected
  if (selectedElement.value !== null) {
    selectedSegment.value = null;
  }
  // Determine the selected position
  if (selectedSegment.value !== null) {
    const segment = rung.segments.find(seg => seg.id === selectedSegment.value);
    // console.log('CCC- Found segment for selectedSegment:', segment);

    if (segment) {
      position = segment.x1;
    }
  } else if (selectedElement.value !== null) {
    const element = rung.elements.find(el => el.id === selectedElement.value);
    // console.log('CCC- Found element:', element);

    if (element) {
      position = element.position;
      selectedSegment.value = null; // Ensure selectedSegment is null when an element is selected
      // console.log(`CCC- Element Position: ${position}`);

    }
  }

  if (position === null) {
    console.error('No position found for adding branch.');
    return;
  }

  // Find all branch levels at the selected position, considering both segments and elements
  const branchesAtPosition = rung.elements.filter(el => 
    el.position === position && 
    (el.type === 'branch_vertical' || el.type === 'branch_horizontal' || (el.type !== 'branch_vertical' && el.type !== 'branch_horizontal'))
  );
  // console.log('CCC- branchesAtPosition:', JSON.parse(JSON.stringify(branchesAtPosition)));

  let level = 1;

  if (branchesAtPosition.length > 0) {
    // Determine the appropriate level for the new branch
    const maxLevelAtPosition = Math.max(...branchesAtPosition.map(b => b.level || 1));
    // console.log(`CCC- maxLevelAtPosition: ${maxLevelAtPosition}`);

    // Check if the position is part of an extended branch
    const extendedBranchSegment = rung.elements.find(el => 
      el.position === position && 
      el.level === maxLevelAtPosition
    );

    if (extendedBranchSegment) {
      // If extending a branch at this position, add the new branch at the next level
      level = maxLevelAtPosition + 1;
      // console.log(`CCC- if extendedBranchSegment maxLevelAtPosition: ${maxLevelAtPosition}`);

    } else {
      // Otherwise, maintain the level and add the branch at the same level
      level = maxLevelAtPosition;
      // console.log(`CCC- If not extended Branch maxLevelAtPosition: ${maxLevelAtPosition}`);

    }
  }

  // Double-check to ensure we have the correct branch level
  // console.log('CCC- Determined branch level:', level);

  const yOffset = level * 70; // Calculate y offset based on the branch level

  // Add vertical line at the start of the branch
  const verticalStart = {
    id: `branch-vertical-start-${position}-${80}-${level}`,
    type: 'branch_vertical',
    position: position,
    y1: 80 + (level - 1) * 70, // Adjust y1 to start from the previous branch level
    y2: 80 + yOffset, // Adjust y2 based on level
    state: 1,
    level: level
  };
  rung.elements.push(verticalStart);

  // Add horizontal branch segment
  const horizontalBranch = {
    id: `branch-horizontal-${position}-${level}`,
    type: 'branch_horizontal',
    position: position,
    y1: 78 + yOffset, // Adjust y1 based on level
    y2: 78 + yOffset, // Same y for both points in a horizontal line
    state: 1,
    level: level
  };
  rung.elements.push(horizontalBranch);

  // Add vertical line at the end of the branch
  const verticalEnd = {
    id: `branch-vertical-end-${position + 72}-${level}`,
    type: 'branch_vertical',
    position: position + 72,
    y1: 80 + yOffset, // Adjust y1 based on level
    y2: 80 + (level - 1) * 70, // Adjust y2 to end at the previous branch level
    state: 1,
    level: level
  };
  rung.elements.push(verticalEnd);

  // Initialize currentElement and currentRungIndex
  currentElement.value = { id: horizontalBranch.id, position: horizontalBranch.position, type: horizontalBranch.type, level: horizontalBranch.level };
  currentRungIndex.value = rungIndex;

  // Deselect the current segment or element and select the horizontal branch line
  selectedSegment.value = null; // Ensure selectedSegment is null
  selectedElement.value = horizontalBranch.id;
  selectedRung.value = rungIndex;

  // Update the position of the plus button
  plusButtonPosition.value = {
    top: horizontalBranch.y1 - 22, // Position the button 25px below the horizontal branch
    left: horizontalBranch.position + 51  // Center the button, assuming the button width is 24px and element width is 72px
  };
  showPlusButton.value = false;
  showExtendBranchButton.value = false;


  updateSegments();

  // Push the new state to history
  pushState({
    rungs: JSON.parse(JSON.stringify(rungs.value)),
    variables: JSON.parse(JSON.stringify(variables.value)),
  });

  // console.log('CCC- Branch added at position:', position, 'Level:', level, 'Existing branches at position:', branchesAtPosition);
}





function extendBranch() {
  const rung = rungs.value[currentRungIndex.value];
  const branchElement = currentElement.value;

  if (!branchElement) return;

  const branchLevel = branchElement.level;
  const newPosition = branchElement.position + 72; // The position to extend to
  // console.log(`AAA- 1 Attempting to extend branch at level ${branchLevel} from position ${branchElement.position} to ${newPosition}`);

  // Add the new horizontal and vertical segments for the extended branch, ensuring no duplicates
  const existingHorizontalSegment = rung.elements.find(el =>
    el.type === 'branch_horizontal' && el.position === newPosition && el.level === branchLevel
  );

  if (existingHorizontalSegment) {
    // console.log(`AAA- 2 Found existing horizontal segment: ID: ${existingHorizontalSegment.id}, Position: ${existingHorizontalSegment.position}, Level: ${existingHorizontalSegment.level}`);
  } else {
    // console.log(`AAA- 3 No existing horizontal segment found at position ${newPosition} for level ${branchLevel}`);
  }

  const existingVerticalEndSegment = rung.elements.find(el =>
    el.type === 'branch_vertical' && el.position === newPosition + 72 && el.level === branchLevel
  );

  if (existingVerticalEndSegment) {
    // console.log(`AAA- 4 Found existing vertical end segment: ID: ${existingVerticalEndSegment.id}, Position: ${existingVerticalEndSegment.position}, Level: ${existingVerticalEndSegment.level}`);
  } else {
    // console.log(`AAA- 5 No existing vertical end segment found at position ${newPosition + 72} for level ${branchLevel}`);
  }

  if (!existingHorizontalSegment) {
    rung.elements.push({
      id: `branch-horizontal-${newPosition}-${branchLevel}`,
      type: 'branch_horizontal',
      position: newPosition,
      y1: 78 + branchLevel * 70, // Adjust y1 based on branch level
      y2: 78 + branchLevel * 70, // Same y for both points in a horizontal line
      state: 1,
      level: branchLevel
    });
    // console.log(`AAA- 6 Added new horizontal segment at position ${newPosition} for level ${branchLevel}`);
  } else {
    // console.log(`AAA- 7 Horizontal segment at position ${newPosition} already exists.`);
  }

  if (!existingVerticalEndSegment) {
  // Determine the correct y1 and y2 based on the branch level
  const y1 = 80 + (branchLevel - 1) * 70; // y1 starts at the previous level
  const y2 = 80 + branchLevel * 70; // y2 ends at the current level

  rung.elements.push({
    id: `branch-vertical-end-${newPosition + 72}-${branchLevel}`,
    type: 'branch_vertical',
    position: newPosition + 72,
    y1: y1, // Adjust y1 based on the previous level
    y2: y2, // Adjust y2 based on the current level
    state: 1,
    level: branchLevel
  });

  // console.log(`AAA- 8 Added new vertical end segment at position ${newPosition + 72} for level ${branchLevel}, spanning from y=${y1} to y=${y2}`);
  if (branchLevel === 1) {
    // console.log(`AAA- 8a Added vertical end segment at position ${newPosition + 72} for level 1.`);
  }
} else {
  // console.log(`AAA- 9 Vertical end segment at position ${newPosition + 72} already exists.`);
}


  // Extend any lower-level branches that intersect with this new segment
  const lowerBranches = rung.elements.filter(el => 
    (el.type === 'branch_horizontal' || el.type !== 'branch_vertical') 
    && el.position === branchElement.position
  );
  // console.log('AAA- lowerBranches:', JSON.parse(JSON.stringify(lowerBranches)));

  lowerBranches.forEach(lowerBranch => {
    const lowerBranchNewPosition = lowerBranch.position + 72;
    const currentVerticalEndPosition = newPosition + 72;

    // Find the vertical end of the lower branch
    const lowerBranchVerticalEnd = rung.elements.find(el =>
      el.type === 'branch_vertical' && el.level === lowerBranch.level && el.position === lowerBranch.position + 72
    );

    if (lowerBranchVerticalEnd) {
      // console.log(`AAA- 10 Found lower branch vertical end: ID: ${lowerBranchVerticalEnd.id}, Position: ${lowerBranchVerticalEnd.position}, Level: ${lowerBranchVerticalEnd.level}`);
    } else {
      // console.log(`AAA- 11 No lower branch vertical end found at expected position (${lowerBranch.position + 72}) for level ${lowerBranch.level}`);
    }

    // Check if the current branch extends beyond the lower branch
    if (lowerBranchVerticalEnd && currentVerticalEndPosition > lowerBranchVerticalEnd.position) {
      // console.log(`AAA- 12 Extending lower branch at level ${lowerBranch.level} from position ${lowerBranch.position} to ${lowerBranchNewPosition}`);

      rung.elements = rung.elements.filter(el => {
        const shouldRemove = (el.type === 'branch_vertical' && el.position === lowerBranchNewPosition && el.level === lowerBranch.level);
        if (shouldRemove) {
          // console.log(`AAA- 13 Removing overlapping vertical segment for lower branch: ID: ${el.id}, Position: ${el.position}, Level: ${el.level}`);
        } else {
          // console.log(`AAA- 14 Keeping element: ID: ${el.id}, Position: ${el.position}, Level: ${el.level}`);
        }
        return !shouldRemove;
      });

      // Check if there's a non-branch element or an existing horizontal segment in this position
      const existingElementAtNewPosition = rung.elements.find(el =>
        (el.type !== 'branch_vertical' && el.position === lowerBranchNewPosition && el.level === lowerBranch.level) ||
        (el.type === 'branch_horizontal' && el.position === lowerBranchNewPosition && el.level === lowerBranch.level)
      );

      if (!existingElementAtNewPosition) {
        rung.elements.push({
          id: `branch-horizontal-${lowerBranchNewPosition}-${lowerBranch.level}`,
          type: 'branch_horizontal',
          position: lowerBranchNewPosition,
          y1: 78 + lowerBranch.level * 70, // Adjust y1 based on lower branch level
          y2: 78 + lowerBranch.level * 70, // Same y for both points in a horizontal line
          state: 1,
          level: lowerBranch.level
        });
        // console.log(`AAA- 15 Added new horizontal segment at position ${lowerBranchNewPosition} for level ${lowerBranch.level}`);
      } else {
        // console.log(`AAA- 16 An element already exists at position ${lowerBranchNewPosition} for level ${lowerBranch.level}.`);
      }

      const existingLowerVerticalEndSegment = rung.elements.find(el =>
        el.type === 'branch_vertical' && el.position === lowerBranchNewPosition + 72 && el.level === lowerBranch.level
      );

      if (!existingLowerVerticalEndSegment) {
          // Determine the correct y1 and y2 based on the levels
          const y1 = 80 + (lowerBranch.level - 1) * 70; // y1 starts at the previous level
          const y2 = 80 + lowerBranch.level * 70; // y2 ends at the current level

          rung.elements.push({
            id: `branch-vertical-end-${lowerBranchNewPosition + 72}-${lowerBranch.level}`,
            type: 'branch_vertical',
            position: lowerBranchNewPosition + 72,
            y1: y1, // Adjust y1 based on the previous level
            y2: y2, // Adjust y2 based on the current level
            state: 1,
            level: lowerBranch.level
          });

          // console.log(`AAA- 17 Added new vertical end segment at position ${lowerBranchNewPosition + 72} for level ${lowerBranch.level}, spanning from y=${y1} to y=${y2}`);
        }

    } 
  });

  // Update the UI
  updateSegments();

  // Recalculate and show the extend button if needed
  if (currentElement.value && currentElement.value.level === branchLevel) {
    extendBranchButtonPosition.value = {
      top: branchElement.y1 - 30,
      left: newPosition + 100
    };
    showExtendBranchButton.value = false;
  }

  pushState({
    rungs: JSON.parse(JSON.stringify(rungs.value)),
    variables: JSON.parse(JSON.stringify(variables.value)),
  });

  // Clear selection after extending
  selectedSegment.value = null;
  selectedRung.value = null;
  selectedElement.value = null;

  // console.log(`AAA- 20 Branch successfully extended from position ${branchElement.position} to ${newPosition}`);
}

































function getElementPosition(element, rungIndex) {
  const rung = computedSegments.value[rungIndex];
  if (!rung) return { x: 0, y: 0 };

  const segment = rung.segments.find(seg => seg.x1 === element.position);
  return { x: segment ? segment.x1 : 0, y: rungIndex * 100 }; // Adjust y-coordinate based on rung index
}

function getSegmentPosition(segment) {
  return { x: segment.x1, y: segment.y1 }; // Adjust y1 based on your segment structure
}

function deleteSelectedBranch(position, rungIndex) {
  const rung = rungs.value[rungIndex];

  // Log the current rung state for debugging
  // console.log('Current rung state:', JSON.stringify(rung.elements, null, 2));

  // Find the selected horizontal branch element at the highest level
  const selectedBranchElement = rung.elements
    .filter(el => el.type === 'branch_horizontal' && el.position === position)
    .sort((a, b) => b.level - a.level)[0];  // Select the highest-level branch

  if (!selectedBranchElement) {
    console.error('Branch element not found at position:', position);
    return;
  }

  // console.log(`Selected branch element: ${JSON.stringify(selectedBranchElement)}`);

  // Find the branch-vertical-start to the left of the selected branch element at the same level
  const branchStartElement = rung.elements
    .filter(el => el.type === 'branch_vertical' && el.id.includes('start') && el.level === selectedBranchElement.level)
    .reverse()
    .find(el => el.position <= selectedBranchElement.position);

  if (!branchStartElement) {
    console.error('Branch start element not found.');
    return;
  }

  // console.log(`Branch start element: ${JSON.stringify(branchStartElement)}`);

  // Find the branch-vertical-end to the right of the selected branch element at the same level
  const branchEndElement = rung.elements.find(el => 
    el.type === 'branch_vertical' && 
    el.id.includes('end') && 
    el.position > selectedBranchElement.position && 
    el.level === selectedBranchElement.level
  );

  if (!branchEndElement) {
    console.error('Branch end element not found.');
    return;
  }

  // console.log(`Branch end element: ${JSON.stringify(branchEndElement)}`);

  // Calculate the range of positions to delete
  const startPosition = branchStartElement.position;
  const endPosition = branchEndElement.position;

  // console.log(`Deleting branch elements from position ${startPosition} to ${endPosition} at level ${selectedBranchElement.level}`);

  // Remove all elements within the range between startPosition and endPosition that are at the same level
  rung.elements = rung.elements.filter(el => {
    const isInRange = el.position >= startPosition && el.position <= endPosition;
    const isBranchElement = el.type.startsWith('branch_');
    const isSameLevel = el.level === selectedBranchElement.level;
    // Check if the element is at the same level and within the range
    return !(isInRange && isBranchElement && isSameLevel);
  });

  updateSegments();

  // Push the new state to history
  pushState({
    rungs: JSON.parse(JSON.stringify(rungs.value)),
    variables: JSON.parse(JSON.stringify(variables.value)),
  });

  // console.log(`Branch at level ${selectedBranchElement.level} deleted from position ${startPosition} to ${endPosition}`);

  selectedSegment.value = null;
  selectedRung.value = null;
  showPlusButton.value = false;
  showExtendBranchButton.value = false;
  showDeleteBranchButton.value = false;
}















const nonBranchSegments = computed(() => {
  return rungs.value.map((rung) => {
    return rung.segments.filter(segment => !isBranchSegment(segment));
  });
});

function isBranchSegment(segment) {
  const branchTypes = ['branch_vertical', 'branch_horizontal'];
  return branchTypes.some(type => segment.id.includes(type));
}
const nonBranchElements = computed(() => {
  return rungs.value.map((rung) => {
    return rung.elements.filter(element => !element.type.startsWith('branch_'));
  });
});

    const currentElement = ref(null); // To hold the current element
    const currentRungIndex = ref(null); // To hold the current rung index

 //   watch(() => currentElement.value, (newVal) => {
  // console.log('currentElement changed:', newVal);
// });

//watch(() => currentRungIndex.value, (newVal) => {
  // console.log('currentRungIndex changed:', newVal);
//});

    const deleteButtonCondition = computed(() => {
  if (!currentElement.value || currentRungIndex.value === null) return false;

  const isHorizontalBranch = currentElement.value.id.startsWith('branch-horizontal');
  const conditionMet = (selectedSegment.value === currentElement.value.id && selectedRung.value === currentRungIndex.value && !isSimulating.value && isHorizontalBranch);
  // console.log(`deleteButtonCondition - selectedSegment: ${selectedSegment.value}, element.id: ${currentElement.value.id}, selectedRung: ${selectedRung.value}, rungIndex: ${currentRungIndex.value}, isSimulating: ${isSimulating.value}, isHorizontalBranch: ${isHorizontalBranch}, conditionMet: ${conditionMet}`);
  return conditionMet;
});

const deleteButtonPosition = reactive({ top: 0, left: 0 });




    //+++++++++++++here is script related to add tools to rungs ++++++++++++++
    const tools = reactive([
      {
        id: 'coil',
        label: 'Coil',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="19" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="53" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><circle cx="36" cy="36" r="18" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/></svg>',
        visible: false,
      },
      {
        id: 'r_coil',
        label: 'R_Coil',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="19" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="53" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><circle cx="36" cy="36" r="18" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><text x="36" y="39" fill="rgb(3, 171, 255)" stroke="rgb(3, 171, 255)" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">R</text></svg>',
        visible: false,
      },
      {
        id: 'contact_no',
        label: 'N.O. Contact',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="28" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="44" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="28" y1="18" x2="28" y2="54" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="44" y1="18" x2="44" y2="54" stroke="rgb(3, 171, 255)" stroke-width="4"/></svg>',
        visible: false,
      },
      {
        id: 'contact_nc',
        label: 'N.C. Contact',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="28" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="44" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="28" y1="18" x2="28" y2="54" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="44" y1="18" x2="44" y2="54" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="28" y1="22" x2="44" y2="50" stroke="rgb(3, 171, 255)" stroke-width="4"/></svg>',
        visible: false,
      },
      {
        id: 'contact_pos',
        label: 'P Contact',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="26" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="46" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="26" y1="18" x2="26" y2="54" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="46" y1="18" x2="46" y2="54" stroke="rgb(3, 171, 255)" stroke-width="4"/><text x="36" y="39" fill="rgb(3, 171, 255)" stroke="rgb(3, 171, 255)" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">P</text></svg>',
        visible: false,
      },
      {
        id: 'contact_neg',
        label: 'N Contact',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="26" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="46" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="26" y1="18" x2="26" y2="54" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="46" y1="18" x2="46" y2="54" stroke="rgb(3, 171, 255)" stroke-width="4"/><text x="36" y="39" fill="rgb(3, 171, 255)" stroke="rgb(3, 171, 255)" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">N</text></svg>',
        visible: false,
      },
      {
        id: 'coil_set',
        label: 'Set Coil',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="19" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="53" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><circle cx="36" cy="36" r="18" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><text x="36" y="39" fill="rgb(3, 171, 255)" stroke="rgb(3, 171, 255)" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">S</text></svg>',
        visible: false,
      },
      {
        id: 'coil_reset',
        label: 'Reset Coil',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="19" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="53" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><circle cx="36" cy="36" r="18" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><text x="36" y="39" fill="rgb(3, 171, 255)" stroke="rgb(3, 171, 255)" stroke-width="1" font-size="28" text-anchor="middle" alignment-baseline="middle">R</text></svg>',
        visible: false,
      },
      {
        id: 'timer_ton',
        label: 'TON Timer',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="8" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="64" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><circle cx="36" cy="36" r="26" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><text x="36" y="39" fill="rgb(3, 171, 255)" stroke="rgb(3, 171, 255)" stroke-width="1" font-size="19" text-anchor="middle" alignment-baseline="middle">TON</text></svg>',
        visible: false,
      },
      {
        id: 'timer_tof',
        label: 'TOF Timer',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="8" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="64" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><circle cx="36" cy="36" r="26" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><text x="36" y="39" fill="rgb(3, 171, 255)" stroke="rgb(3, 171, 255)" stroke-width="1" font-size="19" text-anchor="middle" alignment-baseline="middle">TOF</text></svg>',
        visible: false,
      },
      {
        id: 'timer_tonr',
        label: 'TONR Timer',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><line x1="0" y1="36" x2="8" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="64" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><circle cx="36" cy="36" r="26" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><text x="36" y="39" fill="rgb(3, 171, 255)" stroke="rgb(3, 171, 255)" stroke-width="1" font-size="19" text-anchor="middle" alignment-baseline="middle">TONR</text></svg>',
        visible: false,
      },
      {
        id: 'counter_ctu',
        label: 'CTU Counter',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">C T U</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'counter_ctd',
        label: 'CTD Counter',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">C T D</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'counter_ctud',
        label: 'CTUD Counter',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">CTUD</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="25" y1="45" x2="25" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="37" y1="45" x2="37" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="47" y1="45" x2="47" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'comparator_equ',
        label: 'EQU',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">E Q U</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'comparator_neq',
        label: 'NEQ',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">N E Q</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'comparator_grt',
        label: 'GRT',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">G R T</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'comparator_geq',
        label: 'GEQ',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">G E Q</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'comparator_les',
        label: 'LES',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">L E S</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'comparator_leq',
        label: 'LEQ',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">L E Q</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'add',
        label: 'ADD',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="7" width="50" height="51" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><rect x="11" y="7" width="50" height="20" fill="rgb(3, 171, 255)"/><text x="36" y="22" fill="white" font-size="16" font-weight="bold" text-anchor="middle">ADD</text><text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">OUT</text><text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">A</text><text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">B</text><text x="35" y="54" fill="rgb(3, 171, 255)" font-size="14" font-weight="bold" text-anchor="middle">+</text><line x1="0" y1="36" x2="9" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="4"/></svg>',
        visible: false,
      },
      {
        id: 'sub',
        label: 'SUB',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="7" width="50" height="51" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><rect x="11" y="7" width="50" height="20" fill="rgb(3, 171, 255)"/><text x="36" y="22" fill="white" font-size="16" font-weight="bold" text-anchor="middle">SUB</text><text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">OUT</text><text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">A</text><text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">B</text><text x="35" y="54" fill="rgb(3, 171, 255)" font-size="14" font-weight="bold" text-anchor="middle">-</text><line x1="0" y1="36" x2="9" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="4"/></svg>',
        visible: false,
      },
      {
        id: 'mul',
        label: 'MUL',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="7" width="50" height="51" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><rect x="11" y="7" width="50" height="20" fill="rgb(3, 171, 255)"/><text x="36" y="22" fill="white" font-size="16" font-weight="bold" text-anchor="middle">MUL</text><text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">OUT</text><text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">A</text><text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">B</text><text x="35" y="54" fill="rgb(3, 171, 255)" font-size="14" font-weight="bold" text-anchor="middle">x</text><line x1="0" y1="36" x2="9" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="4"/></svg>',
        visible: false,
      },
      {
        id: 'div',
        label: 'DIV',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="7" width="50" height="51" fill="none" stroke="rgb(3, 171, 255)" stroke-width="4"/><rect x="11" y="7" width="50" height="20" fill="rgb(3, 171, 255)"/><text x="36" y="22" fill="white" font-size="16" font-weight="bold" text-anchor="middle">DIV</text><text x="45" y="40" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">OUT</text><text x="20" y="54" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">A</text><text x="50" y="54" fill="rgb(3, 171, 255)" font-size="14" text-anchor="middle">B</text><text x="35" y="54" fill="rgb(3, 171, 255)" font-size="14" font-weight="bold" text-anchor="middle">/</text><line x1="0" y1="36" x2="9" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="20" y1="60" x2="20" y2="64" stroke="rgb(3, 171, 255)" stroke-width="4"/><line x1="50" y1="60" x2="50" y2="64" stroke="rgb(3, 171, 255)" stroke-width="4"/></svg>',
        visible: false,
      },
      {
        id: 'logical_and',
        label: 'AND',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">AND</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'logical_or',
        label: 'OR',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">OR</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="37" y1="45" x2="37" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },
      {
        id: 'logical_not',
        label: 'NOT',
        content:
          '<svg width="64" height="64" viewBox="0 0 72 72"><rect x="11" y="25" width="50" height="20" stroke-width="4" stroke="#000814" fill="none"/><text x="36" y="40" fill="#000814" font-size="16" font-weight="bold" text-anchor="middle">NOT</text><line x1="0" y1="36" x2="9" y2="36" stroke="#000814" stroke-width="4"/><line x1="63" y1="36" x2="72" y2="36" stroke="#000814" stroke-width="4"/><line x1="29" y1="45" x2="29" y2="25" stroke="#000814" stroke-width="0.5"/><line x1="44" y1="45" x2="44" y2="25" stroke="#000814" stroke-width="0.5"/></svg>',
        visible: false,
      },

    ]);

    const draggedTool = ref(null); // To store the currently dragged tool
    const addToolDialog = ref({ visible: false });
    const addRungDialog = ref({ visible: false });


    // Define a counter object for unique identifiers
    const elementCounters = reactive({
      coil: 0,
      r_coil: 0,
      contact_no: 0,
      contact_nc: 0,
      contact_pos: 0,
      contact_neg: 0,
      coil_set: 0,
      coil_reset: 0,
      timer_ton: 0,
      timer_tof: 0,
      timer_tonr: 0,
      counter_ctu: 0,
      counter_ctd: 0,
      counter_ctud: 0,
      comparator_equ: 0,
      comparator_neq: 0,
      comparator_grt: 0,
      comparator_geq: 0,
      comparator_les: 0,
      comparator_leq: 0,
      add: 0,
      sub: 0,
      mul: 0,
      div: 0,
      logical_and: 0,
      logical_or: 0,
      logical_not: 0,
    });

    const history = reactive({
      states: [],
      currentIndex: -1,
    });

    function addToolToRung(toolType) {
  if (isSimulating.value) {
    addToolDialog.value.visible = true; // Show dialog
    return;
  }

  const tool = tools.find(t => t.id === toolType);
  if (!tool) {
    console.error('Tool not found:', toolType);
    return;
  }
  const newId = generateId(toolType);

  // Ensure a rung is selected
  if (selectedRung.value === null || selectedRung.value === undefined) {
    selectedRung.value = 0; // Default to the first rung
  }

  const rung = rungs.value[selectedRung.value];
  if (!rung) {
    console.error('Invalid rung index:', selectedRung.value);
    return;
  }

  let newPosition = 0;
  if (selectedSegment.value !== null) {
    const segment = rung.segments.find(seg => seg.id === selectedSegment.value);
    if (segment) {
      // Replace the selected segment with a new element
      replaceSegmentWithElement(rung, segment, toolType, newId);
      selectedSegment.value = null; // Reset after adding
      showPlusButton.value = false; // Hide the plus button
      showExtendBranchButton.value = false;
    }
  } else {
    // Calculate the first available position that does not overlap any existing elements
    const nonOverlappingSegment = rung.segments.find(seg => !isSegmentOverlappingTool(seg));
    if (nonOverlappingSegment) {
      newPosition = nonOverlappingSegment.x1;
      replaceSegmentWithElement(rung, nonOverlappingSegment, toolType, newId);
    } else if (rung.elements.length > 0) {
      const lastElement = rung.elements[rung.elements.length - 1];
      if (lastElement.type !== 'branch_horizontal') { // Ignore horizontal branches
        newPosition = lastElement.position + 72; // Assuming each element is 72 units wide
      }
      rung.elements.push({
        id: newId,
        position: newPosition,
        type: toolType,
        state: toolType === 'contact_no' ? 0 : 
               (toolType === 'contact_nc' ? 1 : 
               (toolType === 'contact_pos' ? 0 : 
               (toolType === 'contact_neg' ? 1 : 
               (toolType === 'coil' ? 0 : 
               (toolType === 'coil_set' ? 0 : 
               (toolType === 'coil_reset' ? 0 : 0)))))),
       hasToggled: toolType === 'contact_pos' || toolType === 'contact_neg' ? false : undefined, // Initialize hasToggled for POS/NEG contacts
        presetTime: toolType.startsWith('timer') ? 5 : undefined,
        elapsedTime: toolType.startsWith('timer') ? 0 : undefined,
        linkedId: toolType.startsWith('timer') ? null : undefined,
        presetValue: toolType.startsWith('counter') ? 5 : undefined, // Set the preset value for counters to 5
        previousState: toolType.startsWith('counter') ? 0 : undefined, // Initialize previousState for counters
        currentValue: toolType.startsWith('counter') ? (toolType === 'counter_ctd' ? tool.presetValue : 0) : undefined // Initialize currentValue for counters
      });
    } else {
      // If no elements exist, add the element at the beginning
      rung.elements.push({
        id: newId,
        position: newPosition, // Will be 0 if it's the first element
        type: toolType,
        state: toolType === 'contact_no' ? 0 : 
               (toolType === 'contact_nc' ? 1 : 
               (toolType === 'contact_pos' ? 0 : 
               (toolType === 'contact_neg' ? 1 : 
               (toolType === 'coil' ? 0 : 
               (toolType === 'coil_set' ? 0 : 
               (toolType === 'coil_reset' ? 0 : 0)))))),
               hasToggled: toolType === 'contact_pos' || toolType === 'contact_neg' ? false : undefined, // Initialize hasToggled for POS/NEG contacts

        presetTime: toolType.startsWith('timer') ? 5 : undefined,
        elapsedTime: toolType.startsWith('timer') ? 0 : undefined,
        linkedId: toolType.startsWith('timer') ? null : undefined,
        presetValue: toolType.startsWith('counter') ? 5 : undefined, // Set the preset value for counters to 5
        previousState: toolType.startsWith('counter') ? 0 : undefined, // Initialize previousState for counters
        currentValue: toolType.startsWith('counter') ? (toolType === 'counter_ctd' ? tool.presetValue : 0) : undefined // Initialize currentValue for counters
      });
    }
  }

  // Add variable entry
  const variableEntry = createVariableEntry(newId);
  variables.value.push(variableEntry);

  updateSegments();

  if (toolType === 'timer_tonr') {
    addNewRungWithResetCoil(newId);
  } else if (toolType === 'coil_set') {
    addNewRungWithCoilReset(newId);
  } else if (toolType === 'coil_reset') {
    addNewRungWithCoilSet(newId);
  } else if (toolType === 'counter_ctu' || toolType === 'counter_ctd') {
    addNewRungWithCounterReset(newId);
  } else {
    // Push the new state to history for other tools
    pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) });
  }
}


function addNewRungWithCounterReset(counterId) {
  const newRungId = rungs.value.length + 1;
  const newCoilId = generateId('r_coil');

  // Add new rung
  rungs.value.push({ id: newRungId, elements: [], segments: [] });

  // Find the new rung and the last selectable segment
  const newRung = rungs.value[rungs.value.length - 1];
  updateSegments(); // Update segments for all rungs
  const lastSegmentIndex = newRung.segments.length - 2; // Second last segment
  const lastSegment = newRung.segments[lastSegmentIndex];

  
  // Replace the segment with the r_coil element
  replaceSegmentWithElement(newRung, lastSegment, 'r_coil', newCoilId);

  // Add the linkedId to the r_coil element
  const rCoilElement = newRung.elements.find(el => el.id === newCoilId);
  if (rCoilElement) {
    rCoilElement.linkedId = counterId; // Ensure the linkedId is assigned correctly
  }

  // Link the reset coil to the counter
  const counterElement = findElementById(counterId);
  if (counterElement) {
    counterElement.linkedId = newCoilId;
  }

  // Add variable entry
  const variableEntry = createVariableEntry(newCoilId);
  variables.value.push(variableEntry);

  updateSegments();

  // Push the new state to history
  pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) });
}



function addNewRungWithResetCoil(timerId) {
  const newRungId = rungs.value.length + 1;
  const newCoilId = generateId('r_coil');

  // Add new rung
  rungs.value.push({ id: newRungId, elements: [], segments: [] });

  // Find the new rung and the last selectable segment
  const newRung = rungs.value[rungs.value.length - 1];
  updateSegments(); // Update segments for all rungs
  const lastSegmentIndex = newRung.segments.length - 2; // Second last segment
  const lastSegment = newRung.segments[lastSegmentIndex];


  // Replace the segment with the r_coil element
  replaceSegmentWithElement(newRung, lastSegment, 'r_coil', newCoilId);

  // Add the linkedId to the r_coil element
  const rCoilElement = newRung.elements.find(el => el.id === newCoilId);
  if (rCoilElement) {
    rCoilElement.linkedId = timerId; // Ensure the linkedId is assigned correctly
  }

  // Link the reset coil to the TONR timer
  const timerElement = findElementById(timerId);
  if (timerElement) {
    timerElement.linkedId = newCoilId;
  }

  // Add variable entry
  const variableEntry = createVariableEntry(newCoilId);
  variables.value.push(variableEntry);

  updateSegments();

  // Push the new state to history
  pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) });
}


function addNewRungWithCoilReset(linkedId) {
  const newRungId = rungs.value.length + 1;
  const newId = generateId('coil_reset');

  // Add new rung
  rungs.value.push({ id: newRungId, elements: [], segments: [] });

  // Find the new rung and the last selectable segment
  const newRung = rungs.value[rungs.value.length - 1];
  updateSegments(); // Update segments for all rungs
  const lastSegmentIndex = newRung.segments.length - 2; // Second last segment
  const lastSegment = newRung.segments[lastSegmentIndex];



  // Replace the segment with the coil_reset element
  replaceSegmentWithElement(newRung, lastSegment, 'coil_reset', newId);

  // Add the linkedId to the coil_reset element
  const coilResetElement = newRung.elements.find(el => el.id === newId);
  if (coilResetElement) {
    coilResetElement.linkedId = linkedId; // Ensure the linkedId is assigned correctly
  }

  // Link the coil_set and coil_reset
  const coilSetElement = findElementById(linkedId);
  if (coilSetElement) {
    coilSetElement.linkedId = newId; // Mutual linking
  }

  // Add variable entry
  const variableEntry = createVariableEntry(newId);
  variables.value.push(variableEntry);

  updateSegments();

  // Push the new state to history
  pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) });
}




function addNewRungWithCoilSet(linkedId) {
  const newRungId = rungs.value.length + 1;
  const newId = generateId('coil_set');

  // Add new rung
  rungs.value.push({ id: newRungId, elements: [], segments: [] });

  // Find the new rung and the last selectable segment
  const newRung = rungs.value[rungs.value.length - 1];
  updateSegments(); // Update segments for all rungs
  const lastSegmentIndex = newRung.segments.length - 2; // Second last segment
  const lastSegment = newRung.segments[lastSegmentIndex];


  // Replace the segment with the coil_set element
  replaceSegmentWithElement(newRung, lastSegment, 'coil_set', newId);

  // Add the linkedId to the coil_set element
  const coilSetElement = newRung.elements.find(el => el.id === newId);
  if (coilSetElement) {
    coilSetElement.linkedId = linkedId; // Ensure the linkedId is assigned correctly
  }

  // Link the coil_set and coil_reset
  const coilResetElement = findElementById(linkedId);
  if (coilResetElement) {
    coilResetElement.linkedId = newId; // Mutual linking
  }

  // Add variable entry
  const variableEntry = createVariableEntry(newId);
  variables.value.push(variableEntry);

  updateSegments();

  // Push the new state to history
  pushState({ rungs: JSON.parse(JSON.stringify(rungs.value)), variables: JSON.parse(JSON.stringify(variables.value)) });
}









function findElementById(id) {
  for (const rung of rungs.value) {
    const element = rung.elements.find(el => el.id === id);
    if (element) {
      return element;
    }
  }
  return null;
}

function replaceSegmentWithElement(rung, segment, toolType, newId) {
  // console.log(`Replacing segment with element. Segment ID: ${segment.id}, x1: ${segment.x1}, x2: ${segment.x2}, y1: ${segment.y1}`);

  // Log elements before filtering
  // console.log('Elements before filtering:', JSON.parse(JSON.stringify(rung.elements)));

  // Find the level of the branch (if applicable) based on the y1 position of the segment
  let branchLevel = segment.y1 !== 80 ? (segment.y1 - 78) / 70 : 0;

  // console.log(`Branch level determined: ${branchLevel}`);

  // Remove any elements currently in the segment (if any), except branch elements
  rung.elements = rung.elements.filter(el => 
    (el.position !== segment.x1 || el.y1 !== segment.y1) || 
    (el.type === 'branch_vertical') ||
    (el.type === 'branch_horizontal' && el.y1 !== segment.y1)  // Keep horizontal branches not at the same y position
  );

  // Log elements after filtering
  // console.log('RSE-Elements after filtering:', JSON.parse(JSON.stringify(rung.elements)));

  // Add the new element exactly at the start of the segment
  rung.elements.push({
    id: newId,
    position: segment.x1,
    type: toolType,
    y1: segment.y1, // Use the y1 from the segment being replaced
    y2: segment.y2, // Use the y2 from the segment being replaced
    state: toolType === 'contact_no' ? 0 :
           (toolType === 'contact_nc' ? 1 : 
           (toolType === 'contact_pos' ? 0 : 
           (toolType === 'contact_neg' ? 1 : 
           (toolType === 'coil' ? 0 : 0)))), // Set initial state for NO contact to 0, NC contact to 1, POS contact to 0, NEG contact to 1, coil to 0
           hasToggled: toolType === 'contact_pos' || toolType === 'contact_neg' ? false : undefined, // Initialize hasToggled for POS/NEG contacts

           presetTime: toolType.startsWith('timer') ? 5 : undefined,
        elapsedTime: toolType.startsWith('timer') ? 0 : undefined,
        presetValue: toolType.startsWith('counter') ? 5 : undefined, // Set the preset value for counters to 5
    level: branchLevel // Assign the branch level to the new element
  });

  // console.log(`New element added with level: ${branchLevel}`);

  // Recalculate segments after adding the new element
  updateSegments();
}




    function generateId(type) {
      const prefixes = {
        coil: 'C',
        r_coil: 'R_C',
        contact_no: 'NO',
        contact_nc: 'NC',
        contact_pos: 'P',
        contact_neg: 'N',
        coil_set: 'SC',
        coil_reset: 'RC',
        timer_ton: 'TON',
        timer_tof: 'TOF',
        timer_tonr: 'TONR',
        counter_ctu: 'CTU',
        counter_ctd: 'CTD',
        counter_ctud: 'CTUD',
        comparator_equ: 'EQU',
        comparator_neq: 'NEQ',
        comparator_grt: 'GRT',
        comparator_geq: 'GEQ',
        comparator_les: 'LES',
        comparator_leq: 'LEQ',
        add: 'ADD',
        sub: 'SUB',
        mul: 'MUL',
        div: 'DIV',
        logical_and: 'AND',
        logical_or: 'OR',
        logical_not: 'NOT',
      };

      // Increment the counter for this type
      elementCounters[type] += 1;

      // Generate the ID based on the type and current counter value
      return `${prefixes[type]}${elementCounters[type]}`;
    }

    function getPositionX(element) {
      return element.position !== undefined ? element.position : 0;
    }

    function findRungIndexForElement(elementId) {
  for (let i = 0; i < rungs.value.length; i++) {
    if (rungs.value[i].elements.some(el => el.id === elementId)) {
      return i; // Return the rungIndex where the element is found
    }
  }
  return -1; // Return -1 if the element is not found
}


function deleteElement(elementId, rungIndex) {
  // Initialize a static Set to track deleted elements only on the first call
  if (!deleteElement.deletedElements) {
    deleteElement.deletedElements = new Set();
  }

  // Check if the element has already been deleted in this operation to prevent infinite recursion
  if (deleteElement.deletedElements.has(elementId)) {
    return;
  }

  const rung = rungs.value[rungIndex];
  const element = rung.elements.find(el => el.id === elementId);

  if (element) {
    // console.log(`Deleting element with ID: ${elementId}`);
    // console.log(`Element details: ${JSON.stringify(element)}`);

    // Add the element to the deleted set to prevent further deletion in this operation
    deleteElement.deletedElements.add(elementId);

    // Special case: If the element is a timer_tonr, coil_set, or counter, delete its related coil or linked element
    if (['timer_tonr', 'counter_ctu', 'counter_ctd', 'coil_set'].includes(element.type)) {
      const linkedElement = findElementById(element.linkedId);
      // console.log(`RRR- linkedElement ${JSON.stringify(linkedElement)}`);

      if (linkedElement) {
        const linkedRungIndex = findRungIndexForElement(linkedElement.id);
        if (linkedRungIndex !== -1) {
          // console.log(`RRR- Deleting linked element with ID: ${linkedElement.id} related to ${element.type} at rungIndex ${linkedRungIndex}`);
          deleteElement(linkedElement.id, linkedRungIndex); // Recursively delete the linked element with correct rungIndex
        } else {
          console.error(`RRR- Linked element not found in any rung: ${linkedElement.id}`);
        }
      }
    }

    // Special case: If the element is a linked reset coil (r_coil or coil_reset), delete its associated timer, counter, or coil_set
    if (['r_coil', 'coil_reset'].includes(element.type)) {
      const associatedElement = rungs.value
        .flatMap(rung => rung.elements)
        .find(el => el.linkedId === element.id);
      // console.log(`RRR- associatedElement ${JSON.stringify(associatedElement)}`);

      if (associatedElement) {
        const associatedRungIndex = findRungIndexForElement(associatedElement.id);
        if (associatedRungIndex !== -1) {
          // console.log(`RRR- Deleting associated element with ID: ${associatedElement.id} for reset coil at rungIndex ${associatedRungIndex}`);
          deleteElement(associatedElement.id, associatedRungIndex); // Recursively delete the associated element with correct rungIndex
        } else {
          console.error(`RRR- Associated element not found in any rung: ${associatedElement.id}`);
        }
      }
    }

    // If the deleted element is a contact, remove it from any linked elements
    if (['contact_no', 'contact_nc', 'contact_pos', 'contact_neg'].includes(element.type)) {
      rungs.value.forEach(rung => {
        rung.elements.forEach(el => {
          if (el.linkedContacts && el.linkedContacts.includes(elementId)) {
            el.linkedContacts = el.linkedContacts.filter(linkedId => linkedId !== elementId);
            // console.log(`Removed linked contact ${elementId} from element ${el.id}`);
          }
        });
      });
    }

    // Regular element deletion logic
    rung.elements = rung.elements.filter(el => el.id !== elementId);

    // Check if the deleted element was part of a branch
    const branchPosition = element.position;
    const branchLevel = element.level; // Get the branch level of the deleted element

    // console.log(`Branch position: ${branchPosition}, Branch level: ${branchLevel}`);

    // Adjust the condition to handle branch vertical elements in level 1 correctly
    if (branchLevel !== undefined && (element.y1 !== 80 || element.type === 'branch_vertical')) { 
      // console.log('Element is part of a branch.');

      // Find the branch-vertical-start to the left of the deleted element
      const branchStartElement = rung.elements
        .filter(el => el.type === 'branch_vertical' && el.id.includes('start') && el.level === branchLevel)
        .reverse()
        .find(el => el.position <= branchPosition);

      // Find the branch-vertical-end to the right of the deleted element
      const branchEndElement = rung.elements.find(el => 
        el.type === 'branch_vertical' && el.id.includes('end') && el.position > branchPosition && el.level === branchLevel
      );

      if (!branchStartElement || !branchEndElement) {
        console.error('Branch start or end element not found.');
        // console.log(`Branch start element: ${JSON.stringify(branchStartElement)}`);
        // console.log(`Branch end element: ${JSON.stringify(branchEndElement)}`);
        return;
      }

      // console.log(`Branch start element: ${JSON.stringify(branchStartElement)}`);
      // console.log(`Branch end element: ${JSON.stringify(branchEndElement)}`);

      const startPosition = branchStartElement.position;
      const endPosition = branchEndElement.position;

      // Replace the deleted element with the appropriate branch segments
      for (let x = startPosition; x < endPosition; x += 72) {
        // Check if there's already an element at this position
        const existingElement = rung.elements.find(el => el.position === x && el.y1 === (78 + branchLevel * 70));

        if (!existingElement) {
          // Add the horizontal segment if there's no element in the branch
          rung.elements.push({
            id: `branch-horizontal-${x}-${78 + branchLevel * 70}`,
            type: 'branch_horizontal',
            position: x,
            y1: 78 + branchLevel * 70, // Adjust y1 based on level
            y2: 78 + branchLevel * 70, // Same y for both points in a horizontal line
            state: 1,
            level: branchLevel
          });
        }
      }

      // console.log(`Recreated branch elements between positions ${startPosition} and ${endPosition} at level ${branchLevel}`);
    }

    // Handle the case where the element is part of a regular rung (non-branch)
    if (branchLevel === undefined) {
      const segmentPosition = element.position;
      // console.log(`Deleted a regular element at position ${segmentPosition}. Replacing with a regular rung segment.`);

      // Replace the deleted element with a regular rung segment if needed
      rung.elements.push({
        id: `rung-segment-${segmentPosition}`,
        type: 'rung_segment',
        position: segmentPosition,
        y1: 80, // Regular rung height
        y2: 80, // Same y for both points in a rung
        state: 1
      });
    }

    updateSegments();

    // Push the new state to history
    pushState({
      rungs: JSON.parse(JSON.stringify(rungs.value)),
      variables: JSON.parse(JSON.stringify(variables.value)),
    });

    // Hide the plus button after deletion
    showPlusButton.value = false;
    showExtendBranchButton.value = false;

    // Delete the corresponding variable entry
    deleteVariable(elementId); // Call the deleteVariable function here

    // console.log('Element deleted:', elementId);
  } else {
    // console.log('No element found for deletion:', elementId);
  }

  // Clear the deletedElements set after the operation completes
  if (deleteElement.deletedElements.size > 0) {
    deleteElement.deletedElements.clear();
  }
}














const showDeleteBranchButton = ref(false);

function selectSegment(index, rungIndex) {
  if (isSimulating.value) {
    showPlusButton.value = false;
    showExtendBranchButton.value = false;
    showDeleteBranchButton.value = false;
    showWarningButton.value = false;
    return;
  }

  const rung = computedSegments.value[rungIndex];
  const segment = rung?.segments?.find((seg) => seg.id === index);

  let asymmetricalBranchAppeared = false;
  // console.log('Segment part selected:', JSON.parse(JSON.stringify(segment)));


  // Check if the segment exists and is not the last segment
if (segment && !segment.isLast) {
  if (selectedSegment.value === index && selectedRung.value === rungIndex) {
    selectedSegment.value = null;
    selectedRung.value = null;
    selectedElement.value = null;
    showPlusButton.value = false;
    showExtendBranchButton.value = false;
    showDeleteBranchButton.value = false;
    showWarningButton.value = false;
  } else {
    selectedSegment.value = index;
    selectedRung.value = rungIndex;
    selectedElement.value = null;

    // Handle regular rung segments (not horizontal branches)
    if (segment.type === 'segment') {

      // Check for branch vertical start at x2 position and branch vertical end at x1 position
      const isBranchVerticalStartAtX2 = rung.elements.some(el => 
        el.type === 'branch_vertical' && 
        el.id.includes('start') && 
        el.position === segment.x2
      );

      const isBranchVerticalEndAtX1 = rung.elements.some(el => 
        el.type === 'branch_vertical' && 
        el.id.includes('end') && 
        el.position === segment.x1
      );

      const isBranchBelow = rung.elements.some(el =>
        el.type === 'branch_horizontal' &&
        el.position >= segment.x1 &&
        el.position < segment.x2
      );

      // Only show plus button if there's no branch vertical start at x2 or branch vertical end at x1
      if (!isBranchBelow && !isBranchVerticalStartAtX2 && !isBranchVerticalEndAtX1) {
        plusButtonPosition.value = {
          top: 80 - 22,
          left: segment.x1 + 15 + (segment.x2 - segment.x1) / 2
        };
        showPlusButton.value = true;
      } else {
        showPlusButton.value = false;
      }

      currentElement.value = null;
      currentRungIndex.value = null;
      showExtendBranchButton.value = false;
      showDeleteBranchButton.value = false;
      // console.log(`Regular segment selected at position x1: ${segment.x1}`);
    }
// Handle horizontal branch segments
// Handle horizontal branch segments
else if (segment.type === 'branch_horizontal') {
  const branchSections = getBranchSections(rung);

  // Find reference information for level 1 (mandatory)
  const level1Section = branchSections.find(section => section.startLevel === 1);
  if (!level1Section) {
    console.error('Error: No Level 1 branch section found for reference.');
    return;
  }

  // Get reference start and end from level 1
  const referenceStart = level1Section.start;
  const referenceEnd = level1Section.end || level1Section.start + 72;
  const referenceBranchLength = referenceEnd - referenceStart;

  // console.log(`Reference branch length for level 1: ${referenceBranchLength}`);

  // Loop through each branch section
  branchSections.forEach(currentSection => {
    const horizontalBranch = currentSection.elements.find(el =>
      el.type === 'branch_horizontal' && el.position === segment.x1 && el.level === segment.level
    );

    if (horizontalBranch) {
      const highestLevelBranch = Math.max(
        ...currentSection.elements
          .filter(el => el.type === 'branch_horizontal')
          .map(el => el.level)
      );

      // console.log(`Highest branch level in current section: ${highestLevelBranch}`);

      const isHighestLevelBranch = horizontalBranch.level === highestLevelBranch;

      // console.log(`Selected horizontal branch level: ${horizontalBranch.level}`);

      const branchVerticalEnd = currentSection.elements.find(el =>
        el.type === 'branch_vertical' &&
        el.id.includes('end') &&
        el.position > horizontalBranch.position &&
        el.level === horizontalBranch.level
      );

      const shouldShowExtendBranchButton = branchVerticalEnd && horizontalBranch.position === branchVerticalEnd.position - 72;

      if (shouldShowExtendBranchButton && isHighestLevelBranch) {
        plusButtonPosition.value = {
          top: horizontalBranch.y1 - 22,
          left: horizontalBranch.position + 51
        };
        extendBranchButtonPosition.value = {
          top: horizontalBranch.y1 - 30,
          left: horizontalBranch.position + 100
        };
        showExtendBranchButton.value = true;

        if (isHighestLevelBranch) {
          deleteButtonPosition.top = horizontalBranch.y1 - 50;
          deleteButtonPosition.left = horizontalBranch.position + 65;

          currentElement.value = {
            id: horizontalBranch.id,
            position: horizontalBranch.position,
            type: horizontalBranch.type,
            level: horizontalBranch.level
          };
          currentRungIndex.value = rungIndex;
        }

        showDeleteBranchButton.value = isHighestLevelBranch;
      } else {
        plusButtonPosition.value = {
          top: horizontalBranch.y1 - 22,
          left: segment.x1 + 15 + (segment.x2 - segment.x1) / 2
        };

        currentElement.value = null;
        currentRungIndex.value = null;
        showExtendBranchButton.value = false;
        showDeleteBranchButton.value = false;
      }

      showPlusButton.value = isHighestLevelBranch;

      // Perform asymmetry detection for the current branch section
      asymmetricalBranchAppeared = detectAsymmetryInBranchSections(currentSection, referenceBranchLength, asymmetricalBranchAppeared);
    }
  });

  // Show warning button if an asymmetrical branch is detected
  showWarningButton.value = asymmetricalBranchAppeared && showPlusButton.value;
  warningButtonPosition.value = { ...plusButtonPosition.value };
}


    }
  }
}


function detectAsymmetryInBranchSections(branchSection, referenceBranchLength, asymmetricalBranchAppeared) {
  const elementsByLevel = {};

  // Group elements by their level for easier processing
  branchSection.elements.forEach(el => {
    if (!elementsByLevel[el.level]) {
      elementsByLevel[el.level] = [];
    }
    elementsByLevel[el.level].push(el);
  });

  // console.log("Elements grouped by level in detectAsymmetryInBranchSections:", JSON.stringify(elementsByLevel, null, 2));

  // Evaluate levels 2 and higher against the reference length from level 1
  Object.keys(elementsByLevel).forEach(level => {
    if (Number(level) >= 2) {
      // console.log(`Processing level ${level} for asymmetry check...`);

      let levelStart = Infinity;
      let levelEnd = -Infinity;

      elementsByLevel[level].forEach(el => {
        if (el.type === 'branch_vertical' || el.type === 'branch_horizontal') {
          levelStart = Math.min(levelStart, el.position);
          levelEnd = Math.max(levelEnd, el.position + (el.type === 'branch_horizontal' ? 72 : 0));
        }
      });

      const currentLength = levelEnd - levelStart;
      // console.log(`Asymmetry check at level ${level}: current length = ${currentLength}, reference length = ${referenceBranchLength}`);

      // Compare the current level length with the reference length from level 1
      if (currentLength < referenceBranchLength) {
        asymmetricalBranchAppeared = true;
        // console.log(`Asymmetrical branch detected at level ${level}: current length = ${currentLength}, reference length = ${referenceBranchLength}`);
      }
    }
  });

  return asymmetricalBranchAppeared;
}

function getBranchSections(rung) {
  const elementsByLevel = {};
  const branchSections = [];

  // Step 1: Group elements by level
  rung.elements.forEach(el => {
    if (!elementsByLevel[el.level]) {
      elementsByLevel[el.level] = [];
    }
    elementsByLevel[el.level].push(el);
  });

  // console.log("Grouped elements by level:", JSON.stringify(elementsByLevel, null, 2));

  // Step 2: Process each level independently to build branch sections
  Object.keys(elementsByLevel).forEach(level => {
    const elements = elementsByLevel[level];
    let currentBranch = null;

    // Step 3: For each element within the current level
    elements.forEach(el => {
      // console.log(`Processing element in level ${level}: ${el.id}, type: ${el.type}, position: ${el.position}`);

      // Start a new branch when we find a vertical start
      if (el.type === 'branch_vertical' && el.id.includes('start')) {
        currentBranch = {
          start: el.position,
          startLevel: el.level,
          end: null,
          endLevel: null,
          elements: [] // Store all elements within this branch section
        };
        branchSections.push(currentBranch);
        // console.log(`New branch section started at position ${el.position}, level ${el.level}`);
      }

      // Add elements to the current branch section
      if (currentBranch) {
        currentBranch.elements.push(el);

        // Update end position for horizontal elements
        if (el.type === 'branch_horizontal') {
          currentBranch.end = Math.max(currentBranch.end || 0, el.position + 72); // Assume 72 as default length
          currentBranch.endLevel = el.level;
          // console.log(`Updated branch end to ${currentBranch.end} at level ${el.level}`);
        }

        // Close the branch section when we find a vertical end
        if (el.type === 'branch_vertical' && el.id.includes('end')) {
          currentBranch.end = el.position; // Set the branch end position
          currentBranch.endLevel = el.level;
          // console.log(`Branch section ended at position ${el.position}, level ${el.level}`);
          currentBranch = null; // Close the current branch section
        }
      }
    });

    // If current branch didn't find an end, extend its length based on the highest element in the level
    if (currentBranch && !currentBranch.end) {
      const nextBranchEnd = rung.elements.find(
        el => el.type === 'branch_vertical' && el.id.includes('end') && el.position > currentBranch.start
      );
      if (nextBranchEnd) {
        currentBranch.end = nextBranchEnd.position;
        currentBranch.endLevel = nextBranchEnd.level;
        // console.log(`Branch section extended to end at position ${nextBranchEnd.position}, level ${nextBranchEnd.level}`);
      }
    }
  });

  // console.log("Branch Sections after processing:", JSON.stringify(branchSections, null, 2));
  return branchSections;
}










function selectElement(element, rungIndex) {
  if (isSimulating.value) {
    showPlusButton.value = false;
    showExtendBranchButton.value = false;
    showWarningButton.value = false;
    return;
  }

  if (element.type.startsWith('branch_vertical')) {
    // Do not allow selection of individual branch elements
    return;
  }

  // console.log('Selecting element:', element.id);

  let asymmetricalBranchAppeared = false;

  // Check if the selected element is the same as the currently selected one
  if (selectedElement.value === element.id && selectedRung.value === rungIndex) {
    selectedElement.value = null;
    selectedRung.value = null;
    showPlusButton.value = false;
    showExtendBranchButton.value = false;
    showWarningButton.value = false;
    // console.log('Element deselected');
  } else {
    selectedElement.value = element.id;
    selectedRung.value = rungIndex;
    selectedSegment.value = null;

    const rung = computedSegments.value[rungIndex];

    // Handle elements on the main rung (y=80 or level=0)
    if (element.y1 === 80 || element.level === 0) {
      plusButtonPosition.value = {
        top: element.y1 - 5, // Position the button just above the element
        left: element.position + 51  // Center the button horizontally
      };
      showPlusButton.value = true;
      showExtendBranchButton.value = false;
      showWarningButton.value = false;
      // console.log('Main rung element selected');
      return; // Exit for main rung elements
    }

    // Get the branch sections based on the current rung
    const branchSections = getBranchSections(rung);

    // Get reference start and end for level 1 (like in selectSegment)
    const level1Section = branchSections.find(section => section.startLevel === 1);
    if (!level1Section) {
      console.error('Error: No Level 1 branch section found for reference.');
      return;
    }
    
    const referenceStart = level1Section.start;
    const referenceEnd = level1Section.end || level1Section.start + 72;
    const referenceBranchLength = referenceEnd - referenceStart;
    // console.log(`Reference branch length for level 1: ${referenceBranchLength}`);

    // Loop through each branch section for the element
    branchSections.forEach(currentSection => {
      if (currentSection.start <= element.position && currentSection.end >= element.position) {
        // Determine the highest level within the current branch
        const highestLevelInSameBranch = Math.max(
          element.level,
          ...currentSection.elements
            .filter(el => el.position >= currentSection.start && el.position <= currentSection.end)
            .map(el => el.level)
        );
        // console.log('Highest level in same branch:', highestLevelInSameBranch);

        const isHighestLevelBranch = element.level === highestLevelInSameBranch;

        // Perform asymmetry detection for this branch section
        asymmetricalBranchAppeared = detectAsymmetryInBranchSections(currentSection, referenceBranchLength, asymmetricalBranchAppeared);

        const branchStartElement = rung.elements
          .filter(el => el.type === 'branch_vertical' && el.id.includes('start'))
          .reverse()
          .find(el => el.position <= element.position && el.level <= element.level);

        const branchVerticalEnd = rung.elements.find(el => 
          el.type === 'branch_vertical' && 
          el.id.includes('end') && 
          el.position > branchStartElement?.position &&
          el.level === branchStartElement?.level
        );

        const shouldShowExtendBranchButton = branchVerticalEnd && element.position === branchVerticalEnd.position - 72;
        // console.log('Element in branch, evaluating extension:', shouldShowExtendBranchButton);

        if (shouldShowExtendBranchButton && isHighestLevelBranch) {
          plusButtonPosition.value = {
            top: element.y1 - 5, // Position the button just above the element
            left: element.position + 51  // Center the button horizontally
          };
          extendBranchButtonPosition.value = {
            top: element.y1 - 30, // Position the button at the same y as the element
            left: element.position + 100  // Position the button to the right of the rung
          };
          showExtendBranchButton.value = true;

          // Ensure currentElement carries the level for the extendBranch function
          currentElement.value = { 
            id: element.id, 
            position: element.position, 
            type: element.type, 
            level: element.level 
          };
          currentRungIndex.value = rungIndex;

        } else {
          // For lower elements, show the plus button and hide the extend button
          plusButtonPosition.value = {
            top: element.y1 - 5, // Position the button just above the element
            left: element.position + 51  // Center the button horizontally
          };
          showExtendBranchButton.value = false;
          showPlusButton.value = true;
        }

        // Determine if the plus button should be shown
        const isBranchBelow = rung.elements.some(el => 
          el.type === 'branch_horizontal' && 
          el.position === element.position &&
          el.level > element.level
        );

        showPlusButton.value = isHighestLevelBranch && !isBranchBelow;
        showDeleteBranchButton.value = isHighestLevelBranch && shouldShowExtendBranchButton;

        // Show warning button if an asymmetrical branch is detected
        showWarningButton.value = asymmetricalBranchAppeared && showPlusButton.value;
        warningButtonPosition.value = { ...plusButtonPosition.value };

        // console.log('Asymmetrical branch detected:', asymmetricalBranchAppeared);
      }
    });

    // console.log('Selected element:', selectedElement.value);
    // console.log('Show Plus Button:', showPlusButton.value);
    // console.log('Show Extend Branch Button:', showExtendBranchButton.value);
    // console.log('Show Warning Button:', showWarningButton.value);
  }
}
















    function startDrag(toolType) {
      draggedTool.value = toolType;
    }

    function handleDrop(event) {
      event.preventDefault();
      if (draggedTool.value) {
        addToolToRung(draggedTool.value);
        draggedTool.value = null;
      }
    }

    function dropOnSegment(segment, rungIndex) {
      if (draggedTool.value) {
        selectedSegment.value = segment.id;
        selectedRung.value = rungIndex;
        addToolToRung(draggedTool.value);
        draggedTool.value = null;
      }
    }

    function updateSegments() {
  rungs.value.forEach((rung) => { // rungs.value.forEach((rung, rungIndex) => {
    rung.segments = getLineSegments(rung.elements || []).map(segment => ({
      ...segment,
      state: 1, // Reset state to 1
    }));

    // console.log(`Rung ${rungIndex} Segments:`);
    //rung.segments.forEach(segment => {
      // console.log(`US-Segment ${segment.id} - Position: (${segment.x1}, ${segment.x2}) - State: ${segment.state}`);
    //});

    //rung.elements.forEach(element => {
      // console.log(`US-Element ${element.id} - Position: ${element.position} - State: ${element.state}`);
    //});
  });

    // Ensure to re-calculate any additional logic after updating segments if needed
    calculateEnergizedStates();
}








    //+++++++++++++here is script related to tools functionality ++++++++++++++

    
    const timers = reactive({
      timer_ton: [],
      timer_tof: [],
    });
    const counters = reactive({
      counter_ctu: [],
      counter_ctd: [],
      counter_ctud: []
    });


    const availableContacts = computed(() => { 
      const currentElement = selectedCoil.value || timerElement.value || counterElement.value; // Reference the currently selected element (coil, timer, or counter)

  // Get all linked contacts from coils, timers, and counters except for the current element
  const linkedContacts = rungs.value.flatMap(rung =>
    rung.elements
      .filter(el => (el.type === 'coil' || el.type.includes('coil_set') || el.type.includes('timer') || el.type.includes('counter')) && el.linkedContacts && el !== currentElement) // Exclude the currently configured element
      .flatMap(el => el.linkedContacts)
  );

  // Filter contacts that are not already linked to a coil, timer, or counter, or are linked to the current element
  return rungs.value.flatMap(rung =>
    rung.elements.filter(el =>
      ['contact_no', 'contact_nc', 'contact_pos', 'contact_neg'].includes(el.type) &&
      (!linkedContacts.includes(el.id) || (currentElement && currentElement.linkedContacts && currentElement.linkedContacts.includes(el.id))) // Allow linked contacts from the current element
    )
  );
});




    const showTimerDialog = ref(false);
    const dialogPresetTime = ref(0);
    const timerElement = ref(null);
    const showCounterDialog = ref(false);
    const dialogPresetValue = ref(0);
    const dialogLinkedContact = ref(null);
    const counterElement = ref(null);


function initializeTimers() {
  // Initialize timers at the beginning of the simulation
  timers.timer_ton = rungs.value.flatMap(rung => rung.elements.filter(el => el.type === 'timer_ton'));
  timers.timer_tof = rungs.value.flatMap(rung => rung.elements.filter(el => el.type === 'timer_tof'));
  timers.timer_tonr = rungs.value.flatMap(rung => rung.elements.filter(el => el.type === 'timer_tonr'));
}
function initializeCounters() {
  counters.counter_ctu = rungs.value.flatMap(rung =>
    rung.elements
      .filter(el => el.type === 'counter_ctu')
      .map(el => ({
        ...el,
        previousState: el.state,
        currentValue: el.currentValue || 0,
        presetValue: Number(el.presetValue), // Ensure presetValue is a number
        reachedPreset: false // Initialize reachedPreset
      }))
  );
  counters.counter_ctd = rungs.value.flatMap(rung =>
    rung.elements
      .filter(el => el.type === 'counter_ctd')
      .map(el => ({
        ...el,
        previousState: el.state,
        currentValue: el.currentValue || el.presetValue || 0,
        presetValue: Number(el.presetValue), // Ensure presetValue is a number
        reachedPreset: false // Initialize reachedPreset
      }))
  );

 //counters.counter_ctu.forEach(counter => {
 //   console.log(`Initialize CTU: ID=${counter.id}, currentValue=${counter.currentValue}, presetValue=${counter.presetValue}, state=${counter.state}`);
 // });

 // counters.counter_ctd.forEach(counter => {
    // console.log(`Initialize CTD: ID=${counter.id}, currentValue=${counter.currentValue}, presetValue=${counter.presetValue}, state=${counter.state}`);
 // });
}





function configureTimer(element) {
  if (isSimulating.value) return; // Do not allow configuration during simulation

  timerElement.value = element;
  dialogPresetTime.value = element.presetTime || 0;
  // Initialize dialogLinkedContacts with currently linked contacts or an empty array
  dialogLinkedContact.value = [...(element.linkedContacts || [])];
  showTimerDialog.value = true;
}

function openTimerDialog(newId) {
  const timerElement = findElementById(newId);
  if (timerElement) {
    configureTimer(timerElement);
  }
}

function closeTimerDialog() {
  showTimerDialog.value = false;
  timerElement.value = null;    // Reset timerElement

}

function saveTimerSettings() {
  if (timerElement.value) {
    timerElement.value.presetTime = dialogPresetTime.value;
    // Save multiple linked contacts
    timerElement.value.linkedContacts = dialogLinkedContact.value;
  }
  showTimerDialog.value = false;
  timerElement.value = null;    // Reset timerElement

}


const showCoilDialog = ref(false);
const selectedCoil = ref(null);

function configureCoil(element) {
  if (isSimulating.value) return; // Do not allow configuration during simulation
  
  selectedCoil.value = element; // Store the selected coil
  dialogLinkedContact.value = [...(element.linkedContacts || [])]; // Copy linked contacts to dialog
  
  // If no contacts are linked and there are available contacts, pre-select the first one
  if (dialogLinkedContact.value.length === 0 && availableContacts.value.length > 0) {
    dialogLinkedContact.value.push(availableContacts.value[0].id); 
  }
  
  showCoilDialog.value = true; // Show the coil configuration dialog
}


function openCoilDialog(newId) {
  const coilElement = findElementById(newId);
  if (coilElement) {
    configureCoil(coilElement);
  }
}

function closeCoilDialog() {
  showCoilDialog.value = false; // Hide the dialog
  selectedCoil.value = null;    // Reset selectedCoil

}

function saveCoilSettings() {
  if (selectedCoil.value) {
    // Save the linked contacts to the coil
    selectedCoil.value.linkedContacts = dialogLinkedContact.value;
  }
  showCoilDialog.value = false; // Hide the dialog after saving
  selectedCoil.value = null;    // Reset selectedCoil

}


function configureCounter(element) {
  if (isSimulating.value) return; // Do not allow configuration during simulation

  counterElement.value = element;
  dialogPresetValue.value = element.presetValue || 0;
  dialogLinkedContact.value = [...(element.linkedContacts || [])];  
  showCounterDialog.value = true;
}

function closeCounterDialog() {
  showCounterDialog.value = false;
  counterElement.value = null;  // Reset counterElement

}

function saveCounterSettings() {
  if (counterElement.value) {
    counterElement.value.presetValue = dialogPresetValue.value;
    counterElement.value.linkedContacts = dialogLinkedContact.value; // Save multiple linked contacts
  }
  showCounterDialog.value = false;
}
//this is only to mark a new trial
    function updateCounters() {
  counters.counter_ctu.forEach(counter => {
     //console.log(`CTU Counter before update: ID=${counter.id}, currentValue=${counter.currentValue}, state=${counter.state}, previousState=${counter.previousState}`);

    // Only update the counter if it has not been reset (state not 0 or 2)
    if (counter.state === 1 && counter.previousState !== 1) {
      counter.currentValue = (counter.currentValue || 0) + 1;
      
      if (counter.currentValue >= counter.presetValue) {
        counter.currentValue = counter.presetValue; // Cap at preset value
        
        if (counter.state !== 2) {
          counter.state = 2; // Set to the 'done' state (state 2)
          
        }
      }
    }

    // Ensure the counter remains at 0 after reset
    if (counter.state === 0 && counter.previousState === 2) {
      counter.currentValue = 0; // Reset currentValue if the state was 2 (i.e., it was at preset)
    }

    counter.previousState = counter.state; // Update the previous state
    //console.log(`CTU Counter after update: ID=${counter.id}, currentValue=${counter.currentValue}, state=${counter.state}`);
  });

  counters.counter_ctd.forEach(counter => {
    // console.log(`CTD Counter before update: ID=${counter.id}, currentValue=${counter.currentValue}, state=${counter.state}, previousState=${counter.previousState}`);

    // Only update the counter if it has not been reset (state not 0 or 2)
    if (counter.state === 1 && counter.previousState !== 1) {
      if (counter.currentValue > 0) {
        counter.currentValue = (counter.currentValue || counter.presetValue) - 1;
      }

      if (counter.currentValue <= 0) {
        counter.currentValue = 0; // Ensure it doesn't go below 0
        
        if (counter.state !== 2) {
          counter.state = 2; // Set to the 'done' state (state 2)
          
        }
      }
    }

    // Ensure the counter remains at 0 after reset
    if (counter.state === 0 && counter.previousState === 2) {
      counter.currentValue = 0; // Reset currentValue if the state was 2 (i.e., it was at preset)
    }

    counter.previousState = counter.state; // Update the previous state
    // console.log(`CTD Counter after update: ID=${counter.id}, currentValue=${counter.currentValue}, state=${counter.state}`);
  });
}



    function resetElementCounters() {
  // Reset all counters to 0
  Object.keys(elementCounters).forEach(key => {
    elementCounters[key] = 0;
  });
}



      // Check if the element is selected
    function isSelectedElement(elementId) {
      return selectedElement.value === elementId;
    }
    function toggleSimulation() {
  if (isSimulating.value) {
    stopSimulation();
  } else {
    runSimulation();
  }
}

    // Computed property to display elapsed time as an integer
    const displayElapsedTime = (element) => {
      return Math.floor(element.elapsedTime);
    };

    let timerInterval = null;
let lastUpdateTime = performance.now();

function startTimerIntervals() {
  clearInterval(timerInterval);

  timerInterval = setInterval(() => {
    const currentTime = performance.now();
    const elapsedTime = (currentTime - lastUpdateTime) / 1000; // Convert to seconds

    lastUpdateTime = currentTime; // Update the last update time to current

    timers.timer_ton.forEach(timer => {
      if (timer.state === 3 && timer.elapsedTime < timer.presetTime) {
        // Update elapsed time correctly without additional increments
        timer.elapsedTime += elapsedTime;

        // Cap at preset time if it goes over
        if (timer.elapsedTime >= timer.presetTime) {
          timer.elapsedTime = timer.presetTime; // Cap at preset time
        }
      }
    });

    timers.timer_tof.forEach(timer => {
      if (timer.state === 2 && timer.elapsedTime < timer.presetTime) {
        // Update elapsed time correctly without additional increments
        timer.elapsedTime += elapsedTime;

        // Cap at preset time if it goes over
        if (timer.elapsedTime >= timer.presetTime) {
          timer.elapsedTime = timer.presetTime; // Cap at preset time
        }
      }
    });

    timers.timer_tonr.forEach(timer => {
      if (timer.state === 3 && timer.elapsedTime < timer.presetTime) {
        // Update elapsed time correctly without additional increments
        timer.elapsedTime += elapsedTime;

        // Cap at preset time if it goes over
        if (timer.elapsedTime >= timer.presetTime) {
          timer.elapsedTime = timer.presetTime; // Cap at preset time
        }
      }
    });

  // Update counters   
  updateCounters();
    // Your function to calculate energized states
    calculateEnergizedStates();
  }, 100); // Update interval of 100ms
}





function getElementColor(element) {
  if (!isSimulating.value) {
    return 'rgb(3, 171, 255)'; // Default color when not simulating
  }

  if (element.type === 'contact_no' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'contact_nc' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'contact_pos' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'contact_neg' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'coil' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'r_coil' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'coil_set' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'coil_reset' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'timer_ton' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'timer_tof' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'timer_tonr' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'counter_ctu' && element.state === 2) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'counter_ctd' && element.state === 2) {
    return '#ffc300'; // Energized color
  }

  if (element.type === 'counter_ctud' && element.state === 1) {
    return '#ffc300'; // Energized color
  }

  return 'rgb(3, 171, 255)'; // Default color for other states or types
}

function getSegmentColor(rungIndex, segmentId) {
  if (!isSimulating.value) {
    return 'rgb(3, 171, 255)'; // Default color when not simulating
  }

  const rung = rungs.value[rungIndex];
  if (!rung || !rung.segments) {
    return 'rgb(3, 171, 255)'; // Default color if segments are undefined
  }

  // Check if the segment is a vertical branch
  const isVerticalBranch = segmentId.startsWith('branch-vertical');
  const segment = rung.segments.find(seg => seg.id === segmentId) ||
                  rung.elements.find(el => el.id === segmentId);

  if (segment) {
    // If it's a vertical branch, ensure we get its state from the `calculateEnergizedStates` logic
    if (isVerticalBranch) {
      return segment.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)'; // Energized or default color based on state
    } else {
      return segment.state === 1 ? '#ffc300' : 'rgb(3, 171, 255)';  // Energized or default color based on state
    }
  }

  return 'rgb(3, 171, 255)';  // Default color
}




    function isRungEnergized(rungIndex) {
      return energizedRungs.value.includes(rungIndex);
    }
// Initialize the global variable
const asymmetricalBranches = ref([]);

    function calculateEnergizedStates() {

   asymmetricalBranches.value = []; // Clear the previous asymmetrical branch data

  rungs.value.forEach((rung, rungIndex) => {
    // Step 1: Get all elements without filtering out vertical branches
    const nonBranchElements = rung.elements.filter(el => el.type !== 'branch_horizontal');
     // console.log('Non-branch elements: energizedStates', JSON.parse(JSON.stringify(nonBranchElements)));

    // Step 2: Get computed segments without filtering out horizontal branch segments
    const computedSegments = computed(() => {
      if (!rungs.value) return [];
      // console.log('CES- Computing segments for rungs:', JSON.parse(JSON.stringify(rungs.value)));
      return rungs.value.map((rung) => { // return rungs.value.map((rung, index) => {
        // console.log(`CES- Computing segments for rung ${index}:`, JSON.parse(JSON.stringify(rung)));
        const segments = getLineSegments(rung.elements || []);
        // console.log(`CES--Segments for rung ${index}:`, JSON.parse(JSON.stringify(segments)));
        return {
          ...rung,
          elements: rung.elements || [],
          segments,
        };
      });
    });

    const segments = computedSegments.value[rungIndex]?.segments || [];
    // console.log(`CES- Segments for rung ${rungIndex}:`, JSON.parse(JSON.stringify(segments)));

    // Step 3: Identify branch structures including vertical branch elements
    const branchStructures = [];
    const branchStarts = rung.elements.filter(el => el.id.startsWith('branch-vertical-start'));
    const branchEnds = rung.elements.filter(el => el.id.startsWith('branch-vertical-end'));



    branchStarts.forEach(branchStart => {
      const branchLevel = branchStart.level;

      // Find the corresponding branch end based on position and level
      const branchEnd = branchEnds.find(endEl => endEl.level === branchLevel && endEl.position > branchStart.position);

      // console.log('Branch Start:', JSON.parse(JSON.stringify(branchStart)));
      // console.log('Branch End:', JSON.parse(JSON.stringify(branchEnd)));

      if (branchEnd) {
        const branchLength = (branchEnd.position - branchStart.position) / 72;

        // Include all elements in the branch structure
        const branchElements = rung.elements.filter(el => {
          const isInBranch = el.position >= branchStart.position && el.position <= branchEnd.position;
          return isInBranch && el.level === branchLevel;
        });

        branchStructures.push({
          level: branchLevel,
          start: branchStart,
          end: branchEnd,
          length: branchLength,
          elements: branchElements,  // Include vertical branches and other elements
        });

        // console.log(`Branch found: Level ${branchLevel}, Start Position ${branchStart.position}, End Position ${branchEnd.position}, Length ${branchLength}`);
        // console.log(`Branch elements:`, JSON.parse(JSON.stringify(branchElements)));
      }
    });
    // console.log('branchStructures:', JSON.parse(JSON.stringify(branchStructures)));
    // Step 4: Combine segments, non-branch elements, and branches into the matrix
    let combinedElements = [...segments];
    // console.log('combinedElements:', JSON.parse(JSON.stringify(combinedElements)));
    // Insert branches into the combined matrix
    branchStructures.forEach(branch => {
      combinedElements.push(...branch.elements);
    });

    // Filter out non-branch elements that are part of branches from the main rung matrix
    combinedElements.push(...nonBranchElements.filter(el => {
      return !branchStructures.some(branch => branch.elements.includes(el));
    }));

    // Step 5: Sort combined elements by their position
    combinedElements.sort((a, b) => {
      const getPosition = (element) => {
        if (element.x1 !== undefined) return element.x1;
        if (element.position !== undefined) return element.position;
        if (element.elements && element.elements.length > 0) return element.elements[0]?.position;
        return Infinity; // Use Infinity as a fallback for undefined positions to push these elements to the end
      };

      const aPos = getPosition(a);
      const bPos = getPosition(b);

      return aPos - bPos;
    });

    // Step 6: Create the final matrix with full object references
    let rungMatrix = combinedElements.map(el => el);
    // console.log('rungMatrix:', JSON.parse(JSON.stringify(rungMatrix)));


   // Now, split the matrix into chunks based on branch structures
   const populatedChunks = splitRungMatrix(rungMatrix, branchStructures);

  // console.log(`Populated Chunks for rung ${rungIndex}:`, JSON.stringify(populatedChunks, null, 2));


// Step 7: Energy Propagation Logic with Chunk and Branch Handling
let mainRungEnergyFlow = 1; // Start with the assumption that energy flows
let previousChunkAllowedFlow = false; // Track if the previous chunk allowed energy flow

// Use a for loop to allow breaking out of the loop when energy is blocked
for (let chunkIndex = 0; chunkIndex < populatedChunks.length; chunkIndex++) {
    const chunk = populatedChunks[chunkIndex];
    let chunkEnergyFlow = mainRungEnergyFlow; // Current energy flow for the chunk
    let chunkAllowsEnergyFlow = false; // Determines if energy can flow through this chunk
    let chunkLogs = []; // Logs for the current chunk

    // console.log(`\nProcessing Chunk ${chunkIndex + 1}...`);



    // Identify if the chunk contains any branch elements
    const chunkHasBranch = chunk.some(element => element.type.includes('branch_vertical') || element.type.includes('branch_horizontal'));

    if (chunkHasBranch) {
        // console.log("Branches detected in chunk. Processing branches...");

        // Group elements by their levels (paths)
        const elementsByLevel = chunk.reduce((acc, element) => {
            const level = element.level || 0;
            if (!acc[level]) {
                acc[level] = [];
            }
            acc[level].push(element);
            return acc;
        }, {});

        const levels = Object.keys(elementsByLevel);

        // Determine if the branch is asymmetrical only if it has more than one level
        let isAsymmetrical = false;

        if (levels.length > 1) {
    // console.log("Checking for asymmetry...");

    // Filter out level 0 for the asymmetry check
    const nonMainLevels = levels.filter(level => level !== "0");

// Directly set referenceStart and referenceEnd from level 1
const referenceStart = elementsByLevel[1]?.find(el => el.type === 'branch_vertical' && el.id.includes('start'))?.position;
const referenceEnd = elementsByLevel[1]?.find(el => el.type === 'branch_vertical' && el.id.includes('end'))?.position;

if (referenceStart === undefined || referenceEnd === undefined) {
    // console.log("No valid reference found at Level 1. Skipping asymmetry check.");
} else {
    // console.log(`Initial Reference Set - Start Position: ${referenceStart}, End Position: ${referenceEnd}`);

    // Proceed with asymmetry check for remaining levels (2 and above)
    for (let i = 1; i < nonMainLevels.length; i++) {
        const currentStart = elementsByLevel[nonMainLevels[i]]?.find(el => el.type === 'branch_vertical' && el.id.includes('start'))?.position;
        const currentEnd = elementsByLevel[nonMainLevels[i]]?.find(el => el.type === 'branch_vertical' && el.id.includes('end'))?.position;

        // console.log(`Checking Level ${nonMainLevels[i]} - Start Position: ${currentStart}, End Position: ${currentEnd}`);

        if ((currentStart !== referenceStart || currentStart === undefined) || 
            (currentEnd !== referenceEnd || currentEnd === undefined)) {
            isAsymmetrical = true;

                   // Store the position and level of the asymmetrical branch
                  asymmetricalBranches.value.push({
                  start: currentStart,
                  end: currentEnd,
                  level: levels[i+1],
                  rungIndex: rungIndex,
                });
            // console.log(`isAsymmetricalBranch calculate energized states: ${JSON.stringify(asymmetricalBranches.value)}`);

            // console.log(`Asymmetry detected at Level ${nonMainLevels[i]}`);
            break;
        }
    }
}

   
}



        let anyLevelAllowsFlow = false; // Tracks if any branch allows flow

// Declare referenceStart and referenceEnd at the outer scope of the function
let referenceStart;
let referenceEnd;

// Set referenceStart and referenceEnd from level 1
referenceStart = elementsByLevel[1]?.find(el => el.type === 'branch_vertical' && el.id.includes('start'))?.position;
referenceEnd = elementsByLevel[1]?.find(el => el.type === 'branch_vertical' && el.id.includes('end'))?.position;

if (isAsymmetrical) {
    // console.log("Asymmetrical branch detected. Applying special energy propagation logic...");

    // Initialize variables to track CASE 1 processing
let case1Elements = [];
let isCase1AlignedWithStart = false;
let isCase1AlignedWithEnd = false;
let specialCASE2 = false;
let specialCASE2B = false;
let specialCASE4 = false;
    // Initialize branchAllowsEnergyFlow for the entire chunk
  let branchAllowsEnergyFlow = false;
    levels.forEach(level => {
        const elements = elementsByLevel[level];
        let levelFlow = chunkEnergyFlow; // Energy flow for this level
        let levelAllowsEnergyFlow = false; // Track if this level allows energy flow
        // console.log(`CASE Analyzing Level ${level + 999}`);

        // Check if the level is aligned with the reference start and end
        const isAlignedWithStart = elements.some(el => el.type === 'branch_vertical' && el.position === referenceStart && el.id.includes('start'));
        const isAlignedWithEnd = elements.some(el => el.type === 'branch_vertical' && el.position === referenceEnd && el.id.includes('end'));
        let currentLevel = level === '0' ? true : false;

        // Determine the behavior based on the alignment
        if (currentLevel || (isAlignedWithStart && isAlignedWithEnd)) {
            // CASE 1: Normal branch behavior
            // console.log(`CASE 1: Level ${level} is aligned with both start and end.`);

            elements.forEach(element => {
        if (element.type === 'segment' || element.type === 'branch_horizontal') {
            element.state = levelFlow; // Propagate energy flow as usual
        } else if (element.type === 'contact_no' || element.type === 'contact_nc' || element.type === 'contact_pos' || element.type === 'contact_neg') {
            // Handle contact logic for Normally Open, Normally Closed, POS, and NEG

            if (element.type === 'contact_no') {
                levelFlow = element.state === 1 ? levelFlow : 0; // NO contact allows or blocks energy
                //element.state = levelFlow;

            } else if (element.type === 'contact_nc') {
                levelFlow = element.state === 0 ? false : levelFlow; // NC contact blocks or allows energy
                //element.state = levelFlow;

            } else if (element.type === 'contact_pos') {
                // console.log(`Evaluating contact_pos in branch for element ${element.id}`);
                levelFlow = element.state === 1 ? levelFlow : 0; // POS contact allows or blocks energy
                //element.state = levelFlow;

            } else if (element.type === 'contact_neg') {
                // console.log(`Evaluating contact_neg in branch for element ${element.id}`);
                levelFlow = element.state === 0 ? false : levelFlow; // NEG contact blocks or allows energy
                //element.state = levelFlow;
            }
        } else {
            // Use the helper function to evaluate the element's state
            levelFlow = evaluateElementState(element, levelFlow, coilStates.value, rungs, counters);
            element.state = levelFlow;
        }
        chunkLogs.push(`Asymmetrical Chunk ${chunkIndex + 1} Level ${level}: ${element.type} at ${element.position || element.x1} - State: ${element.state}`);
    });

        // Special handling for vertical branch segments in CASE 1
        elements.forEach(element => {

            if (element.type === 'branch_vertical' && element.id.includes('start')) {
                element.state = previousChunkAllowedFlow ? 1 : 0;
                chunkLogs.push(`Special CASE 1: Vertical start at ${element.position} energized to state ${element.state}`);
            }  else if (element.type === 'branch_vertical' && element.id.includes('end')) {
                element.state = levelFlow; // Energize based on current level flow
                chunkLogs.push(`Special CASE 1: Vertical end at ${element.position} energized to state ${element.state} element level ${element.level}`);
            }

        });

         // Store CASE 1 information for later processing
         case1Elements = case1Elements.concat(elements);
        isCase1AlignedWithStart = isAlignedWithStart;
        isCase1AlignedWithEnd = isAlignedWithEnd;

               // Update branch-wide energy flow flag specifically for CASE 1
               if (levelFlow === 1) {
            branchAllowsEnergyFlow = true;
        }
        // console.log(`CASE 1: Level ${anyLevelAllowsFlow} anyLevelAllowsFlow.`);

// Backpropagation for CASE 1
if (anyLevelAllowsFlow) {
  let levelFlow = 1; // Start by assuming energy flows from the right

    for (let i = elements.length - 1; i >= 0; i--) {
        const element = elements[i];
        if (element.type === 'segment' || element.type === 'branch_horizontal') {
            element.state = 1; // Backpropagate energy
        } else if (element.type === 'contact_no' || element.type === 'contact_nc' || element.type === 'contact_pos' || element.type === 'contact_neg') {
                // Handle contact logic for Normally Open, Normally Closed, POS, and NEG during backpropagation
                if (element.type === 'contact_no') {
                    if (element.state === 0) {
                        levelFlow = 0; // Block further backpropagation for NO contact
                        break; // Exit the loop early as energy flow is blocked
                    } else {
                        element.state = 1; // Energize the contact if it allows energy flow
                    }
                } else if (element.type === 'contact_nc') {
                    if (element.state === 1) {
                        levelFlow = 0; // Block further backpropagation for NC contact
                        break; // Exit the loop early as energy flow is blocked
                    } else {
                        element.state = 1; // Energize the contact if it allows energy flow
                    }
                } else if (element.type === 'contact_pos') {
                    // One-Shot Positive: Energize if one-shot is triggered
                    if (element.state === 0) {
                        levelFlow = 0; // Block further backpropagation for POS contact
                        break; // Exit the loop early as energy flow is blocked
                    } else {
                        element.state = 1; // Energize the contact if it allows energy flow
                    }
                } else if (element.type === 'contact_neg') {
                    // One-Shot Negative: Energize if one-shot is triggered
                    if (element.state === 1) {
                        levelFlow = 0; // Block further backpropagation for NEG contact
                        break; // Exit the loop early as energy flow is blocked
                    } else {
                        element.state = 1; // Energize the contact if it allows energy flow
                    }
                }
            } else {
                // Use the helper function to evaluate the element's state during backpropagation
                levelFlow = evaluateElementState(element, levelFlow, coilStates.value, rungs, counters);

   
            if (levelFlow === 0) {
                chunkLogs.push(`Backpropagation CASE 1: Blocked by ${element.type} at ${element.position || element.x1} - Stopping backpropagation`);
                break; // Stop backpropagation if energy is blocked
            }
        }
        chunkLogs.push(`Backpropagation CASE 1: Level ${level}, Element ${element.type} at ${element.position || element.x1} - State: ${element.state}`);
    }
}








        } else if (isAlignedWithStart && !isAlignedWithEnd) {
     // CASE 2: Receive energy normally, propagate locally with bypass
     // console.log(`CASE 2: Level ${level} is aligned with start but not end.`);

let otherelementsinlevelpropagateenergy = true;

// Process each element in the current level to evaluate energy propagation, except the last one
elements.forEach((element, index) => {
    if (index < elements.length - 1) {
        if (element.type === 'segment' || element.type === 'branch_horizontal' ) {
            element.state = levelFlow; // Propagate energy normally
        } else if (element.type === 'contact_no' || element.type === 'contact_nc' || element.type === 'contact_pos' || element.type === 'contact_neg') {
                // Handle Normally Open, Normally Closed, POS, and NEG contacts

                if (element.type === 'contact_no') {
                    levelFlow = element.state === 1 ? levelFlow : 0; // NO contact allows or blocks energy
                    //element.state = levelFlow;

                } else if (element.type === 'contact_nc') {
                    levelFlow = element.state === 0 ? false : levelFlow; // NC contact blocks or allows energy
                    //element.state = levelFlow;

                } else if (element.type === 'contact_pos') {
                    // console.log(`Evaluating contact_pos in CASE 2 for element ${element.id}`);
                    levelFlow = element.state === 1 ? levelFlow : 0; // POS contact allows or blocks energy
                    //element.state = levelFlow;

                } else if (element.type === 'contact_neg') {
                    // console.log(`Evaluating contact_neg in CASE 2 for element ${element.id}`);
                    levelFlow = element.state === 0 ? false : levelFlow; // NEG contact blocks or allows energy
                    //element.state = levelFlow;
                }

                if (levelFlow === 0) {
                    otherelementsinlevelpropagateenergy = false; // Mark that energy propagation is blocked
                }
            } else {
                // Use the helper function to evaluate the element's state
                levelFlow = evaluateElementState(element, levelFlow, coilStates.value, rungs, counters);
                element.state = levelFlow;
                if (levelFlow === 0) {
                    otherelementsinlevelpropagateenergy = false; // Mark that energy propagation is blocked
                }
            }

        // Ensure it propagates energy to the next element if it's not the last one
        const nextElement = elements[index + 1];
        if (nextElement) {
            if (levelFlow === 1 && (nextElement.type === 'segment' || nextElement.type === 'branch_horizontal')) {
                nextElement.state = 1; // Energize the next element if the current one allows it
            } else if (
        nextElement.type === 'contact_no' ||
        nextElement.type === 'contact_nc' ||
        nextElement.type === 'contact_pos' ||
        nextElement.type === 'contact_neg'
    ) {
        // Handle Normally Open, Normally Closed, POS, and NEG contacts
        if (nextElement.type === 'contact_no') {
            // NO contact allows or blocks energy
            levelFlow = nextElement.state === 1 ? levelFlow : 0;

        } else if (nextElement.type === 'contact_nc') {
            // NC contact blocks or allows energy
            levelFlow = nextElement.state === 0 ? false : levelFlow;

        } else if (nextElement.type === 'contact_pos') {
            // console.log(`Evaluating next contact_pos for element ${nextElement.id}`);
            // POS contact allows or blocks energy
            levelFlow = nextElement.state === 1 ? levelFlow : 0;

        } else if (nextElement.type === 'contact_neg') {
            // console.log(`Evaluating next contact_neg for element ${nextElement.id}`);
            // NEG contact blocks or allows energy
            levelFlow = nextElement.state === 0 ? false : levelFlow;
        }

        // Block energy propagation if the contact prevents it
        if (levelFlow === 0) {
            otherelementsinlevelpropagateenergy = false; // Mark that energy propagation is blocked
        }

        // Set the state of the contact based on the level flow
        nextElement.state = levelFlow;
    } else {
        // Use the helper function to evaluate the element's state
        levelFlow = evaluateElementState(nextElement, levelFlow, coilStates.value, rungs, counters);

        // Block energy propagation if the element prevents it
        if (levelFlow === 0) {
            otherelementsinlevelpropagateenergy = false; // Mark that energy propagation is blocked
        }
    }
        }

        chunkLogs.push(`Asymmetrical Chunk ${chunkIndex + 1} Level ${level}: ${element.type} at ${element.position || element.x1} - State: ${element.state}`);
    }
});

// Only proceed to check for bypass and propagate energy to upper levels if energy can flow through all elements
// Only proceed to check for bypass and propagate energy to upper levels if energy can flow through all elements
if (otherelementsinlevelpropagateenergy) {
    // Check for bypass
    const checkUpperLevels = (currentLevel) => {
        const upperLevel = elementsByLevel[currentLevel - 1];
        if (!upperLevel) return false;

        const currentElementPosition = elements[0].position || elements[0].x1;

        // Filter upper level elements that are positioned to the right of the current element
        // Exclude vertical branches from the right-side elements
        const upperLevelRightElements = upperLevel.filter(el => {
            const elPosition = el.position || el.x1;
            return elPosition > currentElementPosition && el.type !== 'branch_vertical';
        });

        // Focus on the horizontal elements directly above the bypass
        const upperElementDirectlyAbove = upperLevel.find(el =>
            (el.position === currentElementPosition || el.x1 === currentElementPosition) &&
            (el.type === 'branch_horizontal' || el.type === 'contact_no' || el.type === 'contact_nc' || el.type === 'contact_pos' || el.type === 'contact_neg' || el.type === 'segment' || el.type === 'coil')
        );

        // Determine if the element directly above is blocking energy flow
        const upperLevelBlocked = (upperElementDirectlyAbove?.type === 'contact_no' && upperElementDirectlyAbove.state === 0) ||
                                  (upperElementDirectlyAbove?.type === 'contact_nc' && upperElementDirectlyAbove.state === 0) ||
                                  (upperElementDirectlyAbove?.type === 'contact_pos' && upperElementDirectlyAbove.state === 0) || 
                                  (upperElementDirectlyAbove?.type === 'contact_neg' && upperElementDirectlyAbove.state === 0);

        // Check if the current level allows energy to flow (including all types of contacts and elements)
        const currentLevelAllowsFlow = elements.some(el => 
            (el.type === 'contact_no' && el.state === 1) ||
            (el.type === 'contact_nc' && el.state === 1) ||
            (el.type === 'contact_pos' && el.state === 1) ||
            (el.type === 'contact_neg' && el.state === 1) ||
            (el.type === 'branch_horizontal')||
            (el.type === 'coil')
        );

        // console.log('XYZW2- upperLevelRightElements:', JSON.parse(JSON.stringify(upperLevelRightElements)));
        // console.log('XYZW2- upperElementDirectlyAbove', JSON.parse(JSON.stringify(upperElementDirectlyAbove)));
        // console.log(`XYZW2- upperLevelBlocked: ${upperLevelBlocked}`);
        // console.log(`XYZW2- upperLevelRightElements.length: ${upperLevelRightElements.length}`);

        // Include the condition for `otherelementsinlevelpropagateenergy`
        if (otherelementsinlevelpropagateenergy && upperLevelBlocked && currentLevelAllowsFlow && upperLevelRightElements.length) {
            // console.log(`Bypass detected at Level ${level}. Energy flowing through bypass.`);

            // Check if the element directly above allows energy flow, not just the right-side elements
            let upperLevelAllowsFlow = upperLevelRightElements.every(el => {
                if (el.type === 'branch_horizontal' || el.type === 'segment' || el.type === 'coil') {
                    return true; // These elements always allow flow
                } else if (el.type === 'contact_no' && el.state === 1) {
                    return true; // NO contact allows flow if state is 1
                } else if (el.type === 'contact_nc' && el.state === 1) {
                    return true; // NC contact allows flow if state is 0
                } else if (el.type === 'contact_pos' && el.state === 1) {
                    return true; // POS contact allows flow if state is 1
                } else if (el.type === 'contact_neg' && el.state === 1) {
                    return true; // NEG contact allows flow if state is 0
                }
                return false; // Block flow otherwise
            });

            if (upperLevelAllowsFlow) {
                // Propagate energy to all right-side elements in the upper level
                upperLevelRightElements.forEach(el => {
                    if (el.type === 'branch_horizontal' || el.type === 'segment' || el.type === 'contact_no' || el.type === 'contact_nc' || el.type === 'contact_pos' || el.type === 'coil' || el.type === 'contact_neg') {
                        el.state = 1; // Set the state to energized for the bypass
                    }
                });

                levelFlow = 1; // Allow energy to flow through bypass
                levelAllowsEnergyFlow = true; // Allow energy to propagate through this level
                return true; // Bypass was successful
            } else {
                levelFlow = 0; // Do not allow energy if upper level conditions are not met
                levelAllowsEnergyFlow = false; // Prevent energy propagation
                return false; // Bypass failed
            }
        } else if (!upperLevelRightElements.length && upperElementDirectlyAbove) {
            // If there are no right-side elements but the element directly above is blocking, continue checking previous levels
            return checkUpperLevels(currentLevel - 1);
        } else {
            // If no bypass is detected, prevent further propagation
            levelAllowsEnergyFlow = false;
            return false;
        }
    };

    checkUpperLevels(level);
}


      // Special handling for vertical branch segments in CASE 2
elements.forEach(element => {
    if (element.type === 'branch_vertical' && element.id.includes('start')) {
        element.state = previousChunkAllowedFlow ? 1 : 0; // Similar to CASE 1
        chunkLogs.push(`Special CASE 2: Vertical start at ${element.position} energized to state ${element.state}`);
    } else if (element.type === 'branch_vertical' && element.id.includes('end')) {
        // Energize based on both the element above and the current level
        const elementAbove = elementsByLevel[level - 1]?.find(el => el.position === element.position - 72 && el.type !== 'branch_vertical');
        const elementInCurrentLevel = elements.find(el => el.position === element.position - 72 && el.type !== 'branch_vertical');

        // New condition: Check if the element/segment/horizontal branch from the previous level at the same position is energized
        const elementAtSamePositionInPreviousLevel = elementsByLevel[level - 1]?.find(el => el.position === element.position);
        // console.log('CASE 2 - Asymmetrical Chunk', JSON.parse(JSON.stringify(elementAtSamePositionInPreviousLevel)));

        // Vertical end state is set to 1 if any of the following conditions are met:
        // 1. The element directly above allows energy flow (elementAbove)
        // 2. The element in the current level allows energy flow (elementInCurrentLevel)
        // 3. The element at the same position in the previous level allows energy flow (elementAtSamePositionInPreviousLevel)
        if ((elementAbove && elementAbove.state === 1) || 
            (elementInCurrentLevel && elementInCurrentLevel.state === 1) ||
            (elementAtSamePositionInPreviousLevel && elementAtSamePositionInPreviousLevel.state === 1)) {
            element.state = 1;
            chunkLogs.push(`Special CASE 2: Vertical end at ${element.position} changed to state 1 due to additional condition.`);
        } else {
            element.state = 0;
            chunkLogs.push(`Special CASE 2: Vertical end at ${element.position} changed to state 0.`);
        }
        if (elementAtSamePositionInPreviousLevel && elementAtSamePositionInPreviousLevel.state === 0) {
        specialCASE2 = true;
        }
        if (elementAbove && elementAbove.state === 1) {
        specialCASE2B = true;
        }

        // Update the corresponding element in the rungMatrix
        const matrixElement = rungMatrix.find(el => el.id === element.id);
        if (matrixElement) {
            matrixElement.state = element.state;
            chunkLogs.push(`Special CASE 2: Updated rungMatrix element ${matrixElement.id} state to ${matrixElement.state}`);
        }
    }
});
         // Update branch-wide energy flow flag specifically for CASE 2
         if (levelFlow === 1 && !specialCASE2) {
            branchAllowsEnergyFlow = true;
        }

            // Backpropagation for CASE 2
            if (((anyLevelAllowsFlow && !specialCASE2) || specialCASE2B)) {
                for (let i = elements.length - 1; i >= 0; i--) {
                    const element = elements[i];
                    if (element.type === 'segment' || element.type === 'branch_horizontal') {
                        element.state = 1; // Backpropagate energy
                    } else if (element.type === 'contact_no' || element.type === 'contact_nc' || element.type === 'contact_pos' || element.type === 'contact_neg') {
                // Handle Normally Open, Normally Closed, POS, and NEG contacts in backpropagation

                if (element.type === 'contact_no') {
                    if (element.state === 0) {
                        levelFlow = 0; // Block further backpropagation for NO contact
                        break; // Exit the loop early as energy flow is blocked
                    } else {
                        //element.state = 1; // Energize the contact if it allows energy flow
                    }
                } else if (element.type === 'contact_nc') {
                    if (element.state === 0) {
                        levelFlow = 0; // Block further backpropagation for NC contact
                        break; // Exit the loop early as energy flow is blocked
                    } else {
                        //element.state = 1; // Energize the contact if it allows energy flow
                    }
                } else if (element.type === 'contact_pos') {
                    // One-Shot Positive: Energize if one-shot is triggered
                    if (element.state === 0) {
                        levelFlow = 0; // Block further backpropagation for POS contact
                        break; // Exit the loop early as energy flow is blocked
                    } else {
                        //element.state = 1; // Energize the contact if it allows energy flow
                    }
                } else if (element.type === 'contact_neg') {
                    // One-Shot Negative: Energize if one-shot is triggered
                    if (element.state === 0) {
                        levelFlow = 0; // Block further backpropagation for NEG contact
                        break; // Exit the loop early as energy flow is blocked
                    } else {
                        //element.state = 1; // Energize the contact if it allows energy flow
                    }
                }
            } else {
                // Use the helper function to evaluate the element's state during backpropagation
                levelFlow = evaluateElementState(element, levelFlow, coilStates.value, rungs, counters);

                if (levelFlow === 0) {
                    chunkLogs.push(`Backpropagation CASE 2: Blocked by ${element.type} at ${element.position || element.x1} - Stopping backpropagation`);
                    break; // Stop backpropagation if energy is blocked
                }
            }

                    chunkLogs.push(`Backpropagation CASE 2: Level ${level}, Element ${element.type} at ${element.position || element.x1} - State: ${element.state}`);
                }
            }


}



else if (!isAlignedWithStart && isAlignedWithEnd) {
    // CASE 3: Receive energy locally, propagate normally
    // console.log(`CASE 3: Level ${level} is aligned with end but not start.`);

    let otherelementsinlevelpropagateenergy = true;

    // Process each element in the current level to evaluate energy propagation, except the last one
    elements.forEach((element, index) => {
        if (index < elements.length - 1) {
            if (element.type === 'segment' || element.type === 'branch_horizontal') {
                element.state = levelFlow; // Propagate energy normally
            } else if (element.type === 'contact_no' || element.type === 'contact_nc' || element.type === 'contact_pos' || element.type === 'contact_neg') {
                // Handle Normally Open, Normally Closed, POS, and NEG contacts

                if (element.type === 'contact_no') {
                    levelFlow = element.state === 1 ? levelFlow : 0; // NO contact allows or blocks energy
                } else if (element.type === 'contact_nc') {
                    levelFlow = element.state === 0 ? false : levelFlow; // NC contact blocks or allows energy
                } else if (element.type === 'contact_pos') {
                    // console.log(`Evaluating contact_pos in CASE 3 for element ${element.id}`);
                    levelFlow = element.state === 1 ? levelFlow : 0; // POS contact allows or blocks energy
                } else if (element.type === 'contact_neg') {
                    // console.log(`Evaluating contact_neg in CASE 3 for element ${element.id}`);
                    levelFlow = element.state === 0 ? false : levelFlow; // NEG contact blocks or allows energy
                }

                if (levelFlow === 0) {
                    otherelementsinlevelpropagateenergy = false; // Mark that energy propagation is blocked
                }
            } else {
                // Use the helper function to evaluate other element types (e.g., coil, timer)
                levelFlow = evaluateElementState(element, levelFlow, coilStates.value, rungs, counters);
                if (levelFlow === 0) {
                    otherelementsinlevelpropagateenergy = false; // Mark that energy propagation is blocked
                }
            }

            // Ensure it propagates energy to the next element if it's not the last one
            const nextElement = elements[index + 1];
            if (nextElement) {
                if (levelFlow === 1 && (nextElement.type === 'segment' || nextElement.type === 'branch_horizontal')) {
                    nextElement.state = 1; // Energize the next element if the current one allows it
                } else if (
                    nextElement.type === 'contact_no' ||
                    nextElement.type === 'contact_nc' ||
                    nextElement.type === 'contact_pos' ||
                    nextElement.type === 'contact_neg'
                ) {
                    // Handle Normally Open, Normally Closed, POS, and NEG contacts for the next element
                    if (nextElement.type === 'contact_no') {
                        levelFlow = nextElement.state === 1 ? levelFlow : 0;
                    } else if (nextElement.type === 'contact_nc') {
                        levelFlow = nextElement.state === 0 ? false : levelFlow;
                    } else if (nextElement.type === 'contact_pos') {
                        // console.log(`Evaluating next contact_pos for element ${nextElement.id}`);
                        levelFlow = nextElement.state === 1 ? levelFlow : 0;
                    } else if (nextElement.type === 'contact_neg') {
                        // console.log(`Evaluating next contact_neg for element ${nextElement.id}`);
                        levelFlow = nextElement.state === 0 ? false : levelFlow;
                    }

                    if (levelFlow === 0) {
                        otherelementsinlevelpropagateenergy = false; // Mark that energy propagation is blocked
                    }
                    nextElement.state = levelFlow;
                } else {
                    // Use the helper function to evaluate the next element's state
                    levelFlow = evaluateElementState(nextElement, levelFlow, coilStates.value, rungs, counters);
                    if (levelFlow === 0) {
                        otherelementsinlevelpropagateenergy = false; // Mark that energy propagation is blocked
                    }
                }
            }

            chunkLogs.push(`Asymmetrical Chunk ${chunkIndex + 1} Level ${level}: ${element.type} at ${element.position || element.x1} - State: ${element.state}`);
        }
    });

    // Only proceed to check for bypass and propagate energy to upper levels if energy can flow through all elements
    if (otherelementsinlevelpropagateenergy) {
        // Recursive function to check energy flow from previous levels
        const checkPreviousLevels = (currentLevel) => {
            if (currentLevel <= 0) return 0; // Base case: stop if we reach the main rung level (level 0)

            const previousLevelFlow = elementsByLevel[currentLevel - 1]?.find(el => 
            el.type === 'coil' || el.type === 'branch_horizontal' || el.type === 'contact_no' || el.type === 'contact_nc' || el.type === 'contact_pos' || el.type === 'contact_neg')?.state;

            if (previousLevelFlow === 1) {
                return 1; // Energy flows if any element in the previous level allows it
            }

            // Recursive call to check the level before the current one
            return checkPreviousLevels(currentLevel - 1);
        };

        levelFlow = checkPreviousLevels(level); // Check energy flow recursively

        // Process the last element separately
        const lastElement = elements[elements.length - 1];
        if (lastElement) {
            if (lastElement.type === 'segment' || lastElement.type === 'branch_horizontal' || lastElement.type === 'coil') {
                lastElement.state = levelFlow; // Propagate energy as usual
              } else if (lastElement.type === 'contact_no' || lastElement.type === 'contact_nc' || lastElement.type === 'contact_pos' || lastElement.type === 'contact_neg') {
                levelFlow = lastElement.state === 1 ? levelFlow : 0;
            }
            chunkLogs.push(`Asymmetrical Chunk ${chunkIndex + 1} Level ${level}: ${lastElement.type} at ${lastElement.position || lastElement.x1} - State: ${lastElement.state}`);
        }

        // If energy flows in this level, allow propagation
        if (levelFlow === 1) {
            levelAllowsEnergyFlow = true;
            // console.log(`levelAllowsEnergyFlow ${chunkIndex + 1} Level ${level} = ${levelAllowsEnergyFlow}`);
        }

        // Update branch-wide energy flow flag specifically for CASE 3
        if (levelFlow === 1) {
            branchAllowsEnergyFlow = true;
        }
    }
   // Special handling for vertical branch segments in CASE 3
   elements.forEach(element => {
        if (element.type === 'branch_vertical' && element.id.includes('start')) {
            // Check conditions for energizing the vertical start
            const elementAbove = elementsByLevel[level - 1]?.find(el => el.position === element.position - 72 && el.type !== 'branch_vertical');
            const elementAbove2 = elementsByLevel[level - 1]?.find(el => el.position === element.position && el.type !== 'branch_vertical');
            const elementInCurrentLevel = elements.find(el => el.position === element.position && el.type !== 'branch_vertical');
            const verticalEnd = elements.find(el => el.type === 'branch_vertical' && el.id.includes('end'));

            if ((elementAbove && elementAbove.state === 1) || 
                (verticalEnd && verticalEnd.state === 1 && (elementInCurrentLevel?.state === 1 || elementAbove2?.state === 1))) {
                element.state = 1;
                chunkLogs.push(`Special CASE 3: Vertical start at ${element.position} energized to state 1 due to previous level, current conditions, or vertical end state.`);
            } else {
                element.state = 0;
                chunkLogs.push(`Special CASE 3: Vertical start at ${element.position} remains de-energized.`);
            }
        } else if (element.type === 'branch_vertical' && element.id.includes('end')) {
            // Energize based on level flow or branch-wide energy flow
            if (levelFlow === 1 || branchAllowsEnergyFlow) {
                element.state = 1;
                chunkLogs.push(`Special CASE 3: Vertical end at ${element.position} energized to state 1 due to level or branch-wide energy flow.`);
            } else {
                element.state = 0;
                chunkLogs.push(`Special CASE 3: Vertical end at ${element.position} remains de-energized.`);
            }
        }
    });

           // Backpropagation for CASE 3
           if (anyLevelAllowsFlow) {
                for (let i = elements.length - 1; i >= 0; i--) {
                    const element = elements[i];
                    if (element.type === 'segment' || element.type === 'branch_horizontal' || element.type === 'coil') {
                        element.state = 1; // Backpropagate energy
                    } else if (element.type === 'contact_no' || element.type === 'contact_nc' || element.type === 'contact_pos' || element.type === 'contact_neg') {
                if (element.type === 'contact_no') {
                    if (element.state === 0) {
                        levelFlow = 0; // Block further backpropagation for NO contact
                        break;
                    }
                } else if (element.type === 'contact_nc') {
                    if (element.state === 1) {
                        levelFlow = 0; // Block further backpropagation for NC contact
                        break;
                    }
                } else if (element.type === 'contact_pos') {
                    if (element.state === 0) {
                        levelFlow = 0; // Block further backpropagation for POS contact
                        break;
                    }
                } else if (element.type === 'contact_neg') {
                    if (element.state === 1) {
                        levelFlow = 0; // Block further backpropagation for NEG contact
                        break;
                    }
                }
            } else {
                // Use the helper function to evaluate the element's state during backpropagation
                levelFlow = evaluateElementState(element, levelFlow, coilStates.value, rungs, counters);
                if (levelFlow === 0) {
                    chunkLogs.push(`Backpropagation CASE 3: Blocked by ${element.type} at ${element.position || element.x1} - Stopping backpropagation`);
                    break;
                }
            }
                    chunkLogs.push(`Backpropagation CASE 3: Level ${level}, Element ${element.type} at ${element.position || element.x1} - State: ${element.state}`);
                }
            }

}


else if (!isAlignedWithStart && !isAlignedWithEnd && !currentLevel) {
    // CASE 4: Receive and propagate energy locally with bypass
    // console.log(`CASE 4: Level ${level} is not aligned with start or end. currentLevel ${currentLevel}`);

    let otherelementsinlevelpropagateenergy = true;

    // Process each element in the current level to evaluate energy propagation
    elements.forEach((element, index) => {
        if (element.type === 'segment' || element.type === 'branch_horizontal' || element.type === 'coil') {
            element.state = levelFlow; // Propagate energy locally
        } else if (element.type === 'contact_no' || element.type === 'contact_nc' || element.type === 'contact_pos' || element.type === 'contact_neg') {
            // Evaluate the state of the contact
            levelFlow = element.state === 1 ? levelFlow : 0; // Stop energy flow if the contact blocks it
            if (levelFlow === 0) {
                otherelementsinlevelpropagateenergy = false; // Mark that energy propagation is blocked
            }
        }

        // If it's not the last element in the level, ensure it propagates energy to the next element
        if (index < elements.length - 1) {
            const nextElement = elements[index + 1];
            if (nextElement) {
                if (levelFlow === 1 && (nextElement.type === 'segment' || nextElement.type === 'branch_horizontal' || nextElement.type === 'coil')) {
                    nextElement.state = 1; // Energize the next element if the current one allows it
                } else if (nextElement.type === 'contact_no' || nextElement.type === 'contact_nc' || nextElement.type === 'contact_pos' || nextElement.type === 'contact_neg') {
                    levelFlow = nextElement.state === 1 ? levelFlow : 0;
                    if (levelFlow === 0) {
                        otherelementsinlevelpropagateenergy = false; // Mark that energy propagation is blocked
                    }
                } else {
                    levelFlow = 0; // Stop propagation if the current element blocks energy
                }
            }
        }

        chunkLogs.push(`Asymmetrical Chunk ${chunkIndex + 1} Level ${level}: ${element.type} at ${element.position || element.x1} - State: ${element.state}`);
    });

    // Only proceed to check for bypass and propagate energy to upper levels if energy can flow through all elements
    if (otherelementsinlevelpropagateenergy) {
        // Check for bypass
        const checkUpperLevels = (currentLevel) => {
            const upperLevel = elementsByLevel[currentLevel - 1];
            if (!upperLevel) return false;

            const currentElementPosition = elements[0].position || elements[0].x1;

            // Filter upper level elements that are positioned to the right of the current element
            // Exclude vertical branches from the right-side elements
            const upperLevelRightElements = upperLevel.filter(el => {
                const elPosition = el.position || el.x1;
                return elPosition > currentElementPosition && el.type !== 'branch_vertical';
            });

            // Focus on the element directly above the bypass
            const upperElementDirectlyAbove = upperLevel.find(el => el.position === currentElementPosition || el.x1 === currentElementPosition);
            const upperLevelBlocked = (upperElementDirectlyAbove?.type === 'contact_no' && upperElementDirectlyAbove.state === 0) ||
                                      (upperElementDirectlyAbove?.type === 'contact_nc' && upperElementDirectlyAbove.state === 0) ||
                                      (upperElementDirectlyAbove?.type === 'contact_pos' && upperElementDirectlyAbove.state === 0) ||
                                      (upperElementDirectlyAbove?.type === 'contact_neg' && upperElementDirectlyAbove.state === 0);

            const currentLevelAllowsFlow = elements.some(el => 
                (el.type === 'contact_no' && el.state === 1) || 
                (el.type === 'contact_nc' && el.state === 1) || 
                (el.type === 'contact_pos' && el.state === 1) || 
                (el.type === 'contact_neg' && el.state === 1) || 
                el.type === 'branch_horizontal'|| 
                el.type === 'coil'
            );            
            // console.log('XYZW- upperLevelRightElements:', JSON.parse(JSON.stringify(upperLevelRightElements)));
            // console.log('XYZW- upperElementDirectlyAbove', JSON.parse(JSON.stringify(upperElementDirectlyAbove)));
            // console.log(`XYZW- upperLevelBlocked: ${upperLevelBlocked}`);
            
            // Include the condition for `otherelementsinlevelpropagateenergy`
            if (otherelementsinlevelpropagateenergy && upperLevelBlocked && currentLevelAllowsFlow && upperLevelRightElements.length) {
                // console.log(`Bypass detected at Level ${level}. Energy flowing through bypass.`);

                // Check if the element directly above allows energy flow, not just the right-side elements
                let upperLevelAllowsFlow = upperLevelRightElements.every(el => {
                    if (el.type === 'branch_horizontal' || el.type === 'segment' || el.type === 'coil') {
                        return true; // These elements always allow flow
                    } else if (el.type === 'contact_no' && el.state === 1) {
                        return true; // NO contact allows flow if state is 1
                    } else if (el.type === 'contact_nc' && el.state === 1) {
                        return true; // NC contact allows flow if state is 0
                    } else if (el.type === 'contact_pos' && el.state === 1) {
                        return true; // NC contact allows flow if state is 0
                    } else if (el.type === 'contact_neg' && el.state === 1) {
                        return true; // NC contact allows flow if state is 0
                    }

                    return false; // Block flow otherwise
                });

                if (upperLevelAllowsFlow) {
                    // Propagate energy to all right-side elements in the upper level
                    upperLevelRightElements.forEach(el => {
                        if (el.type === 'branch_horizontal' || el.type === 'segment' || el.type === 'contact_no' || el.type === 'contact_nc' || el.type === 'contact_pos' || el.type === 'contact_neg' || el.type === 'coil') {
                            el.state = 1; // Set the state to energized for the bypass
                        }
                    });

                    levelFlow = 1; // Allow energy to flow through bypass
                    levelAllowsEnergyFlow = true; // Allow energy to propagate through this level
                    return true; // Bypass was successful
                } else {
                    levelFlow = 0; // Do not allow energy if upper level conditions are not met
                    levelAllowsEnergyFlow = false; // Prevent energy propagation
                    return false; // Bypass failed
                }
            } else if (!upperLevelRightElements.length && upperElementDirectlyAbove) {
                // If there are no right-side elements but the element directly above is blocking, continue checking previous levels
                return checkUpperLevels(currentLevel - 1);
            } else {
                // If no bypass is detected, prevent further propagation
                levelAllowsEnergyFlow = false;
                return false;
            }
        };

        checkUpperLevels(level);
    }

    // Determine if this level (path) allows energy flow through the entire level
    if (levelFlow === 1 && (isAlignedWithEnd || currentLevel)) {
        levelAllowsEnergyFlow = true;
        // console.log(`levelAllowsEnergyFlow ${chunkIndex + 1} Level ${level} = ${levelAllowsEnergyFlow}`);
    }

    // If any level allows energy flow, set the flag for the entire chunk
    if (levelAllowsEnergyFlow) {
        anyLevelAllowsFlow = true;
        // console.log(`anyLevelAllowsFlow ${chunkIndex + 1} Level ${level} = ${anyLevelAllowsFlow}`);
    }

    // Log the result for this level
   // const levelFinalFlow = levelFlow === 1 ? "Energized, let energy flow" : "Not Energized, stop energy flow";
    // console.log(`Asymmetrical Chunk Result ${chunkIndex + 1} Level ${level} = ${levelFinalFlow}`);



    // Special handling for vertical branch segments in CASE 4
    elements.forEach(element => {
        if (element.type === 'branch_vertical' && element.id.includes('start')) {
            // Check conditions for energizing the vertical start
            const elementAbove = elementsByLevel[level - 1]?.find(el => el.position === element.position - 72 && el.type !== 'branch_vertical');
            const elementAbove2 = elementsByLevel[level - 1]?.find(el => el.position === element.position && el.type !== 'branch_vertical');
            const elementInCurrentLevel = elements.find(el => el.position === element.position && el.type !== 'branch_vertical');
            const verticalEnd = elements.find(el => el.type === 'branch_vertical' && el.id.includes('end'));

            if ((elementAbove && elementAbove.state === 1) || 
                (verticalEnd && verticalEnd.state === 1 && (elementInCurrentLevel?.state === 1 || elementAbove2?.state === 1))) {
                element.state = 1;
                chunkLogs.push(`Special CASE 4: Vertical start at ${element.position} energized to state 1 due to previous level, current conditions, or vertical end state.`);
            } else {
                element.state = 0;
                chunkLogs.push(`Special CASE 4: Vertical start at ${element.position} remains de-energized.`);
            }
        } else if (element.type === 'branch_vertical' && element.id.includes('end')) {
            // Energize based on both the element above and the current level
            const elementAbove = elementsByLevel[level - 1]?.find(el => el.position === element.position - 72 && el.type !== 'branch_vertical');
            const elementInCurrentLevel = elements.find(el => el.position === element.position - 72 && el.type !== 'branch_vertical');

            // New condition: Check if the element/segment/horizontal branch from the previous level at the same position is energized
            const elementAtSamePositionInPreviousLevel = elementsByLevel[level - 1]?.find(el => el.position === element.position);

            // Vertical end state is set to 1 if any of the following conditions are met:
            // 1. The element directly above allows energy flow (elementAbove)
            // 2. The element in the current level allows energy flow (elementInCurrentLevel)
            // 3. The element at the same position in the previous level allows energy flow (elementAtSamePositionInPreviousLevel)
            if ((elementAbove && elementAbove.state === 1) || 
                (elementInCurrentLevel && elementInCurrentLevel.state === 1) ||
                (elementAtSamePositionInPreviousLevel && elementAtSamePositionInPreviousLevel.state === 1)) {
                element.state = 1;
                chunkLogs.push(`Special CASE 4: Vertical end at ${element.position} changed to state 1 due to additional condition.`);
            } else {
                element.state = 0;
                chunkLogs.push(`Special CASE 4: Vertical end at ${element.position} changed to state 0.`);
            }

            if (elementAtSamePositionInPreviousLevel && elementAtSamePositionInPreviousLevel.state === 0) {
            specialCASE4 = true;
            }

            // Update the corresponding element in the rungMatrix
            const matrixElement = rungMatrix.find(el => el.id === element.id);
        if (matrixElement) {
            matrixElement.state = element.state;
            chunkLogs.push(`Special CASE 4: Updated rungMatrix element ${matrixElement.id} state to ${matrixElement.state}`);
        }
        }
    });

            // Backpropagation for CASE 4
            if (anyLevelAllowsFlow) {
                for (let i = elements.length - 1; i >= 0; i--) {
                    const element = elements[i];
                    if (element.type === 'segment' || element.type === 'branch_horizontal' || element.type === 'coil') {
                        element.state = 1; // Backpropagate energy
                    } else if (element.type === 'contact_no' || element.type === 'contact_nc' || element.type === 'contact_neg' || element.type === 'contact_pos') {
                        levelFlow = element.state === 1 ? levelFlow : 0; // Block if contact is open
                    }
                    chunkLogs.push(`Backpropagation CASE 4: Level ${level}, Element ${element.type} at ${element.position || element.x1} - State: ${element.state}`);
                }
            }
           // Update branch-wide energy flow flag specifically for CASE 4
           if (levelFlow === 1 && !specialCASE4) {
            branchAllowsEnergyFlow = true;
        }
}









        // Determine if this level (path) allows energy flow through the entire level
        if (levelFlow === 1 && (isAlignedWithEnd || currentLevel)) {
            levelAllowsEnergyFlow = true;
            // console.log(`levelAllowsEnergyFlow ${chunkIndex + 1} Level ${level} = ${levelAllowsEnergyFlow}`);
        }

        // If any level allows energy flow, set the flag for the entire chunk
        if (levelAllowsEnergyFlow) {
            anyLevelAllowsFlow = true;
            // console.log(`anyLevelAllowsFlow ${chunkIndex + 1} Level ${level} = ${anyLevelAllowsFlow}`);
        }

        // Log the result for this level
       // const levelFinalFlow = levelFlow === 1 ? "Energized, let energy flow" : "Not Energized, stop energy flow";
        // console.log(`Asymmetrical Chunk Result ${chunkIndex + 1} Level ${level} = ${levelFinalFlow}`);


        
    });
    // console.log('Special Case 1- case1 Elements:', JSON.parse(JSON.stringify(case1Elements)));

    // console.log(`branchAllowsEnergyFlow ${chunkIndex + 1} = ${branchAllowsEnergyFlow}`);
    // console.log(`Special Case 1- specialCASE2 ${chunkIndex + 1} = ${specialCASE2}`);


// After processing all levels, apply branch-wide energy flow logic only to CASE 1 vertical ends
if (branchAllowsEnergyFlow && (isCase1AlignedWithStart && isCase1AlignedWithEnd)) {
    case1Elements.forEach(element => {
        if (element.type === 'branch_vertical' && element.id.includes('end')) {
            element.state = 1; // Ensure the vertical end in CASE 1 is energized if any level allowed energy flow
            chunkLogs.push(`Special CASE 1: Final energization of vertical end at ${element.position} to state 1 due to branch-wide energy flow, level ${element.level}`);
        }
    });
} else if (!branchAllowsEnergyFlow && (isCase1AlignedWithStart && isCase1AlignedWithEnd)) {
    case1Elements.forEach(element => {
        if (element.type === 'branch_vertical' && element.id.includes('end')) {
            element.state = 0; // Ensure the vertical end in CASE 1 is de-energized if no flow in the branch
            chunkLogs.push(`Special CASE 1: Final de-energization of vertical end at ${element.position} to state 0 due to no branch-wide energy flow, level ${element.level}`);
        }
    });
} 

    // Ensure that energy flow is only passed to connected levels and not across the entire rung
    if (!anyLevelAllowsFlow) {
        // If no level allows energy flow, stop the propagation for the whole chunk
        mainRungEnergyFlow = 0;
        // console.log(`Energy flow stopped for chunk ${chunkIndex + 1} due to lack of flow in all levels.`);
    }
// Step 2: Backpropagation (Right to Left) for Each Level
if (anyLevelAllowsFlow && (isCase1AlignedWithStart && isCase1AlignedWithEnd )) {
    levels.forEach(level => {
        const elements = elementsByLevel[level];
        let levelFlow = 1; // Start by assuming energy flows from the right

        // Process elements from right to left
        for (let i = elements.length - 1; i >= 0; i--) {
            const element = elements[i];

            if (element.type === 'segment' || element.type === 'branch_horizontal') {
                if (levelFlow === 1) {
                    element.state = 1; // Propagate energy flow backward
                }
            } else if (element.type === 'contact_no' || element.type === 'contact_nc') {
                // Stop energy flow if the contact is blocking it
                if (element.state === 0) {
                    levelFlow = 0; // Block further backpropagation
                    break; // Exit the loop early as energy flow is blocked
                } else {
                    element.state = 1; // Energize the contact if it allows energy flow
                }
            }

            // Log each element's state after backpropagation
            chunkLogs.push(`Normal Backpropagation in Chunk ${chunkIndex + 1} Level ${level}: ${element.type} at ${element.position || element.x1} - State: ${element.state}`);
        }
    });
}

}






else {
  // console.log("Normal branch detected. Applying standard energy propagation logic...");

// Logic for normal branches (including single-level branches)
levels.forEach(level => {
    const elements = elementsByLevel[level];
    let levelFlow = chunkEnergyFlow; // Energy flow for this level
    let levelAllowsEnergyFlow = false; // Track if this level allows energy flow

    elements.forEach(element => {
        if (element.type === 'segment') {
            element.state = levelFlow; // Segments propagate energy flow
        } else if (element.type === 'contact_no' || element.type === 'contact_nc' || element.type === 'contact_pos' || element.type === 'contact_neg') {
            // Handle contact logic for Normally Open, Normally Closed, POS, and NEG

            // Normally Open (NO) contact logic
            if (element.type === 'contact_no') {
                levelFlow = element.state === 1 ? levelFlow : 0; // NO contact allows or blocks energy
                //element.state = levelFlow; // Set the state based on the evaluation

            // Normally Closed (NC) contact logic
            } else if (element.type === 'contact_nc') {
                levelFlow = element.state === 0 ? false : levelFlow; // NC contact blocks or allows energy
                //element.state = levelFlow; // Set the state based on the evaluation

            // One-Shot Positive (POS) contact logic
            } else if (element.type === 'contact_pos') {
                // console.log(`Evaluating contact_pos in branch for element ${element.id}`);
                levelFlow = element.state === 1 ? levelFlow : 0; // NO contact allows or blocks energy
                //element.state = levelFlow; // Set the state based on the evaluation

            // One-Shot Negative (NEG) contact logic
            } else if (element.type === 'contact_neg') {
                // console.log(`Evaluating contact_neg in branch for element ${element.id}`);
                levelFlow = element.state === 0 ? false : levelFlow; // NC contact blocks or allows energy
                //element.state = levelFlow; // Set the state based on the evaluation

            }
        } else if (element.type === 'branch_horizontal') {
            // Branch elements can also block or allow flow
            levelFlow = levelFlow === 1 && previousChunkAllowedFlow ? 1 : 0;
            element.state = levelFlow;
        } else {
            // Evaluate other element types (e.g., coil, counter, timer) using the evaluateElementState function
            levelFlow = evaluateElementState(element, levelFlow, coilStates.value, rungs, counters);
            element.state = levelFlow;
        }

        // Log each element's state
        chunkLogs.push(`Normal Chunk ${chunkIndex + 1} Level ${level}: ${element.type} at ${element.position || element.x1} - State: ${element.state}`);
    });

    // Determine if this level (path) allows energy flow through the entire level
    if (levelFlow === 1) {
        levelAllowsEnergyFlow = true;
        // console.log(`levelAllowsEnergyFlow ${chunkIndex + 1} Level ${level} = ${levelAllowsEnergyFlow}`);
    }

    // If any level allows energy flow, set the flag for the entire chunk
    if (levelAllowsEnergyFlow) {
        anyLevelAllowsFlow = true;
        // console.log(`anyLevelAllowsFlow ${chunkIndex + 1} Level ${level} = ${anyLevelAllowsFlow}`);
    }

    // Log the result for this level
  //  const levelFinalFlow = levelFlow === 1 ? "Energized, let energy flow" : "Not Energized, stop energy flow";
    // console.log(`Normal Chunk Result ${chunkIndex + 1} Level ${level} = ${levelFinalFlow}`);
});

// Step 2: Backpropagation (Right to Left) for Each Level
if (anyLevelAllowsFlow) {
    levels.forEach(level => {
        const elements = elementsByLevel[level];
        let levelFlow = 1; // Start by assuming energy flows from the right

        // Process elements from right to left
        for (let i = elements.length - 1; i >= 0; i--) {
            const element = elements[i];

            if (element.type === 'segment' || element.type === 'branch_horizontal') {
                if (levelFlow === 1) {
                    element.state = 1; // Propagate energy flow backward
                }
            } else if (element.type === 'contact_no' || element.type === 'contact_nc' || element.type === 'contact_pos' || element.type === 'contact_neg') {
                // Backpropagation logic for contact_no, contact_nc, contact_pos, and contact_neg
                if (element.type === 'contact_no') {
                    if (element.state === 0) {
                        levelFlow = 0; // Block further backpropagation for NO contact
                        break; // Exit the loop early as energy flow is blocked
                    } else {
                        //element.state = 1; // Energize the contact if it allows energy flow
                    }
                } else if (element.type === 'contact_nc') {
                    if (element.state === 0) {
                        levelFlow = 0; // Block further backpropagation for NC contact
                        break; // Exit the loop early as energy flow is blocked
                    } else {
                       // element.state = 1; // Energize the contact if it allows energy flow
                    }
                } else if (element.type === 'contact_pos') {
                    // One-Shot Positive: Energize if one-shot is triggered
                    if (element.state === 0) {
                        levelFlow = 0; // Block further backpropagation for NO contact
                        break; // Exit the loop early as energy flow is blocked
                    } else {
                        //element.state = 1; // Energize the contact if it allows energy flow
                    }
                } else if (element.type === 'contact_neg') {
                    // One-Shot Negative: Energize if one-shot is triggered
                    if (element.state === 0) {
                        levelFlow = 0; // Block further backpropagation for NC contact
                        break; // Exit the loop early as energy flow is blocked
                    } else {
                        //element.state = 1; // Energize the contact if it allows energy flow
                    }
                }
            } else {
                // Use the helper function to evaluate the element's state
                levelFlow = evaluateElementState(element, levelFlow, coilStates.value, rungs, counters);

                // If energy is blocked, stop further backpropagation
                if (levelFlow === 0) {
                    chunkLogs.push(`Backpropagation: Blocked by ${element.type} at ${element.position || element.x1} - Stopping backpropagation`);
                    break; // Exit the loop early as energy flow is blocked
                }

                chunkLogs.push(`Backpropagation: Level ${level}, Element ${element.type} at ${element.position || element.x1} - State: ${element.state}`);
            }

            // Log each element's state after backpropagation
            chunkLogs.push(`Normal Backpropagation in Chunk ${chunkIndex + 1} Level ${level}: ${element.type} at ${element.position || element.x1} - State: ${element.state}`);
        }
    });
}







                     // Energize vertical branch starts if the previous chunk allowed energy flow
                  chunk.forEach(element => {
                  if (element.type === 'branch_vertical' && element.id.includes('start')) {
                      element.state = previousChunkAllowedFlow ? 1 : 0;
                      chunkLogs.push(`Chunk ${chunkIndex + 1} Level ${element.level}: ${element.type} (start) at ${element.position || element.x1} - State: ${element.state}`);
                  }
              });

                // Energize vertical branch ends if this chunk allows energy flow
                chunk.forEach(element => {
                    if (element.type === 'branch_vertical' && element.id.includes('end')) {
                        element.state = anyLevelAllowsFlow ? 1 : 0;
                        chunkLogs.push(`Chunk ${chunkIndex + 1} Level ${element.level}: ${element.type} (end) at ${element.position || element.x1} - State: ${element.state}`);
                    }
                });
        }

        // After processing all levels, determine if any branch allows flow
        chunkAllowsEnergyFlow = anyLevelAllowsFlow;

        // Update the main rung's energy flow based on whether any branch allows flow
        mainRungEnergyFlow = chunkAllowsEnergyFlow ? 1 : 0;




    } else {
        // No branches in this chunk; process sequentially
        let levelFlow = chunkEnergyFlow; // Energy flow for the main path

        chunk.forEach(element => {
            if (element.level === 0) { // Only process main path
                if (element.type === 'segment') {
                    element.state = levelFlow; // Segments propagate energy flow
                } else {
            // Use the helper function to evaluate the element's state
            levelFlow = evaluateElementState(element, levelFlow, coilStates.value, rungs, counters);
        }

                // Log each element's state
                chunkLogs.push(`Chunk ${chunkIndex + 1} Level 0: ${element.type} at ${element.position || element.x1} - State: ${element.state}`);
            }
        });

        // Determine if the main path allows energy flow
        chunkAllowsEnergyFlow = (levelFlow === 1);

        // Log the result for the whole chunk
      //  const chunkFinalFlow = chunkAllowsEnergyFlow ? "Energized, let energy flow" : "Not Energized, stop energy flow";
        // console.log(`Chunk ${chunkIndex + 1} Level 0 = ${chunkFinalFlow}`);

        // Update the main rung's energy flow based on the main path
        mainRungEnergyFlow = chunkAllowsEnergyFlow ? 1 : 0;

 
    }



    // Output the logs for this chunk
    // chunkLogs.forEach(log =>  // console.log(log));

    // Set the flag to track if the current chunk allowed energy flow
    previousChunkAllowedFlow = chunkAllowsEnergyFlow;

    // Even if energy is blocked, continue processing the rest of the chunks and de-energize them
    if (!chunkAllowsEnergyFlow) {
        mainRungEnergyFlow = 0; // Explicitly stop energy flow
    }
}







    // Step 8: Update the actual rung segments with the calculated states
    rung.segments.forEach((segment) => {
      const matrixElement = rungMatrix.find(el => el.id === segment.id);
      if (matrixElement) {
        segment.state = matrixElement.state;
        // console.log(`CES 8- Segment ${segment.id} updated with state: ${segment.state}`);
      }
    });

     // Step 9: Sync coil_set and coil_reset states after evaluating all elements
  Object.keys(coilStates.value).forEach(id => {
    const coil = rungs.value.flatMap(rung => rung.elements).find(el => el.id === id);
    if (coil) {
      coil.state = coilStates.value[id] ? 1 : 0;

      // Ensure the linked coil has the opposite state
      const linkedCoil = rungs.value.flatMap(rung => rung.elements).find(el => el.id === coil.linkedId);
      if (linkedCoil) {
        linkedCoil.state = coilStates.value[id] ? 0 : 1;
        // console.log(`Linked coil ${linkedCoil.id} updated. State: ${linkedCoil.state}`);
      }
    }
  });


  // Update the UI or state based on energy flow
  rungMatrix.forEach((element) => { // rungMatrix.forEach((element, index) => {
    // console.log(`Element ${index} (${element.id || element.type}) final state: ${element.state}`);

    // Find the corresponding variable in the 'variables' array
    const variable = variables.value.find(v => v.id === element.id);

    if (variable) {
      // Update the 'value' property based on the 'state' of the element
      variable.value = element.state === 1 ? true : false;

      // Log the updated variable for debugging
      // console.log(`calculateEnergizedStates - Updated variable ID: ${variable.id}, new value: ${variable.value}`);
    }
  });

    
  });
}



function splitRungMatrix(rungMatrix, branchStructures) {
  const chunks = [];
  const branchPositions = []; // Array to store branch start and end positions for level 1

  // Step 1: Identify vertical branch start and end positions only for level 1 branches
  branchStructures.forEach((branch) => {
    if (branch.level === 1) {
      const branchStartPos = branch.start.position;
      const branchEndPos = branch.end.position;

      // Check if there is already an entry with the same start and end positions
      const existingBranch = branchPositions.find(pos => pos.x1 === branchStartPos && pos.x2 === branchEndPos);

      if (existingBranch) {
        // If an entry exists, just add the branch level
        existingBranch.levels.push(branch.level);
      } else {
        // Otherwise, create a new entry
        branchPositions.push({ x1: branchStartPos, x2: branchEndPos, levels: [branch.level] });
      }

      // console.log(`Level 1 Branch found: Start Position ${branchStartPos}, End Position ${branchEndPos}, Level ${branch.level}`);
    }
  });

  // console.log("Branch Positions for Level 1:", JSON.stringify(branchPositions));

  // Step 2: Calculate the chunks based on level 1 branch positions
  let currentPos = 0;

  branchPositions.forEach((branch, index) => {
    const previousEndPos = index > 0 ? branchPositions[index - 1].x2 : 0;

    // Create a chunk before the branch if needed
    if (previousEndPos !== branch.x1) {
      chunks.push({ x1: previousEndPos, x2: branch.x1 });
      // console.log(`Chunk ${chunks.length + 1} created from position ${previousEndPos} to ${branch.x1}`);
    }

    // Create a chunk for the branch, including all levels
    chunks.push({ x1: branch.x1, x2: branch.x2, levels: branch.levels });
    // console.log(`Chunk ${chunks.length + 1} created from position ${branch.x1} to ${branch.x2}`);
    
    currentPos = branch.x2;
  });

  // Add the final chunk from the last branch end to the end of the rung
  const lastPosition = rungMatrix[rungMatrix.length - 1].position || rungMatrix[rungMatrix.length - 1].x2;
  if (currentPos < lastPosition) {
    chunks.push({ x1: currentPos, x2: lastPosition });
    // console.log(`Final chunk created from position ${currentPos} to ${lastPosition}`);
  }

  // console.log("Chunks Information:", JSON.stringify(chunks));

  // Step 3: Populate the chunks with elements
  const populatedChunks = chunks.map((chunk) => { //const populatedChunks = chunks.map((chunk, index) => {
    const elementsInChunk = rungMatrix.filter((element) => {
      const elementPos = element.position !== undefined ? element.position : element.x1;

      // console.log(`CESX23 - Checking element ${element.id} with position ${elementPos}`);

      // Only include elements whose x1 is within the chunk's boundaries
      if ((elementPos !== undefined && elementPos >= chunk.x1 && elementPos < chunk.x2) && element.type !== 'branch_vertical') {
        // console.log(`CESX23 - Including element ${element.id} with position ${elementPos}`);
        return true;
      }

      // Ensure vertical start branches are correctly placed
      if (element.type === 'branch_vertical' && elementPos === chunk.x1 && element.id.startsWith('branch-vertical-start')) {
        // console.log(`CESX23 - Including vertical start branch element ${element.id} with position ${elementPos}`);
        return true;
      }

      // Ensure vertical start branches within an asymmetrical branch are correctly placed within the chunk
      if (element.type === 'branch_vertical' && elementPos > chunk.x1 && elementPos < chunk.x2 && element.id.startsWith('branch-vertical-start')) {
        // console.log(`CESX23 - Including vertical start branch element ${element.id} within asymmetrical branch chunk`);
        return true;
      }

      // Ensure vertical end branches are correctly placed within the chunk
      if (element.type === 'branch_vertical' && elementPos === chunk.x2 && element.id.startsWith('branch-vertical-end')) {
        // console.log(`CESX23 - Including vertical end branch element ${element.id} with position ${elementPos}`);
        return true;
      }

      // Ensure vertical end branches within an asymmetrical branch are correctly placed within the chunk
      if (element.type === 'branch_vertical' && elementPos > chunk.x1 && elementPos <= chunk.x2 && element.id.startsWith('branch-vertical-end')) {
        // console.log(`CESX23 - Including vertical end branch element ${element.id} within asymmetrical branch chunk`);
        return true;
      }

      // Exclude elements that belong to the previous chunk if they match chunk.x2 but are not vertical branch ends
      if (elementPos === chunk.x2 && !element.id.startsWith('branch-vertical-end')) {
        return false;
      }

      return false;
    });

    // console.log(`Chunk ${index + 1}:`, JSON.stringify(elementsInChunk, null, 2));
    return elementsInChunk;
  });

  return populatedChunks;
}



function evaluateElementState(element, energized, coilStates, rungs, counters) {
  if (element.type === 'contact_no') {
    // Normally Open Contact
    energized = element.state === 1 && energized;
  } else if (element.type === 'contact_nc') {
    // Normally Closed Contact
    energized = element.state === 0 ? false : energized; // Allow energy if state is 1, block if 0
  } else if (element.type === 'contact_pos') {
    // console.log(`Chunk contact pos is being analyzed`);

    // One-Shot Positive Contact
    energized = element.state === 1 && energized;
  } else if (element.type === 'contact_neg') {
    // One-Shot Positive Contact
    energized = element.state === 0 ? false : energized; // Allow energy if state is 1, block if 0

  } else if (element.type === 'coil') {
  // Handle regular coil logic
  element.state = energized ? 1 : 0;

  if (element.linkedContacts && element.linkedContacts.length > 0) {
    // Loop through each linked contact
    element.linkedContacts.forEach(linkedContactId => {
      const linkedContact = findElementById(linkedContactId);
      if (linkedContact) {
        // Toggle the linked contact's state based on its type
        if (linkedContact.type === 'contact_no') {
          linkedContact.state = energized ? 1 : 0; // Energize or de-energize the NO contact
        } else if (linkedContact.type === 'contact_nc') {
          linkedContact.state = energized ? 0 : 1; // De-energize or energize the NC contact
        } else if (linkedContact.type === 'contact_pos') {
          if (energized && linkedContact.state === 0 && !linkedContact.hasToggled) {
            linkedContact.state = 1; // Activate POS contact
            linkedContact.hasToggled = true;

            setTimeout(() => {
              linkedContact.state = 0; // Reset after one-shot behavior
              calculateEnergizedStates(); // Recalculate states after reset
            }, 100); 
          } else if (!energized) {
            linkedContact.state = 0; 
            linkedContact.hasToggled = false;
          }
        } else if (linkedContact.type === 'contact_neg') {
          if (energized && linkedContact.state === 1 && !linkedContact.hasToggled) {
            linkedContact.state = 0; // Deactivate NEG contact
            linkedContact.hasToggled = true;

            setTimeout(() => {
              linkedContact.state = 1; // Reset after one-shot behavior
              calculateEnergizedStates(); // Recalculate states after reset
            }, 100); 
          } else if (!energized) {
            linkedContact.state = 1; 
            linkedContact.hasToggled = false;
          }
        }
      }
    });
  }
}

  else if (element.type === 'r_coil') {
  // Handle r_coil reset logic
  element.state = energized ? 1 : 0;
  // console.log(`UUU- r_coil: ${element.state}`);

  if (element.linkedId) {
    const linkedElement = findElementById(element.linkedId);
    if (linkedElement) {
      // Reset logic for linked elements
      if (linkedElement.type === 'timer_tonr' && element.state === 1) {
        linkedElement.elapsedTime = 0; // Reset the linked TONR timer's elapsed time
      } else if (linkedElement.type === 'counter_ctu') {
        // CTU Reset logic: set counter to 0 and reset state
        if (element.state === 1) {
          linkedElement.currentValue = 0; // Reset current value to 0
          linkedElement.state = 0; // Reset the counter state

          if (linkedElement.state === 2) { // If previously fully counted
            const linkedContact = findElementById(linkedElement.linkedId);
            if (linkedContact) {
              linkedContact.state = linkedContact.state === 1 ? 0 : 1; // Toggle the linked contact
            }
          }
        }
      } else if (linkedElement.type === 'counter_ctd') {
        // CTD Reset logic: reset counter to preset value
        if (element.state === 1) {
          linkedElement.currentValue = linkedElement.presetValue; // Reset to preset value
          linkedElement.state = 0; // Reset the counter state

          if (linkedElement.state === 2) { // If previously fully counted
            const linkedContact = findElementById(linkedElement.linkedId);
            if (linkedContact) {
              linkedContact.state = linkedContact.state === 1 ? 0 : 1; // Toggle the linked contact
            }
          }
        }
      }
    }
  }
}
else if (element.type === 'coil_set') {
  // console.log(`Coil set is being evaluated`);

  if (energized) {
    // Energize coil_set and latch it in state 1
    element.state = 1;

    // Find linked coil_reset and deactivate it if coil_set is energized
    const linkedResetCoil = findElementById(element.linkedId);
    if (linkedResetCoil && linkedResetCoil.state === 1) {
      // Coil_reset is active, deactivate coil_set
      element.state = 0; // Deactivate coil_set
      // console.log(`Coil set ${element.id} deactivated because coil reset ${linkedResetCoil.id} is prioritized.`);
    } else {
      // Toggle the linked contacts only if coil_set is energized and not deactivated by coil_reset
      if (element.linkedContacts && element.linkedContacts.length > 0) {
        element.linkedContacts.forEach(linkedContactId => {
          const linkedContact = findElementById(linkedContactId);
          if (linkedContact) {
            // Toggle the linked contact's state based on its type
            if (linkedContact.type === 'contact_no') {
              linkedContact.state = 1; // Energize NO contact
            } else if (linkedContact.type === 'contact_nc') {
              linkedContact.state = 0; // De-energize NC contact
            } else if (linkedContact.type === 'contact_pos') {
              if (!linkedContact.hasToggled) {
                linkedContact.state = 1; // Activate POS contact (one-shot)
                linkedContact.hasToggled = true;

                setTimeout(() => {
                  linkedContact.state = 0; // Reset after one-shot
                  calculateEnergizedStates();
                }, 100);
              }
            } else if (linkedContact.type === 'contact_neg') {
              if (!linkedContact.hasToggled) {
                linkedContact.state = 0; // Deactivate NEG contact (one-shot)
                linkedContact.hasToggled = true;

                setTimeout(() => {
                  linkedContact.state = 1; // Reset after one-shot
                  calculateEnergizedStates();
                }, 100);
              }
            }
            // console.log(`Linked contact ${linkedContact.id} toggled by coil_set ${element.id} - New state: ${linkedContact.state}`);
          }
        });
      }
    }
  } else {
    // Coil_set remains latched in state 1 even when de-energized (latching)
    if (element.state === 1) {
      // console.log(`Coil set ${element.id} remains latched in state 1 even though it is de-energized.`);
    } else {
      element.state = 0; // Ensure coil_set is deactivated when not latched
      // console.log(`Coil set ${element.id} is deactivated due to lack of energy.`);
    }
  }
}


else if (element.type === 'coil_reset') {
  // console.log(`Coil reset is being evaluated`);

  if (energized && element.state !== 1) {
    element.state = 1; // Energize coil_reset

    // Find linked coil_set and deactivate it if coil_reset is energized
    const linkedSetCoil = findElementById(element.linkedId);
    if (linkedSetCoil && linkedSetCoil.state === 1) {
      linkedSetCoil.state = 0; // Deactivate coil_set when coil_reset is active
      // console.log(`Coil set ${linkedSetCoil.id} deactivated because coil reset ${element.id} is active.`);

      // Toggle linked contacts only once when coil_reset deactivates coil_set
      if (linkedSetCoil.linkedContacts && linkedSetCoil.linkedContacts.length > 0) {
        linkedSetCoil.linkedContacts.forEach(linkedContactId => {
          const linkedContact = findElementById(linkedContactId);
          if (linkedContact) {
            // Toggle linked contact's state once when coil_reset deactivates coil_set
            if (linkedContact.type === 'contact_no' || linkedContact.type === 'contact_nc') {
              linkedContact.state = linkedContact.state === 1 ? 0 : 1; // Toggle state
            } else if (linkedContact.type === 'contact_pos' && !linkedContact.hasToggled) {
              linkedContact.state = 1; // Activate POS contact
              linkedContact.hasToggled = true;

              setTimeout(() => {
                linkedContact.state = 0; // Reset after one-shot
                calculateEnergizedStates();
              }, 100);
            } else if (linkedContact.type === 'contact_neg' && !linkedContact.hasToggled) {
              linkedContact.state = 0; // Deactivate NEG contact
              linkedContact.hasToggled = true;

              setTimeout(() => {
                linkedContact.state = 1; // Reset after one-shot
                calculateEnergizedStates();
              }, 100);
            }
            // console.log(`Linked contact ${linkedContact.id} toggled by coil_reset ${element.id} - New state: ${linkedContact.state}`);
          }
        });
      }
    }
  } else if (!energized) {
    // Coil_reset is de-energized, so deactivate it
    element.state = 0;
    // console.log(`Coil reset ${element.id} is deactivated due to lack of energy.`);
  }
}




 else if (element.type.includes('timer')) {
    handleTimer(element, energized);
    energized = element.state === 1 && energized; // Only allow energy if timer state is 1 (preset reached)

  } else if (element.type === 'counter_ctu' || element.type === 'counter_ctd') {
    handleCounter(element, energized, rungs, counters);
    energized = element.state === 2 && energized; // Only allow energy if timer state is 1 (preset reached)

  }
  return energized;
}




function handleTimer(element, energized) {

  if (element.type === 'timer_ton') {
  // TON (Timer On Delay) Logic
  if (energized) {
    if (element.state === 0) {
      // Start counting if energized
      element.state = 3; // State 3 indicates counting
    }

    if (element.state === 3 && element.elapsedTime >= element.presetTime) {
      // Preset time is reached
      if (element.linkedContacts && element.linkedContacts.length > 0) {
        element.linkedContacts.forEach(linkedContactId => {
          const linkedContact = findElementById(linkedContactId);
          if (linkedContact) {
            // Handle contact behavior based on type
            if (linkedContact.type === 'contact_no') {
              // Normally Open contact closes when timer reaches preset
              linkedContact.state = 1;
            } else if (linkedContact.type === 'contact_nc') {
              // Normally Closed contact opens when timer reaches preset
              linkedContact.state = 0;
            } else if (linkedContact.type === 'contact_pos') {
              // One-Shot Positive: Activate contact once when preset is reached
              if (!linkedContact.hasToggled) {
                linkedContact.state = 1;
                linkedContact.hasToggled = true;

                // Reset after a short delay to simulate one-shot behavior
                setTimeout(() => {
                  linkedContact.state = 0; // Reset after one-shot
                  calculateEnergizedStates();
                }, 100);
              }
            } else if (linkedContact.type === 'contact_neg') {
              // One-Shot Negative: Deactivate contact once when preset is reached
              if (!linkedContact.hasToggled) {
                linkedContact.state = 0;
                linkedContact.hasToggled = true;

                // Reset after a short delay to simulate one-shot behavior
                setTimeout(() => {
                  linkedContact.state = 1; // Reset after one-shot
                  calculateEnergizedStates();
                }, 100);
              }
            }
          }
        });
      }
      element.state = 1; // State 1 indicates preset time reached
      element.elapsedTime = element.presetTime; // Cap elapsed time at preset
    }
  } else {
    // De-energized
    if (element.state !== 0) {
      if (element.state === 1) {
        // Timer had reached preset; reset linked contacts
        if (element.linkedContacts && element.linkedContacts.length > 0) {
          element.linkedContacts.forEach(linkedContactId => {
            const linkedContact = findElementById(linkedContactId);
            if (linkedContact) {
              // Reset the state of linked contacts based on their type
              if (linkedContact.type === 'contact_no') {
                // Normally Open contact opens when timer resets
                linkedContact.state = 0;
              } else if (linkedContact.type === 'contact_nc') {
                // Normally Closed contact closes when timer resets
                linkedContact.state = 1;
              } else if (linkedContact.type === 'contact_pos' || linkedContact.type === 'contact_neg') {
                // Reset one-shot contacts
                linkedContact.hasToggled = false;
              }
            }
          });
        }
      }
      // Reset the timer
      element.state = 0;
      element.elapsedTime = 0;
    }
  }
}



  else if (element.type === 'timer_tof') {
  // TOF (Timer Off Delay) Logic
  if (energized) {
    if (element.state === 0) {
      // When TOF is first energized, toggle all linked contacts
      element.state = 1;
      if (element.linkedContacts && element.linkedContacts.length > 0) {
        element.linkedContacts.forEach(linkedContactId => {
          const linkedContact = findElementById(linkedContactId);
          if (linkedContact) {
            // Toggle linked NO, NC, POS, NEG contacts
            if (linkedContact.type === 'contact_no' || linkedContact.type === 'contact_nc') {
              linkedContact.state = linkedContact.state === 1 ? 0 : 1; // Toggle state
            } else if (linkedContact.type === 'contact_pos' && !linkedContact.hasToggled) {
              // One-shot POS contact
              linkedContact.state = linkedContact.state === 1 ? 0 : 1; // Toggle state
              linkedContact.hasToggled = true;
              setTimeout(() => {
                linkedContact.state = 0;
                calculateEnergizedStates();
              }, 100);
            } else if (linkedContact.type === 'contact_neg' && !linkedContact.hasToggled) {
              // One-shot NEG contact
              linkedContact.state = linkedContact.state === 1 ? 0 : 1; // Toggle state
              linkedContact.hasToggled = true;
              setTimeout(() => {
                linkedContact.state = 1;
                calculateEnergizedStates();
              }, 100);
            }
          }
        });
      }
    } else if (element.state === 2) {
      // If re-energized before preset time, reset the timer
      element.state = 1;
      element.elapsedTime = 0;
    }
  } else {
    if (element.state === 1) {
      // When TOF is de-energized, start counting
      element.state = 2; // Mark the TOF as timing
    } else if (element.state === 2 && element.elapsedTime >= element.presetTime) {
      // When preset time is reached, toggle all linked contacts back
      if (element.linkedContacts && element.linkedContacts.length > 0) {
        element.linkedContacts.forEach(linkedContactId => {
          const linkedContact = findElementById(linkedContactId);
          if (linkedContact) {
              // Reset the state of linked contacts based on their type
              if (linkedContact.type === 'contact_no') {
                // Normally Open contact opens when timer resets
                linkedContact.state = 0;
              } else if (linkedContact.type === 'contact_nc') {
                // Normally Closed contact closes when timer resets
                linkedContact.state = 1;
              } else if (linkedContact.type === 'contact_pos' || linkedContact.type === 'contact_neg') {
                // Reset one-shot contacts
                linkedContact.hasToggled = false;
              }
            }
        });
      }
      element.state = 0; // Reset the TOF timer state
      element.elapsedTime = 0; // Reset elapsed time
    }
  }
}


else if (element.type === 'timer_tonr') {
  // TONR (Timer On Retentive) Logic
  if (energized) {
    // Start counting if energized
    if (element.state === 0) {
      element.state = 3;
      // console.log(`TONR ${element.id} started counting.`);
    }

    // Check if the preset time is reached
    if (element.elapsedTime >= element.presetTime && element.state === 3) {
      // When preset time is reached, handle multiple linked contacts
      if (element.linkedContacts && element.linkedContacts.length > 0) {
        element.linkedContacts.forEach(linkedContactId => {
          const linkedContact = findElementById(linkedContactId);
          if (linkedContact) {
            if (linkedContact.type === 'contact_no' || linkedContact.type === 'contact_nc') {
              linkedContact.state = linkedContact.state === 1 ? 0 : 1; // Toggle state
              // console.log(`TONR ${element.id} reached preset. Contact ${linkedContact.id} toggled.`);
            } else if (linkedContact.type === 'contact_pos') {
              // One-Shot Positive: Turn ON only once when preset is reached
              if (linkedContact.state === 0 && !linkedContact.hasToggled) {
                linkedContact.state = 1; // Activate POS contact
                linkedContact.hasToggled = true;
                // console.log(`TONR ${element.id} reached preset. POS contact ${linkedContact.id} is ON (One-shot).`);
                setTimeout(() => {
                  linkedContact.state = 0; // Reset after one-shot
                  calculateEnergizedStates();
                  // console.log(`Reset POS contact ${linkedContact.id} to state: ${linkedContact.state} (One-shot complete)`);
                }, 100);
              }
            } else if (linkedContact.type === 'contact_neg') {
              // One-Shot Negative: Turn OFF only once when preset is reached
              if (linkedContact.state === 1 && !linkedContact.hasToggled) {
                linkedContact.state = 0; // Deactivate NEG contact
                linkedContact.hasToggled = true;
                // console.log(`TONR ${element.id} reached preset. NEG contact ${linkedContact.id} is OFF (One-shot).`);
                setTimeout(() => {
                  linkedContact.state = 1; // Reset after one-shot
                  calculateEnergizedStates();
                  // console.log(`Reset NEG contact ${linkedContact.id} to state: ${linkedContact.state} (One-shot complete)`);
                }, 100);
              }
            }
          }
        });
      }
      element.state = 2; // Mark the timer as having reached the preset
      element.elapsedTime = element.presetTime; // Ensure the elapsed time stays at preset
      element.state = 1;
    }
  } else {
    // TONR retains its elapsed time, so do not reset when de-energized
    if (element.state !== 0) {
      element.state = 0; // Reset the TONR state when de-energized
      // console.log(`TONR ${element.id} de-energized.`);
      if (element.elapsedTime >= element.presetTime) {
        if (element.linkedContacts && element.linkedContacts.length > 0) {
          element.linkedContacts.forEach(linkedContactId => {
            const linkedContact = findElementById(linkedContactId);
            if (linkedContact) {
              // Reset the state of linked contacts based on their type
              if (linkedContact.type === 'contact_no') {
                // Normally Open contact opens when timer resets
                linkedContact.state = 0;
              } else if (linkedContact.type === 'contact_nc') {
                // Normally Closed contact closes when timer resets
                linkedContact.state = 1;
              } else if (linkedContact.type === 'contact_pos' || linkedContact.type === 'contact_neg') {
                // Reset one-shot contacts
                linkedContact.hasToggled = false;
              }
            }
          });
        }
      }
    }
  }
}



}



function handleCounter(element, energized, rungs, counters) {
  const counter = counters[element.type].find(c => c.id === element.id);

  if (counter) {
    //console.log(`Evaluating counter ${element.id} (type: ${element.type}) - Current value: ${counter.currentValue}, State: ${counter.state}`);

    // Ensure isReset and reachedPreset properties exist on the counter
    if (counter.isReset === undefined) {
      counter.isReset = false; // Initialize the reset flag
    }
    if (counter.reachedPreset === undefined) {
      counter.reachedPreset = false; // Initialize the preset flag
    }

    // Convert currentValue and presetValue to numbers for comparison
    const currentValue = Number(counter.currentValue);
    const presetValue = Number(counter.presetValue);

    // Update counter state based on energization
    if (energized && counter.state !== 2) {
      if (counter.state !== 1) {
        counter.state = 1;
        //console.log(`Counter ${element.id} is energized - State: ${counter.state}`);
      }
    } else if (!energized) {
      if (counter.state !== 2) {
        counter.state = 0;
       // console.log(`Counter ${element.id} is de-energized - State: ${counter.state}`);
      }
    }

    // Handle reset logic with reset coil
    const linkedResetCoil = rungs.value
      .flatMap(rung => rung.elements || []) // Ensure rung.elements is an array
      .find(el => el.id === counter.linkedId && el.type === 'r_coil' && el.state === 1);

    if (linkedResetCoil) {
      // Only toggle linked contact if the counter reached its preset (CTU) or 0 (CTD)
      if (counter.reachedPreset) {
        // Handle multiple linked contacts
              // Toggle linked contacts based on their types
              if (counter.linkedContacts && counter.linkedContacts.length > 0) {
            counter.linkedContacts.forEach(linkedContactId => {
              const linkedContact = findElementById(linkedContactId);
              if (linkedContact) {
              // Reset the state of linked contacts based on their type
              if (linkedContact.type === 'contact_no') {
                // Normally Open contact opens when timer resets
                linkedContact.state = 0;
              } else if (linkedContact.type === 'contact_nc') {
                // Normally Closed contact closes when timer resets
                linkedContact.state = 1;
              } else if (linkedContact.type === 'contact_pos' || linkedContact.type === 'contact_neg') {
                // Reset one-shot contacts
                linkedContact.hasToggled = false;
              }
            }
            });
      }
    }


      // Reset the counter
      counter.state = 0; // Reset the counter state
      counter.currentValue = element.type === 'counter_ctd' ? presetValue : 0; // Reset CTD to preset or CTU to 0
      counter.isReset = true; // Flag to indicate the counter has been reset
      counter.reachedPreset = false; // Reset the reachedPreset flag

      //console.log(`Counter ${element.id} reset by coil ${linkedResetCoil.id}`);
    } else if (counter.isReset && !energized) {
      // Clear the reset flag when reset coil is de-energized
      counter.isReset = false;
     // console.log(`Counter ${element.id} is no longer reset after coil de-energization.`);
    }

    // Handle counting when energized and not reset
    if (energized && !counter.isReset && counter.previousState !== 1) {
      if (element.type === 'counter_ctu') {
        // Count Up (CTU)
        counter.currentValue = Math.min(currentValue + 1, presetValue);
       // console.log(`Counter CTU ${element.id} incremented to ${counter.currentValue}`);

        // Check if CTU has reached its preset value
        if (counter.currentValue === presetValue && !counter.reachedPreset) {
          counter.state = 2; // Counter reached its preset
          counter.reachedPreset = true; // Flag that counter reached preset
        //  console.log(`Counter CTU ${element.id} reached its preset value of ${presetValue}.`);

          if (counter.linkedContacts && counter.linkedContacts.length > 0) {
            counter.linkedContacts.forEach(linkedContactId => {
              const linkedContact = findElementById(linkedContactId);
              if (linkedContact) {
                if (linkedContact.type === 'contact_no' || linkedContact.type === 'contact_nc') {
                  linkedContact.state = linkedContact.state === 1 ? 0 : 1; // Toggle state
                } else if (linkedContact.type === 'contact_pos') {
                  if (!linkedContact.hasToggled) {
                    linkedContact.state = 1; // Activate POS contact
                    linkedContact.hasToggled = true;
                    setTimeout(() => {
                      linkedContact.state = 0; // Reset after one-shot
                      calculateEnergizedStates();
                    }, 100);
                  }
                } else if (linkedContact.type === 'contact_neg') {
                  if (!linkedContact.hasToggled) {
                    linkedContact.state = 0; // Deactivate NEG contact
                    linkedContact.hasToggled = true;
                    setTimeout(() => {
                      linkedContact.state = 1; // Reset after one-shot
                      calculateEnergizedStates();
                    }, 100);
                  }
                }
              }
            });
      }
    }

      } else if (element.type === 'counter_ctd') {
        // Count Down (CTD)
        counter.currentValue = Math.max(currentValue - 1, 0); // Count down to 0
        //console.log(`Counter CTD ${element.id} decremented to ${counter.currentValue}`);

        // Check if CTD has reached 0
        if (counter.currentValue === 0 && !counter.reachedPreset) {
          counter.state = 2; // Counter reached 0
          counter.reachedPreset = true; // Flag that counter reached 0
        //  console.log(`Counter CTD ${element.id} reached 0.`);


        if (counter.linkedContacts && counter.linkedContacts.length > 0) {
            counter.linkedContacts.forEach(linkedContactId => {
              const linkedContact = findElementById(linkedContactId);
              if (linkedContact) {
                if (linkedContact.type === 'contact_no' || linkedContact.type === 'contact_nc') {
                  linkedContact.state = linkedContact.state === 1 ? 0 : 1; // Toggle state
                } else if (linkedContact.type === 'contact_pos') {
                  if (!linkedContact.hasToggled) {
                    linkedContact.state = 1; // Activate POS contact
                    linkedContact.hasToggled = true;
                    setTimeout(() => {
                      linkedContact.state = 0; // Reset after one-shot
                      calculateEnergizedStates();
                    }, 100);
                  }
                } else if (linkedContact.type === 'contact_neg') {
                  if (!linkedContact.hasToggled) {
                    linkedContact.state = 0; // Deactivate NEG contact
                    linkedContact.hasToggled = true;
                    setTimeout(() => {
                      linkedContact.state = 1; // Reset after one-shot
                      calculateEnergizedStates();
                    }, 100);
                  }
                }
              }
            });
      }
        }
      }
    }

    // Update the previous state of the counter to track transitions
    counter.previousState = energized ? 1 : 0;

    // Ensure the element reflects the current state and value of the counter
    element.state = counter.state;
    element.currentValue = counter.currentValue;
    element.previousState = counter.previousState; // Update the previous state if necessary
   // console.log(`Counter ${element.id} - Final current value: ${counter.currentValue}, State: ${counter.state}`);
  } 
}





//-------------------------------This part is related to cyberphysical connectivity------------------------------------


function createVariableEntry(elementId, elementName, initialState) {
  const prefix = elementId.replace(/[0-9]/g, '');
  const variableClass = getVariableClass(prefix);
  const variableType = ['NO', 'NC', 'P', 'N', 'C', 'SC', 'RC', 'R_C'].includes(prefix) ? 'Bool' : 'Int';
  const initialValue = initialState === 1 ? true : false; // Reflect initial state (as true or false)

  return {
    id: elementId,
    name: elementName,
    class: variableClass,
    type: variableType,
    value: initialValue,
  };
}
const isHeaderVisible = computed(() => props.showHeader);
const isLeftColumnVisible = computed(() => props.showLeftColumn);// Load the diagram and potentially start simulation on mount if needed



    // Watch the Vuex state for updates on diagram ID or run mode
    watch(
      () => store.getters.getLadderLogic,
      (newVal) => {
        if (newVal?.diagramId && newVal.diagramId !== currentDiagramId.value) {
          selectedDiagramId.value = newVal.diagramId;
          openSelectedDiagram(); // Open the diagram if there's a new selected one
        }

      },
      { deep: true }
    );

    onMounted(() => {
  if (selectedDiagramId.value) {
    openSelectedDiagram();
  }

  // Re-establish links between Ladder Logic and VR tags
  const linkedElements = store.getters.getIIoTDataSources.vrTagsTable.filter(tag => tag.linkId);
  
  linkedElements.forEach((vrTag) => {
    const linkedVariable = store.getters.getIIoTDataSources.variablesTable.find(variable => variable.id === vrTag.linkId);
    
    if (linkedVariable) {
      //console.log(`LLBuilder - Re-establishing link for VR Tag: ${vrTag.name}, linked to Variable: ${linkedVariable.name}`);
      
      // Ensure correct handling of both inputs (contacts) and outputs (coils, timers, etc.)
      if (vrTag.direction === 'input') {
        store.dispatch("toggleLadderLogicContact", {
          elementId: linkedVariable.id,
          newState: vrTag.value, // Use the value of the VR Tag to toggle the input contact
        });
      } 
    } //else {
      //console.log(`LLBuilder - No linked variable found for linkId: ${vrTag.linkId}`);
   // }
  });
});


watch(
  () => store.state.toggledContacts,
  (toggledContacts) => {
   // console.log("LLBuilder - Watcher triggered for toggledContacts:", toggledContacts);
    if (toggledContacts && toggledContacts.length > 0) {
      toggledContacts.forEach((toggledContact) => {
        const { elementId, newState } = toggledContact;

       // console.log(`LLBuilder - Processing toggledContact: Element ID: ${elementId}, New State: ${newState}`);

        // Find the element in the diagram to be toggled
        const element = rungs.value
          .flatMap(rung => rung.elements)
          .find(el => el.id === elementId);

        if (element) {
         // console.log(`LLBuilder - Element found:`, element);
          toggleContact(element, newState, false);
        } //else {
         // console.log(`LLBuilder - Element not found for ID: ${elementId}`);
       // }
      });

      store.commit('resetToggledContacts'); // Reset after processing
   //   console.log("LLBuilder - Toggled contacts processed and state reset.");
    } //else {
   //   console.log("LLBuilder - No toggled contacts detected.");
   // }
  },
  { deep: true }
);


function toggleContact(element, newState = null, isUserInitiated = false) {
  // Convert newState to boolean if provided
  const stateValue = newState === true || newState === 'true' || newState === 1;

  if (isSimulating.value || isUserInitiated) {
    if (newState === null && isUserInitiated) {
      // User-initiated toggle
      if (element.type === 'contact_no' || element.type === 'contact_nc') {
        element.state = element.state === 0 ? 1 : 0; // Toggle between 0 and 1
        element.prevInputState = element.state; // Update prevInputState for edge detection
      } else if (element.type === 'contact_pos') {
        // For one-shot positive contact, trigger the one-shot behavior
        if (!element.isUserTriggered) {
          element.isUserTriggered = true; // Prevent re-triggering until reset
          element.state = 1; // Activate the one-shot
          setTimeout(() => {
            element.state = 0; // Reset to 0 after one-shot
            element.isUserTriggered = false; // Allow triggering again
            calculateEnergizedStates();
          }, 100);
        }
      } else if (element.type === 'contact_neg') {
        // For one-shot negative contact, trigger the one-shot behavior
        if (!element.isUserTriggered) {
          element.isUserTriggered = true; // Prevent re-triggering until reset
          element.state = 0; // Deactivate the one-shot
          setTimeout(() => {
            element.state = 1; // Reset to 1 after one-shot
            element.isUserTriggered = false; // Allow triggering again
            calculateEnergizedStates();
          }, 100);
        }
      }
    } else {
      // Programmatic toggling based on newState
      // Initialize prevInputState if undefined
      if (element.prevInputState === undefined) {
        element.prevInputState = !stateValue; // Initialize to the opposite of current state
      }

      // Handle different contact types
      if (element.type === 'contact_no') {
        element.state = stateValue ? 1 : 0;
      } else if (element.type === 'contact_nc') {
        element.state = stateValue ? 0 : 1; // NC contacts invert the state
      } else if (element.type === 'contact_pos') {
        // One-shot positive edge detection
        if (stateValue && !element.prevInputState) {
          // Rising edge detected
          element.state = 1;
          setTimeout(() => {
            element.state = 0;
            calculateEnergizedStates();
          }, 100); // Duration of the positive edge
        } else {
          element.state = 0;
        }
      } else if (element.type === 'contact_neg') {
        // One-shot negative edge detection on rising edge
        if (stateValue && !element.prevInputState && !element.hasTriggeredNegativeEdge) {
          // Rising edge detected
          element.state = 0; // Temporarily set to 0 for the one-shot behavior

          setTimeout(() => {
            element.state = 1; // Reset back to 1 after the negative pulse
            calculateEnergizedStates();
          }, 100); // Duration of the negative pulse

          // Set a flag to prevent re-triggering until the state goes low again
          element.hasTriggeredNegativeEdge = true;
        } else if (!stateValue) {
          // Reset the flag when the input goes back to false
          element.hasTriggeredNegativeEdge = false;
          element.state = 1; // Ensure contact is at rest state when input is low
        } else {
          // Maintain state as 1 when no edge is detected or input is high
          element.state = 1;
        }
      }

      // Update prevInputState for one-shot detection
      element.prevInputState = stateValue;
    }

    // Ensure reactivity update and recalculate states
    rungs.value = [...rungs.value];
    calculateEnergizedStates();
  }
}






// Watch rungs value instead of variables.value in LLBuilder.vue
watch(
  () => rungs.value,  // Watching the rungs value instead of variables
  (newRungs) => {
    //console.log("LLBuilder - Watching for changes in rungs...");
    //console.log("New Rungs:", JSON.parse(JSON.stringify(newRungs)));

    // Find the VR tags that are linked as outputs
    const outputVrTags = store.getters.getIIoTDataSources.vrTagsTable.filter(
      (tag) => tag.direction === 'output'
    );

    // Iterate over each rung
    newRungs.forEach((rung) => {
      // Iterate over each element in the rung
      rung.elements.forEach((element) => {
        // Find the corresponding VR tag linked to this element
        const vrTag = outputVrTags.find(tag => tag.linkId === element.id);

        if (vrTag) {
          // Prepare payload with the updated value (element state) and other properties
          const payload = {
            tagName: vrTag.name,
            value: element.state,
          };

         // console.log(`LLBuilder - Found Linked Element: ${element.id} for VR Tag: ${vrTag.name}, Value: ${element.state}`);

          // If the element is a Counter or Timer, send the preset and accumulated values
          if (element.type === 'counter_ctu' || element.type === 'counter_ctd' || element.type === 'timer_on' || element.type === 'timer_off') {
            payload.preset = element.presetValue || 0;
            payload.accumulated = element.currentValue || 0;  // Use the currentValue field for Counters and Timers
           // console.log(`LLBuilder - Preparing update for ${vrTag.type} ${vrTag.name} with Preset: ${payload.preset}, Accumulated: ${payload.accumulated}`);
          }

          // Dispatch the update for VR Tag
          store.dispatch('updateVrTagValue', payload);
         // console.log(`LLBuilder - Dispatched update for VR Tag: ${vrTag.name}`);
        } //else {
          //console.log(`LLBuilder - No linked VR Tag found for Element: ${element.id}`);
       // }
      });
    });
  },
  { deep: true }
);

watch(
  () => store.getters.getIIoTDataSources.vrTagsTable,
  (vrTags) => {
    vrTags.forEach((vrTag) => {
      if ((vrTag.type === 'Counter' || vrTag.type === 'Timer') && vrTag.linkId) {
        // Find the corresponding element in LLBuilder using linkId
        const element = findElementById(vrTag.linkId);

        if (element) {
          if (vrTag.type === 'Counter') {
            if (element.presetValue !== vrTag.preset) {
              element.presetValue = vrTag.preset;
             // console.log(`LLBuilder - Updated Counter ${element.id} presetValue to ${element.presetValue}`);
              initializeCounters();
            }
          } else if (vrTag.type === 'Timer') {
            if (element.presetTime !== vrTag.preset) {
              element.presetTime = vrTag.preset;
             // console.log(`LLBuilder - Updated Timer ${element.id} presetTime to ${element.presetTime}`);
              initializeTimers();
            }
          }
        } //else {
          // console.log(`LLBuilder - Element not found for linkId: ${vrTag.linkId}`);
       // }
      }
    });
  },
  { deep: true }
);

// Watch linked AV tags for Digital Inputs (D prefix) and Analog Inputs (IR prefix)
watch(
  () => store.getters.getLinkedAVTags,
  (linkedAVTags) => {
    linkedAVTags.forEach((tag) => {
      if (tag.type === 'Digital Input' && tag.tagName.startsWith("D")) {
        // Convert tag.value to boolean
        const newState = tag.value === true || tag.value === 'true' || tag.value === 1;

        store.dispatch("toggleLadderLogicContact", { elementId: tag.linkId, newState });
      } else if (tag.type === 'Analog Input' && tag.tagName.startsWith("IR")) {
        // Handle analog inputs as needed
      } else if (tag.type === 'Digital Output' && tag.tagName.startsWith("C")) {
        // New code for Digital Outputs

        // Convert tag.value to boolean
        const newState = tag.value === true || tag.value === 'true' || tag.value === 1;

        const element = findElementById(tag.linkId);
        if (element) {
          // Identify all linked contacts
          const linkedContacts = rungs.value.flatMap(rung =>
            rung.elements
              .filter(el => el.type.startsWith('contact') && element.linkedContacts && element.linkedContacts.includes(el.id))
          );

          // Trigger toggleContact for each linked contact
          linkedContacts.forEach(contact => {
            toggleContact(contact, newState, false); // Pass `false` for isUserInitiated as this is system-driven
          });

          // Ensure reactivity and calculate states
          rungs.value = [...rungs.value];
          calculateEnergizedStates();
        }
      }
    });
  },
  { deep: true }
);


const iiotRungsState = computed(() => {
  const linkedAVTags = store.state.iiotDataSources.linkedAVTags;

  // Ensure rungs.value is initialized before processing
  if (!rungs.value || rungs.value.length === 0) {
    console.warn('iiotRungsState - Rungs are not yet initialized');
    return []; // Return an empty array if rungs are not ready
  }

  const coilStates = rungs.value
    .map((rung) => {
      return rung.elements
        .filter((element) => {
          const isCoil = element.type === 'coil';
          const linkedTag = linkedAVTags.find(tag => tag.linkId === element.id);

        //  if (isCoil && linkedTag) {
          //  console.log(`iiotRungsState - Linked AV Tag Found for Coil: ID: ${element.id}, State: ${element.state}, Linked Tag: ${linkedTag.tagName}`);
        //  }

          return isCoil && linkedTag;
        })
        .map(element => ({ id: element.id, state: element.state }));
    })
    .flat();

 // console.log('iiotRungsState - Computed Coil States:', coilStates);

  return coilStates;
});



watch(
  iiotRungsState,
  (newStates, oldStates) => {
    // Ensure iiotRungsState is populated before processing
    if (!newStates || newStates.length === 0) {
    //  console.warn('iiotRungsState - No coil states to watch yet');
      return; // Exit if iiotRungsState is empty
    }

    newStates.forEach((newState, index) => {
      const oldState = oldStates ? oldStates[index] : undefined;
      if (oldState && newState.state !== oldState.state) {
        // console.log(`IIoT Coil Changed - ID: ${newState.id}, New State: ${newState.state}`);
        store.commit("updateIiotTagValue", { tagName: newState.id, value: newState.state });
      }
    });
  },
  { deep: true }
);




// Watch ladder logic for Digital Outputs and update Vuex (handled by IIoTDataSources.vue for sending to server)
watch(
  () => store.state.iiotDataSources.variablesTableAV,
  (newVariablesAV) => {
    newVariablesAV.forEach((variable) => {
      // Check if variable and tagName are defined before accessing tagName
      if (variable && variable.tagName && typeof variable.tagName === 'string' && variable.tagName.startsWith("C")) { 
       // console.log(`LLBuilder - Digital Output Changed: ${variable.tagName}, New Value: ${variable.value}`);
        // This change is already being watched in IIoTDataSources.vue for sending to server
      } 
    });
  },
  { deep: true }
);
























    return {
      //here we have all the return for the header menu
      showTooltip,
      hideTooltip,
      logo,
      undo,
      redo,
      newProjectDialog,
      confirmNewProject,
      runSimulation,
      stopSimulation,
      simulationAlertDialog,
      goHome,
      showSaveDiagramDialogMethod,
      saveAsLadderLogicToBackend,
      closeSaveDiagramDialog,
      fetchUserDiagrams,
      closeOpenDiagramDialog,
      showOpenDiagramDialog,
      showSaveDiagramDialog,
      userDiagrams,
      diagramName,
      openSelectedDiagram,
      compressAndRepositionElements,
      getAvailableRungSegments,
      selectedDiagramId,
      currentDiagramId,
      saveCurrentDiagram,
      currentDiagramName,
      showCapacityLimitDialog,
      shareDiagram,
      showShareDiagramDialogMethod,
      toggleSelectAll,
      fetchUsersList,
      showShareDiagramDialog,
      usersList,
      selectedRecipients,
      selectAllTeachers,
      selectAllStudents,
      isSuperAdmin,
      currentUser,
      fetchCurrentUser,
      originalCreatorName,
      originalCreatorEmail,
      ownedDiagrams,
      sharedDiagrams,
      // here we have all from communication with IIoT and cyberphysical connectivvity

      isHeaderVisible,
      isLeftColumnVisible,
      
      //here we have all the return for the left-column tools menu
      showTooltip2,
      tooltipText,
      tooltipVisible,
      tooltipX,
      tooltipY,
      tab,
      variables,
      tools,

      //here we have all the return for the right-column ladder logic area
      rungs,
      addToolToRung,
      getLineSegments,
      isSegmentOverlappingTool,
      filteredSegments,
      getRungWidth,
      deleteVariable,
      deleteElement,
      ladderLogicArea,
      ladderLogicWidth,
      selectElement,
      selectedElement,
      selectSegment,
      selectedSegment,
      segmentCount,
      getPositionX,
      computedSegments,
      computedRungWidths,
      computedWidth,
      updateSegments,
      startDrag,
      handleDrop,
      dropOnSegment,
      addRung,
      deleteRung,
      selectedRung,
      updateVariable,
      isSelectedElement,
      isSimulating,
      energizedSegments,
      toggleSimulation,
      toggleContact,
      getSegmentColor,
      isRungEnergized,
      addToolDialog,
      getElementColor,
      addRungDialog,
      showTimerDialog,
      dialogPresetTime,
      dialogLinkedContact,
      availableContacts,
      configureTimer,
      openTimerDialog,
      closeTimerDialog,
      saveTimerSettings,
      findElementById,
      startTimerIntervals,
      displayElapsedTime,
      initializeCounters,
      updateCounters,
      showCounterDialog,
      dialogPresetValue,
      configureCounter,
      closeCounterDialog,
      saveCounterSettings,
      calculateEnergizedStates,
      computedRungHeights,
      calculateRungHeight,
      showCoilDialog,
      selectedCoil,
      openCoilDialog,
      closeCoilDialog,
      saveCoilSettings,
      configureCoil,
      coilStates,
     // New returns for the "+" button and branch functionality
      showPlusButton,
      plusButtonPosition,
      selectedBranchElement,
      addBranch,
      getElementPosition,
      getSegmentPosition,
      computedVerticalBranches,
      computedHorizontalBranches,
      isBranchSegment,
      nonBranchSegments,
      nonBranchElements,
      deleteButtonCondition,
      deleteSelectedBranch,
      currentElement,
      currentRungIndex, 
      deleteButtonPosition, 
      extendBranch,
      showExtendBranchButton,
      extendBranchButtonPosition,
      showDeleteBranchButton,
      getRungBaseHeight,
      energizeVerticalBranchSegments,
      splitRungMatrix,
      showWarningButton,
      warningButtonPosition,
      warningDialog,

    };
  },
};
</script>

<style scoped>
.tooltip-box {
  position: fixed;
  background: #000814;
  color: #ffd60a;
  padding: 5px 10px;
  border-radius: 7px;
  font-size: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
}
.v-app-bar {
  background-color: #000814;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: auto;
  border-bottom: 20px;
  border-bottom-color: #000000;
}

.header-left,
.header-right {
  display: flex;
  align-items: center;
  height: auto;
}

.custom-button {
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: #000814;
  border-radius: 50%;
  padding: 10px;
  margin: 0 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border: 1px solid #000814;
  outline: none;
  transition: background 0.3s;
}

.custom-button:hover {
  filter: brightness(0.9); /* Darker on hover */
}

.custom-button:active {
  box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.3);
}

.custom-button svg path {
  fill: #000814;
}

.blue-button {
  background: linear-gradient(to bottom, #9ad7ff, #03abff);
  color: #000814;
}

.yellow-button {
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: #000814;
}

.pill-button {
  background: linear-gradient(to bottom, #9ad7ff, #03abff);
  color: #000814;
  border-radius: 20px;
  padding: 10px 15px;
  margin: 0 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border: 1px solid #000814;
  outline: none;
  height: 40px; /* Adjust height to match circular buttons */
  display: flex;
  align-items: center;
  font-weight: bold; /* Bold text */
}

.pill-button:hover,
.pill-button:active {
  filter: brightness(0.9);
  box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.3);
}

.main-container {
  background: linear-gradient(to bottom, #000814, #0d1b2a);
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 0;
  padding: 0;
}
.full-screen {
  width: 100vw;
  height: 100vh;
}

.fill-parent {
  width: 100%;
  height: 100%;
}

.inner-container.with-left-column {
  flex: 1;
  display: flex;
  padding-top: 85px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  background: linear-gradient(to bottom, #000814, #0d1b2a);
}

.inner-container.no-left-column {
  flex: 1;
  display: flex;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  background: linear-gradient(to bottom, #000814, #0d1b2a);
}
.simulation-button-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.left-column {
  background: linear-gradient(to bottom, #000814, #000000);
  width: 25%;
  padding: 10px;
  height: calc(100vh - 70px); /* Adjusted height to account for header and gap */
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center; /* Center align items horizontally */
  position: fixed; /* Makes the left column fixed in the viewport */
  top: 70px; /* Adjust this value based on the height of your header + gap */
  left: 0;
  z-index: 100; /* Ensures it stays above other content */
  margin-right: 5px; /* Adds a gap between the left column and the ladder logic area */
}

.ladder-logic-area {

  flex-direction: column;
  align-items: stretch;
  /*margin-left: calc(25% + 5px);  Adjust margin to account for the width of the left column + gap */
  background: none;
  overflow-x: auto; /* Scroll bar is now at the bottom */
  overflow-y: auto;
  position: relative;
  padding-bottom: 20px;
  padding-right: 10px; /* Add padding to the right */
}

.ladder-logic-area.with-left-column {
  flex: 1;
  display: flex;
  margin-left: calc(25% + 5px); /* Adjust margin for left column */
  height: 100%; /* Occupies full height when left column is visible */

}

.ladder-logic-area.no-left-column {
    flex: none;
  display: block;
  height: 100%; /* Occupies full height when left column is visible */
  margin-left: 5px;
}

.ladder-logic-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0; /* Adjust for no overlap */
  margin-right: 5px;
  bottom: 15px; /* Space for scrollbar, matching the padding in the container */
}

/* When the left column is not visible, adjust styles */
.ladder-logic-wrapper.no-left-column {
  position: relative;
  top: auto;
  bottom: auto;
  height: auto; /* Allow content to determine height */
  max-height: 100%;
  overflow-y: auto;
  margin-right: 0;
}

.rung-wrapper {
  position: relative;
  width: 100%; /* Ensures width matches the content requirement dynamically */
  overflow-x: auto; /* Ensures scrolling is available */
  min-height: 100px; /* Ensures there's enough height for the content */
  transition: height 0.3s ease-in-out;


  
}

.rung-svg {
  height: 100px;
  overflow: visible; /* Allows SVG to extend beyond the initial container */
  padding-right: 5px; /* Adds padding to the right for better visual spacing */
  transition: height 0.3s ease-in-out;
  margin-top: -30px; /* Negative margin to reduce space above */height: 100px; /* Adjust as needed */overflow: visible; /* Allows SVG to extend beyond the initial container */padding-right: 5px; /* Adds padding to the right for better visual spacing */


}


.add-rung-button {
  margin-top: 1px;
  background: linear-gradient(to bottom, #ffc300, #ffd60a); /* Darker background for active tab */
  color: #000814; /* Text color for active tab */
  width: 40px; /* Half the original size */
  height: 40px; /* Half the original size */
  cursor: pointer;

}
.delete-rung-button {
  margin-top: 1px;
  margin-left: 5px;
  background: linear-gradient(to bottom, #9c0000, #ff0303); /* Darker background for active tab */
  color: #000814; /* Text color for active tab */
  width: 40px; /* Half the original size */
  height: 40px; /* Half the original size */
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.element {
  display: inline-block;
  vertical-align: middle;
}

.company-logo {
  max-height: 100%;
  object-fit: contain;
}
.v-tab {
  width: 100%; /* Ensure tabs fill the column */
  border: none; /* Remove any default border */
  position: relative; /* Ensure position-relative to apply z-index */
  display: flex; /* Ensure content inside the tab is flexible */
  justify-content: center;
}

.v-tab--active {
  background: linear-gradient(to bottom, #ffc300, #ffd60a); /* Darker background for active tab */
  color: #000814; /* Text color for active tab */
  border: 3px solid #000814 !important; /* Border around the active tab */
}

.v-tab:not(.v-tab--active) {
  background: linear-gradient(to bottom, #ffc300, #ffd60a); /* Background color for inactive tabs */
  color: #000814; /* Text color for inactive tabs */
}

.full-width-tabs .v-tab {
  flex: 1; /* Make tabs take equal space */
  border-top-right-radius: 35px;
  border-top-left-radius: 35px;
}

.selected {
  stroke: #ffc300 !important; /* Override other stroke colors when selected */
}

.rung-svg .selected-segment {
  stroke: #ffc300; /* Highlight color */
}

.custom-blue-button,
.custom-yellow-button {
  border-radius: 50%;
  width: 65px; /* Reduce size by 25% */
  height: 65px; /* Ensure equal width and height for a circle */
  padding: 5px; /* Remove padding */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border: 1px solid #000814;
}


.custom-blue-button {
  background: linear-gradient(to bottom, #9ad7ff, #03abff);
  color: #000814;
}

.custom-yellow-button {
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: #000814;
}

.custom-green-button,
.custom-red-button {
  border-radius: 50%;
  padding: 10px;
  margin: 0 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border: 1px solid #000814;
  outline: none;
  transition: background 0.3s;
}
.custom-green-button {
  background: linear-gradient(to bottom, #039a01, #03ff03);
  color: #000814;
}

/* New red button styles */
.custom-red-button {
  background: linear-gradient(to bottom, #a10101, #ff0303);
  color: #000814;
}

.custom-green-sim-button,
.custom-red-sim-button {
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}
.custom-green-sim-button {
  background: linear-gradient(to bottom, #039a01, #03ff03);
  color: #000814;
}

/* New red button styles */
.custom-red-sim-button {
  background: linear-gradient(to bottom, #a10101, #ff0303);
  color: #000814;
}

.section-title {
  color: #ffffff;
  margin-top: 3px;
  text-align: center;
  text-transform: uppercase;
}

.section-line {
  width: 80%;
  height: 1px;
  background-color: #ffffff;
  margin: 0 auto 5px;
  margin-bottom: 5px;
}

.delete-button-fo {
  overflow: visible; /* Ensure the entire button is visible */
}

.delete-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 0px;
}

.delete-button:hover {
  background-color: #ff6666; /* Lighter red color on hover */
}

.v-row {
  display: flex;
  justify-content: space-between; /* Distribute space evenly between buttons */
  width: 100%;
}

.tabs-container {
  width: 100%; /* Ensure the tabs container takes full width */
  margin-bottom: 10px; /* Space between tabs and tools */
  
}
.tabs-container .v-tab {
  font-size: 16px; /* Set your desired font size here */
  font-weight: bold; /* Optional: if you want to make the tab labels bold */
}

/* For all scrollbars */
::-webkit-scrollbar {
  width: 4px; /* Adjust this value to change the width */
  height: 4px; /* Adjust this value to change the height for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: #000000; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #5c5b5b; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Optional: Add rounded corners to the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #3a3a3a; /* Color when the user hovers over the scrollbar thumb */
}

.element-id {
  fill: #ffc300; /* Hex color for the yellow text */
  font-size: 18px; /* Adjust size as needed */
  user-select: none; /* Prevents text from being selected */
}
.variables-table {
  width: 100%; /* Ensures the table occupies the full width of the column */
  border-collapse: collapse; /* Removes any default spacing between borders */
}

.variables-table th, .variables-table td {
  border-right: 1px solid rgb(3, 171, 255); /* Vertical lines */
  padding: 8px; /* Padding inside cells */
  text-align: left; /* Aligns text to the left */
  color: rgb(3, 171, 255); /* Sets the text color */
}

.variables-table th:last-child, .variables-table td:last-child {
  border-right: none; /* Removes the border on the last column */
}

.variables-table tr {
  border-bottom: 3px solid #ffc300; /* Horizontal lines between rows */
}

.variables-table thead tr {
  border-bottom: 6px solid #ffc300; /* Thicker line under the header */
}

.variables-table thead th {
  font-weight: bold; /* Bold font for headers */
  font-size: 18px;
}
.variables-table input, .variables-table select {
  width: 100%;
  padding-left: 4px;
  box-sizing: border-box; /* Makes sure padding does not affect width */
}

.variables-table input[type="text"] {
  text-transform: none; /* Optional: if you want all inputs to be uppercase */
}

.diagram-list-scroll {
  max-height: 300px; /* Set a maximum height */
  overflow-y: auto;  /* Enable vertical scrolling */
}

.headline {
  color: #ffc300; /* Ensures text is always visible against the light background */
}

.textline {
  color: #03abff; /* Ensures text is always visible against the light background */
  font-size: 19px;
}

/* Style applies to all options */
.variables-table .variable-select option {
  color: #000814; /* Ensures text is always visible against the light background */
  background-color: #ffffff; /* Background color for options */
}

.add-branch-button {
  width: 24px; /* Half the original size */
  height: 24px; /* Half the original size */
  opacity: 0.9; /* Semi-transparent */
  margin-top: 5px;
  position: absolute;
  cursor: pointer;
}
.extend-branch-button {
  width: 24px; /* Half the original size */
  height: 24px; /* Half the original size */
  opacity: 0.9; /* Semi-transparent */
  margin-top: 5px;
  position: absolute;
  cursor: pointer;
}

.warning-branch-button {
  width: 24px; /* Half the original size */
  height: 24px; /* Half the original size */
  opacity: 0.9; /* Semi-transparent */
  margin-top: 5px;
  position: absolute;
  cursor: pointer;
}



/* Styles for landscape mode */
@media only screen and (orientation: landscape) {
  /* Your landscape styles here */
  .portrait-warning {
    display: none;
  }
}

/* Styles for portrait mode */
@media only screen and (orientation: portrait) {
  /* Hide main content and show a warning in portrait mode */
  .main-container {
    display: none;
  }

  .portrait-warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to bottom, #000814, #0d1b2a);
  color: #ffd60a;
  stroke: #ffd60a;
  text-align: center;
  padding: 20px;
}

.portrait-warning-logo {
  position: bottom;
  padding: 150px;
}

.svg-icons {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.rotate-message {
  font-size: 24px;
}

}

.v-row--no-gutters {
  background: transparent !important;
}

/* Add the necessary styles for highlighting */
.highlight-id-class-type-cps td:nth-child(1), /* ID */
.highlight-id-class-type-cps td:nth-child(3), /* Class */
.highlight-id-class-type-cps td:nth-child(4) /* Type */ {
  color: #ffc300;
}

.highlight-name-value td:nth-child(2), /* Name */
.highlight-name-value td:nth-child(5) { /* Value */
  color: rgb(3, 171, 255);
}

  /* Hide the next and previous navigation controls in Vuetify tabs */

:deep(.v-slide-group__controls .v-slide-group__prev),
:deep(.v-slide-group__controls .v-slide-group__next) {
  display: none !important;
}

/* Media query for devices with width below 1050px */
@media (max-width: 1300px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 20px;
  }
}

/* Media query for devices with width below 1050px */
@media (max-width: 1250px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 25px;
  }
}

/* Media query for devices with width below 900px */
@media (max-width: 1200px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 35px;
  }
}
/* Media query for devices with width below 900px */
@media (max-width: 1150px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 50px;
  }
}

/* Media query for devices with width below 900px */
@media (max-width: 1100px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 60px;
  }
}
/* Media query for devices with width below 900px */
@media (max-width: 1050px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 70px;
  }
}
/* Media query for devices with width below 1050px */
@media (max-width: 1000px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 80px;
  }
}

/* Media query for devices with width below 1050px */
@media (max-width: 950px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 97px;
  }
}

/* Media query for devices with width below 900px */
@media (max-width: 900px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 110px;
  }
}

/* Media query for devices with width below 900px */
@media (max-width: 850px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 122px;
  }
}

/* Media query for devices with width below 900px */
@media (max-width: 800px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 135px;
  }
}
/* Media query for devices with width below 900px */
@media (max-width: 750px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 146px;
  }
}
/* Media query for devices with width below 900px */
@media (max-width: 700px) {
  .variables-table-wrapper {
    overflow-x: visible;
    padding-left: 160px;
  }
}
</style>