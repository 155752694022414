<template>
  <div class="add-user-container" v-if="!isEditing">
    <div class="add-user-container">
      <!-- Button to Create New User -->
      <button class="create-user-button" @click="toggleAddUserSection">
        <font-awesome-icon :icon="['fas', 'plus-circle']" /><span class="button-text">CREATE NEW USER</span>
      </button>

      <button v-if="isSuperAdmin" class="create-project-button" @click="toggleAddProjectSection">
        <font-awesome-icon :icon="['fas', 'plus-circle']" /><span class="button-text">CREATE NEW PROJECT</span>
      </button>

      <!-- Add New User Form Section -->
      <div v-if="showAddUserSection" class="add-new-user">
        <div class="form-container">
          <!-- Row 1: Name and Last Name -->
          <div class="row">
            <div class="input-group">
              <label for="name" class="label-text">NAME</label>
              <input type="text" id="name" class="input-field" v-model="user.name" placeholder="First Name" />
            </div>
            <div class="input-group">
              <label for="lastName" class="label-text">LAST NAME</label>
              <input type="text" id="lastName" class="input-field" v-model="user.last_name" placeholder="Last Name" />
            </div>
          </div>

          <!-- Row 2: Email and Role -->
          <div class="row">
            <div class="input-group">
              <label for="email" class="label-text">EMAIL</label>
              <input type="email" id="email" class="input-field" v-model="user.email" placeholder="Email" />
            </div>
            <div class="input-group">
              <label for="role" class="label-text">ROLE</label>
              <!-- Only show role selection if the user is SuperAdmin -->
              <select v-if="currentUserRole === 'SuperAdmin'" id="role" v-model="user.role" class="input-field">
                <option value="SuperAdmin">SuperAdmin</option>
                <option value="Teacher">Teacher</option>
                <option value="Student">Student</option>
              </select>
              <!-- If the current user is a Teacher, default to Student -->
              <input v-else type="text" id="role" class="input-field" v-model="user.role" placeholder="Role" disabled />
            </div>
          </div>

          <!-- Student Capacity (Only visible for SuperAdmins when adding a Teacher) -->
          <div v-if="user.role === 'Teacher' && isSuperAdmin" class="row">
            <div class="input-group">
              <label for="studentCapacity" class="label-text">STUDENT CAPACITY</label>
              <input type="number" id="studentCapacity" class="input-field" v-model.number="user.studentCapacity" placeholder="Student Capacity" />
            </div>
          </div>

          <!-- Modes Selection (Only visible for SuperAdmins when adding a Teacher) -->
          <div v-if="user.role === 'Teacher' && isSuperAdmin" class="row">
            <div class="input-group">
              <label for="modes" class="label-text">MODES</label>
              <select id="modes" v-model="selectedMode" class="input-field">
                <option v-for="mode in modesOptions" :key="mode" :value="mode">{{ mode }}</option>
              </select>
            </div>
          </div>

          <!-- Projects Selection (Only visible for SuperAdmins when adding a Teacher) -->
          <div v-if="user.role === 'Teacher' && isSuperAdmin" class="row">
            <div class="input-group">
              <label class="label-text">PROJECTS</label>
              <!-- VR Mode Projects -->
              <div v-if="selectedMode === 'VR' || selectedMode === 'Both'">
                <label class="label-text-2">VR Mode Projects:</label>
                <div v-for="project in projectsVR" :key="project.id">
                  <input type="checkbox" :value="project.id" v-model="selectedProjects" />
                  <label class="label-text-3">{{ project.name }}</label>
                </div>
              </div>
              <!-- AV Mode Projects -->
              <div v-if="selectedMode === 'AV' || selectedMode === 'Both'">
                <label class="label-text-2">AV Mode Projects:</label>
                <div v-for="project in projectsAV" :key="project.id">
                  <input type="checkbox" :value="project.id" v-model="selectedProjects" />
                  <label class="label-text-3">{{ project.name }}</label>
                </div>
              </div>
            </div>
          </div>

          <!-- Row 3: Password Fields -->
          <div class="row">
            <div class="input-group">
              <label for="newPassword" class="label-text">New Password</label>
              <input 
                :type="passwordVisible ? 'text' : 'password'" 
                id="newPassword" 
                class="input-field" 
                v-model="passwords.new" 
                placeholder="New Password" 
              />
              <span class="eye-icon" @click="togglePasswordVisibility">
                <i :class="passwordVisible ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"></i>
              </span>
            </div>
            <div class="input-group">
              <label for="confirmPassword" class="label-text">Confirm Password</label>
              <input 
                :type="passwordVisible ? 'text' : 'password'" 
                id="confirmPassword" 
                class="input-field" 
                v-model="passwords.confirm" 
                placeholder="Confirm Password" 
              />
              <span class="eye-icon" @click="togglePasswordVisibility">
                <i :class="passwordVisible ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"></i>
              </span>
            </div>
          </div>

          <!-- Save Button -->
          <div class="row">
            <button class="save-button blue-button" @click="addUser">
              ADD USER
            </button>
          </div>
          <!-- Feedback messages -->
          <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
          <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
        </div>
      </div>

       <!-- Add New Project Form Section -->
        <div v-if="isSuperAdmin && showAddProjectSection" class="add-new-project">
         <div class="form-container">
          <div class="row">
            <div class="input-group">
              <label for="projectName" class="label-text">PROJECT NAME</label>
              <input type="text" id="projectName" class="input-field" v-model="newProject.name" placeholder="Project Name" />
            </div>
            <div class="input-group">
              <label for="projectMode" class="label-text">MODE</label>
              <select id="projectMode" v-model="newProject.mode" class="input-field">
                <option value="VR">VR</option>
                <option value="AV">AV</option>
                <option value="Both">Both</option>
              </select>
            </div>
          </div>
          <div class="row">
            <button class="save-button blue-button" @click="addProject">ADD PROJECT</button>
          </div>
          <!-- Feedback messages -->
          <div v-if="projectSuccessMessage" class="success-message">{{ projectSuccessMessage }}</div>
          <div v-if="projectErrorMessage" class="error-message">{{ projectErrorMessage }}</div>
        </div>
      </div>
    </div>
 
<!-- Project List and Search -->
<div v-if="isSuperAdmin" class="project-list">
  <h4>List of Projects</h4>
  
  <!-- Project Search Bar -->
  <div class="search-container">
    <input 
      type="text" 
      v-model="projectSearchQuery" 
      placeholder="Search projects..." 
      class="search-bar" 
    />

    <!-- Conditionally show the Delete button -->
    <div class="action-buttons" v-if="selectedProjects.length > 0">
      <button class="delete-project-button" @click="deleteSelectedProjects">
        <font-awesome-icon :icon="['fas', 'trash']" /> <span class="button-text">DELETE SELECTED PROJECTS</span>
      </button>
    </div>
  </div>

  <!-- Feedback messages -->
  <div v-if="projectSuccessMessage" class="success-message">{{ projectSuccessMessage }}</div>
  <div v-if="projectErrorMessage" class="error-message">{{ projectErrorMessage }}</div>

  <table>
    <thead>
      <tr>
        <th>Select</th>
        <th>Name</th>
        <th>Mode</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="project in filteredProjects" :key="project.id">
        <td>
          <input type="checkbox" v-model="selectedProjects" :value="project.id" />
        </td>
        <td>{{ project.name }}</td>
        <td>{{ project.mode }}</td>
      </tr>
    </tbody>
  </table>
</div>

    <!-- User List and Search -->
    <div class="user-list-container">
      <h4>List of Users</h4>
      <!-- Search Bar -->
      <div class="search-container">
        <input 
          type="text" 
          v-model="searchQuery" 
          placeholder="Search users..." 
          class="search-bar" 
        />

        <!-- Conditionally show the Edit and Delete buttons -->
        <div class="action-buttons" v-if="selectedUsers.length > 0">
          <button class="edit-user-button" v-if="selectedUsers.length === 1" @click="editUser">
            <font-awesome-icon :icon="['fas', 'pencil-alt']" /> <span class="button-text">EDIT SELECTED USER</span>
          </button>
          <button class="delete-user-button" @click="deleteSelectedUsers">
            <font-awesome-icon :icon="['fas', 'trash']" /> <span class="button-text">DELETE SELECTED USERS</span>
          </button>
        </div>
      </div>

      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      <table>
        <thead>
          <tr>
            <th>Select</th>
            <th>Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in paginatedUsers" :key="user.id">
            <td>
              <input type="checkbox" v-model="selectedUsers" :value="user.id" />
            </td>
            <td>{{ user.name }}</td>
            <td>{{ user.last_name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.role }}</td>
          </tr>
        </tbody>
      </table>

      <!-- Pagination Controls -->
      <div class="pagination-controls" v-if="filteredUsers && filteredUsers.length > usersPerPage">
        <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>
    </div>
  </div>

  <!-- Edit User Component -->
  <EditYagaUser v-if="isEditing" :userId="selectedUsers[0]" @cancelEdit="cancelEdit" />
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue';
import axios from 'axios';
import EditYagaUser from './EditYagaUser.vue'; // Import the edit component

export default {
  name: 'AddYagaUser',
  components: {
    EditYagaUser,
  },
  setup() {
    // State for new user form
    const user = ref({
      name: '',
      last_name: '',
      email: '',
      role: 'Student',
      studentCapacity: 0, // Add this for teacher's student capacity
    });

    const passwords = ref({
      new: '',
      confirm: '',
    });

    const passwordVisible = ref(false);
    const successMessage = ref('');
    const errorMessage = ref('');
    const projectSuccessMessage = ref('');
    const projectErrorMessage = ref('');

    // State for users list
    const users = ref([]);
    const showAddUserSection = ref(false);
    const showAddProjectSection = ref(false);

    const selectedUsers = ref([]);
    const searchQuery = ref('');
    const currentPage = ref(1);
    const usersPerPage = 12;
    const currentUserRole = ref(''); // To store current user role

    // Modes and Projects
    const modesOptions = ref(['VR', 'AV', 'Both']);
    const selectedMode = ref('');
    const projectsVR = ref([]);
    const projectsAV = ref([]);
    const selectedProjects = ref([]);

    // Check if the user is a SuperAdmin
    const isSuperAdmin = ref(false);

    // State for new project form
const newProject = ref({
  name: '', // Initialize name as an empty string
  mode: 'VR', // Set a default mode (optional)
});
const allProjects = ref([]);
    // New project search state
    const projectSearchQuery = ref('');

    // Computed property to filter projects based on search query
    const filteredProjects = computed(() => {
      return allProjects.value.filter(project =>
        project.name.toLowerCase().includes(projectSearchQuery.value.toLowerCase()) ||
        project.mode.toLowerCase().includes(projectSearchQuery.value.toLowerCase())
      );
    });

    // Method to get the current user's role
    const getCurrentUserRole = async () => {
      try {
        const token = localStorage.getItem('jwt');
        if (!token) {
          console.error("No token found in local storage");
          return;
        }
        const response = await axios.get('/yaga-auth/profile', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        currentUserRole.value = response.data.role;
        isSuperAdmin.value = response.data.role === 'SuperAdmin';

        // Load projects only if SuperAdmin
        if (isSuperAdmin.value) {
          await loadProjects();
        }
      } catch (error) {
        console.error('Error fetching current user role:', error);
      }
    };

   // Updated method to load all projects at once
   const loadProjects = async () => {
      try {
        const token = localStorage.getItem('jwt');
        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        // Fetch all projects regardless of mode
        const response = await axios.get('/yaga-auth/projects', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        
        // Assign all projects to allProjects for display
        allProjects.value = response.data;

        // Separate projects by mode
        projectsVR.value = response.data.filter(project => project.mode === 'VR');
        projectsAV.value = response.data.filter(project => project.mode === 'AV');
      } catch (error) {
        console.error('Error loading projects:', error.response ? error.response.data : error.message);
        errorMessage.value = 'Error loading projects.';
      }
    };


    // Watcher to reset selectedProjects when selectedMode changes
    watch(selectedMode, () => {
      selectedProjects.value = [];
    });

    // Computed for filtered and paginated users
    const filteredUsers = computed(() => {
  return (users.value || []).filter(user =>
    (user && user.name && user.name.toLowerCase().includes(searchQuery.value.toLowerCase())) ||
    (user && user.last_name && user.last_name.toLowerCase().includes(searchQuery.value.toLowerCase())) ||
    (user && user.email && user.email.toLowerCase().includes(searchQuery.value.toLowerCase())) ||
    (user && user.role && user.role.toLowerCase().includes(searchQuery.value.toLowerCase()))
  );
});


    const totalPages = computed(() => Math.ceil(filteredUsers.value.length / usersPerPage));

    const paginatedUsers = computed(() => {
      const start = (currentPage.value - 1) * usersPerPage;
      const end = start + usersPerPage;
      return filteredUsers.value.slice(start, end);
    });

    // Method to toggle password visibility
    const togglePasswordVisibility = () => {
      passwordVisible.value = !passwordVisible.value;
    };

    const toggleAddUserSection = () => {
      showAddUserSection.value = !showAddUserSection.value;
    };

    // Method to add a new user
    const addUser = async () => {
      try {
        if (passwords.value.new !== passwords.value.confirm) {
          errorMessage.value = "Passwords do not match.";
          return;
        }

        const payload = {
          ...user.value,
          password: passwords.value.new,
          studentCapacity: user.value.studentCapacity, // Include studentCapacity in the payload
        };

        // Include modes and projectIds for teachers
        if (user.value.role === 'Teacher' && currentUserRole.value === 'SuperAdmin') {
          payload.modes = selectedMode.value;
          payload.projectIds = selectedProjects.value;
        }

        const token = localStorage.getItem('jwt');
        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        const response = await axios.post('/yaga-auth/add-user', payload, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        console.log('Response after adding user:', response.data);
        successMessage.value = 'User added successfully!';
        errorMessage.value = '';

        // Clear the form
        user.value = {
          name: '',
          last_name: '',
          email: '',
          role: 'Student',
          studentCapacity: 0, // Reset this field as well
        };
        passwords.value.new = '';
        passwords.value.confirm = '';
        selectedMode.value = '';
        selectedProjects.value = [];

        // Reload the users list after adding a new user
        await loadUsers();

      } catch (error) {
        console.error('Error adding user:', error.response ? error.response.data : error.message);
        if (error.response && error.response.data.msg === 'You have reached your student capacity limit') {
          errorMessage.value = 'You have reached your student capacity limit.';
        } else {
          errorMessage.value = 'Error adding user. Please try again.';
        }
        successMessage.value = '';
      }
    };

    // Method to load all users
    const loadUsers = async () => {
      try {
        const token = localStorage.getItem('jwt');
        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        const response = await axios.get('/yaga-auth/all-users', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        users.value = response.data;
        console.log('Loaded users:', users.value);

      } catch (error) {
        console.error('Error loading users:', error.response ? error.response.data : error.message);
        errorMessage.value = 'Error loading users.';
      }
    };

    // Method to delete selected users
    const deleteSelectedUsers = async () => {
      try {
        console.log("Selected users:", selectedUsers.value);  // Log the selected users

        const token = localStorage.getItem('jwt');
        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        const response = await axios.post('/yaga-auth/delete-users', {
          userIds: selectedUsers.value
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        console.log('Response after deleting users:', response.data);
        successMessage.value = 'Users deleted successfully!';
        errorMessage.value = '';
        selectedUsers.value = [];
        
        // Reload the users list after deleting
        await loadUsers();

      } catch (error) {
        console.error('Error deleting users:', error.response ? error.response.data : error.message);
        errorMessage.value = 'Error deleting users. Please try again.';
        successMessage.value = '';
      }
    };

    // Pagination control methods
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    const isEditing = ref(false);
    // Method to edit a user
    const editUser = () => {
      isEditing.value = true; // Show the edit user component
    };

    // Method to cancel editing
    const cancelEdit = () => {
      isEditing.value = false; // Hide the edit user component
      loadUsers(); // Reload users
    };

    const toggleAddProjectSection = () => {
      showAddProjectSection.value = !showAddProjectSection.value;
    };

    const addProject = async () => {
  try {
    // Get the token from local storage
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error("No token found in local storage");
      return;
    }

    // Prepare the payload with new project data
    const payload = {
      name: newProject.value.name,
      mode: newProject.value.mode,
    };

    // Send the request to add a project
    const response = await axios.post('/yaga-auth/projects', payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    // Handle successful project creation
    if (response.status === 201) {
      projectSuccessMessage.value = "Project added successfully!";
      projectErrorMessage.value = '';

      // Clear the newProject form inputs
      newProject.value = { name: '', mode: 'VR' };

      // Reload the projects list to display the new project
      await loadProjects();
    }
  } catch (error) {
    console.error('Error adding project:', error.response ? error.response.data : error.message);
    projectErrorMessage.value = 'Error adding project. Please try again.';
    projectSuccessMessage.value = '';
  }
};

const deleteSelectedProjects = async () => {
  try {
    console.log("Selected projects:", selectedProjects.value);  // Log the selected projects

    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error("No token found in local storage");
      return;
    }

    const response = await axios.post('/yaga-auth/delete-projects', {
      projectIds: selectedProjects.value
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    console.log('Response after deleting projects:', response.data);
    projectSuccessMessage.value = 'Projects deleted successfully!';
    projectErrorMessage.value = '';
    selectedProjects.value = [];
    
    // Reload the projects list after deleting
    await loadProjects();

  } catch (error) {
    console.error('Error deleting projects:', error.response ? error.response.data : error.message);
    projectErrorMessage.value = 'Error deleting projects. Please try again.';
    projectSuccessMessage.value = '';
  }
};



    // Load users when the component is mounted
    onMounted(async () => {
      await getCurrentUserRole(); // Fetch the current user's role and load projects if SuperAdmin
      await loadUsers(); // Load users
    });

    return {
      user,
      passwords,
      passwordVisible,
      togglePasswordVisibility,
      addUser,
      successMessage,
      errorMessage,
      users,
      showAddUserSection,
      toggleAddUserSection,
      selectedUsers,
      searchQuery,
      paginatedUsers,
      totalPages,
      currentPage,
      nextPage,
      prevPage,
      deleteSelectedUsers,
      isEditing,
      editUser,
      cancelEdit,
      currentUserRole,
      isSuperAdmin,
      modesOptions,
      selectedMode,
      projectsVR,
      projectsAV,
      selectedProjects,
      newProject, 
      addProject,
      projectSuccessMessage,
      projectErrorMessage,
      showAddProjectSection,
      toggleAddProjectSection,
      allProjects,
      loadProjects,
      projectSearchQuery,
      filteredProjects,
      deleteSelectedProjects,

    };
  }
};
</script>

<style scoped>
.add-user-container {
  padding: 20px;
}

/* Button to Create New User */
.create-user-button {
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: bold;
}

.create-user-button:hover {
  filter: brightness(0.9);
}

.create-project-button {
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: 40px;
  font-weight: bold;
}

.create-project-button:hover {
  filter: brightness(0.9);
}

.delete-project-button {
  background: linear-gradient(to bottom, #ff0000, #920000);
  color: rgb(255, 255, 255);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: bold;
  margin-left: 20px; /* Adjust spacing as needed */
}

.delete-project-button:hover {
  filter: brightness(0.9);
}


.add-new-user {
  margin-bottom: 20px;
}

.form-title {
  color: #03abff;
  margin-bottom: 10px;
}

.label-text {
  color: #ffc300;
  font-weight: bold;
}

.label-text-2 {
  color: #03abff;
  font-weight: bold;
}

.label-text-3 {
  color: #fffcf3;
  font-weight: bold;
}

.form-container {
  width: 100%;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  width: 48%; /* 48% to leave space for the two-column layout */
}

.input-field {
  background-color: #1b263b;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  color: lightgray;
  font-weight: normal;
}

.eye-icon {
  position: relative;
  align-self: flex-end;
  right: 10px;
  top: -35%;
  cursor: pointer;
  color: lightgray; /* Adjust color as needed */
}

.save-button {
  background: linear-gradient(to bottom, #9ad7ff, #03abff); /* Default gradient */
  color: #000814;
  border-radius: 25px;
  padding: 10px 30px;
  margin: 10px auto;
  display: block;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s, filter 0.3s; /* Smooth transition for the background and filter */
  border: none;
  outline: none;
}

.save-button:hover {
  filter: brightness(1.3); /* Darken the button on hover */
}

.save-button:active {
  box-shadow: inset 0 6px 8px rgba(0, 0, 0, 0.3); /* Simulate the button being pressed */
}

.success-message {
  color: #4CAF50;
  font-weight: bold;
  margin-top: 10px;
}

.error-message {
  color: #FF5252;
  font-weight: bold;
  margin-top: 10px;
}

/* Apply styles to the select dropdown */
select.input-field {
  background-color: #1b263b; /* Black background for the dropdown */
  color: white; /* White text color */
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
}

/* Styling the dropdown options */
select.input-field option {
  background-color: #1b263b; /* Black background for options */
  color: white; /* White text color */
}

/* Highlight color for options */
select.input-field option:hover {
  background-color: #ffc300; /* Yellow background on hover */
}

.error-message {
  color: #FF5252;
  font-weight: bold;
  margin-top: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

/* User List Table */
.user-list-title {
  color: #03abff;
  margin-bottom: 10px;
}

th {
  color: #ffc300;
  text-align: left;
  padding: 8px;
}

td {
  color: #9ad7ff;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

h4 {
  color: #03abff;
}

.delete-user-button {
  background: linear-gradient(to bottom, #ff0000, #920000);
  color: rgb(255, 255, 255);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: bold;
  margin-left: 20px; /* Add some space between the create and delete buttons */
}

.delete-user-button:hover {
  filter: brightness(0.9);
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.search-input {
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-right: 20px;
}

.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar {
  width: 33%; /* Set width to one-third of the container */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.button-text {
  margin-left: 10px;
}

/* Button to Create New User */
.edit-user-button {
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: bold;
}

.edit-user-button:hover {
  filter: brightness(0.9);
}
</style>
