<template>
  <div class="hero dark-background">
    <h1>Medusa Blog</h1>
    <h2>Your daily dose of insightful articles</h2>
  </div>

  <div class="container white-background">
    <div class="content-row" v-for="post in posts" :key="post.id">
      <div class="image-column">
        <!-- Use the local image path if available, fallback to a generic image -->
        <img :src="`https://medusadigital.com/images/${post.id}.jpg`" alt="Blog Post Image">
      </div>
      <div class="text-column">
        <h2>{{ post.title }}</h2>
        <p>{{ post.excerpt }}</p>
        <router-link :to="`/blog/${post.slug}`" class="cta-button">Read More</router-link> <!-- Updated link -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MedusaBlog',
  data() {
    return {
      posts: []
    };
  },
  methods: {
    async fetchPosts() {
      try {
        const response = await fetch('https://medusadigital.com/api/blog/posts');
        if (!response.ok) throw new Error('Failed to fetch');
        const data = await response.json();
        this.posts = data;
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    }
  },
  mounted() {
    this.fetchPosts();
  }
};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;
}

.content-row {
  display: grid;
  grid-template-columns: 1fr; /* Equal division for text and image */
  gap: 20px;
  align-items: center; /* Vertically center the content within each grid */
}

.text-column, .image-column {
  padding: 10px;
}

.image-column img {
  width: 100%;
  height: auto;
}

.inline-image {
  width: 100%; /* Ensures the image is not wider than the text column */
  object-fit: cover; /* Ensures the image covers the area without distorting aspect ratio */
}
/* Background images for different screen widths */
.hero {
  background-image: url('@/assets/HERO-HOME-COMPUTER.webp');
}

@media (min-width: 900px) {
  .hero {
    background-image: url('@/assets/HERO-HOME-COMPUTER.webp');
  }
}

@media (min-width: 1200px) {
  .hero {
    background-image: url('@/assets/HERO-HOME-SUPER.webp');
  }
}
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 300px; /* Adjusted for better visual balance */
  background-color: #333; /* Dark background for contrast */
  color: white;
}

.hero h1, .hero h2 {
  margin: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent background for text */
}

@media (min-width: 900px) {
  .container {
    padding-left: 50px;
    padding-right: 50px;
    max-width: 95%; /* Limiting maximum width for larger screens */
  }

  .content-row {
    grid-template-columns: 1fr 2fr; /* More space for text */
  }
}
</style>