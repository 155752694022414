<template>
  <header class="header">
    <div class="brand">
      <img :src="logo" alt="Medusa Digital Logo" />
    </div>
    <button @click="toggleMenu" class="menu-toggle">
      <font-awesome-icon :icon="menuVisible ? ['fas', 'times'] : ['fas', 'bars']" />
    </button>
    <nav class="navbar" :class="{ 'is-visible': menuVisible }">
    <ul class="nav-links">
        <li><router-link to="/" exact active-class="active-link">HOME</router-link></li>
        <li><router-link to="/AugmentedVirtualityTraining" active-class="active-link">AV&VR TRAININGS</router-link></li>
        <li><router-link to="/EUFundedProjects" active-class="active-link">EU PROJECTS</router-link></li>
        <li><router-link to="/MedusaBlog" active-class="active-link">BLOG</router-link></li>
        <li><router-link to="/MedusaContact" active-class="active-link">CONTACT</router-link></li>
    </ul>
    </nav>
    <div class="social-links" :class="{ 'is-visible': menuVisible || isDesktop }">
      <a :href="facebookLink" target="_blank" class="social-icon">
        <font-awesome-icon :icon="['fab', 'facebook-f']" />
      </a>
      <a :href="instagramLink" target="_blank" class="social-icon">
        <font-awesome-icon :icon="['fab', 'instagram']" />
      </a>
      <a :href="linkedinLink" target="_blank" class="social-icon">
        <font-awesome-icon :icon="['fab', 'linkedin-in']" />
      </a>
    </div>
  </header>
</template>

<script>
import Logo from '@/assets/Logo_Medusa_Digital_Dark.webp';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'SiteHeader',
  components: {
    'font-awesome-icon': FontAwesomeIcon
  },
  data() {
    return {
      logo: Logo,
      facebookLink: 'https://www.facebook.com/medusadigital.VRtrainings',
      instagramLink: 'https://www.instagram.com/medusadigital_',
      linkedinLink: 'https://www.linkedin.com/company/medusadigital/',
      menuVisible: false,  // Will be set based on window width below 550px
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 550;
    },
    isDesktop() {
      // Will return true for widths >= 550px
      return window.innerWidth >= 550;
    },
    currentPath() {
    return this.$route.path;
  }
  },
  methods: {
    handleResize() {
      this.menuVisible = !this.isMobile; // Menu visibility logic reversed for clarity
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    }
  },
  watch: {
    // Watch for changes in screen size past the 550px threshold
    isMobile(newVal) {
      this.menuVisible = !newVal; // Update based on mobile status
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    //console.log("Current Path:", this.$route.path);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>


<style scoped>
.header {
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
}

.brand img {
  height: 100px; /* Adjust as needed */
  width: auto;
  /*transition: transform 0.3s ease-in-out;*/
}

.menu-toggle {
  background: none;
  border: none;
  font-size: 24px; /* Adjust as needed */
  color: white; /* Adjust as needed */
  cursor: pointer;
  padding: 0.5em; /* Adjust as needed */
  display: none;
  align-items: center;
  justify-content: center;
}

.navbar {
  background-color: transparent;
  border-radius: 0px;
  padding: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 10px;
  font-size: 18px;
}

.nav-links a {
  text-decoration: none;
  color: white;
  background: transparent;
  border-radius: 15px;
  padding: 5px 15px;
  transition: color 0.3s, background-color 0.3s, text-decoration-color 0.3s;
}

.nav-links a:hover {
    text-decoration: underline;
    text-decoration-color: rgba(46, 163, 242, 0.8); /* Smooth transition for underline */
}


.nav-links a.active-link {
    color: rgb(46, 163, 242); /* Specific color for active links */
    text-decoration: underline;
    text-decoration-color: rgb(46, 163, 242); /* Ensure the underline is the same color */
}

.social-links {
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.social-icon {
  padding: 5px;
  background-color: rgb(0, 0, 0);
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  transition: background-color 0.3s, border-color 0.3s;
}

@media (min-width: 550px) and (max-width: 979px) {
    .header {
        display: flex;
        flex-direction: column;  /* Stack children vertically */
        align-items: center;  /* Center align the items */
        width: 100%;  /* Use full width of the container */
    }

    .brand img {
        height: 135px;  /* Increased by 25% */
        width: auto;  /* Maintain aspect ratio */
        /*transition: transform 0.3s ease-in-out;  /* Ensure smooth scaling */
    }

    .brand {
        margin-bottom: 10px;  /* Space between brand and social links reduced to half */
    }

    .social-links {
        display: flex;
        flex-direction: row;  /* Align social icons horizontally */
        justify-content: center;  /* Center icons horizontally */
        gap: 6px;  /* Space between each icon */
        margin-bottom: 10px;  /* Space between social links and the navbar */
    }

    .social-icon {
        display: flex;
        align-items: center;
        justify-content: center;  /* Center icons within their individual elements */
    }

    .navbar {
        display: flex;
        flex-direction: column;  /* Ensure the navbar is stacked vertically */
        align-items: center;  /* Center the navbar items */
        width: 100%;  /* Full width */
        margin-bottom: 10px;  /* Optional: space below the navbar */
    }

    .nav-links {
        display: flex;
        flex-direction: row;  /* Align links horizontally */
        justify-content: center;  /* Center links horizontally */
        gap: 10px;  /* Space between links */
        width: 100%;  /* Full width */
    }

    .nav-links a {
        text-align: center;  /* Ensure text is centered within links */
        padding: 8px 16px;  /* Padding for better touch targets */
    }
}


@media (max-width: 549px) {
    .header {
        display: flex;
        flex-direction: column;  /* Stack children vertically */
        align-items: center;  /* Center align the items */
        width: 100%;  /* Use full width of the container */
    }

    .brand img {
        height: 125px;  /* Increased by 25% */
        width: auto;  /* Maintain aspect ratio */
        /*transition: transform 0.3s ease-in-out;   Ensure smooth scaling */
    }

    .brand {
        margin-bottom: 1px;  /* Space between brand and menu button */
    }

    .menu-toggle {
        display: flex;  /* Show menu toggle */
        margin-bottom: 20px;  /* Space between menu toggle and content below */
    }

    .social-links {
        display: none;  /* Initially hidden, toggle via Vue.js */
    }

    .social-links.is-visible {
        display: flex;  /* Show when menu is toggled */
        flex-direction: row;  /* Align social icons horizontally */
        justify-content: center;  /* Center icons horizontally */
        gap: 6px;  /* Space between each icon */
        margin-bottom: 10px;  /* Space between social links and the navbar or any content below */
    }

    .navbar {
        display: none;  /* Initially hidden, toggle via Vue.js */
    }

    .navbar.is-visible {
        display: flex;  /* Show when menu is toggled */
        flex-direction: column;  /* Align navbar links vertically */
        align-items: center;  /* Center the navbar items */
        width: 100%;  /* Full width */
    }

    .nav-links {
        display: flex;
        flex-direction: column;  /* Stack links vertically */
        align-items: center;  /* Center the links */
        width: 100%;  /* Use full width for links container */
    }

    .nav-links a {
        text-align: center;  /* Ensure text is centered within links */
        padding: 8px 16px;  /* Padding for better touch targets */
        width: 100%;  /* Each link takes full width of the container */
        margin-bottom: 5px;  /* Space between links */
    }
}

/* Keep existing media queries and adjust as necessary */
/* ... */
</style>

