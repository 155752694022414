<template>
 <div>
    <!-- Hero Section -->
    <div class="hero dark-background">
      <h1>{{ post.title }}</h1>
    </div>
  
      <!-- Blog Content -->
      <div class="container white-background">
        <div class="content-row">
          <!-- Main Content Column (75%) -->
          <div class="text-column">
            <h3>Introduction</h3>
            <p>{{ post.introduccion }}</p>
            <h3>{{ post.titulo_s1 }}</h3>
            <p>{{ post.contenido_s1 }}</p>
            <h3>{{ post.titulo_s2 }}</h3>
            <p>{{ post.contenido_s2 }}</p>
            <h3>{{ post.titulo_s3 }}</h3>
            <p>{{ post.contenido_s3 }}</p>
            <h3>Conclusion</h3>
            <p>{{ post.conclusion }}</p>
          </div>
  
          <!-- Key Ideas Column (25%) -->
          <div class="side-column">
            <blockquote>
              <h3>Key Idea 1</h3>
              <p>{{ post.idea_clave_s1 }}</p>
            </blockquote>
            <blockquote>
              <h3>Key Idea 2</h3>
              <p>{{ post.idea_clave_s2 }}</p>
            </blockquote>
            <blockquote>
              <h3>Key Idea 3</h3>
              <p>{{ post.idea_clave_s3 }}</p>
            </blockquote>
            <blockquote>
              <h3>Key Idea 4</h3>
              <p>{{ post.idea_clave_conclusion }}</p>
            </blockquote>
          </div>
        </div>
  
        <!-- Buttons -->
        <div class="custom-buttons-container">
          <router-link to="/MedusaBlog" class="cta-button">Read More Blog Entries →</router-link>
          <a class="cta-button" href="https://medusadigital.com">Contact Us →</a>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        post: {}
      };
    },
    methods: {
      async fetchPost() {
        const slug = this.$route.params.slug;
        try {
          const response = await fetch(`https://medusadigital.com/api/blog/posts/${slug}`);
          if (!response.ok) throw new Error('Failed to fetch');
          const data = await response.json();
          this.post = data;
        } catch (error) {
          console.error('Error fetching post:', error);
        }
      }
    },
    mounted() {
      this.fetchPost();
    }
  };
  </script>
  
  <style scoped>
  .hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 300px; /* Full height of the screen */
    background-size: cover;
    background-position: center;
    color: white;
    padding: 0;
    margin: 0;
  }
  
  .hero h1 {
    margin: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for text */
  }
  .hero {
  background-image: url('@/assets/HERO-HOME-MOBILE.webp');
}

@media (min-width: 900px) {
  .hero {
    background-image: url('@/assets/HERO-HOME-COMPUTER.webp');
  }
}

@media (min-width: 1200px) {
  .hero {
    background-image: url('@/assets/HERO-HOME-COMPUTER.webp');
  }
}
  .container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 10px;
    max-width: 95%;
  }
  
  .content-row {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    align-items: start; /* Align the content to the start */
  }
  
  .text-column, .side-column {
    padding: 10px;
  }
  
  @media (min-width: 980px) {
    .content-row {
      grid-template-columns: 3fr 1fr; /* 75% and 25% columns */
    }
    .container {
    padding-left: 10px;
    padding-right: 10px;
    margin: 5px;
    max-width: 95%;
  }
  }
  
  .custom-buttons-container {
    text-align: center;
    padding: 20px 0;
  }
  </style>
  