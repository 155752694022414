<template>
  <div class="hero dark-background">
    <h1>EU Funding</h1>
    <h2>Building European Resilience in the Digital Age</h2>
  </div>

  <div class="container white-background">
    <!-- Row 1: REX-VET -->
    <div class="content-row">
      <div class="text-column">
        <h2>REX-VET</h2>
        <p>REX VET is an international cooperation co-financed by the Erasmus + programme of the European Union. We aspire to innovate VET by introducing innovative teaching methods that leverage the latest technologies. Our project addresses two crucial needs identified by the consortium:</p>
          <ul>
            <li>The need to provide learners with increased access to high-quality training opportunities.</li>
            <li>The need to offer trainees authentic and ubiquitous training experiences that align with their specialization domains.</li>
          </ul>
          <p>Our primary focus is on three specialized domains, namely chemistry, physics, and mechatronics.</p>
          <img :src="require('@/assets/ErasmusREXVET.webp')" alt="Co-funding by Erasmus+ of the European Union for REX VET Project" class="inline-image">

      </div>
      <div class="image-column">
         <picture>
          <source media="(min-width: 1200px)" srcset="@/assets/REX-VET-1-Computer.webp">
          <source media="(min-width: 900px)" srcset="@/assets/REX-VET-1-Computer.webp">
          <source media="(max-width: 899px)" srcset="@/assets/REX-VET-1-Mobile.webp">
          <img src="@/assets/REX-VET-1-Computer.webp" alt="REX-VET Project">
        </picture>
      </div>
    </div>

    <!-- Row 2: Empower & Synergy -->
    <div class="content-row">
      <div class="image-column">
        <picture>
          <source media="(min-width: 1200px)" srcset="@/assets/REX-VET-2-Computer.webp">
          <source media="(min-width: 900px)" srcset="@/assets/REX-VET-2-Computer.webp">
          <source media="(max-width: 899px)" srcset="@/assets/REX-VET-2-Mobile.webp">
          <img src="@/assets/REX-VET-1-Computer.webp" alt="REX-VET Project">
        </picture>
      </div>
      <div class="text-column">
        <h2>Empower & Synergy</h2>
        <p>At REX-VET, our mission is to pioneer the integration of augmented virtuality (AV) within the realm of industrial and educational training. We are committed to developing state-of-the-art digital twins of physical laboratories and equipment, thus creating immersive virtual environments that bridge the gap between theoretical knowledge and practical application. Our goal is to empower learners by providing them with a safe, interactive platform where they can hone their skills, experiment with real-world scenarios, and gain the confidence to operate complex machinery and systems. Through the REX-VET project, we aim to redefine the standards of learning and skill development, preparing a new generation of professionals for the challenges of an increasingly technological world.</p>
      </div>
    </div>

    <!-- Row 3: Augmented Virtuality -->
    <div class="content-row">
      <div class="text-column">
        <h2>Augmented Virtuality</h2>
        <p>In an era where technology evolves at an unprecedented rate, the need for innovative educational models that can keep pace with the change is paramount. Augmented virtuality stands at the forefront of this educational revolution. REX-VET’s utilization of AV is crucial for several reasons. Firstly, it enables learners to practice without the risk of costly mistakes in a real environment. Secondly, it makes education more accessible, allowing students from around the globe to operate equipment remotely, eliminating geographical and financial barriers. Thirdly, it provides a dynamic and adaptive learning experience that can be tailored to individual learning styles and needs. By embracing AV, REX-VET is not just transforming education; it is building the foundation of a future where digital skills and digital literacy go hand in hand</p>
        <a href="https://rexvetproject.com/" class="cta-button">Know more about REX-VET</a>
      </div>
      <div class="image-column">
        <picture>
          <source media="(min-width: 1200px)" srcset="@/assets/REX-VET-3-Computer.webp">
          <source media="(min-width: 900px)" srcset="@/assets/REX-VET-3-Computer.webp">
          <source media="(max-width: 899px)" srcset="@/assets/REX-VET-3-Mobile.webp">
          <img src="@/assets/REX-VET-1-Computer.webp" alt="REX-VET Project">
        </picture>
      </div>
    </div>

    <!-- Row 4: Jobs of the Future -->
    <div class="content-row">
      <div class="image-column">
         <picture>
          <source media="(min-width: 1200px)" srcset="@/assets/JOTF-1-Computer.webp">
          <source media="(min-width: 900px)" srcset="@/assets/JOTF-1-Computer.webp">
          <source media="(max-width: 899px)" srcset="@/assets/JOTF-1-Mobile.webp">
          <img src="@/assets/REX-VET-1-Computer.webp" alt="JOTF Project">
        </picture>
      </div>
      <div class="text-column">
        <h2>Jobs of the Future</h2>
        <p>In the European Union, demand for tech skilled individuals is expected to grow, until at least 2025 (CEDEFOP, 2016). At the same time, the future supply of tech skills in the workforce is forecasted to be substantially less than than what the market demands. For this reason, the Jobs of the Future project aims to teach younger generations the high-tech skills through a hands-on and DIY approach.</p>
        <img :src="require('@/assets/jobs-of-the-future-cofunding-erasmus-plus-european-union.webp')" alt="Co-funding by Erasmus+ of the European Union" class="inline-image">

      </div>
    </div>

    <!-- Row 5: Our Mission -->
    <div class="content-row">
      <div class="text-column">
        <h2>Our Mission</h2>
        <p>Our goal is to promote the development, transfer and implementation of innovative practices in youth work. For this reason we are a consortion of EU based companies, youth organisations and educational institutions to encourage cooperation, peer learning and exchanges of experience at the European Level. We believe that reinforcing teaching of tech subjects outside of the classroom would actually broaden youngsters future career prospects, and provide higher flexibility with regards to employment choice. We aim to empower young people by accompanying them in the development of 21st century skills such as creation of Immersive Virtual Training, which are in fact essential for the fulfilment of youth in the social, personal professional and educational spheres of life. These skills will notably be promoted by adopting a learning-by-doing approach to all educational activities implemented within the frame of our initiative.</p>
      </div>
      <div class="image-column">
        <picture>
          <source media="(min-width: 1200px)" srcset="@/assets/JOTF-2-Computer.webp">
          <source media="(min-width: 900px)" srcset="@/assets/JOTF-2-Computer.webp">
          <source media="(max-width: 899px)" srcset="@/assets/JOTF-2-Mobile.webp">
          <img src="@/assets/REX-VET-1-Computer.webp" alt="JOTF Project">
        </picture>
      </div>
    </div>

    <!-- Row 6: Modern Learning for Modern Challenges -->
    <div class="content-row">
      <div class="image-column">
        <picture>
          <source media="(min-width: 1200px)" srcset="@/assets/JOTF-3-Computer.webp">
          <source media="(min-width: 900px)" srcset="@/assets/JOTF-3-Computer.webp">
          <source media="(max-width: 899px)" srcset="@/assets/JOTF-3-Mobile.webp">
          <img src="@/assets/REX-VET-1-Computer.webp" alt="JOTF Project">
        </picture>
      </div>
      <div class="text-column">
        <h2>Modern learning for modern challenges</h2>
        <p>We are applying a modern learning methodology involving a «learning by doing» approach, where students and educators are both expected to learn and teach, engaging cross-generational learning. Throughout the process, children learn about self-regulation, assertiveness, critical thinking, collaboration, resistance to failure, perserverance and communication skills, specially as part of the process involves cross-border mobility opportunities.</p>
        <a href="http://www.jobsofthefuture.eu/" class="cta-button">Know more about JOTF</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EUFundedProjects',
  
};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;
}

.content-row {
  display: grid;
  grid-template-columns: 1fr; /* Single column for mobile */
  gap: 0;
}

.text-column, .image-column {
  padding: 10px;
}

.image-column img {
  width: 100%;
  height: auto;
}

.inline-image {
  width: 100%; /* Ensures the image is not wider than the text column */
  max-height: 200px; /* Limits the height of the image */
  object-fit: cover; /* Ensures the image covers the area without distorting aspect ratio */
  margin-top: 10px; /* Adds space between the text and the image */
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 400px;
  background-size: cover;
  background-position: center;
  color: white;
}

/* Default mobile image */
.hero {
  background-image: url('@/assets/HERO-EUFUNDING-MOBILE.webp');
}

.hero h1 {
  margin: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2); /* Optional: darkens the background for better readability */
  color: rbg(255,255,255); /* Sets the text color to white for contrast */
}

.hero h2 {
  margin: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2); /* Optional: darkens the background for better readability */
  color: rbg(255,255,255); /* Sets the text color to white for contrast */
}

@media (min-width: 900px) {
  .container {
    padding-left: 15px; /* Inner spacing */
    padding-right: 15px; /* Inner spacing */
    margin-left: auto; /* Centering the container */
    margin-right: auto; /* Centering the container */
    max-width: 1980px; /* Adjust this value based on your design needs */
  }

  .hero {
    background-image: url('@/assets/HERO-EUFUNDING-COMPUTER.webp');
  }
  .content-row {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Equal division for text and image */
    gap: 0; /* No gap between columns */
    align-items: center; /* Vertically center the content within each grid */
  }

  .content-row:nth-child(odd) {
    grid-template-areas: "text image";
  }

  .content-row:nth-child(odd) .text-column {
    grid-area: text;
    order: 1; /* Text first */
    padding-left: 30px;
    padding-right: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .content-row:nth-child(odd) .image-column {
    grid-area: image;
    order: 2; /* Image second */
    padding: 0; /* Full width image */
  }

  .content-row:nth-child(even) {
    grid-template-areas: "image text";
  }

  .content-row:nth-child(even) .image-column {
    grid-area: image;
    order: 1; /* Image first */
    padding: 0; /* Full width image */
  }

  .content-row:nth-child(even) .text-column {
    grid-area: text;
    order: 2; /* Text second */
    padding-left: 40px;
    padding-right: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

/* Mobile and Tablet Views: Always text first, then image */
@media (max-width: 899px) {
  .content-row .text-column {
    order: 1; /* Ensures text is always first */
  }

  .content-row .image-column {
    order: 2; /* Ensures image is always second */
  }
  .container {
    margin-left: auto; /* Centering the container */
    margin-right: auto; /* Centering the container */
    max-width: 899px; /* Adjust this value based on your design needs */
  }
}

@media (min-width: 1200px) {
  .hero {
    background-image: url('@/assets/HERO-EUFUNDING-COMPUTER.webp');
  }
}


</style>
