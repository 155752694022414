<template>
    <div class="av-simulation-container">
      <div class="ladder-logic-section">
        <!-- Wrap LLBuilder in a box with margin and background color -->
        <div class="ladder-logic-box">
          <LLBuilder :showHeader="false" :showLeftColumn="false" />
        </div>
      </div>
      <div class="vr-scenario-section">
        <div class="vr-scenario-container">
          <component :is="currentVRScene" />
        </div>
      </div>
    </div>
  </template>
  
  
  
  <script>
  import { computed, watch, onMounted, onUnmounted } from 'vue';
  import { useStore } from 'vuex';
  import LLBuilder from './LLBuilder.vue';
  import { vrScenarios } from './vrScenarios.js';
  import { avScenarios } from './avScenarios.js'; // Import AV scenarios
  
  export default {
    name: 'AVSimulation',
    components: {
      LLBuilder,
    },
    setup() {
      const store = useStore();
  
      // Get the selected VR/AV scenario from the Vuex store
      const digitalTwinData = store.getters.getDigitalTwin;
  
      const currentVRScene = computed(() => {
        if (digitalTwinData && digitalTwinData.vrScenarioId) {
          // Check for a match in VR scenarios
          let scenario = vrScenarios.find((s) => s.id === digitalTwinData.vrScenarioId);
          // If not found in VR scenarios, check in AV scenarios
          if (!scenario) {
            scenario = avScenarios.find((s) => s.id === digitalTwinData.vrScenarioId);
          }
          return scenario ? scenario.component : null;
        }
        return null;
      });
  
      // Watch for changes in VR tags and update ladder logic variables
      const vrTagsTable = computed(() => store.getters.getIIoTDataSources.vrTagsTable);
      const variablesTable = computed(() => store.getters.getIIoTDataSources.variablesTable);
  
      watch(
        vrTagsTable,
        (newTags, oldTags) => {
          if (!oldTags) return; // Skip the initial run
          newTags.forEach((newTag) => {
            const oldTag = oldTags.find((t) => t.name === newTag.name);
            if (oldTag && newTag.value !== oldTag.value) {
              if (newTag.linkId !== undefined && newTag.value !== null) {
                const linkedVariable = variablesTable.value.find(
                  (variable) => variable.linkId === newTag.linkId
                );
                if (linkedVariable) {
                  store.dispatch("toggleLadderLogicContact", {
                    elementId: linkedVariable.id,
                    newState: newTag.value,
                  });
                }
              }
            }
          });
        },
        { deep: true }
      );
  
      // Handle VR Tag Updates
      const handleVrTagUpdate = (tagName, value) => {
        store.dispatch('updateVrTagValue', { tagName, value });
      };
  
      // Event Listeners for start and stop button events
      const startButtonListener = () => {
        handleVrTagUpdate('Start Button', true);
        setTimeout(() => handleVrTagUpdate('Start Button', false), 100);
      };
  
      const stopButtonListener = () => {
        handleVrTagUpdate('Stop Button', true);
        setTimeout(() => handleVrTagUpdate('Stop Button', false), 100);
      };
  
      onMounted(() => {
        window.addEventListener('start-button-pressed', startButtonListener);
        window.addEventListener('stop-button-pressed', stopButtonListener);
      });
  
      onUnmounted(() => {
        window.removeEventListener('start-button-pressed', startButtonListener);
        window.removeEventListener('stop-button-pressed', stopButtonListener);
      });
  
      return {
        currentVRScene,
      };
    },
  };
  </script>
  
  
  <style scoped>
  .av-simulation-container {
    display: flex;
    flex-direction: column; /* Ladder logic on top, VR scenario below */
    height: 100vh;
  }
  
  .ladder-logic-section {
  flex: none;
  height: 50vh; /* Occupies 50% of viewport height */
}

.vr-scenario-section {
  flex: none;
  height: 50vh; /* Occupies the remaining 50% */
}
  
  .ladder-logic-box {
    width: 95%; /* Adjust as needed */
    height: 95%; /* Adjust as needed */
    background-color: #fff; /* White background inside the box */
    border: 3px solid #ffc300; /* Margin color */
    box-sizing: border-box; /* Include border in size calculations */
  }
  
  .ladder-logic-box > * {
    width: 100%;
    height: 100%;
  }

  .vr-scenario-container {
  width: 95%; /* Adjust as needed */
  height: 95%; /* Adjust as needed */
  background-color: #fff; /* Optional */
  border: 3px solid #ffc300; /* Match the ladder logic box */
  box-sizing: border-box;
}

.vr-scenario-container > * {
  width: 100%;
  height: 100%;
}

  </style>
