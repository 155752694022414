export function getCBLevel1VRTags() {
    return [
      { id: "start", name: "Start Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
      { id: "stop", name: "Stop Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
      { id: "motor1", name: "Motor 1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    ];
  }

  export function getCBLevel2VRTags() {
    return [
      { id: "start", name: "Start Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
      { id: "stop", name: "Stop Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
      { id: "motor1", name: "Motor 1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
      { id: "processLights", name: "Process Active Lights", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    ];
  }
  
export function getCBLevel3VRTags() {
  return [
    { id: "start", name: "Start Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "stop", name: "Stop Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "motor1", name: "Motor 1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "processLights", name: "Process Active Lights", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor1", name: "Sensor 1", type: "Input", dataType: "Bool", value: false, linkId: undefined },
  ];
}

export function getCBLevel4VRTags() {
  return [
    { id: "start", name: "Start Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "stop", name: "Stop Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "motor1", name: "Motor 1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "processLights", name: "Process Active Lights", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor1", name: "Sensor 1", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "warningLamp1", name: "Warning_Lamp_1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
  ];
}

export function getCBLevel5VRTags() {
  return [
    { id: "start", name: "Start Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "stop", name: "Stop Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "motor1", name: "Motor 1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "processLights", name: "Process Active Lights", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor1", name: "Sensor 1", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "warningLamp1", name: "Warning_Lamp_1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor2", name: "Sensor 2", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "warningLamp2", name: "Warning_Lamp_2", type: "Output", dataType: "Bool", value: false, linkId: undefined },
  ];
}

export function getCBLevel6VRTags() {
  return [
    { id: "start", name: "Start Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "stop", name: "Stop Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "motor1", name: "Motor 1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "processLights", name: "Process Active Lights", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor1", name: "Sensor 1", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "warningLamp1", name: "Warning_Lamp_1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor2", name: "Sensor 2", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "warningLamp2", name: "Warning_Lamp_2", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "counter1", name: "Counter 1", type: "Counter", dataType: "Integer", value: false, linkId: undefined, preset: undefined, accumulated: undefined },

  ];
}
export function getCBLevel7VRTags() {
  return [
    { id: "start", name: "Start Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "stop", name: "Stop Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "motor1", name: "Motor 1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "processLights", name: "Process Active Lights", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor1", name: "Sensor 1", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "warningLamp1", name: "Warning_Lamp_1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor2", name: "Sensor 2", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "warningLamp2", name: "Warning_Lamp_2", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "counter1", name: "Counter 1", type: "Counter", dataType: "Integer", value: false, linkId: undefined, preset: undefined, accumulated: undefined },
    { id: "sensor3", name: "Sensor 3", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "warningLamp3", name: "Warning_Lamp_3", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor4", name: "Sensor 4", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "warningLamp4", name: "Warning_Lamp_4", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor5", name: "Sensor 5", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "warningLamp5", name: "Warning_Lamp_5", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor6", name: "Sensor 6", type: "Input", dataType: "Bool", value: false, linkId: undefined },


  ];
}
export function getCBLevel8VRTags() {
  return [
    { id: "start", name: "Start Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "stop", name: "Stop Button", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "motor1", name: "Motor 1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "processLights", name: "Process Active Lights", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor1", name: "Sensor 1", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "warningLamp1", name: "Warning_Lamp_1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor2", name: "Sensor 2", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "warningLamp2", name: "Warning_Lamp_2", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "counter1", name: "Counter 1", type: "Counter", dataType: "Integer", value: false, linkId: undefined, preset: undefined, accumulated: undefined },
    { id: "sensor3", name: "Sensor 3", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "warningLamp3", name: "Warning_Lamp_3", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor4", name: "Sensor 4", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "warningLamp4", name: "Warning_Lamp_4", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor5", name: "Sensor 5", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "warningLamp5", name: "Warning_Lamp_5", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "sensor6", name: "Sensor 6", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "motor2", name: "Motor 2", type: "Output", dataType: "Bool", value: false, linkId: undefined },

  ];
}
export function getSBGLC2030Level1VRTags() {
  return [
    { id: "HS2_Button", name: "Button HS2", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "HS3_Button", name: "Button HS3", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "HS4_Button", name: "Button HS4", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "HS5_Button", name: "Button HS5", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "pumpM4", name: "Pump M4", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "LampM4", name: "Lamp M4", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "HeaterQ1", name: "Heater Q1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "LampQ1", name: "Lamp Q1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "LampSensorB3", name: "Lamp B3", type: "Output", dataType: "Bool", value: false, linkId: undefined },

  ];
}

export function getSBGLC2030Level2VRTags() {
  return [
    { id: "HS4_Button", name: "Button HS4", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "HS5_Button", name: "Button HS5", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "pumpM3", name: "Pump M3", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "LampM3", name: "Lamp M3", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "LampSensorB3", name: "Lamp B3", type: "Output", dataType: "Bool", value: false, linkId: undefined },
  ];
}

export function getSBGLC2030Level3VRTags() {
  return [
    { id: "HS4_Button", name: "Button HS4", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "HS5_Button", name: "Button HS5", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "pumpM1", name: "Pump M1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "LampM1", name: "Lamp M1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "pumpM2", name: "Pump M2", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "LampM2", name: "Lamp M2", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "LampSensorB1", name: "Lamp B1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "LampSensorB2", name: "Lamp B2", type: "Output", dataType: "Bool", value: false, linkId: undefined },

  ];
}

export function getSBGLC2030Level4VRTags() {
  return [
    { id: "HS1_Button", name: "Button HS1", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "HS2_Button", name: "Button HS2", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "HS3_Button", name: "Button HS3", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "HS4_Button", name: "Button HS4", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "HS5_Button", name: "Button HS5", type: "Input", dataType: "Bool", value: false, linkId: undefined },
    { id: "pumpM4", name: "Pump M4", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "LampM4", name: "Lamp M4", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "HeaterQ1", name: "Heater Q1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "LampQ1", name: "Lamp Q1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "pumpM3", name: "Pump M3", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "LampM3", name: "Lamp M3", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "LampSensorB3", name: "Lamp B3", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "pumpM1", name: "Pump M1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "LampM1", name: "Lamp M1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "pumpM2", name: "Pump M2", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "LampM2", name: "Lamp M2", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "LampSensorB1", name: "Lamp B1", type: "Output", dataType: "Bool", value: false, linkId: undefined },
    { id: "LampSensorB2", name: "Lamp B2", type: "Output", dataType: "Bool", value: false, linkId: undefined },
  ];
}