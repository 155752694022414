<template> 
  <div class="vr-container">
    <!-- Loading Screen -->
    <div v-if="loading" class="loading-screen">
      <div class="loading-logo-container">
        <svg class="progress-circle" viewBox="0 0 36 36">
          <path
            class="circle-bg"
            d="M18 2.0845
               a 15.9155 15.9155 0 0 1 0 31.831
               a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            class="circle"
            stroke-dasharray="100, 100"
            :stroke-dashoffset="100 - loadingPercentage"
            d="M18 2.0845
               a 15.9155 15.9155 0 0 1 0 31.831
               a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <g transform="rotate(90 18 18)">
            <image :href="logo" x="8" y="8" height="20" width="20" />
          </g>
        </svg>
        <div class="loading-text">Loading... {{ loadingPercentage }}%</div>
      </div>
    </div>

    <!-- Babylon.js canvas -->
    <canvas ref="renderCanvas" class="canvas-container"></canvas>
  </div>
</template>

<script>
import {
  Engine,
  Scene,
  ArcRotateCamera,
  DirectionalLight,
  HemisphericLight,
  Vector3,
  SceneLoader,
  ShadowGenerator,
  Color3,
  Texture,
  Animation,
  StandardMaterial,
  ActionManager,
  ExecuteCodeAction,
  Sound,
} from "@babylonjs/core";
import "@babylonjs/core/XR";
import "@babylonjs/loaders/glTF";
import { useStore } from "vuex";
import { ref, watch, onMounted, onUnmounted } from 'vue';
import logo from '@/assets/Logo_Medusa_Digital_Dark.webp'; 

export default {
  name: "CB-Level1",
  setup() {
    const store = useStore();
    const renderCanvas = ref(null);
    const loading = ref(true);
    const loadingPercentage = ref(0);

    let engine = null;
    

    // Reactive variables
    const conveyorSpeed = 1.5;
    const conveyorRunning = ref(false);
    let conveyorAnimationObserver = null;
    let scene = null;
    let meshes = null;

    // Wood models and animations
    const woodModels = {};
    const animationsQueue = [];
    let currentAnimationIndex = 0;
    let currentAnimation = null;

    // Sound variables
    let buttonClickSound = null;
    let factorySound = null;

    // Babylon.js scene initialization
    const initBabylonScene = async () => {
      const canvas = renderCanvas.value;
      engine = new Engine(canvas, true, { preserveDrawingBuffer: true });
      engine.useIdleDetection = false; // Disable idle detection
      scene = new Scene(engine);
      scene.clearColor = new Color3(0.95, 0.95, 0.95);

      const camera = new ArcRotateCamera(
        "camera",
        0,
        Math.PI / 2.5,
        5,
        new Vector3(5, 1, -3.85),
        scene
      );
      camera.attachControl(canvas, true);

      const light = new DirectionalLight("dirLight", new Vector3(0, -1, 1), scene);
      light.position = new Vector3(0, 5, -10);
      light.intensity = 0.6;

      const hemisphericLight = new HemisphericLight("hemiLight", new Vector3(0, 1, 0), scene);
      hemisphericLight.intensity = 0.3;

      const environment = scene.createDefaultEnvironment({
        enableGroundShadow: true,
        groundYBias: 2.8,
      });
      environment.setMainColor(new Color3(0.95, 0.95, 0.95));
      if (environment.skybox) {
        environment.skybox.material.alpha = 0; // Make the ground transparent
      }
      if (environment.ground) {
        environment.ground.isVisible = false; // Hide the ground
      }

      const shadowGenerator = new ShadowGenerator(512, light);
      shadowGenerator.useBlurExponentialShadowMap = true;
      shadowGenerator.blurKernel = 32;

      // Load sounds
      buttonClickSound = new Sound(
        "buttonClick",
        "/assets/button_click.mp3",
        scene,
        null,
        { autoplay: false }
      );

      factorySound = new Sound(
        "factorySound",
        "/assets/factory_sound.mp3",
        scene,
        null,
        { loop: true, autoplay: false }
      );

      // Load the GLTF file
      await new Promise((resolve, reject) => {
        SceneLoader.ImportMesh(
          "",
          "/assets/",
          "ConveyorB.gltf",
          scene,
          (loadedMeshes) => {
            let conveyorRoot = loadedMeshes[0];
            conveyorRoot.position.y = 2.5;

            const meshMap = {};
            loadedMeshes.forEach((mesh) => {
              meshMap[mesh.name] = mesh;
            });

            loadedMeshes.forEach((mesh) => {
              if (mesh.name.startsWith("RailC_") && mesh.getTotalVertices() > 0) {
                shadowGenerator.addShadowCaster(mesh);
                // Physics removed
              }

              if (mesh.name.startsWith("RailC_")) {
                const material = mesh.material;
                if (material) {
                  if (!material.albedoTexture) {
                    material.albedoTexture = new Texture("/assets/Rail_AlbedoTransparency.png", scene);
                    material.bumpTexture = new Texture("/assets/Rail_Normal.png", scene);
                    material.specularTexture = new Texture("/assets/Rail_SpecularSmoothness.png", scene);
                  }
                }
              }

              if (mesh.name.startsWith("PlatA_") || mesh.name.startsWith("PlatB_")) {
                shadowGenerator.addShadowCaster(mesh);
                // Physics removed
              }
            });

            const startButton = meshMap["Button_Start_1"];
            if (startButton) {
              setupButtonInteraction(startButton, scene, "start");
            }

            const stopButton = meshMap["Button_Stop_1"];
            if (stopButton) {
              setupButtonInteraction(stopButton, scene, "stop");
            }

            meshes = loadedMeshes;

            resolve();
          },
          (event) => {
            // Update the loading percentage
            if (event.lengthComputable) {
              loadingPercentage.value = Math.floor((event.loaded / event.total) * 100);
            } else {
              loadingPercentage.value = Math.floor((event.loaded / 20000) * 100); // Fallback if length is not computable
            }
          },
          (scene, message) => {
            reject(message);
          }
        );
      });
      

      // Load Wood models
      const loadWoodModel = (name, fileName) => {
        return new Promise((resolve, reject) => {
          SceneLoader.ImportMesh(
            "",
            "/assets/",
            fileName,
            scene,
            (meshes) => {
              const woodMesh = meshes[0];
              woodMesh.setEnabled(false); // Initially disabled
              woodMesh.scaling.scaleInPlace(2); // Double the size
              woodModels[name] = woodMesh;
              resolve();
            },
            null,
            (scene, message) => {
              reject(message);
            }
          );
        });
      };

      await Promise.all([
        loadWoodModel("Wood_1", "Wood_1.gltf"),
        loadWoodModel("Wood_2", "Wood_2.gltf"),
        loadWoodModel("Wood_3", "Wood_3.gltf"),
        loadWoodModel("Wood_4", "Wood_4.gltf"),
      ]);

      // Prepare animations
      prepareWoodAnimations();

      let isXRSupported = await checkXRSupport();
      if (isXRSupported) {
        const xrHelper = await scene.createDefaultXRExperienceAsync({
          floorMeshes: [environment.ground],
        });
       // xrHelper.baseExperience.onStateChangedObservable.add((state) => {
          // console.log("XR State Changed:", state);
        //});
        xrHelper.teleportation.addFloorMesh(environment.ground);
      }

      engine.runRenderLoop(() => {
  scene.render();  // Keep rendering the scene

});


      window.addEventListener("resize", () => {
        engine.resize();
      });
      // Hide the loader when loading is complete
      loading.value = false;
      engine.resize();
    };


    // Setup button interactions with correct animation handling
    const setupButtonInteraction = (buttonMesh, scene, type) => {
      const colorMesh = buttonMesh.getChildMeshes().find((m) => m.name === `${buttonMesh.name}_Color`) || buttonMesh;
      const originalMaterial = colorMesh.material;
      const pressedMaterial = new StandardMaterial(`pressedMaterial_${type}`, scene);

      pressedMaterial.diffuseColor = type === "start" ? new Color3(0.5, 1, 0.5) : new Color3(1, 0.5, 0.5);

      buttonMesh.actionManager = new ActionManager(scene);
      buttonMesh.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
          // Play button click sound
          if (buttonClickSound) {
            buttonClickSound.play();
          }

          // Animate button press and release
          colorMesh.material = pressedMaterial;
          const downPosition = buttonMesh.position.clone();
          downPosition.y -= 0.05;

          const pressDownAnimation = new Animation("pressDownAnimation", "position", 30, Animation.ANIMATIONTYPE_VECTOR3);
          pressDownAnimation.setKeys([
            { frame: 0, value: buttonMesh.position.clone() },
            { frame: 5, value: downPosition },
          ]);

          const releaseAnimation = new Animation("releaseAnimation", "position", 30, Animation.ANIMATIONTYPE_VECTOR3);
          releaseAnimation.setKeys([
            { frame: 0, value: downPosition },
            { frame: 5, value: buttonMesh.position.clone() },
          ]);

          scene.beginDirectAnimation(buttonMesh, [pressDownAnimation], 0, 5, false, 1.0, () => {
            scene.beginDirectAnimation(buttonMesh, [releaseAnimation], 0, 5, false, 1.0, () => {
              colorMesh.material = originalMaterial;
            });
          });

          // Dispatch event or update store based on button type
          if (type === "start") {
            handleButtonPress("Start Button");
          } else if (type === "stop") {
            handleButtonPress("Stop Button");
          }
        })
      );
    };

    const handleButtonPress = (buttonName) => {
      const iiotDataSources = store.getters.getIIoTDataSources;
      const linkedVrTag = iiotDataSources.vrTagsTable.find((tag) => tag.name === buttonName);

      if (linkedVrTag && linkedVrTag.direction === 'input') {
        store.dispatch("updateVrTagValue", { tagName: buttonName, value: true });

        setTimeout(() => {
          store.dispatch("updateVrTagValue", { tagName: buttonName, value: false });
        }, 100);
      } 
    };

    // Start the conveyor animation
    const startConveyor = () => {
      if (conveyorRunning.value) return;
      conveyorRunning.value = true;

      if (!conveyorAnimationObserver) {
        conveyorAnimationObserver = scene.onBeforeRenderObservable.add(() => {
          meshes.forEach((mesh) => {
            if (mesh.name.startsWith("RailC_")) {
              const material = mesh.material;
              if (material && material.albedoTexture) {
                material.albedoTexture.uOffset += conveyorSpeed * 0.0105;
              }
            }
          });
        });
      }

      // Play factory sound
      if (factorySound) {
        factorySound.play();
      }

      // Start or resume wood animations
      if (currentAnimation && currentAnimation.paused) {
        currentAnimation.restart();
      } else {
        playWoodAnimations();
      }
    };

    // Stop the conveyor animation
    const stopConveyor = () => {
      if (!conveyorRunning.value) return;
      conveyorRunning.value = false;

      if (conveyorAnimationObserver) {
        scene.onBeforeRenderObservable.remove(conveyorAnimationObserver);
        conveyorAnimationObserver = null;
      }

      if (currentAnimation) {
        currentAnimation.pause();
      }

      // Stop factory sound
      if (factorySound && factorySound.isPlaying) {
        factorySound.stop();
      }
    };

    // Prepare wood animations
    const prepareWoodAnimations = () => {
      // Define each animation as an object in the animationsQueue
      // Animation_1
      animationsQueue.push({
        wood: woodModels["Wood_1"],
        startFrame: 0,
        endFrame: 120,
        positionKeys: [
          { frame: 0, value: new Vector3(7.04, 1.01, -5.08) },
          { frame: 120, value: new Vector3(7.04, 1.01, -1.08) },
        ],
        rotationKeys: [
          { frame: 0, value: new Vector3(0, -3.09, 0) },
          { frame: 120, value: new Vector3(0, -3.09, 0) },
        ],
      });

      // Animation_2
      animationsQueue.push({
        wood: woodModels["Wood_2"],
        startFrame: 0,
        endFrame: 240,
        positionKeys: [],
        rotationKeys: [],
      });

      const positions2 = [
        { x: 7.04, y: 1.01, z: -1.08, rotY: 3.09 },
        { x: 7.04, y: 1.01, z: -0.48, rotY: 3.09 },
        { x: 7.04, y: 1.01, z: 0.12, rotY: 3.09 },
        { x: 7.04, y: 1.01, z: 0.72, rotY: 3.09 },
        { x: 6.84, y: 1.01, z: 1.12, rotY: 2.59 },
        { x: 6.54, y: 1.01, z: 1.52, rotY: 2.19 },
        { x: 6.14, y: 1.01, z: 1.82, rotY: 1.84 },
        { x: 5.74, y: 1.01, z: 2.02, rotY: 1.54 },
        { x: 5.34, y: 1.01, z: 2.02, rotY: 1.29 },
        { x: 4.94, y: 1.01, z: 1.92, rotY: 0.99 },
        { x: 4.54, y: 1.01, z: 1.62, rotY: 0.74 },
        { x: 4.24, y: 1.01, z: 1.32, rotY: 0.34 },
        { x: 4.04, y: 1.01, z: 0.72, rotY: 0.19 },
        { x: 4.04, y: 1.01, z: 0.12, rotY: 0.01 },
        { x: 4.04, y: 1.01, z: -0.98, rotY: 0.01 },
      ];
      const totalFrames2 = 240;
      const frameIncrement2 = totalFrames2 / (positions2.length - 1);

      positions2.forEach((pos, index) => {
        animationsQueue[1].positionKeys.push({ frame: index * frameIncrement2, value: new Vector3(pos.x, pos.y, pos.z) });
        animationsQueue[1].rotationKeys.push({ frame: index * frameIncrement2, value: new Vector3(0, pos.rotY, 0) });
      });

      // Animation_3
      animationsQueue.push({
        wood: woodModels["Wood_3"],
        startFrame: 0,
        endFrame: 90,
        positionKeys: [
          { frame: 0, value: new Vector3(4.04, 1.01, -0.98) },
          { frame: 90, value: new Vector3(4.04, 1.01, -2.98) },
        ],
        rotationKeys: [
          { frame: 0, value: new Vector3(0, 3.14, 0) },
          { frame: 90, value: new Vector3(0, 3.14, 0) },
        ],
      });

      // Animation_4
      animationsQueue.push({
        wood: woodModels["Wood_3"],
        startFrame: 0,
        endFrame: 90,
        positionKeys: [
          { frame: 0, value: new Vector3(4.04, 1.01, -2.98) },
          { frame: 90, value: new Vector3(4.04, 1.01, -4.98) },
        ],
        rotationKeys: [
          { frame: 0, value: new Vector3(0, 3.14, 0) },
          { frame: 90, value: new Vector3(0, 3.14, 0) },
        ],
      });

      // Animation_5
      animationsQueue.push({
        wood: woodModels["Wood_4"],
        startFrame: 0,
        endFrame: 240,
        positionKeys: [],
        rotationKeys: [],
      });

      const positions5 = [
        { x: 4.04, y: 0.95, z: -4.98, rotY: -3.14 },
        { x: 3.94, y: 0.91, z: -5.78, rotY: -2.84 },
        { x: 3.64, y: 0.91, z: -6.48, rotY: -2.39 },
        { x: 2.94, y: 0.91, z: -6.88, rotY: -1.89 },
        { x: 2.04, y: 0.91, z: -6.88, rotY: -1.29 },
        { x: 1.34, y: 0.91, z: -6.38, rotY: -0.64 },
        { x: 1.04, y: 0.91, z: -4.98, rotY: 0.01 },
      ];
      const totalFrames5 = 240;
      const frameIncrement5 = totalFrames5 / (positions5.length - 1);

      positions5.forEach((pos, index) => {
        animationsQueue[4].positionKeys.push({ frame: index * frameIncrement5, value: new Vector3(pos.x, pos.y, pos.z) });
        animationsQueue[4].rotationKeys.push({ frame: index * frameIncrement5, value: new Vector3(0, pos.rotY % (2 * Math.PI), 0) });
      });

      // Animation_6
      animationsQueue.push({
        wood: woodModels["Wood_4"],
        startFrame: 0,
        endFrame: 90,
        positionKeys: [
          { frame: 0, value: new Vector3(1.04, 0.91, -4.98) },
          { frame: 90, value: new Vector3(1.04, 0.91, -2.88) },
        ],
        rotationKeys: [
          { frame: 0, value: new Vector3(0, 0.01, 0) },
          { frame: 90, value: new Vector3(0, 0.01, 0) },
        ],
      });

      // Assign animations to models
      animationsQueue.forEach((animationObj) => {
        const animPosition = new Animation(
          `${animationObj.wood.name}_PositionAnimation_${animationObj.startFrame}`,
          "position",
          30,
          Animation.ANIMATIONTYPE_VECTOR3,
          Animation.ANIMATIONLOOPMODE_CONSTANT
        );

        const animRotation = new Animation(
          `${animationObj.wood.name}_RotationAnimation_${animationObj.startFrame}`,
          "rotation",
          30,
          Animation.ANIMATIONTYPE_VECTOR3,
          Animation.ANIMATIONLOOPMODE_CONSTANT
        );

        animPosition.setKeys(animationObj.positionKeys);
        animRotation.setKeys(animationObj.rotationKeys);

        animationObj.animations = [animPosition, animRotation];
      });
    };

    // Play wood animations in sequence
    const playWoodAnimations = () => {
      currentAnimationIndex = 0;
      playNextAnimation();
    };

    const playNextAnimation = () => {
      if (currentAnimationIndex >= animationsQueue.length) {
        // All animations finished
        currentAnimation = null;
        return;
      }

      const animationObj = animationsQueue[currentAnimationIndex];
      const wood = animationObj.wood;

      // Reset wood model
      wood.setEnabled(true);
      wood.position.copyFrom(animationObj.positionKeys[0].value);
      wood.rotation.copyFrom(animationObj.rotationKeys[0].value);

      // Hide previous wood models if different
      animationsQueue.forEach((animObj, index) => {
        if (index !== currentAnimationIndex && animObj.wood !== wood) {
          animObj.wood.setEnabled(false);
        }
      });

      currentAnimation = scene.beginDirectAnimation(
        wood,
        animationObj.animations,
        animationObj.startFrame,
        animationObj.endFrame,
        false,
        conveyorSpeed,
        () => {
          // If wood model is different in next animation, disable current wood
          if (currentAnimationIndex + 1 < animationsQueue.length) {
            const nextWood = animationsQueue[currentAnimationIndex + 1].wood;
            if (nextWood !== wood) {
              wood.setEnabled(false);
            }
          } else {
            wood.setEnabled(false);
          }

          currentAnimationIndex++;
          playNextAnimation();
        }
      );
    };

    // Check for WebXR support
    const checkXRSupport = async () => {
      if (navigator.xr) {
        try {
          const isSupported = await navigator.xr.isSessionSupported("immersive-vr");
          return isSupported;
        } catch (error) {
          console.error("Error checking WebXR support:", error);
          return false;
        }
      }
      return false;
    };

    const unifiedWatcher = () => {
      watch(
        () => store.getters.getIIoTDataSources.vrTagsTable,
        (newVrTags) => {
          const motorVrTag = newVrTags.find((tag) => tag.name === "Motor 1" && tag.direction === "output");
          
          if (motorVrTag) {
            if (motorVrTag.value && !conveyorRunning.value) {
              startConveyor();
            } else if (!motorVrTag.value && conveyorRunning.value) {
              stopConveyor();
            }
          } 
        },
        { deep: true }
      );
    };

    onMounted(() => {
      initBabylonScene().then(() => {
        const motorVrTag = store.getters.getIIoTDataSources.vrTagsTable.find(
          (tag) => tag.name === "Motor 1" && tag.direction === "output"
        );

        if (motorVrTag) {
          conveyorRunning.value = motorVrTag.value;
          if (conveyorRunning.value) {
            startConveyor();
          }
        }
        unifiedWatcher();

            // Sound to play when the scene starts
    const sphereSound = new Sound(
      "sphereSound",
      "/assets/factory_sound_1.mp3", // Adjust path if necessary
      scene,
      () => {
        // Play sound when it's loaded
        sphereSound.play();
      },
      { loop: true, autoplay: false, preload: true }
    );


      });
    });

    // Cleanup in onUnmounted
    onUnmounted(() => {
      if (conveyorAnimationObserver) {
        scene.onBeforeRenderObservable.remove(conveyorAnimationObserver);
      }
      // Dispose sounds
      if (buttonClickSound) {
        buttonClickSound.dispose();
      }
      if (factorySound) {
        factorySound.dispose();
      }
      // Dispose animations
      if (currentAnimation) {
        currentAnimation.stop();
        currentAnimation = null;
      }
      // Dispose wood models
      Object.values(woodModels).forEach((wood) => {
        wood.dispose();
      });
      // Dispose scene and engine
      if (scene) {
        scene.dispose();
      }
      if (engine) {
        engine.dispose();
      }
    });
  
    return {
      renderCanvas,
      logo,
      loading,
      loadingPercentage,
    };
  },
};
</script>

<style scoped>
.vr-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  display: block;
}

.canvas-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Loading Screen Styles */
.loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #000814, #0d1b2a);
  color: #ffd60a;
  text-align: center;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-text {
  margin-top: 20px;
  font-size: 24px;
  color: #ffd60a;
}

/* Circular progress bar */
.progress-circle {
  width: 150px;
  height: 150px;
  transform: rotate(-90deg);
}

.circle-bg {
  fill: none;
  stroke: #444;
  stroke-width: 2.8;
}

.circle {
  fill: none;
  stroke: #ffd60a;
  stroke-width: 2.8;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.3s;
}
</style>
