<template>
  <div class="hero dark-background">
    <h1>VR & Augmented Virtuality</h1>
    <h2>Redefining Industrial Automation Training</h2>
  </div>

  <div class="container white-background">
    <!-- Row 1: Virtual Reality in Industry 4.0 Training -->
    <div class="content-row">
      <div class="text-column">
        <h2>Virtual Reality in Industry 4.0 Training</h2>
        <p> In the Industry 4.0 era, Virtual Reality (VR) is essential for industrial automation training because it offers immersive, hands-on experience without the risks associated with physical machinery. VR enables trainees to interact with realistic simulations of complex industrial systems, enhancing understanding and retention. It also allows for flexible, scalable learning opportunities, reducing downtime and costs. By integrating VR, companies can rapidly upskill their workforce, boost productivity, and innovate safely, ensuring they remain competitive in a rapidly evolving technological landscape</p>
      </div>
      <div class="image-column">
         <picture>
          <source media="(min-width: 1200px)" srcset="@/assets/VR-HOME-COMPUTER.webp">
          <source media="(min-width: 900px)" srcset="@/assets/VR-HOME-COMPUTER.webp">
          <source media="(max-width: 899px)" srcset="@/assets/VR-HOME-MOBILE.webp">
          <img src="@/assets/VR-HOME-COMPUTER.webp" alt="VR in Industry 4.0 Training">
        </picture>
      </div>
    </div>

    <!-- Row 2: AV in Industry -->
    <div class="content-row">
      <div class="image-column">
        <picture>
          <source media="(min-width: 1200px)" srcset="@/assets/AV-HOME-COMPUTER.webp">
          <source media="(min-width: 900px)" srcset="@/assets/AV-HOME-COMPUTER.webp">
          <source media="(max-width: 899px)" srcset="@/assets/AV-HOME-MOBILE.webp">
          <img src="@/assets/AV-HOME-COMPUTER.webp" alt="AV in Industry 4.0 Training">
        </picture>
      </div>
      <div class="text-column">
        <h2>Augmented Virtuality for Industry 4.0 Skills</h2>
        <p>Augmented Virtuality (AV) is crucial for industrial automation training in the Industry 4.0 era as it merges real-world elements with virtual enhancements, enabling trainees to interact with both physical components and simulated data. This dual interaction fosters practical problem-solving skills and enhances decision-making in real-time scenarios. AV facilitates continuous feedback and adaptation, improving operational accuracy and efficiency. It uniquely supports the transition from digital simulations to actual control of industrial systems, preparing workers for the complexities of modern manufacturing environments.</p>
      </div>
    </div>

    <!-- Row 3: YAGA 1 -->
    <div class="content-row">
      <div class="text-column">
        <h2>Introducing YagaCore: Augmented Virtuality Programming Excellence</h2>
        <p>Yaga, our innovative SaaS platform, revolutionizes how students worldwide learn to program PLCs using ladder diagrams and Structured Text languages. Leveraging both VR and AV technologies, Yaga enables learners to design and test their programs using our unique VR HMI, accessible via any website or through Meta Quest 3 headsets. This state-of-the-art tool empowers students to practice and perfect their programming skills in a highly immersive and interactive environment.</p>
        <a href="https://medusadigital.com/AugmentedVirtualityTraining" class="cta-button">Know more about YagaCore</a>
      </div>
      <div class="image-column">
        <picture>
          <source media="(min-width: 1200px)" srcset="@/assets/YAGA-HOME-COMPUTER.webp">
          <source media="(min-width: 900px)" srcset="@/assets/YAGA-HOME-COMPUTER.webp">
          <source media="(max-width: 899px)" srcset="@/assets/YAGA-HOME-MOBILE.webp">
          <img src="@/assets/YAGA-HOME-COMPUTER.webp" alt="YAGA VR TRAINING FOR INDUSTRIAL AUTOMATION">
        </picture>
      </div>
    </div>

    <!-- Row 4: YAGA 2 -->
    <div class="content-row">
      <div class="image-column">
         <picture>
          <source media="(min-width: 1200px)" srcset="@/assets/YAGA-2-HOME-COMPUTER.webp">
          <source media="(min-width: 900px)" srcset="@/assets/YAGA-2-HOME-COMPUTER.webp">
          <source media="(max-width: 899px)" srcset="@/assets/YAGA-2-HOME-MOBILE.webp">
          <img src="@/assets/YAGA-2-HOME-COMPUTER.webp" alt="YAGA VR TRAINING FOR INDUSTRIAL AUTOMATION">
        </picture>
      </div>
      <div class="text-column">
        <h2>Seamless VR to Real-World Integration</h2>
        <p>Yaga extends beyond training by connecting seamlessly with real-world industrial systems through Industrial Ethernet and other communication protocols. This capability allows our VR HMI to control actual industrial instruments remotely, bridging the gap between virtual training and industrial operation. By simulating real plant operations, Yaga provides a unique, hands-on experience that prepares users for direct involvement in managing and optimizing modern industrial processes.</p>
      </div>
    </div>
   </div>

</template>

<script>
export default {
  name: 'HomeView',
};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;
}

.content-row {
  display: grid;
  grid-template-columns: 1fr; /* Single column for mobile */
  gap: 0;
}

.text-column, .image-column {
  padding: 10px;
}

.image-column img {
  width: 100%;
  height: auto;
}

.inline-image {
  width: 100%; /* Ensures the image is not wider than the text column */
  max-height: 200px; /* Limits the height of the image */
  object-fit: cover; /* Ensures the image covers the area without distorting aspect ratio */
  margin-top: 10px; /* Adds space between the text and the image */
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 400px;
  background-size: cover;
  background-position: center;
  color: white;
}

/* Default mobile image */
.hero {
  background-image: url('@/assets/HERO-HOME-MOBILE.webp');
}

.hero h1 {
  margin: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2); /* Optional: darkens the background for better readability */
  color: rbg(255,255,255); /* Sets the text color to white for contrast */
}

.hero h2 {
  margin: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2); /* Optional: darkens the background for better readability */
  color: rbg(255,255,255); /* Sets the text color to white for contrast */
}

@media (min-width: 900px) {
  .container {
    padding-left: 15px; /* Inner spacing */
    padding-right: 15px; /* Inner spacing */
    margin-left: auto; /* Centering the container */
    margin-right: auto; /* Centering the container */
    max-width: 1980px; /* Adjust this value based on your design needs */
  }

  .hero {
    background-image: url('@/assets/HERO-HOME-COMPUTER.webp');
  }
  .content-row {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Equal division for text and image */
    gap: 0; /* No gap between columns */
    align-items: center; /* Vertically center the content within each grid */
  }

  .content-row:nth-child(odd) {
    grid-template-areas: "text image";
  }

  .content-row:nth-child(odd) .text-column {
    grid-area: text;
    order: 1; /* Text first */
    padding-left: 30px;
    padding-right: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .content-row:nth-child(odd) .image-column {
    grid-area: image;
    order: 2; /* Image second */
    padding: 0; /* Full width image */
  }

  .content-row:nth-child(even) {
    grid-template-areas: "image text";
  }

  .content-row:nth-child(even) .image-column {
    grid-area: image;
    order: 1; /* Image first */
    padding: 0; /* Full width image */
  }

  .content-row:nth-child(even) .text-column {
    grid-area: text;
    order: 2; /* Text second */
    padding-left: 40px;
    padding-right: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

/* Mobile and Tablet Views: Always text first, then image */
@media (max-width: 899px) {
  .content-row .text-column {
    order: 1; /* Ensures text is always first */
  }

  .content-row .image-column {
    order: 2; /* Ensures image is always second */
  }
  .container {
    margin-left: auto; /* Centering the container */
    margin-right: auto; /* Centering the container */
    max-width: 899px; /* Adjust this value based on your design needs */
  }
}

@media (min-width: 1200px) {
  .hero {
    background-image: url('@/assets/HERO-HOME-COMPUTER.webp');
  }
}
</style>
