<template>
  <div class="hero dark-background">
    <h1>YagaCore - Augmented Virtuality</h1>
    <h3>Industrial Grade Automation Trainings</h3>
    <button @click="openLoginDialog" class="cta-button">Sign-in for Beta Testers</button>
    <LoginDialog ref="loginDialog"/>
  </div>
</template>

<script>
import LoginDialog from '@/views/LoginDialog.vue';

export default {
  name: 'AugmentedVirtuality',
  components: {
    LoginDialog
  },
  methods: {
    openLoginDialog() {
      console.log("Open Login Dialog button clicked");
      this.$refs.loginDialog.open();
    }
  }
};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 400px;
  background-size: cover;
  background-position: center;
  color: white;
}

/* Default mobile image */
.hero {
  background-image: url('@/assets/YagaWebVR-MOBILE.webp');
}

.hero h1 {
  margin: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: darkens the background for better readability */
  color: rgb(255,255,255); /* Sets the text color to white for contrast */
}

.hero h3 {
  margin: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: darkens the background for better readability */
  color: rgb(255,255,255); /* Sets the text color to white for contrast */
}

.cta-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color:  rgb(5, 26, 41);  /* Button background color */
  color: white; /* Button text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.cta-button:hover {
  background-color: #000000; /* Darker blue on hover */
}

@media (min-width: 900px) {
  .hero {
    background-image: url('@/assets/YagaWebVR-COMPUTER.webp');
  }
}

@media (min-width: 1200px) {
  .hero {
    background-image: url('@/assets/YagaWebVR-COMPUTER.webp');
  }
}
</style>


