import { createApp } from 'vue';
import App from './App.vue';
import './global.css';
import router from './router/router';
import store from './store'; // Ensure this import is correct
import vuetify from './plugins/vuetify'; // Correctly import Vuetify
import 'vuetify/styles'; // Ensure Vuetify styles are imported
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import draggable from 'vuedraggable';
import { faFile, faSave, faFolderOpen, faUndo, faRedo, faShare, faTimes } from '@fortawesome/free-solid-svg-icons';

library.add(faFile, faSave, faFolderOpen, faUndo, faRedo, faShare, faTimes, faFacebookF, faInstagram, faLinkedinIn, fas);

// Axios interceptor to attach the JWT to every request
axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('jwt');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
                // Log the JWT to verify it's being passed
                //console.log('JWT attached to request:', token);
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

const app = createApp(App)
  .use(router)
  .use(store)
  .use(vuetify) // Use Vuetify globally
  .component('draggable', draggable);

app.component('font-awesome-icon', FontAwesomeIcon);
app.config.globalProperties.$axios = axios;

app.mount('#app');
