import * as dresdeDataSource from "./dresdeDataSource";

export const AVDataSources = [
  {
    id: "dresde",
    name: "Dresden Data Source",
    aliases: ["SBG-LC2030-1"], // Add aliases here
    ...dresdeDataSource,
  },
];
