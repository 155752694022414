<template>
    <div class="vr-container">
      <!-- Loading Screen -->
      <div v-if="loading" class="loading-screen">
        <div class="loading-logo-container">
          <svg class="progress-circle" viewBox="0 0 36 36">
            <path
              class="circle-bg"
              d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
              class="circle"
              stroke-dasharray="100, 100"
              :stroke-dashoffset="100 - loadingPercentage"
              d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <!-- Rotated Logo -->
            <g transform="rotate(90 18 18)">
              <image :href="logo" x="8" y="8" height="20" width="20" />
            </g>
          </svg>
          <div class="loading-text">Loading... {{ loadingPercentage }}%</div>
        </div>
      </div>
  
      <!-- Babylon.js canvas -->
      <canvas ref="renderCanvas" class="canvas-container"></canvas>
    </div>
  </template>
  
  <script>
  import {
    Engine,
    Scene,
    ArcRotateCamera,
    DirectionalLight,
    HemisphericLight,
    Vector3,
    SceneLoader,
    ShadowGenerator,
    Color3,
    Texture,
    Animation,
    StandardMaterial,
    ActionManager,
    MeshBuilder,
    ExecuteCodeAction,
    Sound,
    Quaternion, // Add Quaternion for VR keypad rotation
  } from "@babylonjs/core";
  import "@babylonjs/core/XR";
  import "@babylonjs/loaders/glTF";
  import { WebXRState } from "@babylonjs/core/XR";
  import {
    AdvancedDynamicTexture,
    TextBlock,
    Button,
    StackPanel,
    Control,
    Grid,
    Rectangle,
  } from "@babylonjs/gui";
  import logo from "@/assets/Logo_Medusa_Digital_Dark.webp";
  import { ref, watch, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  
  export default {
    name: "CB-Level6",
    setup() {
      const store = useStore();
      const renderCanvas = ref(null);
      const loading = ref(true);
      const loadingPercentage = ref(0);
  
      let engine = null;
      let scene = null;
  
      // Reactive variables
      const conveyorSpeed = 1.5;
      const conveyorRunning = ref(false);
      const lamps = []; // Array to store lamp materials
      let conveyorAnimationObserver = null;
      let meshes = null;
               // Wood models and animations
    const woodModels = {};
    const animationsQueue = [];
    let currentAnimationIndex = 0;
    let currentAnimation = null;
  
    // Sensor variables
    let sensor1Active = false;
    let sensor2Active = false;
    let sensor1VrTagName = 'Sensor 1';
    let sensor2VrTagName = 'Sensor 2';
  
      // Warning Lamp variables
      let warningLampMaterial = null;
      let warningLampMaterial2 = null; // New variable for the second warning lamp
  
      // Text variables for counter display
      let counterPresetTextMesh = null;
      let counterCurrentValueTextMesh = null;
  
      // Sound variables
      let buttonClickSound = null;
      let factorySound = null;
  
      // Keypad variable
      let keypadTexture = null;
  
      // Babylon.js scene initialization
      const initBabylonScene = async () => {
        const canvas = renderCanvas.value;
        engine = new Engine(canvas, true, { preserveDrawingBuffer: true });
        engine.useIdleDetection = false; // Disable idle detection
        scene = new Scene(engine);
        scene.clearColor = new Color3(0.95, 0.95, 0.95);
  
        const camera = new ArcRotateCamera(
          "camera",
          0,
          Math.PI / 2.5,
          5,
          new Vector3(5, 1, -3.85),
          scene
        );
        camera.attachControl(canvas, true);
  
        const light = new DirectionalLight("dirLight", new Vector3(0, -1, 1), scene);
        light.position = new Vector3(0, 5, -10);
        light.intensity = 0.6;
  
        const hemisphericLight = new HemisphericLight("hemiLight", new Vector3(0, 1, 0), scene);
        hemisphericLight.intensity = 0.3;
  
        const environment = scene.createDefaultEnvironment({
          enableGroundShadow: true,
          groundYBias: 2.8,
        });
        environment.setMainColor(new Color3(0.95, 0.95, 0.95));
  
        // Make the environment transparent/invisible
        if (environment.skybox) {
          environment.skybox.material.alpha = 0; // Make the skybox transparent
        }
        if (environment.ground) {
          environment.ground.isVisible = false; // Hide the ground
        }
  
        const shadowGenerator = new ShadowGenerator(1024, light);
        shadowGenerator.useBlurExponentialShadowMap = true;
        shadowGenerator.blurKernel = 32;
  
        // Load sounds
        buttonClickSound = new Sound(
          "buttonClick",
          "/assets/button_click.mp3",
          scene,
          null,
          { autoplay: false }
        );
  
        factorySound = new Sound(
          "factorySound",
          "/assets/factory_sound.mp3",
          scene,
          null,
          { loop: true, autoplay: false }
        );
  
        // Load the GLTF file
        await new Promise((resolve, reject) => {
          SceneLoader.ImportMesh(
            "",
            "/assets/",
            "ConveyorB.gltf",
            scene,
            (loadedMeshes) => {
              let conveyorRoot = loadedMeshes[0];
              conveyorRoot.position.y = 2.5;
  
              const meshMap = {};
              loadedMeshes.forEach((mesh) => {
                meshMap[mesh.name] = mesh;
              });
  
              loadedMeshes.forEach((mesh) => {
                if (mesh.name.startsWith("RailC_") && mesh.getTotalVertices() > 0) {
                  shadowGenerator.addShadowCaster(mesh);
                }
  
                if (mesh.name.startsWith("RailC_")) {
                  const material = mesh.material;
                  if (material) {
                    if (!material.albedoTexture) {
                      material.albedoTexture = new Texture("/assets/Rail_AlbedoTransparency.png", scene);
                      material.bumpTexture = new Texture("/assets/Rail_Normal.png", scene);
                      material.specularTexture = new Texture("/assets/Rail_SpecularSmoothness.png", scene);
                    }
                  }
                }
  
                if (mesh.name.startsWith("PlatA_") || mesh.name.startsWith("PlatB_")) {
                  shadowGenerator.addShadowCaster(mesh);
                }
              });
  
              const startButton = meshMap["Button_Start_1"];
              if (startButton) {
                setupButtonInteraction(startButton, scene, "start");
              }
  
              const stopButton = meshMap["Button_Stop_1"];
              if (stopButton) {
                setupButtonInteraction(stopButton, scene, "stop");
              }
  
  
              meshes = loadedMeshes;
  
              // Call setupLamps here after GLTF is loaded
              setupLamps();
  
              resolve();
            },
            (event) => {
              // Update the loading percentage
              if (event.lengthComputable) {
                loadingPercentage.value = Math.floor((event.loaded / event.total) * 100);
              } else {
                loadingPercentage.value = Math.floor((event.loaded / 20000) * 100); // Fallback if length is not computable
              }
            },
            (scene, message) => {
              reject(message);
            }
          );
        });
  
        create3DTextBoxes(scene);
  
              // Load Wood models
       // Load Wood models
       const loadWoodModel = (name, fileName) => {
        return new Promise((resolve, reject) => {
          SceneLoader.ImportMesh(
            "",
            "/assets/",
            fileName,
            scene,
            (meshes) => {
              const woodMesh = meshes[0];
              woodMesh.setEnabled(false); // Initially disabled
              woodMesh.scaling.scaleInPlace(2); // Double the size
              woodModels[name] = woodMesh;
              resolve();
            },
            null,
            (scene, message) => {
              reject(message);
            }
          );
        });
      };

      await Promise.all([
        loadWoodModel("Wood_1", "Wood_1.gltf"),
        loadWoodModel("Wood_2", "Wood_2.gltf"),
        loadWoodModel("Wood_3", "Wood_3.gltf"),
        loadWoodModel("Wood_4", "Wood_4.gltf"),
      ]);

      // Prepare animations
      prepareWoodAnimations();
  

        let isXRSupported = await checkXRSupport();
        if (isXRSupported) {
          const xrHelper = await scene.createDefaultXRExperienceAsync({
            floorMeshes: [environment.ground],
          });
      //    xrHelper.baseExperience.onStateChangedObservable.add((state) => {
      //      console.log("XR State Changed:", state);
      //    });
          xrHelper.teleportation.addFloorMesh(environment.ground);
          scene.xrHelper = xrHelper; // Store reference in the scene for easy access

        }
  
        engine.runRenderLoop(() => {
          scene.render();
        });
  
        window.addEventListener("resize", () => {
          engine.resize();
        });
  
        // Hide the loader when loading is complete
        loading.value = false;
        engine.resize(); // This ensures the canvas and engine are correctly resized
      };
  
      const create3DTextBoxes = (scene) => {
        // Create a black background plane for the preset text
        const presetBackgroundPlane = MeshBuilder.CreatePlane("presetBackgroundPlane", { width: 1, height: 0.1 }, scene);
        presetBackgroundPlane.position = new Vector3(4.55, 1.8, -1); // Position it slightly behind the text plane
        presetBackgroundPlane.rotation.y = -Math.PI / 2; // Rotate 90 degrees on the Y-axis
  
        // Create black material for the background
        const presetBackgroundMaterial = new StandardMaterial("presetBackgroundMaterial", scene);
        presetBackgroundMaterial.diffuseColor = new Color3(0, 0, 0); // Set black color
        presetBackgroundPlane.material = presetBackgroundMaterial;
  
        // Create the plane for counter preset text
        const presetPlane = MeshBuilder.CreatePlane("presetPlane", { size: 1 }, scene);
        presetPlane.position = new Vector3(4.6, 1.8, -0.95); // Place it slightly in front of the background plane
        presetPlane.rotation.y = -Math.PI / 2; // Rotate 90 degrees on the Y-axis
  
        // Create dynamic texture for the preset text
        const presetTexture = AdvancedDynamicTexture.CreateForMesh(presetPlane);
  
        // Use a Button instead of TextBlock to make it interactive
        const presetButton = Button.CreateSimpleButton("presetButton", "Preset: 0");
        presetButton.color = "white";
        presetButton.fontSize = 100;
        presetButton.background = "transparent";
        presetButton.onPointerDownObservable.add(() => {
          showNumericKeypad();
        });
        presetTexture.addControl(presetButton);
  
        // Store for later dynamic updates
        counterPresetTextMesh = presetButton;
  
        // Create a black background plane for the current value text
        const currentValueBackgroundPlane = MeshBuilder.CreatePlane("currentValueBackgroundPlane", { width: 1, height: 0.1 }, scene);
        currentValueBackgroundPlane.position = new Vector3(4.55, 1.7, -1); // Position it slightly behind the text plane
        currentValueBackgroundPlane.rotation.y = -Math.PI / 2; // Rotate 90 degrees on the Y-axis
  
        // Create black material for the background
        const currentValueBackgroundMaterial = new StandardMaterial("currentValueBackgroundMaterial", scene);
        currentValueBackgroundMaterial.diffuseColor = new Color3(0, 0, 0); // Set black color
        currentValueBackgroundPlane.material = currentValueBackgroundMaterial;
  
        // Create the plane for counter current value text
        const currentValuePlane = MeshBuilder.CreatePlane("currentValuePlane", { size: 1 }, scene);
        currentValuePlane.position = new Vector3(4.6, 1.7, -0.95); // Place it slightly in front of the background plane
        currentValuePlane.rotation.y = -Math.PI / 2; // Rotate 90 degrees on the Y-axis
  
        // Create dynamic texture for the current value text
        const currentValueTexture = AdvancedDynamicTexture.CreateForMesh(currentValuePlane);
        const currentValueTextBlock = new TextBlock();
        currentValueTextBlock.text = "Current Value: 0";
        currentValueTextBlock.color = "white"; // White text color
        currentValueTextBlock.fontSize = 100;
        currentValueTexture.addControl(currentValueTextBlock);
  
        // Store for later dynamic updates
        counterCurrentValueTextMesh = currentValueTextBlock;
      };

      const showNumericKeypad = () => {
  if (keypadTexture) return; // Prevent multiple keypads

  const xrHelper = scene.xrHelper; // Reference to XR helper

  if (xrHelper && xrHelper.baseExperience.state === WebXRState.IN_XR) {
    // User is in VR mode
    showVRKeypad();
  } else {
    // User is not in VR mode
    showScreenKeypad();
  }
};

const showVRKeypad = async () => {
  // Load VRKeyboard GLTF model using LoadAssetContainerAsync
  const assetContainer = await SceneLoader.LoadAssetContainerAsync(
    "/assets/",
    "VRKeyboard.gltf",
    scene
  );

  // Add all loaded assets to the scene
  assetContainer.addAllToScene();

  // Log loaded meshes and nodes for debugging
//  console.log("Loaded Meshes:", assetContainer.meshes.map(mesh => mesh.name));
 // console.log("Loaded TransformNodes:", assetContainer.transformNodes.map(node => node.name));

  // Find the VRKeyboard node
  const vrKeyboard = assetContainer.transformNodes.find((node) => node.name === "VRKeyboard");

  if (!vrKeyboard) {
    console.error("VRKeyboard node not found in the GLTF model.");
    return;
  }

  // Position the VR Keyboard directly at the fixed position
  vrKeyboard.position = new Vector3(-4.6, 1.7, -2.2); // Set the position directly

  // Set the rotation to face in the desired direction
  vrKeyboard.rotationQuaternion = Quaternion.FromEulerAngles(Math.PI, -Math.PI / 2, 0); // Rotate to face desired orientation

  // Adjust scaling to reduce size and fix mirroring
  vrKeyboard.scaling = new Vector3(0.025, 0.025, 0.025); // Scale down and fix mirroring

  // Log the position and rotation of the VR Keyboard
 // console.log("VRKeyboard Position (X, Y, Z):", vrKeyboard.position.x, vrKeyboard.position.y, vrKeyboard.position.z);
 // console.log("VRKeyboard Rotation (Quaternion):", vrKeyboard.rotationQuaternion.toEulerAngles().toString());

  // Initialize inputValue for the display
  let inputValue = "";

  // ---- CREATE THE DISPLAY USING THE 3D PLANE APPROACH ----

  // Create a black background plane for the display
  const displayBackgroundPlane = MeshBuilder.CreatePlane("displayBackgroundPlane", { width: 1, height: 0.4 }, scene);
  displayBackgroundPlane.position = new Vector3(4.8, 2.5, -2.2); // Position above the keyboard
  displayBackgroundPlane.rotation.y = -Math.PI / 2; // Rotate 90 degrees on the Y-axis to face the correct way

  // Create black material for the background
  const displayBackgroundMaterial = new StandardMaterial("displayBackgroundMaterial", scene);
  displayBackgroundMaterial.diffuseColor = new Color3(0, 0, 0); // Set black color
  displayBackgroundPlane.material = displayBackgroundMaterial;

  // Create the plane for the actual display text
  const displayPlane = MeshBuilder.CreatePlane("displayPlane", { width: 1, height: 0.4 }, scene);
  displayPlane.position = new Vector3(4.85, 2.5, -2.2); // Slightly in front of the background plane
  displayPlane.rotation.y = -Math.PI / 2; // Rotate to face the correct direction

  // Create dynamic texture for the display text
  const displayTexture = AdvancedDynamicTexture.CreateForMesh(displayPlane);
  const displayTextBlock = new TextBlock();
  displayTextBlock.text = "Preset: "; // Initial text
  displayTextBlock.color = "white";
  displayTextBlock.fontSize = 180;
  displayTexture.addControl(displayTextBlock);

  // Function to update display text
  const updateDisplay = (text) => {
    displayTextBlock.text = `Preset: ${text}`;
  };

  // ---- END OF 3D DISPLAY CREATION ----

  // Function to highlight button on press and change its material
  const highlightButton = (mesh, originalMaterial) => {
    const pressedMaterial = new StandardMaterial("pressedMaterial", scene);
    pressedMaterial.diffuseColor = Color3.FromHexString("#ceac01"); // Highlight color

    mesh.material = pressedMaterial;

    // Reset after a short delay
    setTimeout(() => {
      mesh.material = originalMaterial;
    }, 200); // Highlight for 200ms
  };

  // Attach action to each number button and functional button (delete, enter, cancel)
  const attachButtonAction = (buttonName, action) => {
    const buttonMesh = scene.getMeshByName(buttonName);
    if (buttonMesh) {
      const originalMaterial = buttonMesh.material; // Store original material for highlight reset

      buttonMesh.actionManager = new ActionManager(scene);
      buttonMesh.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
          if (buttonClickSound) {
            buttonClickSound.play();
          }
          action(); // Execute the button's action
          highlightButton(buttonMesh, originalMaterial); // Highlight on press
        })
      );
    } else {
      console.warn(`Button mesh ${buttonName} not found`);
    }
  };

  // Attach number button actions
  for (let i = 0; i <= 9; i++) {
    attachButtonAction(`num_${i}`, () => {
      inputValue += i.toString();
      updateDisplay(inputValue);
    });
  }

  // Attach delete button action
  attachButtonAction("num_del", () => {
    inputValue = inputValue.slice(0, -1);
    updateDisplay(inputValue || "");
  });

  // Function to hide VR keyboard and display
  const hideVRKeyboardAndDisplay = () => {
    vrKeyboard.dispose(); // Dispose the VR keyboard
    displayBackgroundPlane.dispose(); // Hide the display background
    displayPlane.dispose(); // Hide the display text
  };

  // Attach cancel button action
  attachButtonAction("num_cancel", () => {
    inputValue = "";
    updateDisplay("");
    hideVRKeyboardAndDisplay(); // Hide the keyboard and the display
  });

  // Attach enter button action to finalize the preset value
  attachButtonAction("num_enter", () => {
    const newValue = parseInt(inputValue, 10);
    if (!isNaN(newValue)) {
      updateCounterPresetValue(newValue); // Update the preset counter
      inputValue = "";
      updateDisplay("");
      hideVRKeyboardAndDisplay(); // Hide the keyboard and the display
    }
  });
};
















      const showScreenKeypad = () => {
  if (keypadTexture) return; // Prevent multiple keypads

  keypadTexture = AdvancedDynamicTexture.CreateFullscreenUI("KeypadUI");
  keypadTexture.idealWidth = 600; // Set ideal width for scaling
  keypadTexture.idealHeight = 800; // Set ideal height for scaling

  const backgroundRect = new Rectangle();
  backgroundRect.width = 0.25; // Approx 1/3 of previous width
  backgroundRect.height = 0.7; // 70% of the screen height
  backgroundRect.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
  backgroundRect.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
  backgroundRect.cornerRadius = 10;
  backgroundRect.color = "#ffc300";
  backgroundRect.thickness = 0.5;
  backgroundRect.background = "#000814"; // Set background color to match brand
  keypadTexture.addControl(backgroundRect);

  // Create a StackPanel to hold the display and the keypad
  const mainPanel = new StackPanel();
  mainPanel.width = "100%";
  mainPanel.height = "100%";
  mainPanel.isVertical = true;
  mainPanel.paddingTop = "10px";
  mainPanel.paddingBottom = "10px";
  backgroundRect.addControl(mainPanel);

  // Display Rectangle
  const displayRect = new Rectangle();
  displayRect.width = "100%";
  displayRect.height = "19px";
  displayRect.background = "#0d1b2a"; // Lighter background color
  displayRect.thickness = 0.5;
  displayRect.color = "#ffc300";
  displayRect.paddingLeft = "5px";
  displayRect.paddingRight = "5px";
  mainPanel.addControl(displayRect);

  // Display Text
  const displayText = new TextBlock();
  displayText.text = "New Preset";
  displayText.height = "19px";
  displayText.color = "#ffc300"; // Brand's text color
  displayText.fontSize = "16px"; // Adjust font size
  displayText.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
  displayText.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
  displayText.paddingRight = "10px"; // Add some padding to the right
  displayRect.addControl(displayText);

  // Keypad Grid
  const keypadGrid = new Grid();
  keypadGrid.width = "100%";
  keypadGrid.height = "90px"; // Remaining height after display
  keypadGrid.paddingTop = "10px";
  keypadGrid.paddingLeft = "5px";
  keypadGrid.paddingRight = "5px";
  mainPanel.addControl(keypadGrid);

  // Define rows and columns
  for (let i = 0; i < 5; i++) keypadGrid.addRowDefinition(1 / 5); // 5 rows
  for (let i = 0; i < 3; i++) keypadGrid.addColumnDefinition(1 / 3); // 3 columns

  const createButton = (
  text,
  row,
  col,
  callback,
  backgroundColor = "#000814",
  textColor = "#ffc300",
  columnSpan = 1
) => {
  const button = Button.CreateSimpleButton(`btn${text}`, text);
  button.color = textColor;
  button.background = backgroundColor;
  button.fontSize = "13px"; // Adjust font size
  button.height = "100%";
  button.width = "100%";
  button.padding = "2px";
  button.onPointerDownObservable.add(callback);
  keypadGrid.addControl(button, row, col);
  
  if (columnSpan > 1) {
    keypadGrid.setColumnSpan(button, columnSpan); // Corrected method
  }
};


  // Initialize inputValue
  let inputValue = "";

  // Number buttons
  const numbers = [
    ["1", 0, 0],
    ["2", 0, 1],
    ["3", 0, 2],
    ["4", 1, 0],
    ["5", 1, 1],
    ["6", 1, 2],
    ["7", 2, 0],
    ["8", 2, 1],
    ["9", 2, 2],
    ["0", 3, 1],
  ];

  numbers.forEach(([num, row, col]) => {
    createButton(num, row, col, () => {
      inputValue += num;
      displayText.text = inputValue;
    });
  });

  // Clear button
  createButton(
    "Clear",
    3,
    0,
    () => {
      inputValue = "";
      displayText.text = "New Preset";
    },
    "#0188cb", // Background color
    "black"  // Text color
  );

  // Backspace button
  createButton(
    "←",
    3,
    2,
    () => {
      inputValue = inputValue.slice(0, -1);
      displayText.text = inputValue || "New Preset";
    },
    "#0188cb", // Background color
    "black"  // Text color
  );

// Add Cancel button in row 4, column 0 (spanning 1 column)
createButton(
  "Cancel",
  4, // Row 4
  0, // Column 0
  () => {
    keypadTexture.dispose();
    keypadTexture = null;
  },
  "#ff0303", // Background color
  "black"  // Text color
);
// Add Cancel button in row 4, column 0 (spanning 1 column)
createButton(
  "",
  4, // Row 4
  1, // Column 0
  () => {
    keypadTexture.dispose();
    keypadTexture = null;
  },
  "#000814", // Background color
  "#000814"  // Text color
);

// Add Enter button in row 4, column 1 (spanning 2 columns)
createButton(
  "Enter",
  4, // Row 4
  2, // Start in column 1
  () => {
    const newValue = parseInt(inputValue, 10);
    if (!isNaN(newValue)) {
      updateCounterPresetValue(newValue);
    }
    keypadTexture.dispose();
    keypadTexture = null;
  },
  "#03ff03", // Background color
  "black"  // Text color
);
  // Remove gaps
  keypadGrid.padding = 0;
  keypadGrid.margin = 0;
  backgroundRect.padding = 0;
  backgroundRect.margin = 0;
};




  
const updateCounterPresetValue = (newValue) => {
//  console.log(`Updating counter preset value to ${newValue}`);

  // Update the displayed preset value
  counterPresetTextMesh.textBlock.text = `Preset: ${newValue}`;

  // Dispatch an action to update the preset value in Vuex
  store.dispatch('updateVrTagValue', {
    tagName: 'Counter 1', // Ensure this matches the name in your vrTagsTable
    preset: newValue,
  });
};



const monitorCounterChanges = () => {
  watch(
    () => store.getters.getIIoTDataSources.vrTagsTable,
    (vrTags) => {
      const counterTag = vrTags.find(
        (tag) => tag.name === "Counter 1" && tag.direction === "output"
      );

      if (counterTag) {
      //  console.log(`Counter 1 - Preset: ${counterTag.preset}, Accumulated: ${counterTag.accumulated}`);

        if (counterPresetTextMesh && counterPresetTextMesh.textBlock) {
          counterPresetTextMesh.textBlock.text = `Preset: ${counterTag.preset}`;
        }

        if (counterCurrentValueTextMesh) {
          counterCurrentValueTextMesh.text = `Current Value: ${counterTag.accumulated}`;
        }
      } //else {
       // console.log("Counter 1 not found in vrTagsTable.");
    //  }
    },
    { deep: true }
  );
};


  
      // Setup lamps for the 'Process Active Lights' and the warning lamps
      const setupLamps = () => {
        // Setup Process Active Lamps
        for (let i = 1; i <= 8; i++) {
          const lampNodeName = `Lamp_Color_${i}`;
          const lampNode = scene.getTransformNodeByName(lampNodeName);
          if (!lampNode) {
            continue;
          }
  
          const lampMesh = lampNode.getChildMeshes()[0];
          if (!lampMesh) {
            continue;
          }
  
          const material = new StandardMaterial(`Lamp_Color_Choose_${i}`, scene);
          material.diffuseColor = new Color3(0.5, 1, 0.5); // Green color for active state
          material.emissiveColor = new Color3(0, 0.3, 0); // Emissive glow for brightness
          lampMesh.material = material;
  
          lamps.push(material);
        }
  
        // Setup Warning Lamp 1
        const warningLampNodeName = "Lamp_Warning_1";
        const warningLampNode = scene.getTransformNodeByName(warningLampNodeName);
        if (warningLampNode) {
          const lampMesh = warningLampNode.getChildMeshes()[0];
          if (lampMesh) {
            warningLampMaterial = new StandardMaterial("WarningLampMaterial1", scene);
            warningLampMaterial.diffuseColor = new Color3(1, 1, 1); // White color
            warningLampMaterial.emissiveColor = new Color3(0, 0, 0); // No emissive glow
            lampMesh.material = warningLampMaterial;
          } else {
            console.warn(`No mesh found under warning lamp node '${warningLampNodeName}'!`);
          }
        } else {
          console.warn(`Warning lamp node '${warningLampNodeName}' not found in the scene!`);
        }
  
        // Setup Warning Lamp 2
        const warningLampNodeName2 = "Lamp_Warning_2";
        const warningLampNode2 = scene.getTransformNodeByName(warningLampNodeName2);
        if (warningLampNode2) {
          const lampMesh = warningLampNode2.getChildMeshes()[0];
          if (lampMesh) {
            warningLampMaterial2 = new StandardMaterial("WarningLampMaterial2", scene);
            warningLampMaterial2.diffuseColor = new Color3(1, 1, 1); // White color
            warningLampMaterial2.emissiveColor = new Color3(0, 0, 0); // No emissive glow
            lampMesh.material = warningLampMaterial2;
          } else {
            console.warn(`No mesh found under warning lamp node '${warningLampNodeName2}'!`);
          }
        } else {
          console.warn(`Warning lamp node '${warningLampNodeName2}' not found in the scene!`);
        }
      };
  
      // Watch and update lamps when the 'Process Active Lights' VR tag changes
      const monitorProcessActive = () => {
        watch(
          () => store.getters.getIIoTDataSources.vrTagsTable,
          (vrTags) => {
            const processTag = vrTags.find(
              (tag) => tag.name === "Process Active Lights" && tag.direction === "output"
            );
            if (processTag) {
              updateLamps(processTag.value);
            }
          },
          { deep: true }
        );
      };
  
      // Function to update the lamps' color based on the 'Process Active Lights' tag value
      const updateLamps = (isActive) => {
        lamps.forEach((material) => {
          if (isActive) {
            material.diffuseColor = new Color3(0.5, 1, 0.5); // Green
            material.emissiveColor = new Color3(0, 0.8, 0); // Brightness
          } else {
            material.diffuseColor = new Color3(1, 0.3, 0.3); // Red
            material.emissiveColor = new Color3(0.6, 0, 0); // Brightness
          }
        });
      };
  
      // Watch and update warning lamp when the 'Warning_Lamp_1' VR tag changes
      const monitorWarningLamp = () => {
        watch(
          () => store.getters.getIIoTDataSources.vrTagsTable,
          (vrTags) => {
            const warningLampTag = vrTags.find(
              (tag) => tag.name === "Warning_Lamp_1" && tag.direction === "output"
            );
            if (warningLampTag) {
              updateWarningLamp(warningLampTag.value);
            }
          },
          { deep: true }
        );
      };
  
      // Function to update the warning lamp's color based on the 'Warning_Lamp_1' tag value
      const updateWarningLamp = (isActive) => {
        if (warningLampMaterial) {
          if (isActive) {
            warningLampMaterial.diffuseColor = new Color3(1, 1, 0); // Yellow color
            warningLampMaterial.emissiveColor = new Color3(1, 1, 0); // Emissive glow
          } else {
            warningLampMaterial.diffuseColor = new Color3(1, 1, 1); // White color
            warningLampMaterial.emissiveColor = new Color3(0, 0, 0); // No emissive glow
          }
        }
      };
  
      // Watch and update warning lamp when the 'Warning_Lamp_2' VR tag changes
      const monitorWarningLamp2 = () => {
        watch(
          () => store.getters.getIIoTDataSources.vrTagsTable,
          (vrTags) => {
            const warningLampTag2 = vrTags.find(
              (tag) => tag.name === "Warning_Lamp_2" && tag.direction === "output"
            );
            if (warningLampTag2) {
              updateWarningLamp2(warningLampTag2.value);
            }
          },
          { deep: true }
        );
      };
  
      // Function to update the warning lamp's color based on the 'Warning_Lamp_2' tag value
      const updateWarningLamp2 = (isActive) => {
        if (warningLampMaterial2) {
          if (isActive) {
            warningLampMaterial2.diffuseColor = new Color3(1, 1, 0); // Yellow color
            warningLampMaterial2.emissiveColor = new Color3(1, 1, 0); // Emissive glow
          } else {
            warningLampMaterial2.diffuseColor = new Color3(1, 1, 1); // White color
            warningLampMaterial2.emissiveColor = new Color3(0, 0, 0); // No emissive glow
          }
        }
      };
  

  
      // Setup button interactions with correct animation handling
      const setupButtonInteraction = (buttonMesh, scene, type) => {
        const colorMesh =
          buttonMesh.getChildMeshes().find((m) => m.name === `${buttonMesh.name}_Color`) ||
          buttonMesh;
        const originalMaterial = colorMesh.material;
        const pressedMaterial = new StandardMaterial(`pressedMaterial_${type}`, scene);
  
        pressedMaterial.diffuseColor =
          type === "start" ? new Color3(0.5, 1, 0.5) : new Color3(1, 0.5, 0.5);
  
        buttonMesh.actionManager = new ActionManager(scene);
        buttonMesh.actionManager.registerAction(
          new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
            // Play button click sound
            if (buttonClickSound) {
              buttonClickSound.play();
            }
  
            // Animate button press and release
  
            // Change the material to indicate the button is pressed
            colorMesh.material = pressedMaterial;
  
            // Define the down position for the button animation
            const downPosition = buttonMesh.position.clone();
            downPosition.y -= 0.05;
  
            // Create animations for pressing down and releasing the button
            const pressDownAnimation = new Animation(
              "pressDownAnimation",
              "position",
              30,
              Animation.ANIMATIONTYPE_VECTOR3
            );
            pressDownAnimation.setKeys([
              { frame: 0, value: buttonMesh.position.clone() },
              { frame: 5, value: downPosition },
            ]);
  
            const releaseAnimation = new Animation(
              "releaseAnimation",
              "position",
              30,
              Animation.ANIMATIONTYPE_VECTOR3
            );
            releaseAnimation.setKeys([
              { frame: 0, value: downPosition },
              { frame: 5, value: buttonMesh.position.clone() },
            ]);
  
            // Perform press and release animations sequentially
            scene.beginDirectAnimation(
              buttonMesh,
              [pressDownAnimation],
              0,
              5,
              false,
              1.0,
              () => {
                scene.beginDirectAnimation(
                  buttonMesh,
                  [releaseAnimation],
                  0,
                  5,
                  false,
                  1.0,
                  () => {
                    // Reset the material to the original after release
                    colorMesh.material = originalMaterial;
                  }
                );
              }
            );
  
            // Dispatch event or update store based on button type
            if (type === "start") {
              handleButtonPress("Start Button");
            } else if (type === "stop") {
              handleButtonPress("Stop Button");
            }
          })
        );
      };
  
      const handleButtonPress = (buttonName) => {
        const iiotDataSources = store.getters.getIIoTDataSources;
        const linkedVrTag = iiotDataSources.vrTagsTable.find((tag) => tag.name === buttonName);
  
        if (linkedVrTag && linkedVrTag.direction === "input") {
          store.dispatch("updateVrTagValue", { tagName: buttonName, value: true });
  
          setTimeout(() => {
            store.dispatch("updateVrTagValue", { tagName: buttonName, value: false });
          }, 100);
        } //else {
         // console.log("IIOT- No linked input VR tag found for button:", buttonName);
      //  }
      };
  
      // Start the conveyor animation
      const startConveyor = () => {
        if (conveyorRunning.value) return;
        conveyorRunning.value = true;
  
        if (!conveyorAnimationObserver) {
          conveyorAnimationObserver = scene.onBeforeRenderObservable.add(() => {
            // Update conveyor belt texture
            meshes.forEach((mesh) => {
              if (mesh.name.startsWith("RailC_")) {
                const material = mesh.material;
                if (material && material.albedoTexture) {
                  material.albedoTexture.uOffset += conveyorSpeed * 0.0105;
                }
              }
            });
  
           });
        }
  

        // Play factory sound
        if (factorySound) {
          factorySound.play();
        }
                     // Start or resume wood animations
      if (currentAnimation && currentAnimation.paused) {
        currentAnimation.restart();
      } else {
        playWoodAnimations();
      }
      };
  
      // Stop the conveyor animation
      const stopConveyor = () => {
        if (!conveyorRunning.value) return;
        conveyorRunning.value = false;
  
        if (conveyorAnimationObserver) {
          scene.onBeforeRenderObservable.remove(conveyorAnimationObserver);
          conveyorAnimationObserver = null;
        }
  
        if (currentAnimation) {
        currentAnimation.pause();
      }
     // Reset sensor 1 state
     if (sensor1Active) {
        sensor1Active = false;
        updateSensorVrTag(false, sensor1VrTagName);
      //  console.log("Sensor 1 deactivated (conveyor stopped)");
      }

      // Reset sensor 2 state
      if (sensor2Active) {
        sensor2Active = false;
        updateSensorVrTag(false, sensor2VrTagName);
      //  console.log("Sensor 2 deactivated (conveyor stopped)");
      }
  
        // Stop factory sound
        if (factorySound && factorySound.isPlaying) {
          factorySound.stop();
        }
      };

          // Prepare wood animations
          const prepareWoodAnimations = () => {
  // Animation_1
  animationsQueue.push({
    wood: woodModels["Wood_1"],
    startFrame: 0,
    endFrame: 120,
    positionKeys: [
      { frame: 0, value: new Vector3(7.04, 1.01, -5.08) },
      { frame: 120, value: new Vector3(7.04, 1.01, -1.08) },
    ],
    rotationKeys: [
      { frame: 0, value: new Vector3(0, -3.09, 0) },
      { frame: 120, value: new Vector3(0, -3.09, 0) },
    ],
  });

  // Animation_2
  animationsQueue.push({
    wood: woodModels["Wood_2"],
    startFrame: 0,
    endFrame: 240,
    positionKeys: [],
    rotationKeys: [],
  });

  const positions2 = [
    { x: 7.04, y: 1.01, z: -1.08, rotY: 3.09 },
    { x: 7.04, y: 1.01, z: -0.48, rotY: 3.09 },
    { x: 7.04, y: 1.01, z: 0.12, rotY: 3.09 },
    { x: 7.04, y: 1.01, z: 0.72, rotY: 3.09 },
    { x: 6.84, y: 1.01, z: 1.12, rotY: 2.59 },
    { x: 6.54, y: 1.01, z: 1.52, rotY: 2.19 },
    { x: 6.14, y: 1.01, z: 1.82, rotY: 1.84 },
    { x: 5.74, y: 1.01, z: 2.02, rotY: 1.54 },
    { x: 5.34, y: 1.01, z: 2.02, rotY: 1.29 },
    { x: 4.94, y: 1.01, z: 1.92, rotY: 0.99 },
    { x: 4.54, y: 1.01, z: 1.62, rotY: 0.74 },
    { x: 4.24, y: 1.01, z: 1.32, rotY: 0.34 },
    { x: 4.04, y: 1.01, z: 0.72, rotY: 0.19 },
    { x: 4.04, y: 1.01, z: 0.12, rotY: 0.01 },
    { x: 4.04, y: 1.01, z: -0.98, rotY: 0.01 },
  ];
  const totalFrames2 = 240;
  const frameIncrement2 = totalFrames2 / (positions2.length - 1);

  positions2.forEach((pos, index) => {
    animationsQueue[1].positionKeys.push({
      frame: index * frameIncrement2,
      value: new Vector3(pos.x, pos.y, pos.z),
    });
    animationsQueue[1].rotationKeys.push({
      frame: index * frameIncrement2,
      value: new Vector3(0, pos.rotY, 0),
    });
  });

  // **Add Animations 3 to 6**

  // Animation_3
  animationsQueue.push({
    wood: woodModels["Wood_3"],
    startFrame: 0,
    endFrame: 90,
    positionKeys: [
      { frame: 0, value: new Vector3(4.04, 1.01, -0.98) },
      { frame: 90, value: new Vector3(4.04, 1.01, -2.98) },
    ],
    rotationKeys: [
      { frame: 0, value: new Vector3(0, 3.14, 0) },
      { frame: 90, value: new Vector3(0, 3.14, 0) },
    ],
  });

  // Animation_4
  animationsQueue.push({
    wood: woodModels["Wood_3"],
    startFrame: 0,
    endFrame: 90,
    positionKeys: [
      { frame: 0, value: new Vector3(4.04, 1.01, -2.98) },
      { frame: 90, value: new Vector3(4.04, 1.01, -4.98) },
    ],
    rotationKeys: [
      { frame: 0, value: new Vector3(0, 3.14, 0) },
      { frame: 90, value: new Vector3(0, 3.14, 0) },
    ],
  });

  // Animation_5
  animationsQueue.push({
    wood: woodModels["Wood_4"],
    startFrame: 0,
    endFrame: 240,
    positionKeys: [],
    rotationKeys: [],
  });

  const positions5 = [
    { x: 4.04, y: 0.95, z: -4.98, rotY: -3.14 },
    { x: 3.94, y: 0.91, z: -5.78, rotY: -2.84 },
    { x: 3.64, y: 0.91, z: -6.48, rotY: -2.39 },
    { x: 2.94, y: 0.91, z: -6.88, rotY: -1.89 },
    { x: 2.04, y: 0.91, z: -6.88, rotY: -1.29 },
    { x: 1.34, y: 0.91, z: -6.38, rotY: -0.64 },
    { x: 1.04, y: 0.91, z: -4.98, rotY: 0.01 },
  ];
  const totalFrames5 = 240;
  const frameIncrement5 = totalFrames5 / (positions5.length - 1);

  positions5.forEach((pos, index) => {
    animationsQueue[4].positionKeys.push({
      frame: index * frameIncrement5,
      value: new Vector3(pos.x, pos.y, pos.z),
    });
    animationsQueue[4].rotationKeys.push({
      frame: index * frameIncrement5,
      value: new Vector3(0, pos.rotY % (2 * Math.PI), 0),
    });
  });

  // Animation_6
  animationsQueue.push({
    wood: woodModels["Wood_4"],
    startFrame: 0,
    endFrame: 90,
    positionKeys: [
      { frame: 0, value: new Vector3(1.04, 0.91, -4.98) },
      { frame: 90, value: new Vector3(1.04, 0.91, -2.88) },
    ],
    rotationKeys: [
      { frame: 0, value: new Vector3(0, 0.01, 0) },
      { frame: 90, value: new Vector3(0, 0.01, 0) },
    ],
  });

  // Assign animations to models
  animationsQueue.forEach((animationObj) => {
    const animPosition = new Animation(
      `${animationObj.wood.name}_PositionAnimation_${animationObj.startFrame}`,
      "position",
      30,
      Animation.ANIMATIONTYPE_VECTOR3,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    const animRotation = new Animation(
      `${animationObj.wood.name}_RotationAnimation_${animationObj.startFrame}`,
      "rotation",
      30,
      Animation.ANIMATIONTYPE_VECTOR3,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    animPosition.setKeys(animationObj.positionKeys);
    animRotation.setKeys(animationObj.rotationKeys);

    animationObj.animations = [animPosition, animRotation];
  });
};


    const playWoodAnimations = () => {
  currentAnimationIndex = 0; // Start at the first animation
  playNextAnimation();
};
// Add sensor triggers at specific points in the animations
const playNextAnimation = () => {
  if (currentAnimationIndex >= animationsQueue.length) {
    // All animations finished
    currentAnimation = null;
    return;
  }

  const animationObj = animationsQueue[currentAnimationIndex];
  const wood = animationObj.wood;

  // Reset wood model
  wood.setEnabled(true);
  wood.position.copyFrom(animationObj.positionKeys[0].value);
  wood.rotation.copyFrom(animationObj.rotationKeys[0].value);

  // Hide previous wood models if different
  animationsQueue.forEach((animObj, index) => {
    if (index !== currentAnimationIndex && animObj.wood !== wood) {
      animObj.wood.setEnabled(false);
    }
  });

  currentAnimation = scene.beginDirectAnimation(
    wood,
    animationObj.animations,
    animationObj.startFrame,
    animationObj.endFrame,
    false, // No looping
    conveyorSpeed,
    () => {
      // Trigger sensors at specific animation points
      if (currentAnimationIndex === 0) {
        triggerSensor1(); // Trigger Sensor 1 after Animation 1
      }
      if (currentAnimationIndex === 1) {
        triggerSensor2(); // Trigger Sensor 2 after Animation 2
      }

      // Ensure to disable current wood if not the last one
      if (currentAnimationIndex + 1 < animationsQueue.length) {
        const nextWood = animationsQueue[currentAnimationIndex + 1].wood;
        if (nextWood !== wood) {
          wood.setEnabled(false);
        }
      } else {
        wood.setEnabled(false);
      }

      currentAnimationIndex++;
      // Proceed to the next animation
      playNextAnimation(); // Continue with the next animation
    }
  );
};



    const triggerSensor1 = () => {
      if (!sensor1Active) {
        sensor1Active = true;
        updateSensorVrTag(true, sensor1VrTagName);
     //   console.log("Sensor 1 activated due to Wood_1 reaching the sensor.");
      }
    };

    const triggerSensor2 = () => {
      if (!sensor2Active) {
        sensor2Active = true;
        updateSensorVrTag(true, sensor2VrTagName);
     //   console.log("Sensor 2 activated due to Wood_2 reaching the sensor.");
      }
    };

  
      // Update Sensor VR Tag
      const updateSensorVrTag = (isActive, sensorTagName) => {
        const iiotDataSources = store.getters.getIIoTDataSources;
        const sensorVrTag = iiotDataSources.vrTagsTable.find((tag) => tag.name === sensorTagName);
  
        if (sensorVrTag && sensorVrTag.direction === "input") {
          store.dispatch("updateVrTagValue", { tagName: sensorTagName, value: isActive });
        }
      };
  
         // Unified watcher for motor and other changes
         const unifiedWatcher = () => {
        watch(
          () => store.getters.getIIoTDataSources.vrTagsTable,
          (newVrTags) => {
            const motorVrTag = newVrTags.find(tag => tag.name === 'Motor 1' && tag.direction === 'output');
            if (motorVrTag) {
              if (motorVrTag.value && !conveyorRunning.value) {
                startConveyor();
              } else if (!motorVrTag.value && conveyorRunning.value) {
                stopConveyor();
              }
            }
          },
          { deep: true }
        );
      };

      // Check for WebXR support
      const checkXRSupport = async () => {
        if (navigator.xr) {
          try {
            const isSupported = await navigator.xr.isSessionSupported("immersive-vr");
            return isSupported;
          } catch (error) {
            return false;
          }
        }
        return false;
      };
  
  
      // On Mounted
      onMounted(() => {
        initBabylonScene().then(() => {
          monitorProcessActive(); // Monitor changes in 'Process Active Lights' tag
          monitorWarningLamp();   // Monitor changes in 'Warning_Lamp_1' tag
          monitorWarningLamp2();  // Monitor changes in 'Warning_Lamp_2' tag
          monitorCounterChanges(); // Add the counter monitoring here

  
          // Once the scene is initialized, proceed to set initial states.
          const motorVrTag = store.getters.getIIoTDataSources.vrTagsTable.find(
            (tag) => tag.name === "Motor 1" && tag.direction === "output"
          );
  
          if (motorVrTag) {
            conveyorRunning.value = motorVrTag.value; // Set conveyorRunning to match the initial state
            if (conveyorRunning.value) {
              startConveyor(); // Start the conveyor if the motor is already running
            }
          }
          unifiedWatcher();

          
                     // Sound to play when the scene starts
    const sphereSound = new Sound(
      "sphereSound",
      "/assets/factory_sound_1.mp3", // Adjust path if necessary
      scene,
      () => {
        // Play sound when it's loaded
        sphereSound.play();
      },
      { loop: true, autoplay: false, preload: true }
    );
        });
      });
  
        // Cleanup in onUnmounted
        onUnmounted(() => {
        if (conveyorAnimationObserver) {
          scene.onBeforeRenderObservable.remove(conveyorAnimationObserver);
        }
        // Dispose sounds
        if (buttonClickSound) {
          buttonClickSound.dispose();
        }
        if (factorySound) {
          factorySound.dispose();
        }
        if (engine) {
        engine.dispose();
      }
      // Dispose animations
      if (currentAnimation) {
        currentAnimation.stop();
        currentAnimation = null;
      }
      // Dispose wood models
      Object.values(woodModels).forEach((wood) => {
        wood.dispose();
      });
      // Dispose scene and engine
      if (scene) {
        scene.dispose();
      }

      });
  
      return {
        renderCanvas, // Expose renderCanvas to the template
        logo,
        loading,
        loadingPercentage,
      };
    },
  };
  </script>
  
  <style scoped>
  .vr-container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative; /* Added to contain absolutely positioned children */
    display: block; /* Changed from flex to block */
  }
  
  .canvas-container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  /* Loading Screen Styles */
  .loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #000814, #0d1b2a);
    color: #ffd60a;
    text-align: center;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .loading-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loading-text {
    margin-top: 20px;
    font-size: 24px;
    color: #ffd60a;
  }
  
  /* Circular progress bar */
  .progress-circle {
    width: 150px;
    height: 150px;
    transform: rotate(-90deg);
  }
  
  .circle-bg {
    fill: none;
    stroke: #444;
    stroke-width: 2.8;
  }
  
  .circle {
    fill: none;
    stroke: #ffd60a;
    stroke-width: 2.8;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.3s;
  }

  .custom-green-button {
  background: linear-gradient(to bottom, #039a01, #03ff03);
  color: #000814;
}

/* New red button styles */
.custom-red-button {
  background: linear-gradient(to bottom, #a10101, #ff0303);
  color: #000814;
}
  </style>
  