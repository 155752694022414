// vrScenarios.js
import CBLevel1 from './CB-Level1.vue';
import CBLevel2 from './CB-Level2.vue';
import CBLevel3 from './CB-Level3.vue';
import CBLevel4 from './CB-Level4.vue';
import CBLevel5 from './CB-Level5.vue';
import CBLevel6 from './CB-Level6.vue';
import CBLevel7 from './CB-Level7.vue';
import CBLevel8 from './CB-Level8.vue';

import Level1Thumbnail from '../assets/EWERS-Level-1.png';
import Level2Thumbnail from '../assets/EWERS-Level-2.png';
import Level3Thumbnail from '../assets/EWERS-Level-3.png';
import Level4Thumbnail from '../assets/EWERS-Level-4.png';
import Level5Thumbnail from '../assets/EWERS-Level-5.png';
import Level6Thumbnail from '../assets/EWERS-Level-6.png';
import Level7Thumbnail from '../assets/EWERS-Level-7.png';
import Level8Thumbnail from '../assets/EWERS-Level-8.png';

export const vrScenarios = [
  {
    id: 'cb-level-1',
    name: 'Conveyor Belt - Exercise 1',
    component: CBLevel1,
    thumbnail: Level1Thumbnail,
    project: 'EWERS - 1',
    instructions: {
      title: "Exercise 1 - Start and Stop the Production Line",
      objective: {
        subtitle: "Objective:",
        description:
          "In this exercise, you’ll be working with a simulated production line that processes raw wood logs, transforming them into smaller, more manageable materials for construction and decoration. Your task is to design ladder logic to control the starting and stopping of a motor, which will drive the conveyor belt on this line."
      },
      equipmentOverview: {
        subtitle: "Equipment Overview:",
        description:
          "In the training environment, you’ll see two push buttons:\n\n- **Start Button:** This is a momentary push button (also called a “one-shot” or “press and release” type). When you press it, it completes a circuit for a short time before automatically returning to its original state.\n- **Stop Button:** This is also a momentary push button, designed to stop the motor when pressed.\n\nThese buttons are similar to a doorbell. When pressed, they briefly make contact, then return to their resting state as soon as you release them. Momentary push buttons are used for quick, on-off control."
      },
      taskInstructions: {
        subtitle: "Task Instructions:",
        description:
          "Your goal is to create a ladder logic diagram where pressing the **Start button** (using a one-shot positive contact) activates the motor coil (representing the conveyor belt motor). Since the one-shot positive contact is only momentary, you will need to use **interlocking logic** to keep the coil energized continuously until the Stop button is pressed."
      },
      stepsToFollow: {
        subtitle: "Steps to Follow:",
        steps: [
          "Design the ladder logic to activate the motor coil using the **Start button**.",
          "Use a one-shot positive contact on the Start button, which will send a single pulse to energize the motor.",
          "Represent the motor in your logic with a coil, **C1**.",
          "Implement Interlocking Logic:\n\n - Since the Start button’s one-shot signal is momentary, the motor would only stay on for a brief moment without additional logic.\n - To keep the motor coil energized, add a Normally Open (NO) interlock contact, linked to the motor coil (C1).\n - This NO contact should close when C1 is energized, allowing energy to continue flowing even after the Start button is released. This keeps the motor running until a separate command stops it.",
          "Adding the Stop Button:\n\n - Introduce a one-shot negative contact for the Stop button. When pressed, this contact will briefly open the circuit, de-energizing the motor coil (C1) and stopping the conveyor."
        ]
      },
      keyConcept: {
        subtitle: "Key Concept: Interlocking Logic",
        description:
          "Interlocking logic is a technique used to maintain the state of an output device (like a motor) even after the initial control signal (from the Start button) is gone. By using a Normally Open (NO) interlock contact tied to the motor coil (C1), you create a path for current that remains closed while C1 is active. This way, the motor stays energized until the Stop button is pressed."
      },
      exampleOutline: {
        subtitle: "Example Ladder Logic Outline:",
        steps: [
          "Rung 1: Start Sequence\n\n - One-shot positive contact (linked to Start button) —> Motor Coil (C1)",
          "Branch Below One-Shot Positive Contact in Rung 1: Interlock Sequence\n\n - NO Contact (C1) —> Motor Coil (C1)",
          "Rung 1: Stop Sequence\n\n - One-shot negative contact (linked to Stop button) —> Breaks the path to Motor Coil (C1), de-energizing it."
        ]
      }
    }
  },
  {
    id: 'cb-level-2',
    name: 'Conveyor Belt - Exercise 2',
    component: CBLevel2,
    thumbnail: Level2Thumbnail,
    project: 'EWERS - 1',
    instructions: {
      title: "Exercise 2 - Start and Stop the Production Line with Process Active Lights",
      objective: {
        subtitle: "Objective:",
        description:
          "Building on the skills from Exercise 1, this exercise adds a new layer of functionality to the production line control. In addition to starting and stopping the motor, your task now includes managing process active lights that indicate the operational status of the motor, improving both safety and visibility of system state."
      },
      equipmentOverview: {
        subtitle: "Equipment Overview:",
        description:
          "In addition to the Start and Stop buttons from Exercise 1, you’ll now see new equipment in the training environment:\n\n- **Process Active Lights:** These lights will turn green when the Motor Coil (C1) is energized, indicating the motor is running. When C1 is de-energized, the lights will turn red, signaling that the motor is off.\n\nThese lights provide a visual indicator of the motor’s state, ensuring operators and personnel can quickly understand whether the production line is active or inactive at any time."
      },
      taskInstructions: {
        subtitle: "Task Instructions:",
        description:
          "Extend the ladder logic from Exercise 1 by incorporating process active lights. When the motor coil (C1) is energized, the process active lights should turn green, and when C1 is de-energized, the lights should turn red. This provides a clear visual indication of the motor’s current state."
      },
      stepsToFollow: {
        subtitle: "Steps to Follow:",
        steps: [
          "Use the ladder logic from Exercise 1 to control the motor coil (C1) with the Start and Stop buttons.",
          "Introduce logic for **Process Active Lights**:\n\n - When the motor coil (C1) is energized (motor running), the process active lights should be green.\n - When C1 is de-energized (motor stopped), the process active lights should turn red.",
          "Implement the interlocking logic as in Exercise 1 to keep the motor coil energized after pressing the Start button until the Stop button is pressed."
        ]
      },
      keyConcept: {
        subtitle: "Key Concept: Visual Indicators for Operational Status",
        description:
          "Process active lights provide a critical visual cue for safety and system awareness. By indicating when equipment is on or off, these lights help prevent accidental engagement, ensure safer work conditions, and allow operators to quickly assess whether the production line is active. This safety feature is especially valuable in environments where multiple operators or machines are involved."
      },
      exampleOutline: {
        subtitle: "Example Ladder Logic Outline:",
        steps: [
          "Rung 1: Start Sequence\n\n - One-shot positive contact (linked to Start button) —> Motor Coil (C1)",
          "Branch Below One-Shot Positive Contact in Rung 1: Interlock Sequence\n\n - NO Contact (C1) —> Motor Coil (C1)",
          "Rung 1: Stop Sequence\n\n - One-shot negative contact (linked to Stop button) —> Breaks the path to Motor Coil (C1), de-energizing it.",
          "Branch Below Motor Coil (C1): Process Active Light Control\n\n - Process Active Light Control (C2) energized —> Green Light ON (Process Active)\n - Process Active Light Control (C2) de-energized —> Red Light ON (Process Inactive)"
        ]
        
      }
    }    

  },
  {
    id: 'cb-level-3',
    name: 'Conveyor Belt - Exercise 3',
    component: CBLevel3,
    thumbnail: Level3Thumbnail,
    project: 'EWERS - 1',
    instructions: {
      title: "Exercise 3 - Automated Stop and Restart with Sensor and Timer",
      objective: {
        subtitle: "Objective:",
        description:
          "Building upon the logic from Exercise 2, this exercise introduces automated control with a sensor and timer to temporarily stop the motor and restart it after a delay. Your task is to use ladder logic to control motor C1 as a log reaches a specific unit operation station on the conveyor. This involves using a limit switch sensor to stop the motor and start a timer, allowing the log to remain in the station momentarily before resuming movement."
      },
      equipmentOverview: {
        subtitle: "Equipment Overview:",
        description:
          "In addition to the Start and Stop buttons and process active lights, you will now have new equipment in the environment:\n\n- **Log and Conveyor Simulation:** Once the motor coil (C1) is energized, a log will move along the conveyor in the training environment.\n- **Limit Switch Sensor:** When the log reaches the first unit operation station, this sensor is triggered. It performs two actions:\n  1. Stops the motor (C1) temporarily by cutting the energy flow to C1.\n  2. Starts a **TON Timer** that counts down a preset time, after which the motor will automatically restart.\n\nThese additional elements will allow you to simulate and control the timing of the log’s operation at the station."
      },
      taskInstructions: {
        subtitle: "Task Instructions:",
        description:
          "Extend your ladder logic from Exercise 2 by adding control logic for the limit switch sensor and timer. When the motor coil (C1) is energized, the log moves along the conveyor until it reaches the unit operation station, where the limit switch sensor is triggered. The sensor should perform two simultaneous actions: de-energizing motor C1 and starting a timer. Once the timer reaches zero, the motor should automatically restart, and the sensor’s control should reset to allow the cycle to continue."
      },
      stepsToFollow: {
        subtitle: "Steps to Follow:",
        steps: [
          "Use the ladder logic from Exercise 2 to control motor coil (C1) with the Start and Stop buttons and process active lights.",
          "Add logic for the **Limit Switch Sensor**:\n\n - When the log reaches the station, the sensor should de-energize motor C1 by cutting the power flow.\n - Simultaneously, the sensor should energize a new coil (C3) using a one-shot positive contact and interlocking logic.",
          "Add a Normally Open (NO) contact linked to C3:\n\n - When this NO contact closes, it should start a TON timer.\n - Set a preset time for the timer to keep the motor off and allow the log to stay at the station.",
          "Define the **TON Timer Logic**:\n\n - When the timer reaches zero, it should:\n   - Toggle a one-shot positive contact to re-energize motor coil (C1), restarting the conveyor.\n   - Toggle a one-shot negative contact to de-energize C3, resetting the sensor logic."
        ]
      },
      keyConcept: {
        subtitle: "Key Concept: Sensors (Limit Switch) and TON Timer",
        description:
          "Sensors, such as limit switches, are critical components for automation, allowing precise control over the movement and timing of processes. In this case, the limit switch sensor stops the motor when the log reaches the unit operation station, ensuring accurate processing time. The TON timer then controls the delay, making sure the log stays long enough for processing before resuming. This automated timing helps to streamline the process without manual intervention and enhances accuracy, consistency, and safety."
      },
      exampleOutline: {
        subtitle: "Example Ladder Logic Outline:",
        steps: [
          "Rung 1: Start Sequence\n\n - One-shot positive contact (linked to Start button) —> Motor Coil (C1)",
          "Branch Below One-Shot Positive Contact in Rung 1: Interlock Sequence\n\n - NO Contact (C1) —> Motor Coil (C1)",
          "Rung 1: Stop Sequence\n\n - One-shot negative contact (linked to Stop button) —> Breaks the path to Motor Coil (C1), de-energizing it.",
          "Branch Below Motor Coil (C1): Process Active Light Control\n\n - Process Active Light Control (C2) energized —> Green Light ON (Process Active)\n - Process Active Light Control (C2) de-energized —> Red Light ON (Process Inactive)",
          "Rung 2: Sensor Trigger Logic\n\n - When the Limit Switch Sensor is activated:\n   - De-energize Motor Coil (C1)\n   - Energize Coil (C3) using a one-shot positive contact and interlocking logic.",
          "Rung 3: Timer Start Logic\n\n - NO Contact (linked to C3) closes —> Starts TON Timer",
          "Rung 1 and Rung 2: Timer Control Logic\n\n - When TON Timer reaches preset time:\n   - One-shot positive contact re-energizes Motor Coil (C1)\n   - One-shot negative contact de-energizes Coil (C3) to reset the sensor control."
        ]
      }
    }
    

  },
  {
    id: 'cb-level-4',
    name: 'Conveyor Belt - Exercise 4',
    component: CBLevel4,
    thumbnail: Level4Thumbnail,
    project: 'EWERS - 1',
    instructions: {
      title: "Exercise 4 - Warning Lamp Indicator for Unit Operation Station",
      objective: {
        subtitle: "Objective:",
        description:
          "This exercise builds upon Exercise 3 by introducing a warning lamp above the first unit operation box. This lamp provides visual feedback on the conveyor and processing status by changing colors depending on the motor and sensor states. Your task is to incorporate this warning light logic so it indicates the status of the log processing."
      },
      equipmentOverview: {
        subtitle: "Equipment Overview:",
        description:
          "In addition to the Start and Stop buttons, process active lights, and limit switch sensor, there is now a **Warning Lamp** above the first unit operation station:\n\n- **Warning Lamp:** This lamp will change colors to indicate different states:\n  - **Red:** When the sensor (limit switch) and Coil (C3) are de-energized, indicating the conveyor is moving, or the station is inactive.\n  - **Yellow:** When the sensor (limit switch) and Coil (C3) are energized, indicating the conveyor has stopped, and the log is undergoing processing.\n\nThis warning lamp enhances safety by providing real-time visual feedback of the conveyor status and helps operators know when the unit operation station is actively processing."
      },
      taskInstructions: {
        subtitle: "Task Instructions:",
        description:
          "Extend the ladder logic from Exercise 3 by incorporating control logic for the warning lamp. This lamp should turn yellow when the limit switch sensor is triggered and the conveyor stops (indicating log processing) and should turn red when the motor resumes operation and the sensor is de-energized."
      },
      stepsToFollow: {
        subtitle: "Steps to Follow:",
        steps: [
          "Use the ladder logic from Exercise 3 to control motor coil (C1) with the Start and Stop buttons, process active lights, and the limit switch sensor with TON timer.",
          "Add logic for **Warning Lamp Control**:\n\n - The warning lamp should remain **red** when motor coil (C1) is energized and moving the conveyor.\n - The warning lamp should switch to **yellow** when coil (C3) is energized, indicating that the log has reached the operation station and the motor is off for processing.",
          "Adjust the **Limit Switch Sensor Logic** to control both coil (C1) and coil (C3), as well as the warning lamp, based on the sensor state."
        ]
      },
      keyConcept: {
        subtitle: "Key Concept: Warning Lights for Visual Indicators",
        description:
          "Warning lights are essential in automated systems for signaling machine or process status. In this case, the warning lamp provides a clear indication of the conveyor and processing status, helping operators immediately understand whether the conveyor is moving or a log is actively being processed. Such indicators increase operational safety, allowing for quick decisions and response in case of any issues or emergencies."
      },
      exampleOutline: {
        subtitle: "Example Ladder Logic Outline:",
        steps: [
          "Rung 1: Start Sequence\n\n - One-shot positive contact (linked to Start button) —> Motor Coil (C1)",
          "Branch Below One-Shot Positive Contact in Rung 1: Interlock Sequence\n\n - NO Contact (C1) —> Motor Coil (C1)",
          "Rung 1: Stop Sequence\n\n - One-shot negative contact (linked to Stop button) —> Breaks the path to Motor Coil (C1), de-energizing it.",
          "Branch Below Motor Coil (C1): Process Active Light Control\n\n - Process Active Light Control (C2) energized —> Green Light ON (Process Active)\n - Process Active Light Control (C2) de-energized —> Red Light ON (Process Inactive)",
          "Rung 2: Sensor Trigger Logic\n\n - When the Limit Switch Sensor is activated:\n   - De-energize Motor Coil (C1)\n   - Energize Coil (C3) using a one-shot positive contact and interlocking logic.",
          "Rung 3: Timer Start Logic\n\n - NO Contact (linked to C3) closes —> Starts TON Timer",
          "Rung 1 and Rung 2: Timer Control Logic\n\n - When TON Timer reaches preset time:\n   - One-shot positive contact re-energizes Motor Coil (C1)\n   - One-shot negative contact de-energizes Coil (C3) to reset the sensor control.",
          "Branch Below Coil (C3): Warning Lamp Control\n\n - Warning Lamp remains **red** when Coil (C1) is energized.\n - Warning Lamp turns **yellow** when Coil (C3) is energized, indicating the log is in the unit operation station."
        ]
      }
    }
    
  },
  {
    id: 'cb-level-5',
    name: 'Conveyor Belt - Exercise 5',
    component: CBLevel5,
    thumbnail: Level5Thumbnail,
    project: 'EWERS - 1',
    instructions: {
      title: "Exercise 5 - Sequential Stop and Warning Light for Second Unit Operation Box",
      objective: {
        subtitle: "Objective:",
        description:
          "Building on Exercise 4, this exercise introduces a second unit operation station equipped with its own sensor and warning light. Your task is to expand the ladder logic to handle sequential processing. When the log reaches the second unit operation station, the conveyor should stop, and a second warning light should indicate the log's presence. Unlike the first unit operation, this station does not use a timer to restart the conveyor."
      },
      equipmentOverview: {
        subtitle: "Equipment Overview:",
        description:
          "In addition to the Start and Stop buttons, process active lights, warning light 1, and the first limit switch sensor, the following new equipment is added:\n\n- **Second Limit Switch Sensor (Sensor 2):** Located at the second unit operation station, this sensor is triggered when the log arrives at the second station, stopping the conveyor.\n- **Warning Light 2:** Positioned above the second unit operation box, this light provides visual feedback on the log's position.\n  - **Red:** When the second sensor (limit switch 2) is de-energized, indicating the conveyor is moving, or the station is inactive.\n  - **Yellow:** When sensor 2 is triggered and motor C1 is off, indicating the log is present at the station."
      },
      taskInstructions: {
        subtitle: "Task Instructions:",
        description:
          "Expand the ladder logic from Exercise 4 to incorporate the second limit switch sensor and warning light. When the log reaches the second unit operation station, the sensor should turn off motor C1 and activate warning light 2 (yellow). This visual cue signals the presence of the log at the second station and stops the conveyor without using a timer."
      },
      stepsToFollow: {
        subtitle: "Steps to Follow:",
        steps: [
          "Use the ladder logic from Exercise 4 to control motor coil (C1), process active lights, warning light 1, and the first limit switch sensor with TON timer.",
          "Add logic for the **Second Limit Switch Sensor (Sensor 2)**:\n\n - When the log reaches the second station, the second sensor should de-energize motor C1 to stop the conveyor.\n - Simultaneously, the sensor should activate **Warning Light 2** (yellow) to indicate the log's presence at the station.",
          "Adjust the logic so that **Warning Light 2** remains **red** when motor coil (C1) is energized and the log is in motion, and switches to **yellow** when Sensor 2 is triggered and the conveyor stops at the second unit."
        ]
      },
      keyConcept: {
        subtitle: "Key Concept: Sequential Control and Visual Indicators for Multi-Station Processes",
        description:
          "Sequential control allows multiple operations along a conveyor line, with each unit station stopping and visually signaling the log's presence. This setup is common in complex processes that involve different stages. Visual indicators like warning lights at each station help operators quickly recognize the log’s current stage and prevent unnecessary interference."
      },
      exampleOutline: {
        subtitle: "Example Ladder Logic Outline:",
        steps: [
          "Rung 1: Start Sequence\n\n - One-shot positive contact (linked to Start button) —> Motor Coil (C1)",
          "Branch Below One-Shot Positive Contact in Rung 1: Interlock Sequence\n\n - NO Contact (C1) —> Motor Coil (C1)",
          "Rung 1: Stop Sequence\n\n - One-shot negative contact (linked to Stop button) —> Breaks the path to Motor Coil (C1), de-energizing it.",
          "Branch Below Motor Coil (C1): Process Active Light Control\n\n - Process Active Light Control (C2) energized —> Green Light ON (Process Active)\n - Process Active Light Control (C2) de-energized —> Red Light ON (Process Inactive)",
          "Rung 2: Sensor 1 Trigger Logic\n\n - When Limit Switch Sensor 1 is activated:\n   - De-energize Motor Coil (C1)\n   - Energize Coil (C3) using a one-shot positive contact and interlocking logic.",
          "Rung 3: Timer Start Logic for Sensor 1\n\n - NO Contact (linked to C3) closes —> Starts TON Timer",
          "Rung 1 and Rung 2: Timer Control Logic\n\n - When TON Timer reaches preset time:\n   - One-shot positive contact re-energizes Motor Coil (C1)\n   - One-shot negative contact de-energizes Coil (C3) to reset Sensor 1 control.",
          "Branch Below Coil (C3): Warning Light 1 Control\n\n - Warning Light 1 remains **red** when Motor Coil (C1) is energized.\n - Warning Light 1 turns **yellow** when Coil (C3) is energized, indicating the log is at the first unit operation station.",
          "Rung 4: Sensor 2 Trigger Logic\n\n - When Limit Switch Sensor 2 is activated:\n   - De-energize Motor Coil (C1), stopping the conveyor.\n   - Activate Warning Light 2 (yellow) to indicate log presence at the second station.",
          "Branch Below Motor Coil (C1): Warning Light 2 Control\n\n - Warning Light 2 remains **red** when Motor Coil (C1) is energized and the conveyor is moving.\n - Warning Light 2 turns **yellow** when Sensor 2 is triggered, signaling the log has arrived at the second unit operation station."
        ]
      }
    }
    

  },
  {
    id: 'cb-level-6',
    name: 'Conveyor Belt - Exercise 6',
    component: CBLevel6,
    thumbnail: Level6Thumbnail,
    project: 'EWERS - 1',
    instructions: {
      title: "Exercise 6 - Iterative Counter-Controlled Restart of Motor C1",
      objective: {
        subtitle: "Objective:",
        description:
          "In this exercise, you’ll build on the previous logic by adding a countdown loop that repeatedly activates a timer and counts each cycle. Once this counter reaches its preset count, Motor C1 will restart automatically. This method introduces iterative control using a counter and a second timer, providing further automation to the conveyor process."
      },
      equipmentOverview: {
        subtitle: "Equipment Overview:",
        description:
          "In addition to the Start and Stop buttons, process active lights, sensors, and the first timer, this exercise introduces a **second timer (Timer 2)** and a **counter**:\n\n- **Timer 2:** When activated, Timer 2 will begin a countdown (preset to 3 seconds) that triggers an increase in the counter’s value each time it reaches zero.\n- **Counter:** The counter will keep track of the number of times Timer 2 reaches zero. When the counter reaches a count of 10, it will re-energize Motor C1, allowing the conveyor to resume."
      },
      taskInstructions: {
        subtitle: "Task Instructions:",
        description:
          "Using the logic from Exercise 5 as a base, add a second timer and a counter to restart Motor C1 after it has been stopped by Sensor 2. Each time Timer 2 reaches zero, it should trigger a one-shot positive contact that increments the counter by 1. When the counter reaches a count of 10, Motor C1 will restart. To ensure that Timer 2 restarts each cycle, use an additional coil to control Timer 2’s activation, creating an iterative process that runs until the counter reaches its preset."
      },
      stepsToFollow: {
        subtitle: "Steps to Follow:",
        steps: [
          "Use the ladder logic from Exercise 5 to control motor coil (C1), process active lights, warning lights, and Sensor 2.",
          
          "Add **Coil C5** Control Logic:\n\n - When Sensor 2 detects the log at the second unit operation station, it energizes **Coil C5**.",
          
          "Define **Timer 2 Control Logic**:\n\n - Create a Normally Open (NO) contact linked to Coil C5 that will energize an additional coil (Coil C6).\n - Use Coil C6 to control the flow of power to Timer 2, allowing Timer 2 to begin its countdown.",
          
          "Timer 2 Countdown Logic:\n\n - Set Timer 2 with a preset of 3 seconds.\n - When Timer 2 reaches zero, it should toggle a one-shot positive contact that is in series with a counter, incrementing the counter by 1.\n - Additionally, when Timer 2 reaches zero, it should toggle a one-shot negative contact that briefly de-energizes Coil C6, resetting Timer 2 and allowing the process to begin again. This creates a cycle that repeats until the counter reaches its preset.",
          
          "Counter Logic:\n\n - Place the counter immediately after the one-shot positive contact triggered by Timer 2 reaching zero.\n - Set the counter with a preset of 10. Each cycle of Timer 2 will increase the counter by 1, incrementing the count until it reaches 10.",
          
          "Restarting Motor C1:\n\n - When the counter reaches its preset of 10, it will energize Motor C1 to resume conveyor movement.",
          
          "Counter Reset Logic:\n\n - When Motor C1 is re-energized, add a one-shot positive contact to enable the counter reset rung.\n - In the rung with the counter reset coil, place this one-shot positive contact to allow energy to reach the reset coil, ensuring the counter resets only after Motor C1 restarts."
        ]
      },
      keyConcept: {
        subtitle: "Key Concept: Iterative Control and Counters for Process Automation",
        description:
          "Using iterative control with a counter allows for precise automation cycles that depend on conditions, such as a sensor trigger. This technique is common in complex automation systems where repeated actions, such as timed pauses or counts, determine the progression of a process. In this exercise, Timer 2 repeatedly counts down and increments a counter until the preset is reached, demonstrating a feedback loop often seen in automated manufacturing processes."
      },
      exampleOutline: {
        subtitle: "Example Ladder Logic Outline:",
        steps: [
          "Rung 1: Start Sequence\n\n - One-shot positive contact (linked to Start button) —> Motor Coil (C1)",
          
          "Branch Below One-Shot Positive Contact in Rung 1: Interlock Sequence\n\n - NO Contact (C1) —> Motor Coil (C1)",
          
          "Rung 1: Stop Sequence\n\n - One-shot negative contact (linked to Stop button) —> Breaks the path to Motor Coil (C1), de-energizing it.",
          
          "Branch Below Motor Coil (C1): Process Active Light Control\n\n - Process Active Light Control (C2) energized —> Green Light ON (Process Active)\n - Process Active Light Control (C2) de-energized —> Red Light ON (Process Inactive)",
          
          "Rung 2: Sensor 1 Trigger Logic\n\n - When Limit Switch Sensor 1 is activated:\n   - De-energize Motor Coil (C1)\n   - Energize Coil (C3) using a one-shot positive contact and interlocking logic.",
          
          "Rung 3: Timer Start Logic for Sensor 1\n\n - NO Contact (linked to C3) closes —> Starts TON Timer",
          
          "Rung 1 and Rung 2: Timer Control Logic\n\n - When TON Timer reaches preset time:\n   - One-shot positive contact re-energizes Motor Coil (C1)\n   - One-shot negative contact de-energizes Coil (C3) to reset Sensor 1 control.",
          
          "Branch Below Coil (C3): Warning Light 1 Control\n\n - Warning Light 1 remains **red** when Motor Coil (C1) is energized.\n - Warning Light 1 turns **yellow** when Coil (C3) is energized, indicating the log is at the first unit operation station.",
          
          "Rung 4: Sensor 2 Trigger Logic\n\n - When Limit Switch Sensor 2 is activated:\n   - De-energize Motor Coil (C1), stopping the conveyor.\n   - Activate Warning Light 2 (yellow) to indicate log presence at the second station.",
          
          "Rung 5: Coil C5 Activation\n\n - When Sensor 2 is triggered, energize Coil (C5).",
          
          "Rung 6: Timer 2 Control\n\n - NO Contact (linked to C5) —> Energizes Coil (C6)\n - Coil (C6) energizes Timer 2, allowing it to begin its countdown.",
          
          "Rung 7: Timer 2 Countdown and Counter Increment\n\n - Timer 2 reaches zero —> One-shot positive contact activates Counter (preset: 10), increasing the count by 1.\n - One-shot negative contact de-energizes Coil (C6) momentarily, resetting Timer 2 to start again, creating a cycle.",
          
          "Rung 8: Motor C1 Restart\n\n - When Counter reaches 10, it energizes Motor Coil (C1), restarting the conveyor.",
          
          "Rung 9: Counter Reset Logic\n\n - When Motor C1 restarts, one-shot positive contact enables the counter reset coil, resetting the counter to 0 for the next cycle."
        ]
      }
    }
    

  },
  {
    id: 'cb-level-7',
    name: 'Conveyor Belt - Exercise 7',
    component: CBLevel7,
    thumbnail: Level7Thumbnail,
    project: 'EWERS - 1',
    instructions: {
      title: "Exercise 7 - Complete Sequential Control for Multi-Stage Unit Operations",
      objective: {
        subtitle: "Objective:",
        description:
          "This exercise extends the sequential control system to include additional unit operations. You will add sensors, timers, and warning lamps for three new unit operation stations (3, 4, and 5) along the conveyor. Each station will stop the motor to allow processing time before the conveyor resumes. Finally, you’ll add a sensor at the end of the conveyor to detect when the log has reached the end of the line, completing the sequence."
      },
      equipmentOverview: {
        subtitle: "Equipment Overview:",
        description:
          "In addition to the Start and Stop buttons, process active lights, existing sensors, timers, and warning lamps, this exercise introduces new equipment for additional unit operation stations and an end-of-line sensor:\n\n- **Unit Operation Stations 3, 4, and 5**\n  - **Sensor 3, Sensor 4, and Sensor 5:** Each station has a limit switch sensor that triggers when the log reaches it, stopping the conveyor and starting a timer for processing time.\n  - **Timer 3, Timer 4, and Timer 5:** Each sensor activates a respective timer (set with a preset time), which allows processing time at each station.\n  - **Warning Lamp 3, Warning Lamp 4, and Warning Lamp 5:** Each station has a warning lamp that turns **yellow** when the log is at that station (sensor activated) and **red** when the station is inactive.\n\n- **End-of-Line Sensor (Sensor 6):** Positioned at the end of the conveyor, this sensor detects when the log reaches the end. No timer or warning lamp is needed for this sensor."
      },
      taskInstructions: {
        subtitle: "Task Instructions:",
        description:
          "Expand your ladder logic to include sensors, timers, and warning lamps for Unit Operation Stations 3, 4, and 5. When the log reaches each station, the respective sensor should stop Motor C1, activate the corresponding warning lamp (yellow), and start a timer to allow processing time. After the timer reaches zero, Motor C1 should restart, moving the log to the next station. For Sensor 6, simply stop the motor at the end of the conveyor to complete the sequence."
      },
      stepsToFollow: {
        subtitle: "Steps to Follow:",
        steps: [
          "Use the ladder logic from Exercise 6 to control motor coil (C1), process active lights, warning lamps, sensors, and timers for Unit Operation Stations 1 and 2.",
          
          "Add logic for **Sensor 3, Timer 3, and Warning Lamp 3**:\n\n - When Sensor 3 is activated:\n   - De-energize Motor Coil (C1), stopping the conveyor.\n   - Energize Warning Lamp 3, turning it **yellow**.\n   - Start Timer 3 to allow processing time at Station 3.\n - When Timer 3 reaches zero:\n   - Re-energize Motor Coil (C1), restarting the conveyor.\n   - Reset Warning Lamp 3 to **red**.",
          
          "Add logic for **Sensor 4, Timer 4, and Warning Lamp 4**:\n\n - When Sensor 4 is activated:\n   - De-energize Motor Coil (C1), stopping the conveyor.\n   - Energize Warning Lamp 4, turning it **yellow**.\n   - Start Timer 4 to allow processing time at Station 4.\n - When Timer 4 reaches zero:\n   - Re-energize Motor Coil (C1), restarting the conveyor.\n   - Reset Warning Lamp 4 to **red**.",
          
          "Add logic for **Sensor 5, Timer 5, and Warning Lamp 5**:\n\n - When Sensor 5 is activated:\n   - De-energize Motor Coil (C1), stopping the conveyor.\n   - Energize Warning Lamp 5, turning it **yellow**.\n   - Start Timer 5 to allow processing time at Station 5.\n - When Timer 5 reaches zero:\n   - Re-energize Motor Coil (C1), restarting the conveyor.\n   - Reset Warning Lamp 5 to **red**.",
          
          "Define **End-of-Line Sensor 6**:\n\n - When Sensor 6 is triggered:\n   - De-energize Motor Coil (C1), stopping the conveyor at the end of the line.\n   - No timer or warning lamp is needed for Sensor 6."
        ]
      },
      keyConcept: {
        subtitle: "Key Concept: Multi-Stage Sequential Control with Timers and Visual Indicators",
        description:
          "Adding multiple unit operation stations with sequential controls allows for complex, staged processing along the conveyor. Each station uses a timer to allow specific processing time, while warning lamps provide visual indicators of active and inactive stages. This arrangement is common in manufacturing and assembly lines, where products undergo multiple stages of processing with built-in timing and status indicators."
      },
      exampleOutline: {
        subtitle: "Example Ladder Logic Outline:",
        steps: [
          "Rung 1: Start Sequence\n\n - One-shot positive contact (linked to Start button) —> Motor Coil (C1)",
          
          "Branch Below One-Shot Positive Contact in Rung 1: Interlock Sequence\n\n - NO Contact (C1) —> Motor Coil (C1)",
          
          "Rung 1: Stop Sequence\n\n - One-shot negative contact (linked to Stop button) —> Breaks the path to Motor Coil (C1), de-energizing it.",
          
          "Branch Below Motor Coil (C1): Process Active Light Control\n\n - Process Active Light Control (C2) energized —> Green Light ON (Process Active)\n - Process Active Light Control (C2) de-energized —> Red Light ON (Process Inactive)",
          
          "Rung 2: Sensor 1 Trigger Logic\n\n - When Limit Switch Sensor 1 is activated:\n   - De-energize Motor Coil (C1)\n   - Energize Coil (C3) using a one-shot positive contact and interlocking logic.",
          
          "Rung 3: Timer Start Logic for Sensor 1\n\n - NO Contact (linked to C3) closes —> Starts TON Timer",
          
          "Rung 1 and Rung 2: Timer Control Logic\n\n - When TON Timer reaches preset time:\n   - One-shot positive contact re-energizes Motor Coil (C1)\n   - One-shot negative contact de-energizes Coil (C3) to reset Sensor 1 control.",
          
          "Branch Below Coil (C3): Warning Light 1 Control\n\n - Warning Light 1 remains **red** when Motor Coil (C1) is energized.\n - Warning Light 1 turns **yellow** when Coil (C3) is energized, indicating the log is at the first unit operation station.",
          
          "Rung 4: Sensor 2 Trigger Logic\n\n - When Limit Switch Sensor 2 is activated:\n   - De-energize Motor Coil (C1), stopping the conveyor.\n   - Activate Warning Light 2 (yellow) to indicate log presence at the second station.",
          
          "Additional Rungs for Unit Operation Stations 3, 4, and 5:",
          
          "Rung 5: Sensor 3 Trigger Logic\n\n - When Sensor 3 is activated:\n   - De-energize Motor Coil (C1)\n   - Start Timer 3 (preset) to control processing time.\n   - Warning Lamp 3 ON (yellow for active processing).",
          
          "Rung 6: Timer 3 Control Logic\n\n - When Timer 3 reaches zero:\n   - Re-energize Motor Coil (C1)\n   - Warning Lamp 3 OFF (red).",
          
          "Rung 7: Sensor 4 Trigger Logic\n\n - When Sensor 4 is activated:\n   - De-energize Motor Coil (C1)\n   - Start Timer 4 (preset) to control processing time.\n   - Warning Lamp 4 ON (yellow for active processing).",
          
          "Rung 8: Timer 4 Control Logic\n\n - When Timer 4 reaches zero:\n   - Re-energize Motor Coil (C1)\n   - Warning Lamp 4 OFF (red).",
          
          "Rung 9: Sensor 5 Trigger Logic\n\n - When Sensor 5 is activated:\n   - De-energize Motor Coil (C1)\n   - Start Timer 5 (preset) to control processing time.\n   - Warning Lamp 5 ON (yellow for active processing).",
          
          "Rung 10: Timer 5 Control Logic\n\n - When Timer 5 reaches zero:\n   - Re-energize Motor Coil (C1)\n   - Warning Lamp 5 OFF (red).",
          
          "Rung 11: End-of-Line Sensor 6\n\n - When Sensor 6 is triggered, de-energize Motor Coil (C1), stopping the conveyor at the end of the line."
        ]
      }
    }
    

  },
  {
    id: 'cb-level-8',
    name: 'Conveyor Belt - Exercise 8',
    component: CBLevel8,
    thumbnail: Level8Thumbnail,
    project: 'EWERS - 1',
    instructions: {
      title: "Exercise 8 - Multi-Log Processing and Sequential Launching with Motor 2",
      objective: {
        subtitle: "Objective:",
        description:
          "This exercise builds on previous exercises by introducing multiple logs on the conveyor. Now, each time a log passes through Unit Operation Station 1, a new log is launched from the start of the conveyor. This setup uses a second motor (Motor 2) and additional logic to control the launch of new logs, simulating a mass production line with multiple items moving along the conveyor simultaneously."
      },
      equipmentOverview: {
        subtitle: "Equipment Overview:",
        description:
          "In addition to the Start and Stop buttons, sensors, timers, and warning lamps from previous exercises, this exercise introduces **Motor 2**:\n\n- **Motor 2**: This motor controls the launch of new logs onto the conveyor. It is initially energized, allowing a log to appear at the start of the conveyor when Motor 1 is first activated.\n\n- **Sequence Control Logic for Multi-Log Processing**: A new rung with a Normally Closed (NC) contact and Motor 2’s coil will control the launch of additional logs onto the conveyor. This NC contact is linked to Sensor 1 (at Unit Operation Station 1) and will open whenever Sensor 1 is active, temporarily de-energizing Motor 2 and creating the conditions for launching a new log when Motor 2 re-energizes."
      },
      taskInstructions: {
        subtitle: "Task Instructions:",
        description:
          "Expand the ladder logic to include Motor 2 and multi-log functionality. When Motor 1 is started, a log will appear on the conveyor. As the log moves along the conveyor and triggers Sensor 1, Motor 2 will briefly de-energize and then re-energize, resetting the condition to release a new log when Motor 1 restarts. This logic will allow multiple logs to move along the conveyor simultaneously, simulating a more complex production line environment."
      },
      stepsToFollow: {
        subtitle: "Steps to Follow:",
        steps: [
          "Use the ladder logic from Exercise 7 to control motor coil (C1), process active lights, warning lamps, and sensors for Unit Operation Stations 1 through 5.",
          
          "Define **Motor 2 Launch Control Logic**:\n\n - In the final rung, add a Normally Closed (NC) contact on the left side of the rung, linked to the coil of **Sensor 1**.\n - Place the coil for **Motor 2** on the right side of this rung.",
          
          "Sequence Logic for Motor 2:\n\n - When **Sensor 1** is activated by a log, the Normally Closed (NC) contact will open, de-energizing Motor 2.\n - When Sensor 1 is no longer active (after the log moves away), the NC contact will close again, re-energizing Motor 2.\n - This cycle of de-energizing and re-energizing Motor 2 creates a condition that will allow a new log to be launched from the beginning of the conveyor.",
          
          "Log Creation Condition:\n\n - Define the logic so that each time Motor 2 goes from an **energized to de-energized to energized** state, and Motor 1 (C1) goes from **de-energized to energized**, a new log is created at the start of the conveyor.",
          
          "This condition will release a new log onto the conveyor each time a log passes through Unit Operation 1, simulating the launch of additional items in a mass production line."
        ]
      },
      keyConcept: {
        subtitle: "Key Concept: Multi-Item Production Control with Sequential Motor Logic",
        description:
          "This exercise introduces the concept of multi-item control in an automated production line. By using two motors and a sequential control setup, this system can create and process multiple items simultaneously. This logic is commonly applied in manufacturing lines to keep items moving through the stages of production without interruption. The interlocking behavior of Motor 2 provides a foundation for understanding how items can be launched at regular intervals based on sensor triggers and motor states."
      },
      exampleOutline: {
        subtitle: "Example Ladder Logic Outline:",
        steps: [
          "Rung 1: Start Sequence\n\n - One-shot positive contact (linked to Start button) —> Motor Coil (C1)",
          
          "Branch Below One-Shot Positive Contact in Rung 1: Interlock Sequence\n\n - NO Contact (C1) —> Motor Coil (C1)",
          
          "Rung 1: Stop Sequence\n\n - One-shot negative contact (linked to Stop button) —> Breaks the path to Motor Coil (C1), de-energizing it.",
          
          "Branch Below Motor Coil (C1): Process Active Light Control\n\n - Process Active Light Control (C2) energized —> Green Light ON (Process Active)\n - Process Active Light Control (C2) de-energized —> Red Light ON (Process Inactive)",
          
          "Rung 2: Sensor 1 Trigger Logic\n\n - When Limit Switch Sensor 1 is activated:\n   - De-energize Motor Coil (C1)\n   - Start Timer 1 (preset) to control processing time at Unit Operation 1.\n   - Warning Lamp 1 ON (yellow for active processing).",
          
          "Rung 3: Timer 1 Control Logic\n\n - When Timer 1 reaches zero:\n   - Re-energize Motor Coil (C1)\n   - Warning Lamp 1 OFF (red).",
          
          "Additional Rungs for Unit Operations 2 to 5 as per Exercise 7:",
          
          "Rung 8: Motor 2 Control Logic\n\n - Final rung with Normally Closed (NC) contact linked to Sensor 1 coil —> Motor 2 coil\n - When Sensor 1 is active, NC contact opens and de-energizes Motor 2.\n - When Sensor 1 is inactive, NC contact closes, re-energizing Motor 2 and setting the condition for a new log to launch when Motor 1 restarts."
        ]
      }
    }
    
  },

];
