// Styles
import '@mdi/font/css/materialdesignicons.css'  // Ensure material design icons are available
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    defaultTheme: 'light',  // Default to light theme
    themes: {
      light: {
        colors: {
          primary: '#2ea3f2',   // Blue
          secondary: '#202020', // Dark Gray
          accent: '#1E88E5',    // Another shade of blue
          error: '#FF5252',     // Red
          info: '#2196F3',      // Blue
          success: '#4CAF50',   // Green
          warning: '#FB8C00',   // Amber
          background: '#f9fcff', // Very light blue, for page backgrounds
          text: '#333',         // Dark grey for text
          anchor: '#2ea3f2',    // Blue for links
        },
        dark: {
          background: '#121212',  // Dark theme background
          text: '#FFF',           // White text for dark theme
          primary: '#2ea3f2',     // Blue
          secondary: '#424242',   // Dark grey for secondary elements
          anchor: '#2ea3f2',      // Blue for links
        },
      },
    }
  },
  icons: {
    iconfont: 'mdi',  // Default is 'mdi'; ensures Material Design Icons are used
  }
});
