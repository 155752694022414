<template>
  <div>
    <div class="hero dark-background">
      <h1>Contact Us!</h1>
      <h2>How can we help you?</h2>
    </div>
    <div class="form-container">
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScZ_JIDltVA4YCCZofEFd02gX_koAIpPkd4fVKjrgczZx3d1Q/viewform?embedded=true" width="640" height="821" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MedusaContact',
};
</script>

<style scoped>
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 400px;
  background-size: cover;
  background-position: center;
  color: white;
}

/* Background images for different screen widths */
.hero {
  background-image: url('@/assets/HERO-HOME-MOBILE.webp');
}

@media (min-width: 900px) {
  .hero {
    background-image: url('@/assets/HERO-HOME-COMPUTER.webp');
  }
}

@media (min-width: 1200px) {
  .hero {
    background-image: url('@/assets/HERO-HOME-COMPUTER.webp');
  }
}

.hero h1, .hero h2 {
  margin: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2); /* Darken the background for better readability */
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Use at least the remaining height or more depending on your layout */
  margin-top: -100px; /* Optional: adjust this value to align the top of the form with the end of the hero section */
}

iframe {
  border: none; /* Removes the border around the iframe */
  transition: transform 0.3s ease-out; /* Smooth transition for zoom effect on hover */
}

iframe:hover {
  transform: scale(1.05); /* Slightly enlarges the iframe when hovered */
}
</style>
