import axios from "axios";

// Helper function to map the alias to the internal path
const mapAliasToInternalPath = (alias) => {
  const aliasMapping = {
    "SBG-LC2030-1": "dresde", // Add any new mappings here as needed
  };
  return aliasMapping[alias] || alias; // Use alias if no mapping is found
};

// Mapping of tag names to LC2030 Tags
const lc2030TagMapping = {
  "C01": "Pump M1",
  "C02": "Pump M2",
  "C03": "Pump M3",
  "C04": "Pump M4",
  "C05": "Heater Q1",
  "C06": "Lamp P1",
  "C07": "Lamp P2",
  "C08": "Lamp P3",
  "D01": "LevelSwitchB1",
  "D02": "LevelSwitchB2",
  "D03": "LevelSwitchB3",
  "D04": "Button HS1",
  "D05": "Button HS2",
  "D06": "Button HS3",
  "D07": "Button HS4",
  "D08": "Button HS5",
};

export const fetchIIoTData = async (alias, iiotData) => {
  const internalPath = mapAliasToInternalPath(alias);

  try {
    const url = `https://app.plain4.com/api/messaging/tags?path=${internalPath}`;
    const response = await axios.get(url);

    // Process the response data and classify each tag's type, while filtering out IR and HR tags
    const processedData = response.data
      .filter((item) => {
        const tagName = item.path.split(".").pop();
        // Filter out tags that start with "IR" "Analog Input" or "HR" "Analog Output"
        return !tagName.startsWith("IR") && !tagName.startsWith("HR");
      })
      .map((item) => {
        const tagName = item.path.split(".").pop();
        const value = item.value;
        let type;

        if (tagName.startsWith("D")) {
          type = "Digital Input";
        } else if (tagName.startsWith("C")) {
          type = "Digital Output";
        } else {
          type = "Unknown";
        }

        const existingTag = iiotData.value.find((t) => t.tagName === tagName);

        // Get the LC2030 Tag from the mapping
        const lc2030Tag = lc2030TagMapping[tagName] || "Unknown";

        return {
          tagName,
          lc2030Tag,
          value,
          type,
          newValue: null,
          linkId: existingTag ? existingTag.linkId : null,
          direction: existingTag ? existingTag.direction : null,
        };
      });

    // Sort the data to ensure Digital Inputs (D01-D08) are shown first, then Digital Outputs (C01-C08)
    iiotData.value = processedData.sort((a, b) => {
      const isADigitalInput = a.type === "Digital Input";
      const isBDigitalInput = b.type === "Digital Input";

      if (isADigitalInput && !isBDigitalInput) return -1; // a is Digital Input, b is not
      if (!isADigitalInput && isBDigitalInput) return 1;  // b is Digital Input, a is not

      if (a.type === b.type) {
        // Both are the same type, sort numerically by the suffix number
        const aNumber = parseInt(a.tagName.match(/\d+/), 10);
        const bNumber = parseInt(b.tagName.match(/\d+/), 10);
        return aNumber - bNumber;
      }

      return 0; // Equal type, and sorted by suffix number
    });
  } catch (error) {
    console.error("Error fetching IIoT data:", error);
    alert("Failed to fetch data. Please check the I/O Card Path.");
  }
};

// Adjusted sendDigitalOutput function in dresdeDataSources.js
export const sendDigitalOutput = async (alias, tagName, value) => {
  // Map alias to internal path
  const internalPath = mapAliasToInternalPath(alias); // e.g., "dresde"

  const url = "https://app.plain4.com/api/messaging/tag";
  const timestamp = new Date().toISOString();

  // Construct the path as "hmi.imepi.{alias}.pc1.{tagName}"
  const path = `hmi.imepi.${internalPath}.pc1.${tagName}`; // Ensure {tagName} is last part

  // Correctly structure the payload with path and value
  const payload = {
    datetime: timestamp,
    value: value,  // Correct value assignment
    path: path,    // Path with correct alias and tag name structure
    origin: "AuroraHMISCADA",
  };

  // Log the payload to verify
 // console.log("Payload for Digital Output:", payload);

  try {
    const response = await axios.post(url, JSON.stringify(payload), {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 201 || response.status === 204) {
     // console.log("Digital Output Update Successful");
    } else {
      console.error("Unexpected response status:", response.status);
    }
  } catch (error) {
    console.error("Error sending Digital Output:", error.message);
  }
};
