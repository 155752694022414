import { createStore } from 'vuex';

// Dictionary to map aliases to import paths
const dataSourceModules = {
  dresde: () => import('../views/dresdeDataSource.js'),
};
export default createStore({
  state: {
    token: localStorage.getItem('jwt') || null,
    ladderLogic: {
      diagramId: null,
    },
    digitalTwin: {
      vrScenarioId: null,
    },
    iiotDataSources: {
      selectedDiagramId: null,
      selectedVRScenarioId: null,
      variablesTable: [],
      variablesTableAV: [], // AV-related

      vrTagsTable: [],
      linkedAVTags: [],          // Array to store linked AV tags

    },
    selectedMenu: 'homepage',  // Add selectedMenu to the state
    toggledContact: null, // New property to store the toggled contact
    currentDataSource: null, // New property for holding the selected data source module

  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('jwt', token);
    },
    clearToken(state) {
      state.token = null;
      localStorage.removeItem('jwt');
    },
    setLadderLogicToRun(state, payload) {
      state.ladderLogic.diagramId = payload.diagramId;
    },
    setDigitalTwinToRun(state, payload) {
      state.digitalTwin.vrScenarioId = payload.vrScenarioId;
    },
    setIIoTDataSources(state, payload) {
      state.iiotDataSources = {
        ...state.iiotDataSources, // Spread existing state to preserve properties
        ...payload,               // Spread payload to update provided properties
      };
    },

  setSelectedDiagramId(state, value) {
    state.iiotDataSources.selectedDiagramId = value;
  },
  setSelectedVRScenarioId(state, value) {
    state.iiotDataSources.selectedVRScenarioId = value;
  },
  setVariablesTable(state, variablesTable) {
    state.iiotDataSources.variablesTable = variablesTable;
  },
// In your Vuex store mutations
  setVariablesTableAV(state, variablesTableAV) {
    state.iiotDataSources.variablesTableAV = [...variablesTableAV];
   // console.log("Updated variablesTableAV:", state.iiotDataSources.variablesTableAV);
  },

  

  setVRTagsTable(state, vrTagsTable) {
    state.iiotDataSources.vrTagsTable = vrTagsTable;
  },
    setSelectedMenu(state, menu) {  // Mutation to set selectedMenu
      state.selectedMenu = menu;
    }, 
    updateVrTagValue(state, payload) {
      const { tagName, value = null, preset, accumulated } = payload;      
    //  console.log(`Vuex - Received Update for VR Tag: ${tagName}, Value: ${value}, Preset: ${preset}, Accumulated: ${accumulated}`);
    
      const index = state.iiotDataSources.vrTagsTable.findIndex(t => t.name === tagName);
    
      if (index !== -1) {
        const vrTag = state.iiotDataSources.vrTagsTable[index];
    
        //console.log(`Vuex - Found VR Tag: ${vrTag.name}, Current Value: ${vrTag.value}`);
    
        const updatedTag = {
          ...vrTag,
          // Only update value if provided
          ...(value !== null ? { value: value } : {}),
        };
    
        // Check for Counter or Timer and update preset/accumulated
        if (vrTag.type === 'Counter' || vrTag.type === 'Timer') {
          updatedTag.preset = preset !== undefined && preset !== null ? preset : vrTag.preset || 0; // Fallback to 0 if undefined or null
          updatedTag.accumulated = accumulated !== undefined && accumulated !== null ? accumulated : vrTag.accumulated || 0; // Fallback to 0
          //console.log(`Vuex - Updating ${vrTag.type} with Preset: ${updatedTag.preset}, Accumulated: ${updatedTag.accumulated}`);
        }
    
        // Apply the update
        state.iiotDataSources.vrTagsTable.splice(index, 1, updatedTag);
        //console.log(`Vuex - VR Tag ${tagName} updated with new values.`);
      } //else {
        //console.log(`Vuex - VR Tag ${tagName} not found.`);
     // }
    },
    
    toggleContact(state, payload) {
      let { elementId, newState } = payload;
  
      // Convert newState to boolean
      newState = newState === true || newState === 'true' || newState === 1;
  
      const index = state.iiotDataSources.variablesTable.findIndex(variable => variable.id === elementId);
      if (index !== -1) {
        const variable = state.iiotDataSources.variablesTable[index];
  
        // Create a new object with the updated value
        const updatedVariable = { ...variable, value: newState };
  
        // Replace the object in the array
        state.iiotDataSources.variablesTable.splice(index, 1, updatedVariable);
  
        // Update toggledContacts for tracking multiple toggles
        if (!state.toggledContacts) {
          state.toggledContacts = [];
        }
        state.toggledContacts.push({ elementId, newState });
      }
    },
    resetToggledContacts(state) {
      state.toggledContacts = [];
    },
    
    
    setConveyorState(state, payload) {
      const index = state.iiotDataSources.vrTagsTable.findIndex(tag => tag.name === "Motor 1");
      if (index !== -1) {
        const vrTag = state.iiotDataSources.vrTagsTable[index];
    
        // Create a new object with the updated value
        const updatedVrTag = { ...vrTag, value: payload.running };
    
        // Replace the object in the array
        state.iiotDataSources.vrTagsTable.splice(index, 1, updatedVrTag);
      }
    },
    
    // Mutation to set the current data source module
    setCurrentDataSource(state, module) {
      state.currentDataSource = module;
    },
    setLinkedAVTags(state, linkedTags) {
      // Avoid updating if linkedTags are the same
      if (JSON.stringify(state.iiotDataSources.linkedAVTags) === JSON.stringify(linkedTags)) {
        return;
      }
      
      // Deep clone each linkedTag to avoid reactive dependency issues
      state.iiotDataSources.linkedAVTags = JSON.parse(JSON.stringify(linkedTags));
    //  console.log("Updated linked AV Tags (deep clone):", state.iiotDataSources.linkedAVTags);
    },
    
    
    updateIiotTagValue(state, { tagName, value }) {
      const tagIndex = state.iiotDataSources.variablesTableAV.findIndex((tag) => tag.id === tagName);
      if (tagIndex !== -1) {
        state.iiotDataSources.variablesTableAV[tagIndex].value = value;
      //  console.log(`Vuex Store - Updated Output Tag ${tagName} with Value: ${value}`);
      }
    },
    

    fetchIiotData(state, payload) {
      state.iiotDataSources.variablesTable = payload.variablesTable;
    },
    
  },
  actions: {
    login({ commit }, token) {
      commit('setToken', token);
    },
    logout({ commit }) {
      commit('clearToken');
    },
    setLadderLogicToRun({ commit }, payload) {
      commit('setLadderLogicToRun', payload);
    },
    setDigitalTwinToRun({ commit }, payload) {
      commit('setDigitalTwinToRun', payload);
    },
    setIIoTDataSources({ commit }, payload) {
      commit('setIIoTDataSources', payload);
    },
    setSelectedMenu({ commit }, menu) {  // Action to set selectedMenu
      commit('setSelectedMenu', menu);
    },
    toggleLadderLogicContact({ commit }, payload) {
      commit('toggleContact', payload);
    },
    setConveyorState({ commit }, payload) {
     // console.log("Action setConveyorState called with:", payload);
      commit('setConveyorState', payload);
    },
    updateVrTagValue({ commit }, payload) {
      commit('updateVrTagValue', payload);
    },
    // Action to load a data source module based on alias
    async loadDataSourceModule({ commit }, alias) {
      const loadModule = dataSourceModules[alias];
      if (!loadModule) {
        console.error(`No data source found for alias ${alias}`);
        return;
      }

      try {
        const module = await loadModule();
        commit("setCurrentDataSource", module); // Set the loaded module in the state
      } catch (error) {
        console.error(`Failed to load data source module for alias ${alias}:`, error);
      }
    },


  // Action to dispatch Digital Outputs to the currently selected data source
  sendDigitalOutput({ state }, { alias, tagName, value }) {
    if (state.currentDataSource && typeof state.currentDataSource.sendDigitalOutput === "function") {
      state.currentDataSource.sendDigitalOutput(alias, tagName, value);
    } else {
      console.warn("No valid data source module loaded or sendDigitalOutput function not found.");
    }
  },

    fetchIiotData({ commit }, payload) {
    commit('fetchIiotData', payload);
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    getLadderLogic: state => state.ladderLogic,
    getDigitalTwin: state => state.digitalTwin,
    getIIoTDataSources: (state) => state.iiotDataSources,
    getSelectedMenu: state => state.selectedMenu,  // Getter to get selectedMenu
    getLadderLogicState: (state) => state, // Return the current state, including toggled contacts
    getLinkedAVTags: (state) => state.iiotDataSources.linkedAVTags,


  }
});
