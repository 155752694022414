// avScenarios.js
import LC2030Level1 from './SBG-Level1.vue';
import LC2030Level2 from './SBG-Level2.vue';
import LC2030Level3 from './SBG-Level3.vue';
import LC2030Level4 from './SBG-Level4.vue';



import SBGLevel1Thumbnail from '../assets/SBG-Level-1.png';
import SBGLevel2Thumbnail from '../assets/SBG-Level-2.png';
import SBGLevel3Thumbnail from '../assets/SBG-Level-3.png';
import SBGLevel4Thumbnail from '../assets/SBG-Level-4.png';



export const avScenarios = [
  {
    id: 'sbg-level-1',
    name: 'LC2030 - Heating Cycle',
    component: LC2030Level1,
    thumbnail: SBGLevel1Thumbnail,
    project: 'SBG - 1',
    alias: 'SBG-LC2030-1',
    instructions: {
      title: "Exercise 1 - Starting the Heating Cycle in Tank T1",
      objective: {
        subtitle: "Objective:",
        description:
          "In this exercise, you will learn how to start the heating cycle in Tank T1 of the LC2030 system. You will create a ladder logic diagram to control Pump M4 and the heating coil Q1 using the push buttons and switches on the LC2030 panel. Additionally, you will implement safety measures and visual indicators using lamps P1 and P2 to ensure safe and clear operation."
      },
      equipmentOverview: {
        subtitle: "Equipment Overview:",
        description:
          "The LC2030 is a control engineering training workstation featuring two open water tanks (Tank T1 and Tank T2), pumps, sensors, a heating element, and indicator lamps. In this exercise, you will focus on the following components:\n\n- **Push Buttons and Switches**:\n  - **HS4 (D07)**: Start Pump M4 (momentary push button).\n  - **HS5 (D08)**: Stop Pump M4 (momentary push button).\n  - **HS1 (D04)**: Turn ON Heating Coil Q1 (on/off switch).\n  - **HS2 (D05)**: Turn OFF Heating Coil Q1 (on/off switch).\n- **Pumps and Heater**:\n  - **Pump M4 (C01)**: Circulates water in Tank T1.\n  - **Heating Coil Q1 (C04)**: Heats the water in Tank T1.\n- **Indicator Lamps**:\n  - **Lamp P1 (C05)**: Indicates when Pump M4 is running.\n  - **Lamp P2 (C06)**: Indicates when Heating Coil Q1 is active.\n- **Interlocks and Safety Measures**: You'll use interlock logic, a timer, and visual indicators to ensure safe operation of the pump and heater."
      },
      taskInstructions: {
        subtitle: "Task Instructions:",
        description:
          "Your task is to create a ladder logic diagram that controls Pump M4 and the heating coil Q1 using the LC2030's push buttons and switches. You'll implement interlock logic to maintain the state of the pump and heater, add safety measures to ensure the heater turns off when there is no water flow, and use lamps P1 and P2 to provide visual indicators of the system's status. Specifically, you will:\n\n- Start and stop Pump M4 using HS4 and HS5.\n- Start and stop Heating Coil Q1 using HS1 and HS2.\n- Ensure Heating Coil Q1 only operates when Pump M4 is running.\n- Use a TOF (Off-Delay) Timer to safely turn off Heating Coil Q1 after Pump M4 stops.\n- Use Lamp P1 to indicate when Pump M4 is running.\n- Use Lamp P2 to indicate when Heating Coil Q1 is active."
      },
      stepsToFollow: {
        subtitle: "Steps to Follow:",
        steps: [
          "**Rung 1: Control Pump M4 and Lamp P1**\n\n- Place a **one-shot positive contact** linked to **HS4 (D07)** to start **Pump M4 (C01)**.\n- Next to it, place a **one-shot negative contact** linked to **HS5 (D08)** to stop Pump M4.\n- Below the one-shot positive contact, add a branch with a **Normally Open (NO) contact** internally linked to **Pump M4's coil (C01)** for interlock logic. This ensures Pump M4 stays on after HS4 is released.\n- **Branch Below Pump M4 Coil**:\n  - Add another coil linked to **Lamp P1 (C05)**. This ensures Lamp P1 turns on whenever Pump M4 is running.",
          
          "**Rung 2: Control Heating Coil Q1 and Lamp P2**\n\n- Place a **one-shot positive contact** internally linked to **Pump M4's coil (C01)** to start **Heating Coil Q1 (C04)** when Pump M4 is running.\n- Next to it, add a **NC (Normally Closed) contact** linked to **HS2 (D05)** to allow the operator to stop the heater.\n- Next to the NC contact, add an **NO contact** linked to the **TOF Timer** (from Rung 3) for safety interlock.\n- Below the one-shot positive contact, add a branch with a **NO contact** linked to **HS1 (D04)** to allow the operator to start the heater.\n- Below the main rung, add another branch with a **NO contact** internally linked to **Heating Coil Q1's coil (C04)** for interlock logic, ensuring the heater stays on after being started.\n- **Branch Below Heating Coil Q1 Coil**:\n  - Add another coil linked to **Lamp P2 (C06)**. This ensures Lamp P2 turns on whenever Heating Coil Q1 is active.",
          
          "**Rung 3: Safety Timer Logic**\n\n- Place a **NO contact** internally linked to **Pump M4's coil (C01)** on the left.\n- Connect it to a **TOF (Off-Delay) Timer** set to a 2-second delay.\n- The TOF Timer output is linked back to the additional **NO contact** in Rung 2 for safety interlock.\n- This configuration ensures that when Pump M4 stops, the TOF Timer begins timing. After the 2-second delay, the NO contact opens, cutting power to **Heating Coil Q1 (C04)** and **Lamp P2 (C06)**, preventing them from operating without water flow.",
          
          "**Important Note**: Leaving the heating coil on without water flow is hazardous as it can cause overheating and damage to the system. The safety measures and visual indicators implemented ensure that the heating coil automatically turns off shortly after the pump stops and that operators are aware of the system's status."
        ]
      },
      keyConcept: {
        subtitle: "Key Concept: Safety Interlocks, Timer Functions, and Visual Indicators",
        description:
          "This exercise emphasizes the importance of safety and clear communication in control systems. Implementing interlock logic ensures that equipment operates only under safe conditions. The use of a **TOF (Off-Delay) Timer** provides a controlled delay to safely shut down the heating coil after the pump stops, preventing potential hazards like overheating. Visual indicators using **Lamps P1 and P2** provide immediate feedback on the status of critical components, enhancing operational safety and efficiency."
      },
      exampleOutline: {
        subtitle: "Example Ladder Logic Outline:",
        steps: [
          "**Rung 1: Pump M4 Control and Lamp P1 Indicator**\n\n- **One-Shot Positive Contact** (HS4/D07) —> **Pump M4 Coil (C01)**\n- **One-Shot Negative Contact** (HS5/D08) in series to stop Pump M4.\n- **Branch Below One-Shot Positive Contact**:\n  - **NO Contact** internally linked to **Pump M4 Coil (C01)** for interlock.\n- **Branch Below Pump M4 Coil**:\n  - **Coil** linked to **Lamp P1 (C05)** to indicate Pump M4 is running.",
          
          "**Rung 2: Heating Coil Q1 Control and Lamp P2 Indicator**\n\n- **One-Shot Positive Contact** internally linked to **Pump M4 Coil (C01)** —> **Heating Coil Q1 Coil (C04)**\n- **NC Contact** (HS2/D05) in series to allow operator to stop Heating Coil Q1.\n- **NO Contact** (from TOF Timer in Rung 3) in series for safety interlock.\n- **Branch Below One-Shot Positive Contact**:\n  - **NO Contact** (HS1/D04) to allow operator to start Heating Coil Q1.\n- **Branch Below Heating Coil Q1 Coil**:\n  - **NO Contact** internally linked to **Heating Coil Q1 Coil (C04)** for interlock.\n  - **Coil** linked to **Lamp P2 (C06)** to indicate Heating Coil Q1 is active.",
          
          "**Rung 3: Safety Timer Logic**\n\n- **NO Contact** internally linked to **Pump M4 Coil (C01)** —> **TOF Timer** (set to 2 seconds)\n- **TOF Timer Output** linked to **NO Contact** in Rung 2 for safety interlock.\n- When Pump M4 stops, the TOF Timer begins timing. After 2 seconds, the timer output deactivates the NO contact in Rung 2, de-energizing **Heating Coil Q1 Coil (C04)** and **Lamp P2 (C06)**."
        ]
      }
    }   
  },
   {
    id: 'sbg-level-2',
    name: 'LC2030 - Transfer T1 -> T2',
    component: LC2030Level2,
    thumbnail: SBGLevel2Thumbnail,
    project: 'SBG - 1',
    alias: 'SBG-LC2030-1',
    instructions: {
      title: "Exercise 2 - Transferring Water from Tank T1 to Tank T2 with Visual Indicators",
      objective: {
        subtitle: "Objective:",
        description:
          "In this exercise, you will learn how to transfer water from Tank T1 to Tank T2 using the LC2030 system. You will create a ladder logic diagram to control Pump M3, which moves water from Tank T1 to Tank T2. The exercise involves using push buttons to start and stop the pump and incorporating a level sensor to prevent the pump from running when Tank T1 reaches a low water level. Additionally, you will use lamps P1 and P2 as visual indicators to show when Pump M3 is active."
      },
      equipmentOverview: {
        subtitle: "Equipment Overview:",
        description:
          "The LC2030 is a control engineering training workstation featuring two open water tanks (Tank T1 and Tank T2), pumps, sensors, and indicator lamps. In this exercise, you will focus on the following components:\n\n- **Push Buttons**:\n  - **HS4 (D07)**: Start Pump M3 (momentary push button).\n  - **HS5 (D08)**: Stop Pump M3 (momentary push button).\n- **Sensors**:\n  - **Level Switch Low (B3/D03)**: Normally closed sensor that opens when Tank T1 reaches a low water level.\n- **Pump**:\n  - **Pump M3 (C03)**: Transfers water from Tank T1 to Tank T2.\n- **Indicator Lamps**:\n  - **Lamp P1 (C05)**: Indicates when Pump M3 is running.\n  - **Lamp P2 (C06)**: Provides an additional visual indicator, if needed for extended process status.\n- **Interlocks**: You'll use interlock logic to maintain the state of the pump and ensure safe operation based on the tank's water level."
      },
      taskInstructions: {
        subtitle: "Task Instructions:",
        description:
          "Your task is to create a ladder logic diagram that controls Pump M3 using the LC2030's push buttons. You'll implement interlock logic to maintain the pump's state after the start button is released and use the Level Switch Low sensor to prevent the pump from operating when Tank T1 reaches a low water level. Additionally, you will add visual indicators using lamps P1 and P2 to show when Pump M3 is active. Specifically, you will:\n\n- Start and stop Pump M3 using HS4 and HS5.\n- Use the Level Switch Low sensor to automatically stop Pump M3 when Tank T1 is low on water.\n- Implement interlock logic to keep Pump M3 running after starting it, until it is stopped manually or by the low-level sensor.\n- Add lamps P1 and P2 as indicators for Pump M3’s operation."
      },
      stepsToFollow: {
        subtitle: "Steps to Follow:",
        steps: [
          "**Preparation**:\n\n- Ensure that Tank T1 is completely filled with water before starting the exercise.",
          
          "**Rung 1: Control Pump M3 and Lamp P1**\n\n- Place a **one-shot positive contact** linked to **HS4 (D07)** to start **Pump M3 (C03)**.\n- Next to it, place a **one-shot negative contact** linked to **HS5 (D08)** to stop Pump M3.\n- Next in series, add a **Normally Closed (NC) contact** linked to **Level Switch Low (B3/D03)**. This contact will open when Tank T1 reaches a low water level, stopping the pump automatically.\n- At the end of the rung, place the **coil** for **Pump M3 (C03)**.\n- **Branch Below One-Shot Positive Contact**:\n  - Add a **Normally Open (NO) contact** internally linked to **Pump M3's coil (C03)** for interlock logic. This ensures Pump M3 stays on after HS4 is released, as long as the level in Tank T1 is sufficient.\n- **Branch Below Pump M3 Coil**:\n  - Add a coil linked to **Lamp P1 (C05)** to indicate when Pump M3 is running.\n- **Branch Below Lamp P1 Coil**:\n  - Add another coil linked to **Lamp P2 (C06)** to provide additional process status indication if needed.",
          
          "**Operation Logic**:\n\n- When **HS4** is pressed, the one-shot positive contact will momentarily close, energizing **Pump M3's coil (C03)**.\n- The interlock **NO contact** below will close because the coil is energized, maintaining the circuit even after **HS4** is released.\n- **HS5** can be used to stop the pump at any time by opening the one-shot negative contact.\n- If Tank T1's water level drops to the low level, the **NC contact** linked to **Level Switch Low (B3/D03)** will open, de-energizing **Pump M3** to prevent damage or unsafe conditions.\n- **Lamp P1** will illuminate to indicate when Pump M3 is active, while **Lamp P2** can be used as an additional indicator for extended status monitoring."
        ]
      },
      keyConcept: {
        subtitle: "Key Concept: Interlocks, Safety with Level Sensors, and Visual Indicators",
        description:
          "This exercise highlights the use of interlock logic and safety mechanisms in controlling pumps. By integrating a level sensor, you ensure that the pump operates only when there is enough water in Tank T1, preventing potential damage due to dry running. Interlocks maintain the pump's operation without requiring continuous input from the operator, enhancing efficiency and safety. Visual indicators using **Lamps P1 and P2** provide immediate feedback on pump status, improving operational awareness."
      },
      exampleOutline: {
        subtitle: "Example Ladder Logic Outline:",
        steps: [
          "**Rung 1: Pump M3 Control with Level Sensor Safety and Lamp Indicators**\n\n- **One-Shot Positive Contact** (HS4/D07)\n- **One-Shot Negative Contact** (HS5/D08) in series to allow manual stopping of Pump M3.\n- **Normally Closed (NC) Contact** (Level Switch Low B3/D03) in series to prevent operation when Tank T1 is low.\n- **Coil**: Pump M3 (C03) at the end of the rung.\n- **Branch Below One-Shot Positive Contact**:\n  - **NO Contact** internally linked to **Pump M3's coil (C03)** for interlock.\n- **Branch Below Pump M3 Coil**:\n  - **Coil** linked to **Lamp P1 (C05)** to indicate Pump M3 is running.\n- **Branch Below Lamp P1 Coil**:\n  - **Coil** linked to **Lamp P2 (C06)** to provide additional process status indication.",
          
          "**Operation Flow**:\n\n- Pressing **HS4** starts **Pump M3**.\n- **Pump M3** continues running due to interlock **NO contact**.\n- Pump can be stopped manually using **HS5**.\n- If Tank T1 reaches low water level, **Level Switch Low** opens, stopping **Pump M3** automatically.\n- **Lamp P1** and **Lamp P2** provide visual indicators to show the status of **Pump M3**."
        ]
      }
    }
    
    
  },
  {
    id: 'sbg-level-3',
    name: 'LC2030 - Transfer T2 -> T1',
    component: LC2030Level3,
    thumbnail: SBGLevel3Thumbnail,
    project: 'SBG - 1',
    alias: 'SBG-LC2030-1',
    instructions: {
      title: "Exercise 3 - Transferring Water from Tank T2 to Tank T1 with Dual Pump Control, Safety Interlocks, and Visual Indicators",
      objective: {
        subtitle: "Objective:",
        description:
          "In this exercise, you will learn how to transfer water from Tank T2 to Tank T1 using two pumps (M1 and M2) in the LC2030 system. You will create a ladder logic diagram to control the pumps, ensuring efficient transfer while incorporating safety measures. Both pumps will operate together initially to expedite the transfer. When Tank T1 reaches a medium water level, Pump M2 will stop automatically, and Pump M1 will continue until Tank T1 is full. Safety interlocks will prevent Pump M2 from operating if Pump M1 stops unexpectedly. Additionally, you will use lamps P1 and P2 as visual indicators to show when each pump is running."
      },
      equipmentOverview: {
        subtitle: "Equipment Overview:",
        description:
          "The LC2030 is a control engineering training workstation featuring two open water tanks (Tank T1 and Tank T2), pumps, sensors, and indicator lamps. In this exercise, you will focus on the following components:\n\n- **Push Buttons**:\n  - **HS4 (D07)**: Start Pumps M1 and M2 (momentary push button).\n  - **HS5 (D08)**: Stop Pumps M1 and M2 (momentary push button).\n- **Sensors**:\n  - **Level Switch High (B1/D01)**: Normally closed sensor that opens when Tank T1 reaches a high water level.\n  - **Level Switch Medium (B2/D02)**: Normally closed sensor that opens when Tank T1 reaches a medium water level.\n- **Pumps**:\n  - **Pump M1 (C01)**: Primary pump transferring water from Tank T2 to Tank T1.\n  - **Pump M2 (C02)**: Secondary pump operating alongside Pump M1 to increase transfer speed.\n- **Indicator Lamps**:\n  - **Lamp P1 (C05)**: Indicates when Pump M1 is running.\n  - **Lamp P2 (C06)**: Indicates when Pump M2 is running.\n- **Interlocks and Safety Measures**: You'll implement interlock logic and a safety timer to ensure that Pump M2 stops if Pump M1 stops unexpectedly."
      },
      taskInstructions: {
        subtitle: "Task Instructions:",
        description:
          "Your task is to create a ladder logic diagram that controls Pumps M1 and M2 to transfer water from Tank T2 to Tank T1. You'll use push buttons to start and stop the pumps, level sensors to control pump operation based on Tank T1's water level, and safety interlocks to prevent Pump M2 from running without Pump M1. Additionally, you will add visual indicators using lamps P1 and P2 to show when each pump is running. Specifically, you will:\n\n- Start both Pumps M1 and M2 using HS4.\n- Stop both pumps using HS5.\n- Use Level Switch High (B1) to stop Pump M1 when Tank T1 is full.\n- Use Level Switch Medium (B2) to stop Pump M2 when Tank T1 reaches a medium level.\n- Implement safety logic to ensure Pump M2 stops if Pump M1 stops unexpectedly.\n- Add lamps P1 and P2 as indicators for Pumps M1 and M2, respectively."
      },
      stepsToFollow: {
        subtitle: "Steps to Follow:",
        steps: [
          "**Preparation**:\n\n- Ensure that Tank T2 is completely filled with water before starting the exercise.\n- Verify that Tank T1's Level Switch High (B1) is not activated (Tank T1 is not full).",
          
          "**Rung 1: Control Pump M1 and Lamp P1**\n\n- Place a **one-shot positive contact** linked to **HS4 (D07)** to start **Pump M1 (C01)**.\n- Next to it, place a **one-shot negative contact** linked to **HS5 (D08)** to stop Pump M1.\n- Next in series, add a **Normally Closed (NC) contact** linked to **Level Switch High (B1/D01)**. This contact opens when Tank T1 is full, stopping Pump M1 automatically.\n- At the end of the rung, place the **coil** for **Pump M1 (C01)**.\n- **Branch Below One-Shot Positive Contact**:\n  - Add a **Normally Open (NO) contact** internally linked to **Pump M1's coil (C01)** for interlock logic. This ensures Pump M1 stays on after HS4 is released, as long as Tank T1 is not full.\n- **Branch Below Pump M1 Coil**:\n  - Add a coil linked to **Lamp P1 (C05)** to indicate when Pump M1 is running.",
          
          "**Rung 2: Control Pump M2 and Lamp P2**\n\n- Place a **one-shot positive contact** internally linked to **Pump M1's coil (C01)**. This ensures Pump M2 can only start if Pump M1 is running.\n- Next to it, add a **Normally Closed (NC) contact** linked to **Level Switch Medium (B2/D02)**. This contact opens when Tank T1 reaches a medium water level, stopping Pump M2 automatically.\n- Next in series, add a **Normally Open (NO) contact** linked to the **TOF Timer** (from Rung 3) for safety interlock.\n- At the end of the rung, place the **coil** for **Pump M2 (C02)**.\n- **Branch Below One-Shot Positive Contact**:\n  - Add a **NO contact** internally linked to **Pump M2's coil (C02)** for interlock logic.\n- **Branch Below Pump M2 Coil**:\n  - Add a coil linked to **Lamp P2 (C06)** to indicate when Pump M2 is running.",
          
          "**Rung 3: Safety Timer Logic for Pump M2**\n\n- Place a **NO contact** internally linked to **Pump M1's coil (C01)** on the left.\n- Connect it to a **TOF (Off-Delay) Timer** set to a 1-second delay.\n- The TOF Timer output is linked back to the **NO contact** in Rung 2 for safety interlock.\n- This configuration ensures that if Pump M1 stops, Pump M2 will stop after a 1-second delay, preventing Pump M2 from operating alone.",
          
          "**Operation Logic**:\n\n- Pressing **HS4** starts **Pump M1**. The interlock keeps it running.\n- The start of **Pump M1** triggers the one-shot positive contact in Rung 2, starting **Pump M2**.\n- **Pump M2** continues running due to its interlock.\n- **HS5** can be used to stop both pumps manually.\n- When Tank T1 reaches medium level, **Level Switch Medium (B2)** opens, stopping **Pump M2**.\n- **Pump M1** continues to run until Tank T1 is full, at which point **Level Switch High (B1)** opens, stopping **Pump M1**.\n- If **Pump M1** stops unexpectedly, the safety timer ensures **Pump M2** stops after 1 second.\n- **Lamp P1** will indicate when **Pump M1** is running, and **Lamp P2** will indicate when **Pump M2** is running."
        ]
      },
      keyConcept: {
        subtitle: "Key Concept: Coordinated Control, Safety Interlocks, and Visual Indicators in Multi-Pump Systems",
        description:
          "This exercise demonstrates the importance of coordinated control in systems using multiple pumps. By using interlocks and safety timers, you ensure that secondary pumps operate only when primary pumps are running, preventing unsafe conditions. Level sensors provide automatic control based on the tank's water level, enhancing efficiency and preventing overfilling. Visual indicators using **Lamps P1 and P2** provide immediate feedback on the status of each pump, improving operational safety and awareness."
      },
      exampleOutline: {
        subtitle: "Example Ladder Logic Outline:",
        steps: [
          "**Rung 1: Pump M1 Control with Level Sensor and Lamp P1 Indicator**\n\n- **One-Shot Positive Contact** (HS4/D07)\n- **One-Shot Negative Contact** (HS5/D08) in series to allow manual stopping of Pump M1.\n- **Normally Closed (NC) Contact** (Level Switch High B1/D01) in series to prevent overfilling Tank T1.\n- **Coil**: Pump M1 (C01) at the end of the rung.\n- **Branch Below One-Shot Positive Contact**:\n  - **NO Contact** internally linked to **Pump M1's coil (C01)** for interlock.\n- **Branch Below Pump M1 Coil**:\n  - **Coil** linked to **Lamp P1 (C05)** to indicate Pump M1 is running.",
          
          "**Rung 2: Pump M2 Control with Level Sensor, Safety Interlock, and Lamp P2 Indicator**\n\n- **One-Shot Positive Contact** internally linked to **Pump M1's coil (C01)** to start Pump M2 only when Pump M1 is running.\n- **Normally Closed (NC) Contact** (Level Switch Medium B2/D02) in series to stop Pump M2 when Tank T1 reaches medium level.\n- **NO Contact** (from TOF Timer in Rung 3) in series for safety interlock.\n- **Coil**: Pump M2 (C02) at the end of the rung.\n- **Branch Below One-Shot Positive Contact**:\n  - **NO Contact** internally linked to **Pump M2's coil (C02)** for interlock.\n- **Branch Below Pump M2 Coil**:\n  - **Coil** linked to **Lamp P2 (C06)** to indicate Pump M2 is running.",
          
          "**Rung 3: Safety Timer Logic for Pump M2**\n\n- **NO Contact** internally linked to **Pump M1's coil (C01)** —> **TOF Timer** (set to 1 second)\n- **TOF Timer Output** linked to **NO Contact** in Rung 2 for safety interlock.\n- If Pump M1 stops, the TOF Timer begins timing. After 1 second, the timer output deactivates the NO contact in Rung 2, de-energizing **Pump M2's coil (C02)**.",
          
          "**Operation Flow**:\n\n- Start both pumps using **HS4**.\n- Pumps continue running due to interlocks.\n- **Pump M2** stops when Tank T1 reaches medium level (**B2** opens).\n- **Pump M1** stops when Tank T1 is full (**B1** opens).\n- If **Pump M1** stops unexpectedly, **Pump M2** stops after a 1-second delay due to the safety timer.\n- **Lamp P1** indicates when **Pump M1** is running, and **Lamp P2** indicates when **Pump M2** is running."
        ]
      }
    }
    
  },
  {
    id: 'sbg-level-4',
    name: 'LC2030 - Full Cycle',
    component: LC2030Level4,
    thumbnail: SBGLevel4Thumbnail,
    project: 'SBG - 1',
    alias: 'SBG-LC2030-1',
    instructions: {
      title: "Exercise 4 - Integrated Process Control with Start/Stop Buttons",
      objective: {
        subtitle: "Objective:",
        description:
          "In this exercise, you will integrate the ladder logic diagrams from Exercises 1, 2, and 3 to create a comprehensive control system for the LC2030. You will design a ladder logic diagram that simultaneously handles the heating process, transferring water from Tank T1 to Tank T2, and recirculating water from Tank T2 back to Tank T1. Due to limited buttons on the LC2030 panel, you will implement a single start button and a single stop button to control all processes simultaneously."
      },
      equipmentOverview: {
        subtitle: "Equipment Overview:",
        description:
          "The LC2030 is a control engineering training workstation featuring two open water tanks (Tank T1 and Tank T2), pumps, sensors, a heating element, and indicator lamps. In this exercise, you will focus on the following components:\n\n- **Push Buttons**:\n  - **HS4 (D07)**: Start Process (momentary push button).\n  - **HS5 (D08)**: Stop Process (momentary push button).\n- **Processes**:\n  - **Heating Process**: Controls Pump M4 and Heating Coil Q1.\n  - **Transfer from T1 to T2**: Controls Pump M3.\n  - **Recirculation from T2 to T1**: Controls Pumps M1 and M2.\n- **Interlocks and Safety Measures**: You'll implement interlock logic and safety features as in previous exercises."
      },
      taskInstructions: {
        subtitle: "Task Instructions:",
        description:
          "Your task is to create a ladder logic diagram that combines the logic from Exercises 1, 2, and 3. You will use a single start button to initiate all processes simultaneously and a single stop button to halt them. Specifically, you will:\n\n- Use **HS4 (D07)** as the Start Process button.\n- Use **HS5 (D08)** as the Stop Process button.\n- Implement a **Start Process Coil** that is interlocked and internally linked to the one-shot positive contacts for the heating process, transfer from T1 to T2, and recirculation from T2 to T1.\n- Implement a **Stop Process Coil** that is interlocked and internally linked to the one-shot negative contacts for all processes.\n- Ensure that safety interlocks and visual indicators from previous exercises are incorporated."
      },
      stepsToFollow: {
        subtitle: "Steps to Follow:",
        steps: [
          "**Rung 1: Start Process Control**\n\n- Place a **one-shot positive contact** linked to **HS4 (D07)** to energize the **Start Process Coil**.\n- At the end of the rung, place the **Start Process Coil**.\n- **Branch Below One-Shot Positive Contact**:\n  - Add a **Normally Open (NO) contact** internally linked to the **Start Process Coil** for interlock logic. This ensures the Start Process Coil remains energized after HS4 is released.",
          
          "**Rung 2: Stop Process Control**\n\n- Place a **one-shot positive contact** linked to **HS5 (D08)** to energize the **Stop Process Coil**.\n- At the end of the rung, place the **Stop Process Coil**.\n- **Branch Below One-Shot Positive Contact**:\n  - Add a **Normally Open (NO) contact** internally linked to the **Stop Process Coil** for interlock logic. This ensures the Stop Process Coil remains energized after HS5 is released.",
          
          "**Process Integration**:\n\n- **Modify Heating Process (from Exercise 1):**\n  - Replace the original start contacts with a **contact** linked to the **Start Process Coil** to initiate the heating process.\n  - Replace the original stop contacts with a **contact** linked to the **Stop Process Coil** to stop the heating process.\n- **Modify Transfer from T1 to T2 (from Exercise 2):**\n  - Replace the original start contacts with a **contact** linked to the **Start Process Coil** to start Pump M3.\n  - Replace the original stop contacts with a **contact** linked to the **Stop Process Coil** to stop Pump M3.\n- **Modify Transfer from T2 to T1 (from Exercise 3):**\n  - Replace the original start contacts with a **contact** linked to the **Start Process Coil** to start Pumps M1 and M2.\n  - Replace the original stop contacts with a **contact** linked to the **Stop Process Coil** to stop Pumps M1 and M2.\n- **Ensure all safety interlocks, timers, and visual indicators** are maintained as per the original exercises.",
          
          "**Operation Logic**:\n\n- Pressing **HS4** energizes the **Start Process Coil**, starting all processes simultaneously.\n- The interlock keeps the **Start Process Coil** energized after the button is released.\n- Pressing **HS5** energizes the **Stop Process Coil**, stopping all processes simultaneously.\n- The interlock keeps the **Stop Process Coil** energized after the button is released, ensuring all processes remain stopped until restarted.\n- All safety features and visual indicators function as previously defined, providing safe and efficient operation."
        ]
      },
      keyConcept: {
        subtitle: "Key Concept: Process Synchronization and Efficient Control",
        description:
          "This exercise demonstrates how to synchronize multiple processes using minimal input controls. By integrating the start and stop functions into single buttons, operators can efficiently manage complex systems. This approach simplifies the control interface, reduces the potential for operator error, and maintains all necessary safety interlocks and visual indicators. It highlights the importance of efficient control strategies in automation and process engineering."
      },
      exampleOutline: {
        subtitle: "Example Ladder Logic Outline:",
        steps: [
          "**Rung 1: Start Process Control**\n\n- **One-Shot Positive Contact** (HS4/D07) —> **Start Process Coil**\n- **Branch Below One-Shot Positive Contact**:\n  - **NO Contact** internally linked to **Start Process Coil** for interlock.",
          
          "**Rung 2: Stop Process Control**\n\n- **One-Shot Positive Contact** (HS5/D08) —> **Stop Process Coil**\n- **Branch Below One-Shot Positive Contact**:\n  - **NO Contact** internally linked to **Stop Process Coil** for interlock.",
          
          "**Process Control Modifications**:\n\n- **Heating Process Control (Modified):**\n  - Replace original start contact with **NO Contact** linked to **Start Process Coil**.\n  - Replace original stop contact with **NO Contact** linked to **Stop Process Coil**.\n  - Retain all safety interlocks, timers, and visual indicators (e.g., Pump M4 control, Heating Coil Q1 control, Lamps P1 and P2).\n\n- **Transfer T1 to T2 Control (Modified):**\n  - Replace original start contact with **NO Contact** linked to **Start Process Coil**.\n  - Replace original stop contact with **NO Contact** linked to **Stop Process Coil**.\n  - Retain interlock logic, level sensor safety, and visual indicators (e.g., Pump M3 control, Lamp P1).\n\n- **Transfer T2 to T1 Control (Modified):**\n  - Replace original start contact with **NO Contact** linked to **Start Process Coil**.\n  - Replace original stop contact with **NO Contact** linked to **Stop Process Coil**.\n  - Retain interlock logic, safety timers, level sensor controls, and visual indicators (e.g., Pumps M1 and M2 control, Lamps P1 and P2)."
        ]
      }
    }
    
  },

];
